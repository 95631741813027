import { Component, OnInit, OnDestroy, HostListener, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Subscription,merge, Subject, interval } from 'rxjs';
import { MustMatch } from '../_helpers/must-match.validator';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { Autre, Deccomptabilite } from '../models/dec-comptabilite';
import { AlertService } from '../_helpers/alert.service';
import Swal from 'sweetalert2';
import { ComponentCanDeactivate  } from '../services/component-can-deactivate';
import { CommunService } from '../services/commun';
import { Decfiscmens } from '../models/dec-fisc-mens';

import { CarouselService } from '../services/settings';
import { UsedressourceService } from '../services/usedressource.service';
import { Usedressourcemodel } from '../models/usedressource.model';

@Component({
  selector: 'app-modify-deccomptabilite',
  templateUrl: './modify-deccomptabilite.component.html',
  styleUrls: ['./modify-deccomptabilite.component.scss']
})
// @ts-ignore
export class ModifyDeccomptabiliteComponent extends ComponentCanDeactivate implements OnInit,OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
this.ngOnDestroy()
}
  fileUploaded = false;
  uploadFilesautre3: File[] = [];
  uploadFilesautre5: File[] = [];
  uploadFilesautre6: File[] = [];
  deccomptabilite:Deccomptabilite;
  private deccomptabilitesSub: Subscription;
  isLoggedIn=false
  loading=false;
  showeditionnote=false;
  showrecettejour=false
  showrelevemanuel=false;
  showrelevejoint=false
  showpaiemanuel=false
  showcatab=false
  showachattab=false
  showbanquetab=false
  showsalairetab=false
  showinvoiceform=false
  showdocjoint3=false
  showdocjoint5=false
  showdocjoint6=false

  errormsg:string;
  natureactivite:string;
  activite:string;
  sousactivite:string;
  regimefiscalimpot:string;
  regimefiscaltva:string;
  matriculefiscale:string;
  currentUser: User;
  user:User;
  choixfacture:string;
  option1Value:any
  option2Value:any
  option3Value=false
  option4Value=false
  option5Value=false
  option6Value=false
  option7Value:string
  option8Value:any
  option9Value:any
  option10Value:any
  option11Value:any
  totalht=0.000
  totaltva=0.000
  realtotaltva=0.000
  totaldt=0.000
  totalttc=0.000
  totalht2=0.000
  totaltva2=0.000
  totaldt2=0.000
  totalttc2=0.000
  totalrecette=0.000
  totalht3=0.000
  totaltva3=0.000
  totaldt3=0.000
  totalttc3=0.000
  totaldebit=0.000
  totalcredit=0.000
  totalsoldemois=0.000
  totaldebitdevise=0.000
  totalcreditdevise=0.000
  totalsoldemoisdevise=0.000
  totalsalairebrut=0.000
  totalcnss=0.000
  totalsalaireimposable=0.000
  totalretenueimpot=0.000
  totalavancepret=0.000
  totalsalairenet=0.000
realht1=0.000
realdt1=0.000
realht2=0.000
realdt2=0.000
realht3=0.000
realtva3=0.000
realsalairebrut6=0.000
realsalaireimposable6=0.000
realretenue6=0.000
tfpapayer=0.000
foprolosapayer=0.000
totalhtexo=0.000
  totaltvaexo=0.000
  totalhtsus=0.000
  totaltvasus=0.000
  totalhtfodec=0.000
  totaltvafodec=0.000
  totalhttpe=0.000
  totaltvatpe=0.000
  totalhtexp=0.000
  totaltvaexp=0.000
  realtotaltvaexo=0.000
  realtotaltvasus=0.000
  realtotaltvatpe=0.000
  realtotaltvafodec=0.000

  realtotaltvaexp=0.000
  totalttcexo=0.000
  totalttcsus=0.000
  totalttctpe=0.000
  totalttcfodec=0.000

  totalttcexp=0.000
  totalht2exo=0.000
  totaltva2exo=0.000
  totalht2sus=0.000
  totaltva2sus=0.000

  totalht2fodec=0.000
  totaltva2fodec=0.000

  totalht2tpe=0.000
  totaltva2tpe=0.000
  totalht2exp=0.000
  totaltva2exp=0.000
  totalttc2exo=0.000
  totalttc2sus=0.000

  totalttc2tpe=0.000


  totalttc2fodec=0.000

  totalttc2exp=0.000
  totalrecette2=0.000;
  totalrecette2exo=0.000;
  totalrecette2sus=0.000;

  totalrecette2fodec=0.000;
  totalrecette2tpe=0.000;

  totalrecette2exp=0.000;
  totalrecetteexo=0.000
  totalrecettesus=0.000

  totalrecettetpe=0.000

  totalrecettefodec=0.000

  totalrecetteexp=0.000
  realht1exo=0.000
realht1sus=0.000

realht1fodec=0.000


realht1tpe=0.000

realht1exp=0.000
realht2exo=0.000
realht2sus=0.000

realht2tpe=0.000

realht2fodec=0.000

realht2exp=0.000
  editionnoteform: FormGroup;
  public ammounts: FormArray;
  editionnotepastform: FormGroup;
  public ammounts0: FormArray;
  recettejournaliereform: FormGroup;
  public ammounts2: FormArray;
  factureachatform: FormGroup;
  public ammounts3: FormArray;
  relevemanuelform: FormGroup;
  public skills: FormArray;
  relevejointform: FormGroup;
  public ammounts5: FormArray;
  salaireform: FormGroup;
  public ammounts6: FormArray;
  factureachatchequeform: FormGroup;
  public ammounts8: FormArray;
  facturefournisseurform: FormGroup;
  public ammounts9: FormArray;
  ammounts10:FormArray

  factureachatespeceform: FormGroup;
  public ammounts11: FormArray;
  private destroyed$ = new Subject<void>();
  tauxtva=0.07;
  tauxdt:any;
  specialite: string;
  totalht219=0.000;
  totaltva219=0.000;
  totaldt219=0.000;
  totalttc219=0.000;
  totalht3devise=0.000;

  totalrecette19=0.000;
  realht219=0.000;
  totalht19=0.000;
  totaltva19=0.000;
  totaldt19=0.000;
  totalttc19=0.000;
  realht119=0.000;
  realdt219=0.000;
  realdt119=0.000;
  realht19=0.000;
  realdt19=0.000;
  totalht019=0.000;
  totaltva019=0.000;
  totaldt019=0.000;
  totalttc019=0.000;
  realht019=0.000;
  realdt019=0.000;
  totalht0=0.000
  totaltva0=0.000
  realtotaltva0=0.000
  totaldt0=0.000
  totalttc0=0.000
  realht0=0.000
realdt0=0.000
  role: string;
  usertype: string;
  option204Value: number;
  nature: string;
  alldeccomptabilites: Deccomptabilite[];
  clients=[];
  fournisseurs=[];
  natures=[];
  settedclients=[];
  settednatures=[];
  settedfournisseurs=[];
  settedobjetsdebit=[];
  settedobjetscredit=[];
  numeros=[];
  filtrednumeros=[];
  banques=[];
  settedbeneficiaires=[];
  beneficiaires=[];

  objets=['règlement client','blocage','déblocage','impayé client','règlement impayé','remise chèque','règlement impayé client','règlement fournisseur','commission et agios','alimentation caisse','règlement salaire','règlement impot','règlement cnss'];
  objetsdebit=['alimentation caisse','commission et agios',
  'règlement cnss','règlement fournisseur','règlement impot','règlement salaire','blocage','impayé client'];
  objetscredit=['déblocage','règlement impayé','remise chèque',];
  settednumeros=[];
  settedbanques=[];
  settedobjets=[];
  totaldebitbis: number;
  totalcreditbis: number;
  totaldebitbischange: number;
  totalcreditbischange: number;
  settings=[];
  cours=[];
  displaysalaire="none";
  ajoutsalarieform: FormGroup;
  naturescontrat=[];
  settednaturecontrats=[];
  public annees: any[]=["2021","2022","2023","2024"];
  public anneesmbs: any[]=["2013","2014","2015","2016","2017","2018","2019","2020"];

  etatscivil=['marié','célibataire','divorcé','veuf'];
  sexes=['masculin','féminin'];
  typessalaires=['Paie normal','Prime','Rémunération'];
  settedtypessalaires=[];
  ammounts7:FormArray
  displaycop="none";
  filtredcops=[];
  ajoutdccopform: FormGroup;
  periodicites=['Annuelle','Trimestrielle','Bimensuelle','Semestrielle','Mensuelle'];
  settedperiodicites: string[]=['Annuelle','Trimestrielle','Bimensuelle','Semestrielle','Mensuelle'];
showmaquettesyndic=false
  option0Value: any;
  contientnumeroother:boolean;
  showbuttonotheretatrecette:boolean;
  contientnumero:boolean;
  settedcopobjets=[]
  settedcopnatures=[]
  copobjets=['frais syndic', 'commande sous sol', 'badges d\'entrée', 'remboursement de frais']
  copnatures=['espèce', 'chèque', 'traite', 'virement', 'carte de credit']
  showeditionnotepast=false;
  forassiette=false;
  option12Value: boolean;
  option13Value: boolean;
  totalht8=0.000;
  totaltva8=0.000;
  totaldt8=0.000;
  totalttc8=0.000;
  realht8=0.000;
  realtva8=0.000;
  totalht9=0.000;
  totaltva9=0.000;
  totaldt9=0.000;
  totalttc9=0.000;
  realht9=0.000;
  realtva9=0.000;
  totalht11=0.000;
  totaltva11=0.000;
  totaldt11=0.000;
  totalttc11=0.000;
  realht11=0.000;
  realtva11=0.000;
  uploadFilesautre8:File[] = [];
  totalht8devise: number;
  uploadFilesautre9: File[] = [];
  totalht9devise: number;
  uploadFilesautre11: File[] = [];
  totalht11devise: number;
  option14Value: any;
  showachatchequetab= false;
  showfacturefournisseurtab=false;
  showachatespecetab=false;
  intervalSub: Subscription;
  countdown=0;
  firsttimer: number;
  tva19=false;
  tvasuspens=false;

  tvafodec=false;

  tvatpe=false;



  tvaexonere=false;
  tvaexport=false;
  tva7=false;
  tva13=false;
  realht213= 0.000;
  totalht213= 0.000;
  totaltva213= 0.000;
  totalttc213= 0.000;
  totalrecette13= 0.000;
  totalht13= 0.000;
  totaltva13= 0.000;
  totalttc13= 0.000;
  realht113= 0.000;
  realdt113= 0.000;
  totaldt13= 0.000;
  totalht013= 0.000;
  totaltva013= 0.000;
  totalttc013= 0.000;
  realht013= 0.000;
  realdt013= 0.000;
  totaldt013= 0.000;
  totaldt213= 0.000;
  realdt213= 0.000;
  constructor(
    private usedres: UsedressourceService,
    private DecfiscmensService :DecfiscmensService,
    private fb: FormBuilder,
    private userservice: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private deccompt: DeccomptabiliteService,
    private auth: AuthService,
    private tokenStorage: TokenStorageService,private settservice: CarouselService,
    private alertService: AlertService,private commun: CommunService) {
      super();
      this.editionnotepastform = this.fb.group({
        ammounts0: this.fb.array([ this.createammount0() ])
     });
      this.editionnoteform = this.fb.group({
        ammounts: this.fb.array([ this.createammount() ])
     });
     
     this.recettejournaliereform = this.fb.group({
      ammounts2: this.fb.array([ this.createammount2() ])
   });
   this.factureachatform = this.fb.group({
    ammounts3: this.fb.array([ this.createammount3() ])
  });
  this.relevejointform = this.fb.group({
    ammounts5: this.fb.array([ this.createammount5() ])
  });
  this.factureachatchequeform = this.fb.group({
    ammounts8: this.fb.array([ this.createammount8() ])
  });
  this.facturefournisseurform = this.fb.group({
    ammounts9: this.fb.array([ this.createammount9() ])
  });
  this.ajoutdccopform = this.fb.group({
    ammounts10: this.fb.array([ this.createammount10() ])
  });
  this.factureachatespeceform = this.fb.group({
    ammounts11: this.fb.array([ this.createammount11() ])
  });
  /*this.salaireform = this.fb.group({
    ammounts6: this.fb.array([ this.createammount6() ])
  });*/

    }

  ngOnInit() {
    this.loading = true;
    this.currentUser = this.tokenStorage.getUser();
    this.role=this.currentUser.role
    this.tokenStorage.saved=false;
    this.tauxdt=0.600
    this.naturescontrat.push(
      {
        natcontrat:"CDD",
      }
    )
    this.naturescontrat.push(
      {
        natcontrat:"CDI",
      }
    )
    this.naturescontrat.push(
      {
        
        natcontrat:"CIVP",
      }
    )
    this.naturescontrat.push(
      {
      
        natcontrat:"Karama"
      }
    )
    this.ajoutsalarieform =this.fb.group({
      matricule: '',
      nomprenom: '',
      naturecontrat:'',
      autrenaturecontrat:'',
      sexe:'',
      naissance:'',
      etatcivil:'',
      salairenet:'0',
      salairebrut:'0',
      cin:'',
      delivre:'',
      adresse:'',
      mobile:'',
      mail:'',
      chef:'',
      enfant:'',
      image:'0',
      ficheUrl:'',
      contientfiche:false
    });
    this.route.params.subscribe(
      (params) => {
        this.deccompt.getDeccomptabilitereqById(params.id).then(
          (deccomptabilite: Deccomptabilite) => {
            
            this.deccomptabilite = deccomptabilite;
            this.userservice.getUserById(this.deccomptabilite.userId).then(
              (user: User) => {
                this.loading = false;
            this.user = user;
            this.user.tvaaffiche.find(el=>el.tauxtva=='7'&&el.affiche=='oui')?this.tva7=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='13'&&el.affiche=='oui')?this.tva13=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='19'&&el.affiche=='oui')?this.tva19=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='suspension'&&el.affiche=='oui')?this.tvasuspens=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='fodec'&&el.affiche=='oui')?this.tvafodec=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='tpe'&&el.affiche=='oui')?this.tvatpe=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='exonere'&&el.affiche=='oui')?this.tvaexonere=true:''
            this.user.tvaaffiche.find(el=>el.tauxtva=='export'&&el.affiche=='oui')?this.tvaexport=true:''
  
            this.natureactivite=this.user.natureactivite;
            this.activite=this.user.activite;
            this.sousactivite=this.user.sousactivite;
            this.specialite=this.user.specialite
            this.regimefiscalimpot=this.user.regimefiscalimpot;
            this.matriculefiscale=this.user.matriculefiscale;
            this.usertype=this.user.usertype
            this.showbuttonotheretatrecette=deccomptabilite.showbuttonotheretatrecette
            this.contientnumero=deccomptabilite.contientnumero
            this.contientnumeroother=deccomptabilite.contientnumeroother
            console.log(this.user.coproprietaires,this.user.activite)
            this.usedres.getUsedressourcedataById(deccomptabilite._id).then(
              (usedres:Usedressourcemodel)=>
                    {
                      if(usedres&&usedres.connecteduser!=this.currentUser.userId)
                      {
              return (Swal.fire({
                title: `fichier en cours d\'utilisation par  ${usedres.firstname} ${usedres.lastname}`,
                icon: 'error',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                this.tokenStorage.saved=true
                this.router.navigate(['home'])
                this.loading=false
              }).catch(() => {
                Swal.fire('opération non aboutie!')
              })) 
            }
            else if(!usedres)
            {
              const newusedres:Usedressourcemodel =new Usedressourcemodel()
              newusedres.connecteduser=this.currentUser.userId
              newusedres.ressourceid=this.deccomptabilite._id
              newusedres.firstname=this.currentUser.Firstname
              newusedres.lastname=this.currentUser.Lastname
              newusedres.raisonsociale=this.currentUser.raisonsociale
              newusedres.firstnameclient=this.deccomptabilite.firstname
              newusedres.lastnameclient=this.deccomptabilite.lastname
              newusedres.mois=this.deccomptabilite.mois
              newusedres.annee=this.deccomptabilite.annee
              newusedres.type='comptabilité'
              this.usedres.create(newusedres).then(
                (usedres:Usedressourcemodel)=>
                      {
            
                      }
              )
            }
                    }
            )
            if(this.user.activite=='Syndic des copropriétaires'&&this.user.coproprietaires)
            {
              console.log(this.user.coproprietaires)
              this.user.coproprietaires.forEach((element,index) => {
              element.donneescomptable?
              !element.donneescomptable.find(e=>e.annee==deccomptabilite.annee)?
              (this.filtredcops.push(element),
              this.displaycop='block' 
              )
              :
              '':
              ( this.filtredcops.push(element),
                this.displaycop='block' 
              )
             
            })
            this.ajoutdccopform = new FormGroup({                
              ammounts10: new FormArray(this.filtredcops.map(item => {
                const group = this.createammount10();
                //@ts-ignore
                group.patchValue(
                  {
                    numero:item.numero,
                    coproprietaire:item.coproprietaire,
                    annee:deccomptabilite.annee
                  }); 
                  return group;
              }))
            })
            }
            if (user.regimefiscalimpot=='Forfait D\'assiette')
            {
              this.forassiette=true
            }
            if(deccomptabilite.annee=='2023'||deccomptabilite.annee=='2024')
            {
              this.tauxdt=1.000
            }
            else if(deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024')
            {
              this.tauxdt=0.600
            }
            if(deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Architectes'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Ingénieurs-conseil'||
            deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Dessinateurs'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Géomètres'||
            deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Topographes'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Notaire'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Syndic des copropriétaires'||
            deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Expert'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Huissiers notaire'||
            deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Interprètes'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Avocat')
            {
              this.tva19=true
            }
             else   if(deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Architectes'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Ingénieurs-conseil'||
             deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Dessinateurs'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Géomètres'||
             deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Topographes'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Notaire'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Syndic des copropriétaires'||
             deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Expert'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Huissiers notaire'||
             deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Interprètes'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Avocat')
             {
              this.tva19=true
             }    
              if(deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Architectes'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Ingénieurs-conseil'||
              deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Dessinateurs'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Géomètres'||
              deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Topographes'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Syndic des copropriétaires'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Notaire'||
              deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Huissiers notaire'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Interprètes'
              ||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Expert'||deccomptabilite.annee!='2023'&&deccomptabilite.annee!='2024'&&deccomptabilite.activite=='Avocat' )
              {
                this.tva13=true
              }
              if(deccomptabilite.activite=='Médecin'||deccomptabilite.activite=='Infirmier'||deccomptabilite.activite=='Masseur'||deccomptabilite.activite=='Physiothérapeute'||
              deccomptabilite.activite=='Ergothérapeute'||deccomptabilite.activite=='Psychomotricien'||deccomptabilite.activite=='Diététicien'||deccomptabilite.activite=='Orthophoniste'||
              deccomptabilite.activite=='Orthoptiste'||deccomptabilite.activite=='Sage-femmes')
              {
                this.tva7=true
                this.tva19=true

              }
              if(deccomptabilite.activite=='Consultant'||deccomptabilite.sousactivite=='Consultant'||deccomptabilite.sousactivite=="Activités informatiques")
              {
                this.tva19=true
              }
            //general functions oninit
            if (this.activite != deccomptabilite.activite&&this.role!='admin'&&this.role!='supervisor'||this.sousactivite != deccomptabilite.sousactivite&&this.role!='admin'&&this.role!='supervisor') 
            return (Swal.fire({ 
              title: 'vous ne pouvez pas modifier une déclaration existente avec une activité/sous activité différente',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            }).then((result) => {
              this.tokenStorage.saved=true
              this.router.navigate(['user-board/'])
              this.loading=false
            }).catch(() => {
              Swal.fire('opération non aboutie!')
            }),console.log(this.activite,deccomptabilite.activite,this.sousactivite,deccomptabilite.sousactivite)) 
            this.nature=deccomptabilite.nature 
            this.option1Value=this.deccomptabilite.annee
          this.option2Value=this.deccomptabilite.mois
          if (this.deccomptabilite.autre1.length>0||this.deccomptabilite.autre2.length>0)
          {
            this.option3Value=true
            this.showcatab=true
            this.showinvoiceform=true
            if(this.deccomptabilite.autre0[0])
            {
              if(this.deccomptabilite.autre0[0].montantht!=''&&+this.deccomptabilite.autre0[0].montantht!=0||this.deccomptabilite.autre0[0].annule)
              {
  this.showeditionnotepast=true
              }
            }
            if(this.deccomptabilite.autre1[0])
            {
              if(this.deccomptabilite.autre1[0].montantht!=''&&+this.deccomptabilite.autre1[0].montantht!=0||this.deccomptabilite.autre1[0].montantht19!=''&&+this.deccomptabilite.autre1[0].montantht19!=0||
              this.deccomptabilite.autre1[0].montantht13!=''&&+this.deccomptabilite.autre1[0].montantht13!=0||this.deccomptabilite.autre1[0].annule
              ||this.deccomptabilite.autre1[0].montanthtexo!=''&&+this.deccomptabilite.autre1[0].montanthtexo!=0
              ||this.deccomptabilite.autre1[0].montanthtsus!=''&&+this.deccomptabilite.autre1[0].montanthtsus!=0

              ||this.deccomptabilite.autre1[0].montanthttpe!=''&&+this.deccomptabilite.autre1[0].montanthttpe!=0

              ||this.deccomptabilite.autre1[0].montanthtfodec!=''&&+this.deccomptabilite.autre1[0].montanthtfodec!=0

              ||this.deccomptabilite.autre1[0].montanthtexp!=''&&+this.deccomptabilite.autre1[0].montanthtexp!=0
              ||this.deccomptabilite.autre1[0].montanthtexpdevise!=''&&+this.deccomptabilite.autre1[0].montanthtexpdevise!=0)
              {
                this.showeditionnote=true

              }
            }
            if(this.deccomptabilite.autre2.length>0)
            {
this.showrecettejour=true
            }
          }
          if (this.deccomptabilite.autre3.length>0)
          {
            this.option4Value=true
            this.showachattab=true
          }
          if (this.deccomptabilite.autre8.length>0)
          {
            this.option14Value=true
            this.showachatchequetab=true
          }
          if (this.deccomptabilite.autre9.length>0)
          {
            this.option13Value=true
            this.showfacturefournisseurtab=true
          }
          if (this.deccomptabilite.autre11.length>0)
          {
            this.option12Value=true
            this.showachatespecetab=true
          }
          if (this.deccomptabilite.autre4.length>0||this.deccomptabilite.autre5.length>0)
          {
            this.option5Value=true
            this.showbanquetab=true
            if(this.deccomptabilite.autre4.length>0)
            {
//this.showrelevemanuel=true
            }
            if(this.deccomptabilite.autre5.length>0)
            {
this.showrelevejoint=true
            }
          }
          if (this.deccomptabilite.autre6.length>0)
          {
            this.option6Value=true
            this.showsalairetab=true
            this.showpaiemanuel=true
          }
          this.editionnotepastform = new FormGroup({
            
            ammounts0: new FormArray(deccomptabilite.autre0.map(item => {
              const group = this.createammount0();
              //@ts-ignore
              group.patchValue(item);
              return group;
            }))
          });
          if(deccomptabilite.autre1.length>0)
{
this.editionnoteform = new FormGroup({
            
ammounts: new FormArray(deccomptabilite.autre1.map(item => {
  const group = this.createammount();
  //@ts-ignore
  group.patchValue(item);
  return group;
}))
});
}
         
         /* for (let i = 1; i < 32; i++)
      {
        this.addammount2()
        let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
        ammounts2.at(i).patchValue({
          jour:i
         })
         this.setdate2(i)
      }
      this.removeammount2(0)
      if(this.option2Value==='04'||this.option2Value==='06'||this.option2Value==='09'||this.option2Value==='11')
      {
        this.removeammount2(30)
      }
      if(this.option2Value=='02')
      {
        if(+this.option1Value % 4 ==0)
        {
          this.removeammount2(30)
          this.removeammount2(29)
          this.removeammount2(28)
        }
        else 
        {
        this.removeammount2(30)
        this.removeammount2(29)
        }
      }*/
if(deccomptabilite.autre2.length>0)
{
this.recettejournaliereform = new FormGroup({
ammounts2: new FormArray(deccomptabilite.autre2.map(item => 
  {
  const group = this.createammount2();
  //@ts-ignore
  group.patchValue(item);
  return group;
}))
});
}          

/*for (let i = 1; i < 32; i++)
{
this.addammount2()
let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
ammounts2.at(i).patchValue({
  jour:i
 })
 this.setdate2(i)
}
if(this.option2Value==='04'||this.option2Value==='06'||this.option2Value==='09'||this.option2Value==='11')
{
this.removeammount2(30)
}
if(this.option2Value=='02')
{
if(+this.option1Value % 4 ==0)
{
  this.removeammount2(30)
  this.removeammount2(29)
  this.removeammount2(28)
}
else 
{
this.removeammount2(30)
this.removeammount2(29)
}
}*/

if(deccomptabilite.autre3.length>0)
{
this.factureachatform = new FormGroup({
ammounts3: new FormArray(deccomptabilite.autre3.map((item,i) => {
  const group = this.createammount3();
  //@ts-ignore
  group.patchValue(item);
 
  /*if(item.contientfiche)
  {
    var text2 = document.getElementById('achat'+`${i}`);
    text2.style.display='block'
  }*/
  return group;
}))
});
for (let j = 0; j < this.factureachatform.getRawValue().ammounts3.length; j++)
{
if(+(this.factureachatform.getRawValue().ammounts3)[j].montanthtdevise)
{
let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
ammounts3.controls[j].get('montanttva').disable()
ammounts3.controls[j].get('montantdt').disable()
}
}
}
if(deccomptabilite.autre8.length>0)
{
this.factureachatchequeform = new FormGroup({
ammounts8: new FormArray(deccomptabilite.autre8.map((item,i) => {
  const group = this.createammount8();
  //@ts-ignore
  group.patchValue(item);
 
  /*if(item.contientfiche)
  {
    var text2 = document.getElementById('achat'+`${i}`);
    text2.style.display='block'
  }*/
  return group;
}))
});
for (let j = 0; j < this.factureachatchequeform.getRawValue().ammounts8.length; j++)
{
if((this.factureachatchequeform.getRawValue().ammounts8)[j].typepiece=='recu')
{
let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
ammounts8.controls[j].get('numerofacture').disable()
}
}
}
if(deccomptabilite.autre9.length>0)
{
this.facturefournisseurform = new FormGroup({
ammounts9: new FormArray(deccomptabilite.autre9.map((item,i) => {
  const group = this.createammount9();
  //@ts-ignore
  group.patchValue(item);
 
  /*if(item.contientfiche)
  {
    var text2 = document.getElementById('achat'+`${i}`);
    text2.style.display='block'
  }*/
  return group;
}))
});
for (let j = 0; j < this.facturefournisseurform.getRawValue().ammounts9.length; j++)
{
if((this.facturefournisseurform.getRawValue().ammounts9)[j].typepiece=='recu')
{
let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
ammounts9.controls[j].get('numerofacture').disable()
}
}
}
if(deccomptabilite.autre11.length>0)
{
this.factureachatespeceform = new FormGroup({
ammounts11: new FormArray(deccomptabilite.autre11.map((item,i) => {
  const group = this.createammount11();
  //@ts-ignore
  group.patchValue(item);
 
  /*if(item.contientfiche)
  {
    var text2 = document.getElementById('achat'+`${i}`);
    text2.style.display='block'
  }*/
  return group;
}))
});
for (let j = 0; j < this.factureachatespeceform.getRawValue().ammounts11.length; j++)
{
if((this.factureachatespeceform.getRawValue().ammounts11)[j].typepiece=='recu')
{
let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
ammounts11.controls[j].get('numerofacture').disable()
}
}
}
/*for (let j = 0; j < this.factureachatform.getRawValue().ammounts3.length; j++)
{
if(this.factureachatform.getRawValue().ammounts3)[j].montanthtdevise!=0)
{
ammounts3.controls[i].get('montanttva').disable()
ammounts3.controls[i].get('montantdt').disable()
}
    }*/
         
          this.createrelevemanuelForm();

          // Direct patching does not patch nested arrays
          this.patchValuesDirectly();
      
          //Correct way
          this.patchValueIteratively();
          this.createsalaireForm();

          // Direct patching does not patch nested arrays
          this.patchsalaireValuesDirectly();
      
          //Correct way
          this.patchsalaireValueIteratively();
          /*this.relevemanuelform = new FormGroup({
            employees: new FormArray(deccomptabilite.autre4.map(item => {
              const group = this.newEmployee();
              
              //@ts-ignore
              group.patchValue(
                {
                  
                  type:item.type,
                  numerocompte:item.numerocompte,
                  debit:item.soldedebit,
                  credit:item.soldecredit,
                  skills:item.mouvements
                }
              );
              console.log(group)

              return group;
            }))              
          });*/
          if(deccomptabilite.autre5.length>0)
          {
            this.relevejointform = new FormGroup({
            
              ammounts5: new FormArray(deccomptabilite.autre5.map(item => {
                const group = this.createammount5();
                //@ts-ignore
                group.patchValue(item);
                return group;
              }))
            });
          }
         /* if(deccomptabilite.autre6.length>0)
          {
            this.salaireform = new FormGroup({
            
              ammounts6: new FormArray(deccomptabilite.autre6.map(item => {
                const group = this.createammount6();
                //@ts-ignore
                group.patchValue(item);
                return group;
              }))
            });
          }*/
          

          //update sum of different formarrays
          let factureammounts=[]
                          let avoirammounts=[]
                          let totalhtavoir=0
                          let totalhtfacture=0
                          let totaltvaavoir=0
                          let totaltvafacture=0
                          let totaldtavoir=0
                          let totaldtfacture=0
                          let totalttcavoir=0
                          let totalttcfacture=0
                          let totalht19avoir=0
                          let totalht19facture=0
                          let totaltva19avoir=0
                          let totaltva19facture=0
                          let totalttc19avoir=0
                          let totalttc19facture=0

                          let totalht13avoir=0
                          let totalht13facture=0
                          let totaltva13avoir=0
                          let totaltva13facture=0
                          let totalttc13avoir=0
                          let totalttc13facture=0
                          let totalhtexoavoir=0
                          let totalhtexofacture=0
                          
                          let totalttcexoavoir=0
                          let totalttcexofacture=0
                          let totalhtsusavoir=0
                          let totalhtsusfacture=0
                         
                          let totalttcsusavoir=0
                          let totalttcsusfacture=0

                          let totalhtfodecavoir=0
                          let totalhtfodecfacture=0
                         
                          let totalttcfodecavoir=0
                          let totalttcfodecfacture=0

                          let totalhttpeavoir=0
                          let totalhttpefacture=0
                         
                          let totalttctpeavoir=0
                          let totalttctpefacture=0
                          let totalhtexpavoir=0
                          let totalhtexpfacture=0
                          
                          let totalttcexpavoir=0
                          let totalttcexpfacture=0
                          let facture3ammounts=[]
                          let avoir3ammounts=[]
                          let totalht3avoir=0
                          let totalht3facture=0
                          let totalht3deviseavoir=0
                          let totalht3devisefacture=0
                          let totaltva3avoir=0
                          let totaltva3facture=0
                          let totaldt3avoir=0
                          let totaldt3facture=0
                          let totalttc3avoir=0
                          let totalttc3facture=0
                          let factureammounts9=[]
                          let avoirammounts9=[]
    let totalht9avoir=0
    let totalht9facture=0
                          this.editionnoteform.getRawValue().ammounts.filter((item)=>
                          {
                            item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                          })
                          this.factureachatform.getRawValue().ammounts3.filter((item)=>
                          {
                            item.typepiece=='facture'?facture3ammounts.push(item):avoir3ammounts.push(item)
                          })
                          this.facturefournisseurform.getRawValue().ammounts9.filter((item)=>
{
  item.typepiece=='facture'?factureammounts9.push(item):avoirammounts9.push(item)
})
totalht9facture = +(factureammounts9).reduce((acc,curr)=>{
  acc += +(curr.montantht || 0);
  return acc;
},0);
totalht9avoir = +(avoirammounts9).reduce((acc,curr)=>{
  acc += +(curr.montantht || 0);
  return acc;
},0);      
this.totalht9 = totalht9facture - totalht9avoir
                          totalht3facture = +(facture3ammounts).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);
                          totalht3avoir = +(avoir3ammounts).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);      
                    this.totalht3 = totalht3facture - totalht3avoir
                    totalht3devisefacture = +(facture3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtachat || 0);
                      return acc;
                    },0);
                    totalht3deviseavoir = +(avoir3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtachat || 0);
                      return acc;
                    },0);      
              this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
                    totaltva3facture= +(facture3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva || 0);
                      return acc;
                    },0);
                    totaltva3avoir= +(avoir3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva || 0);
                      return acc;
                    },0);
                    this.totaltva3 = totaltva3facture - totaltva3avoir
                    totaldt3facture= +(facture3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantdt || 0);
                      return acc;
                    },0);
                    totaldt3avoir= +(avoir3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantdt || 0);
                      return acc;
                    },0);
                    this.totaldt3 = totaldt3facture - totaldt3avoir
                    totalttc3facture= +(facture3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc || 0);
                      return acc;
                    },0);
                    totalttc3avoir=+(avoir3ammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc || 0);
                      return acc;
                    },0);
                    this.totalttc3 = totalttc3facture - totalttc3avoir 
          totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
            acc += +(curr.montantht || 0);
            return acc;
          },0);
          totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
            acc += +(curr.montantht || 0);
            return acc;
          },0);      
    this.totalht = totalhtfacture - totalhtavoir
    totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanttva || 0);
      return acc;
    },0);
    totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanttva || 0);
      return acc;
    },0);
    this.totaltva = totaltvafacture - totaltvaavoir
    totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montantdt || 0);
      return acc;
    },0);
    totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montantdt || 0);
      return acc;
    },0);
    this.totaldt = totaldtfacture - totaldtavoir
    totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montantttc || 0);
      return acc;
    },0);
    totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montantttc || 0);
      return acc;
    },0);
    this.totalttc = totalttcfacture-totalttcavoir
  
  
  totalht19facture=+(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantht19 || 0);
    return acc;
  },0);
  totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantht19 || 0);
    return acc;
  },0);
    this.totalht19 = totalht19facture-totalht19avoir
  totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva19 || 0);
    return acc;
  },0);
  totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva19 || 0);
    return acc;
  },0);
    this.totaltva19 = totaltva19facture-totaltva19avoir
   totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc19 || 0);
    return acc;
  },0);
  totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc19 || 0);
    return acc;
  },0);
    this.totalttc19 = totalttc19facture-totalttc19avoir
   

    totalht13facture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montantht13 || 0);
      return acc;
    },0);
    totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montantht13 || 0);
      return acc;
    },0);
      this.totalht13 = totalht13facture-totalht13avoir
    totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanttva13 || 0);
      return acc;
    },0);
    totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanttva13 || 0);
      return acc;
    },0);
      this.totaltva13 = totaltva13facture-totaltva13avoir
     totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montantttc13 || 0);
      return acc;
    },0);
    totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montantttc13 || 0);
      return acc;
    },0);
      this.totalttc13 = totalttc13facture-totalttc13avoir
    totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtexo || 0);
      return acc;
    },0);
    totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtexo || 0);
      return acc;
    },0);
    this.totalhtexo = totalhtexofacture-totalhtexoavoir
    totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtsus || 0);
      return acc;
    },0);
    totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtsus || 0);
      return acc;
    },0);
    this.totalhtsus = totalhtsusfacture-totalhtsusavoir

    totalhttpefacture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthttpe || 0);
      return acc;
    },0);
    totalhttpeavoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthttpe || 0);
      return acc;
    },0);
    this.totalhttpe = totalhttpefacture-totalhttpeavoir

    totalhtfodecfacture=+(factureammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtfodec || 0);
      return acc;
    },0);
    totalhtfodecavoir=+(avoirammounts).reduce((acc,curr)=>{
      acc += +(curr.montanthtfodec || 0);
      return acc;
    },0);
    this.totalhtfodec = totalhtfodecfacture-totalhtfodecavoir
   totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtexp || 0);
    return acc;
  },0);
  totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtexp || 0);
    return acc;
  },0);
  this.totalhtexp = totalhtexpfacture-totalhtexpavoir

          this.totalht2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantht || 0);
            return acc;
          },0);
          this.totalht219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantht19 || 0);
            return acc;
          },0);
          this.totalht213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantht13 || 0);
            return acc;
          },0);
          this.totalht2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanthtexo || 0);
            return acc;
          },0);
          this.totalht2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanthtsus || 0);
            return acc;
          },0);

          this.totalht2fodec = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanthtfodec || 0);
            return acc;
          },0);

          this.totalht2tpe = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanthttpe || 0);
            return acc;
          },0);
          this.totalht2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanthtexp || 0);
            return acc;
          },0);
          this.totaltva2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanttva || 0);
            return acc;
          },0);
          this.totaltva219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanttva19 || 0);
            return acc;
          },0);
          this.totaldt2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantdt || 0);
            return acc;
          },0);
          this.totalttc2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantttc || 0);
            return acc;
          },0);
          this.totalttc219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantttc19 || 0);
            return acc;
          },0);

          this.totaltva213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montanttva13 || 0);
            return acc;
          },0);
          this.totaldt2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantdt || 0);
            return acc;
          },0);
          this.totalttc2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantttc || 0);
            return acc;
          },0);
          this.totalttc213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.montantttc13 || 0);
            return acc;
          },0);
          this.totalrecette = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recette || 0);
            return acc;
          },0);
          this.totalrecette19 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recette19 || 0);
            return acc;
          },0);
          this.totalrecette13 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recette13 || 0);
            return acc;
          },0);
          this.totalrecette2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recetteexo || 0);
            return acc;
          },0);
          this.totalrecette2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recettesus || 0);
            return acc;
          },0);

          this.totalrecette2tpe = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recettetpe || 0);
            return acc;
          },0);

          this.totalrecette2fodec = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
            acc += +(curr.recettefodec || 0);
            return acc;
          },0);
          this.totalrecette2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
            acc += +(curr.recetteexp || 0);
            return acc;
          },0);
          this.totaldebit = +(this.relevemanuelform.get('employees').value).reduce((acc,curr)=>{
            acc += +(curr.debit || 0);
            return acc;
          },0);
          
          this.totalcredit= +(this.relevemanuelform.get('employees').value).reduce((acc,curr)=>{
            acc += +(curr.credit || 0);
            return acc;
          },0);
          
          this.totalsoldemois = this.totalcredit-this.totaldebit
          this.totalht8 = +(this.factureachatchequeform.getRawValue().ammounts8).reduce((acc,curr)=>{
            acc += +(curr.montantht || 0);
            return acc;
          },0);
          this.realht8=this.totalht8
         
          this.totalht11 = +(this.factureachatespeceform.getRawValue().ammounts11).reduce((acc,curr)=>{
            acc += +(curr.montantht || 0);
            return acc;
          },0);
          
          this.realht11=this.totalht11
          this.realht219=this.totalht219
          this.realdt219=this.totaldt219
          this.realht213=this.totalht213
          this.realdt213=this.totaldt213
          this.realht2=this.totalht2
          this.realdt2=this.totaldt2
          this.realht1=this.totalht
          this.realdt1=this.totaldt
          this.realht119=this.totalht19
          this.realdt119=this.totaldt19
          this.realht113=this.totalht13
          this.realdt113=this.totaldt13
          this.realht3=this.totalht3
          this.realtva3=this.totaltva3
          this.realsalairebrut6=this.totalsalairebrut
          this.realsalaireimposable6=this.totalsalaireimposable
          this.realretenue6=this.totalretenueimpot
          const salairesbrutstfp=this.realsalairebrut6
const tauxtfp=0.02
const tauxfoprolos=0.01
const basetfp=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
  const montanttfpmois=+ Math.trunc((+basetfp* +tauxtfp)*1000)/1000;
  this.tfpapayer=+ Math.trunc((+montanttfpmois)*1000)/1000;
  const basefoprolos=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
  const montantfoprolosmois=+ Math.trunc((+basefoprolos* +tauxfoprolos)*1000)/1000;
  this.foprolosapayer=+ Math.trunc((+montantfoprolosmois)*1000)/1000;
  this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
    (deccomptabilites) => {
      this.alldeccomptabilites = deccomptabilites;
      //@ts-ignore
  this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetsdebit.push(e.objetdebit)))))
  //@ts-ignore
  this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetscredit.push(e.objetcredit)))))
  this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => (this.numeros.push({nc:e.numerocompte,bq:e.banque}))))
  this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => (this.banques.push({bq:e.banque}))))
  user.activite!='Syndic des copropriétaires'?this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.clients.push({cl:e.client})))):''
  this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.copnatures.push(e.nature))))
  
  this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.copobjets.push(e.objet))))
  this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.copnatures.push(e.nature))))
  this.alldeccomptabilites.forEach(element => element.autre0.forEach(e => (this.copnatures.push(e.nature))))
  this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.copobjets.push(e.objet))))
  this.alldeccomptabilites.forEach(element => element.autre0.forEach(e => (this.copobjets.push(e.objet))))
  this.alldeccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
  this.alldeccomptabilites.forEach(element => element.autre9.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
  this.alldeccomptabilites.forEach(element => element.autre11.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))

  this.alldeccomptabilites.forEach(element => element.autre8.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
    this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
      return index === this.beneficiaires.findIndex(o => obj.four === o.four);
    });
    this.settedbeneficiaires=this.settedbeneficiaires.sort()
  //@ts-ignore
  this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur}),this.clients.push({cl:item.client})))))
  this.alldeccomptabilites.find(objet => objet.autre6.forEach(element => element.salarie!=null))?      
        this.alldeccomptabilites.forEach(objet => objet.autre6.forEach(element => element.salarie.forEach(e =>this.naturescontrat.push({natcontrat:e.naturecontrat})))):''
  this.loading = false;
      this.settedobjetsdebit= this.objetsdebit.filter((obj, index) => {
        return index === this.objetsdebit.findIndex(o => obj === o);
      });
      this.settedobjetsdebit=this.settedobjetsdebit.sort()
      this.settedobjetscredit= this.objetscredit.filter((obj, index) => {
        return index === this.objetscredit.findIndex(o => obj === o);
      });
      this.settedobjetscredit=this.settedobjetscredit.sort()
      this.settednumeros= this.numeros.filter((obj, index) => {
        return index === this.numeros.findIndex(o => obj.nc === o.nc);
      });
      this.settedbanques= this.banques.filter((obj, index) => {
        return index === this.banques.findIndex(o => obj.bq === o.bq);
      });
      this.settednumeros=this.settednumeros.sort()
      this.filtrednumeros=this.settednumeros
      this.settedbanques=this.settedbanques.sort()
      this.settedclients= this.clients.filter((obj, index) => {
        return index === this.clients.findIndex(o => obj.cl === o.cl);
      });
      this.settedclients=this.settedclients.sort()
      this.settednatures= this.natures.filter((obj, index) => {
        return index === this.natures.findIndex(o => obj.nat === o.nat);
      });
      this.settednatures=this.settednatures.sort()
      this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
        return index === this.fournisseurs.findIndex(o => obj.four === o.four);
      });
      this.settedfournisseurs=this.settedfournisseurs.sort()
      this.settednaturecontrats= this.naturescontrat.filter((obj, index) => {
        //@ts-ignore
  return index === this.naturescontrat.findIndex(o => obj.natcontrat === o.natcontrat);
  });
  this.settednaturecontrats=this.settednaturecontrats.sort()
  this.settedcopnatures= this.copnatures.filter((obj, index) => {
    return index === this.copnatures.findIndex(o => obj === o);
  });
  this.copnatures=this.copnatures.sort()
  this.settedcopobjets= this.copobjets.filter((obj, index) => {
    return index === this.copobjets.findIndex(o => obj === o);
  });
  this.copobjets=this.copobjets.sort()
    },
    (error) => {
      this.loading = false;
      this.errormsg=error.message;
    }
  );
  this.currentUser.role=='basic'?this.deccompt.getdeccomptabilite(this.currentUser.userId)   
  :''
  if(this.currentUser.role!='basic')
  {
    if(this.user.activite=='Syndic des copropriétaires'&&this.user.coproprietaires)
    {
      console.log(this.user.coproprietaires)
      this.user.coproprietaires.forEach((element,index) => {
      element.donneescomptable?
      !element.donneescomptable.find(e=>e.annee==this.option1Value)?
      (this.filtredcops.push(element),
      this.displaycop='block' 
      )
      :
      '':
      ( this.filtredcops.push(element),
        this.displaycop='block' 
      )
     
    })
    this.ajoutdccopform = new FormGroup({                
      ammounts10: new FormArray(this.filtredcops.map(item => {
        const group = this.createammount10();
        //@ts-ignore
        group.patchValue(
          {
            numero:item.numero,
            coproprietaire:item.coproprietaire
          }); 
          return group;
      }))
    })
    }
  }

  //load deccomptabilite for admin and collab
this.currentUser.role!='basic'?
(this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
(deccomptabilites) => {
this.alldeccomptabilites = deccomptabilites;
//@ts-ignore
this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetsdebit.push(e.objetdebit)))))
//@ts-ignore
this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetscredit.push(e.objetcredit)))))
this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => (this.numeros.push({nc:e.numerocompte,bq:e.banque}))))
this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => (this.banques.push({bq:e.banque}))))
user.activite!='Syndic des copropriétaires'?this.alldeccomptabilites.forEach(element => element.autre1.forEach(e => (this.clients.push({cl:e.client})))):''
this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copnatures.push(e.nature))))
this.deccompt.deccomptabilites.forEach(element => element.autre0.forEach(e => (this.copnatures.push(e.nature))))
this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copobjets.push(e.objet))))
this.deccompt.deccomptabilites.forEach(element => element.autre0.forEach(e => (this.copobjets.push(e.objet))))

this.alldeccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
this.alldeccomptabilites.forEach(element => element.autre9.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
this.alldeccomptabilites.forEach(element => element.autre11.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}))))

this.alldeccomptabilites.forEach(element => element.autre8.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}))))
  this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
    return index === this.beneficiaires.findIndex(o => obj.four === o.four);
  });
  this.settedbeneficiaires=this.settedbeneficiaires.sort()
//@ts-ignore
this.alldeccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur}),this.clients.push({cl:item.client})))))
//@ts-ignore
this.alldeccomptabilites.forEach(element => element.autre6.forEach(e => (this.naturescontrat.push({natcontrat:e.naturecontrat}))))
this.loading = false;
this.settedobjetsdebit= this.objetsdebit.filter((obj, index) => {
  return index === this.objetsdebit.findIndex(o => obj === o);
});
this.settedobjetsdebit=this.settedobjetsdebit.sort()
this.settedobjetscredit= this.objetscredit.filter((obj, index) => {
  return index === this.objetscredit.findIndex(o => obj === o);
});
this.settedobjetscredit=this.settedobjetscredit.sort()
this.settednumeros= this.numeros.filter((obj, index) => {
  return index === this.numeros.findIndex(o => obj.nc === o.nc);
});
this.settedbanques= this.banques.filter((obj, index) => {
  return index === this.banques.findIndex(o => obj.bq === o.bq);
});
this.settednaturecontrats= this.naturescontrat.filter((obj, index) => {
  //@ts-ignore
return index === this.naturescontrat.findIndex(o => obj.natcontrat === o.natcontrat);
});
this.settedcopnatures= this.copnatures.filter((obj, index) => {
  return index === this.copnatures.findIndex(o => obj === o);
});
this.copnatures=this.copnatures.sort()
this.settedcopobjets= this.copobjets.filter((obj, index) => {
  return index === this.copobjets.findIndex(o => obj === o);
});
this.copobjets=this.copobjets.sort()
this.settednaturecontrats=this.settednaturecontrats.sort()
this.settednumeros=this.settednumeros.sort()
this.settedbanques=this.settedbanques.sort()
this.filtrednumeros=this.settednumeros
this.settedclients= this.clients.filter((obj, index) => {
  return index === this.clients.findIndex(o => obj.cl === o.cl);
});
this.settedclients=this.settedclients.sort()
this.settednatures= this.natures.filter((obj, index) => {
  return index === this.natures.findIndex(o => obj.nat === o.nat);
});
this.settednatures=this.settednatures.sort()
this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
  return index === this.fournisseurs.findIndex(o => obj.four === o.four);
});
this.settedfournisseurs=this.settedfournisseurs.sort()
},
(error) => {
this.loading = false;
this.errormsg=error.message;
}
),
this.deccompt.getdeccomptabilite(this.deccomptabilite.userId),
this.intervalSub=interval(1000).subscribe(value => this.countdown=value), this.countdown=0,
this.firsttimer=Date.now())
:''
              }
            )
          })

  }
    )    

}
createsalaireForm() {
  this.salaireform = this.fb.group({
    typessalaire: this.fb.array([this.newtypesalaire()])
  });
}
patchsalaireValuesDirectly() {
  this.deccomptabilite.autre6.map((item,index) => { 
    //@ts-ignore
    this.salaireform.patchValue(
      {
        type:item.type,
        ammounts6:item.salarie,
        typesalaire:item.typesalaire,
      }
    )
   
  });
 
  
}
patchsalaireValueIteratively() {
  let autre6 = this.deccomptabilite.autre6;
  (this.salaireform.get('typessalaire') as FormArray).clear(); //Removing initial item b/c you are creating form array with 1 initial value
  autre6.forEach((car,empindex) => {
    const vehicleForm = this.newtypesalaire();
    vehicleForm.patchValue({
      type:car.type,
      ammounts6:car.salarie,
      typesalaire:car.typesalaire,
    });
   
    //Create Cars FormArray individually & patch the value
    (vehicleForm.get('ammounts6') as FormArray).clear(); //Initially blank
    car.salarie.forEach((item,skillindex) => {
      const carForm = this.createammount6();
      carForm.patchValue(item);
      
      (vehicleForm.get('ammounts6') as FormArray).push(carForm);
      this.totalsalairebrut = +(vehicleForm.get('ammounts6').value).reduce((acc,curr)=>{
        acc += +(curr.salairebrut || 0);
        return acc;
      },0);
      this.totalsalairenet = +(vehicleForm.get('ammounts6').value).reduce((acc,curr)=>{
        acc += +(curr.salairenet || 0);
        return acc;
      },0);
    });
    (this.salaireform.get('typessalaire') as FormArray).push(vehicleForm);
  });
}

createrelevemanuelForm() {
  this.relevemanuelform = this.fb.group({
    employees: this.fb.array([this.newEmployee()]),
  });
}
patchValuesDirectly() {
  this.deccomptabilite.autre4.map((item,index) => { 
    //@ts-ignore
    this.relevemanuelform.patchValue(
      {
        type:item.type,
        banque:item.banque,
        numerocompte:item.numerocompte,
        devise:item.devise,
        debit:item.soldedebit,
        credit:item.soldecredit,
        creditdevise:item.creditdevise,
        soldefinmoisdevise:item.soldefinmoisdevise,
        soldefinmoisdinar:item.soldefinmoisdinar,
        skills:item.mouvements
      }
    )
   
  });
 
  
}
patchValueIteratively() {
  this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
    (deccomptabilites) => {
      let autre4 = this.deccomptabilite.autre4;
      let identiccomptes:any[]=[]
      let identiccompte:any
      (this.relevemanuelform.get('employees') as FormArray).clear(); //Removing initial item b/c you are creating form array with 1 initial value
      this.showrelevemanuel=true
      autre4.forEach((car,empindex) => {
        const vehicleForm = this.newEmployee();
        vehicleForm.patchValue({
                          
          type:car.type,
          banque:car.banque,
          numerocompte:car.numerocompte,
          devise:car.devise,
          debit:car.soldedebit,
          credit:car.soldecredit,
          creditdevise:car.creditdevise,
          soldefinmoisdevise:car.soldefinmoisdevise,
          soldefinmoisdinar:car.soldefinmoisdinar,
          skills:car.mouvements
        });
        console.log(deccomptabilites)
       //patch solde fin du mois oninit
       if(this.deccompt.deccomptabilites.length>0)
       {
      let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)<new Date(this.deccomptabilite.created)&&!item.source)
      console.log(desireddeccomptables)
      desireddeccomptables.forEach(element => identiccomptes.push(element.autre4.find(e =>e.banque==car.banque&&e.numerocompte==car.numerocompte)))
       identiccomptes.filter(e =>e!=undefined)
       identiccompte=identiccomptes[identiccomptes.length-1]
       console.log(identiccompte)
       if(identiccompte)
       {
      
          vehicleForm.patchValue(
            {
              devise:identiccompte.devise,
            }
          )
          console.log(identiccompte.soldefinmoisdinar)
        
      
          if(identiccompte.soldefinmoisdinar<0)
          {
            console.log(empindex)
            vehicleForm.patchValue(
              {
                debit:-identiccompte.soldefinmoisdinar,
                creditdevise:identiccompte.soldefinmoisdevise
              }
            )
          }
          else if(identiccompte.soldefinmoisdinar>=0)
          {
            vehicleForm.patchValue(
              {
                credit:identiccompte.soldefinmoisdinar,
                creditdevise:identiccompte.soldefinmoisdevise
              }
            )
          
          }
        
      
       }
      }
        //Create Cars FormArray individually & patch the value
        (vehicleForm.get('skills') as FormArray).clear(); //Initially blank
        car.mouvements.forEach((item,skillindex) => {
          var text1 = document.getElementById('relevemanuelfournisseur'+`${empindex}`+`${skillindex}`);
          var text2 = document.getElementById('relevemanuelclient'+`${empindex}`+`${skillindex}`);
          const carForm = this.newSkill();
          carForm.patchValue(item);
          
          (vehicleForm.get('skills') as FormArray).push(carForm);
          this.totaldebitbis = +(vehicleForm.get('skills').value).reduce((acc,curr)=>{
            acc += +(curr.debit || 0);
            return acc;
          },0);
          this.totalcreditbis = +(vehicleForm.get('skills').value).reduce((acc,curr)=>{
            acc += +(curr.credit || 0);
            return acc;
          },0);
        });
        (this.relevemanuelform.get('employees') as FormArray).push(vehicleForm);
        
      });
       for (let j = 0; j < this.relevemanuelform.getRawValue().employees.length; j++)
      {
        let employees = this.relevemanuelform.get('employees') as FormArray;
        let skills= this.relevemanuelform.get('employees')['controls'].at(j).get('skills') as FormArray
        if((this.relevemanuelform.getRawValue().employees)[j].devise=='tnd')
        {   
          employees.controls[j].get('debitdevise').disable()
          employees.controls[j].get('creditdevise').disable()
          employees.controls[j].get('debit').enable()
          employees.controls[j].get('credit').enable()
          for (let k = 0; k < skills.getRawValue().length; k++)
    {
      skills.controls[k].get('debitdevise').disable()
      skills.controls[k].get('creditdevise').disable()
    
      if(skills.getRawValue()[k].debit&&skills.getRawValue()[k].objetdebit!='impayé client')
    {
      skills.controls[k].get('debit').enable()
      skills.controls[k].get('objetdebit').enable()
      skills.controls[k].get('fournisseur').enable()
      skills.controls[k].get('objetcredit').disable()
      skills.controls[k].get('client').disable()
    
    }
    else if(skills.getRawValue()[k].credit)
    {
      skills.controls[k].get('credit').enable()
      skills.controls[k].get('objetdebit').disable()
      skills.controls[k].get('fournisseur').disable()
      skills.controls[k].get('objetcredit').enable()
      skills.controls[k].get('client').enable()
    }
    else if(skills.getRawValue()[k].debit&&skills.getRawValue()[k].objetdebit=='impayé client')
    
    {
      skills.controls[k].get('debit').enable()
      skills.controls[k].get('objetdebit').enable()
      skills.controls[k].get('fournisseur').disable()
      skills.controls[k].get('objetcredit').disable()
      skills.controls[k].get('client').enable()
    }
    }
        } 
      }
    }
  )


}


canDeactivate():boolean {  
  this.ngOnDestroy()
  if(this.tokenStorage.saved)
  {
    return true;
  }
  
    return false;

  
}
onTabClick(event) {
   
}
setTwoNumberDecimal($event) {
  $event.target.value = $event.target.value ? $event.target.value : 0;
  $event.target.value = parseFloat($event.target.value).toFixed(2);
}
setThreeNumberDecimal($event) {
  $event.target.value = $event.target.value ? $event.target.value : 0;
  $event.target.value = parseFloat($event.target.value).toFixed(3);
}
setdate0(i: number) {
  let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
  
  const j= this.editionnotepastform.get('ammounts0').value.at(i).jour
  if (ammounts0.value.find(element => +element.jour > +j))
  return (alert('une note existe avec une date supérieure'),ammounts0.at(i).patchValue({
   jour:''
  }))
  if (j==0)
  return (ammounts0.at(i).patchValue({
   jour:''
  }))
  if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
  return (ammounts0.at(i).patchValue({
   jour:''
  }))
  if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
  return (ammounts0.at(i).patchValue({
   jour:''
  }))
  if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
  return (ammounts0.at(i).patchValue({
   jour:''
  }))
  if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
  return (ammounts0.at(i).patchValue({
   jour:''
  }))
  const date=j+'/'+this.option2Value+'/'+this.option1Value
    const convertedj=date.split('/')[0].padStart(2,'0')
    const rest1='/'+date.split('/')[1]
    const rest2='/'+date.split('/')[2]
    ammounts0.at(i).patchValue({
     date:convertedj+rest1+rest2
    })
  }
setdate(i: number) {
  let ammounts = this.editionnoteform.get('ammounts') as FormArray;
  
   const j= this.editionnoteform.get('ammounts').value.at(i).jour
   if (ammounts.value.find(element => +element.jour > +j))
   return (alert('une note existe avec une date supérieure'),ammounts.at(i).patchValue({
    jour:''
   }))
   if (j==0)
   return (ammounts.at(i).patchValue({
    jour:''
   }))
   if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
   return (ammounts.at(i).patchValue({
    jour:''
   }))
   if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
   return (ammounts.at(i).patchValue({
    jour:''
   }))
   if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
   return (ammounts.at(i).patchValue({
    jour:''
   }))
   if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
   return (ammounts.at(i).patchValue({
    jour:''
   }))
   const date=j+'/'+this.option2Value+'/'+this.option1Value
     const convertedj=date.split('/')[0].padStart(2,'0')
     const rest1='/'+date.split('/')[1]
     const rest2='/'+date.split('/')[2]
     ammounts.at(i).patchValue({
      date:convertedj+rest1+rest2
     })
  }
  setdate2(i: number) {
    let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
     const j= this.recettejournaliereform.get('ammounts2').value.at(i).jour
     if (j==0)
     return (ammounts2.at(i).patchValue({
      jour:''
     }))
     if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
     return (ammounts2.at(i).patchValue({
      jour:''
     }))
     if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
     return (ammounts2.at(i).patchValue({
      jour:''
     }))
     if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
     return (ammounts2.at(i).patchValue({
      jour:''
     }))
     if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
     return (ammounts2.at(i).patchValue({
      jour:''
     }))
     const date=j+'/'+this.option2Value+'/'+this.option1Value
     const convertedj=date.split('/')[0].padStart(2,'0')
     const rest1='/'+date.split('/')[1]
     const rest2='/'+date.split('/')[2]
     ammounts2.at(i).patchValue({
      date:convertedj+rest1+rest2
     })
    }
    setdate3(i: number,ammounts:FormArray) {
      let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
      console.log(ammounts)
      console.log(ammounts9)

       const j= ammounts.value.at(i).jour
       if (j==0)
     return (ammounts.at(i).patchValue({
      jour:''
     }))
       if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
       return (ammounts.at(i).patchValue({
        jour:''
       }))
       if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
       return (ammounts.at(i).patchValue({
        jour:''
       }))
       if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
       return (ammounts.at(i).patchValue({
        jour:''
       }))
       if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
       return (ammounts.at(i).patchValue({
        jour:''
       }))
       const date=j+'/'+this.option2Value+'/'+this.option1Value
       const convertedj=date.split('/')[0].padStart(2,'0')
       const rest1='/'+date.split('/')[1]
       const rest2='/'+date.split('/')[2]
       ammounts.at(i).patchValue({
        date:convertedj+rest1+rest2
       })
      }
      setdate4(empindex: number,skillindex: number) {
        let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
         const j= skills.value.at(skillindex).jour
         if (j==0)
     return (skills.at(skillindex).patchValue({
      jour:''
     }))
         if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
         return (skills.at(skillindex).patchValue({
          jour:''
         }))
   if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
   return (skills.at(skillindex).patchValue({
    jour:''
   }))
   if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
   return (skills.at(skillindex).patchValue({
    jour:''
   }))
   if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
   return (skills.at(skillindex).patchValue({
    jour:''
   }))
         const date=j+'/'+this.option2Value+'/'+this.option1Value
         const convertedj=date.split('/')[0].padStart(2,'0')
         const rest1='/'+date.split('/')[1]
         const rest2='/'+date.split('/')[2]
         skills.at(skillindex).patchValue({
          date:convertedj+rest1+rest2
         })
        }
        setdate5(i: number) {
          let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
           const j= this.relevejointform.get('ammounts5').value.at(i).jour
           if (j==0)
           return (ammounts5.at(i).patchValue({
            jour:''
           }))
           if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
           return (ammounts5.at(i).patchValue({
            jour:''
           }))
           if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
           return (ammounts5.at(i).patchValue({
            jour:''
           }))
           if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
           return (ammounts5.at(i).patchValue({
            jour:''
           }))
           if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
           return (ammounts5.at(i).patchValue({
            jour:''
           }))
           const date=j+'/'+this.option2Value+'/'+this.option1Value
           const convertedj=date.split('/')[0].padStart(2,'0')
           const rest1='/'+date.split('/')[1]
           const rest2='/'+date.split('/')[2]
           ammounts5.at(i).patchValue({
            date:convertedj+rest1+rest2
           })
          }
          setdate6(typeindex:number,i: number) {
            let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
            const j= ammounts6.value.at(i).jour
            if (j==0)
        return (ammounts6.at(i).patchValue({
         jour:''
        }))
            if (this.option2Value=='01'&&j>31||this.option2Value=='03'&&j>31||this.option2Value=='05'&&j>31||this.option2Value=='07'&&j>31||this.option2Value=='08'&&j>31||this.option2Value=='10'&&j>31||this.option2Value=='12'&&j>31)
            return (ammounts6.at(i).patchValue({
             jour:''
            }))
      if (this.option2Value=='09'&&j>30||this.option2Value=='11'&&j>30||this.option2Value=='04'&&j>30||this.option2Value=='06'&&j>30)
      return (ammounts6.at(i).patchValue({
       jour:''
      }))
      if (this.option2Value=='02'&&+this.option1Value % 4 !=0&&j>28)
      return (ammounts6.at(i).patchValue({
       jour:''
      }))
      if (this.option2Value=='02'&&+this.option1Value % 4 ==0&&j>29)
      return (ammounts6.at(i).patchValue({
       jour:''
      }))
            const date=j+'/'+this.option2Value+'/'+this.option1Value
            const convertedj=date.split('/')[0].padStart(2,'0')
            const rest1='/'+date.split('/')[1]
            const rest2='/'+date.split('/')[2]
            ammounts6.at(i).patchValue({
             date:convertedj+rest1+rest2
            })
            }
            settva0(i: number) { 
              let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
              const mht= this.editionnotepastform.get('ammounts0').value.at(i).montantht
              const mht19= this.editionnotepastform.get('ammounts0').value.at(i).montantht19
              const mht13= this.editionnotepastform.get('ammounts0').value.at(i).montantht13
              const mhtexo= this.editionnotepastform.get('ammounts0').value.at(i).montanthtexo
              const mhtsus= this.editionnotepastform.get('ammounts0').value.at(i).montanthtsus
              const mhtexp= this.editionnotepastform.get('ammounts0').value.at(i).montanthtexp
         
               const montanttva19=+(mht19*+(this.editionnotepastform.getRawValue().ammounts0)[i].tauxtva19).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttva19:montanttva19
                })
                const montanttva13=+(mht13*+(this.editionnotepastform.getRawValue().ammounts0)[i].tauxtva13).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttva13:montanttva13
                })
                const montanttvaexo=+(mhtexo*+(this.editionnotepastform.getRawValue().ammounts0)[i].tauxtvaexo).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttvaexo:montanttvaexo
                })
                const montanttvasus=+(mhtsus*+(this.editionnotepastform.getRawValue().ammounts0)[i].tauxtvasus).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttvasus:montanttvasus
                })
                const montanttvaexp=+(mhtexp*+(this.editionnotepastform.getRawValue().ammounts0)[i].tauxtvaexp).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttvaexp:montanttvaexp
                })
               const montanttva=+(mht*+this.tauxtva).toFixed(3)
               ammounts0.at(i).patchValue({
                 montanttva:montanttva+montanttva19+montanttva13
                })
               
              }
              settva(i: number) { 
                let ammounts = this.editionnoteform.get('ammounts') as FormArray;
                 const mht= this.editionnoteform.get('ammounts').value.at(i).montantht
                 const mht19= this.editionnoteform.get('ammounts').value.at(i).montantht19
                 const mht13= this.editionnoteform.get('ammounts').value.at(i).montantht13
                 const mhtexo= this.editionnoteform.get('ammounts').value.at(i).montanthtexo
                 const mhtsus= this.editionnoteform.get('ammounts').value.at(i).montanthtsus
                 const mhtexp= this.editionnoteform.get('ammounts').value.at(i).montanthtexp
            
                  const montanttva19=+(mht19*+(this.editionnoteform.getRawValue().ammounts)[i].tauxtva19).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttva19:montanttva19
                   })
                   const montanttva13=+(mht13*+(this.editionnoteform.getRawValue().ammounts)[i].tauxtva13).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttva13:montanttva13
                   })
                   const montanttvaexo=+(mhtexo*+(this.editionnoteform.getRawValue().ammounts)[i].tauxtvaexo).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttvaexo:montanttvaexo
                   })
                   const montanttvasus=+(mhtsus*+(this.editionnoteform.getRawValue().ammounts)[i].tauxtvasus).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttvasus:montanttvasus
                   })
                   const montanttvaexp=+(mhtexp*+(this.editionnoteform.getRawValue().ammounts)[i].tauxtvaexp).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttvaexp:montanttvaexp
                   })
                  const montanttva=+(mht*+this.tauxtva).toFixed(3)
                  ammounts.at(i).patchValue({
                    montanttva:montanttva+montanttva19+montanttva13
                   })
                 
                }
               
                  settva3(i: number) {
                    let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
                     const mht= this.factureachatform.get('ammounts3').value.at(i).montantht
                     
                     const montanttva=(mht*+this.tauxtva).toFixed(3)
                     ammounts3.at(i).patchValue({
                      montanttva:montanttva
                     })
                   
                    }
                    setht0(i: number) {
                      let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                      const mttc= +this.editionnotepastform.get('ammounts0').value.at(i).montantttc
                      const mttc19= +this.editionnotepastform.get('ammounts0').value.at(i).montantttc19
                      const mdt= +this.editionnotepastform.get('ammounts0').value.at(i).montantdt
                
                      if(mttc!=0&&mttc19==0)
                      {
                      const montantht=+((mttc-mdt)/(1+ +this.tauxtva)).toFixed(3)
                      const montanttva=(mttc-mdt-montantht).toFixed(3)
                      ammounts0.at(i).patchValue({
                       montantht:montantht,
                       montanttva:montanttva
                      })
                      }
                      else if(mttc==0&&mttc19!=0)
                      {
                       const montantht19=+((mttc19-mdt)/(1+ 0.19)).toFixed(3)
                      const montanttva19=(mttc19-mdt-montantht19).toFixed(3)
                      ammounts0.at(i).patchValue({
                       montantht19:montantht19,
                       montanttva19:montanttva19
                      })
                      }
                      else
                      {
                      const montantht=+((mttc-mdt/2)/(1+ +this.tauxtva)).toFixed(3)
                      const montanttva=(mttc-mdt/2-montantht).toFixed(3)
                      ammounts0.at(i).patchValue({
                       montantht:montantht,
                       montanttva:montanttva
                      })
                       const montantht19=+((mttc19-mdt/2)/(1+ 0.19)).toFixed(3)
                      const montanttva19=(mttc19-mdt/2-montantht19).toFixed(3)
                      ammounts0.at(i).patchValue({
                       montantht19:montantht19,
                       montanttva19:montanttva19
                      })
                      }
                      }
                setht(i: number) {
                  /*let ammounts = this.editionnoteform.get('ammounts') as FormArray;
                  const mttc= +this.editionnoteform.get('ammounts').value.at(i).montantttc
                  const mttc19= +this.editionnoteform.get('ammounts').value.at(i).montantttc19
                  const mttcexo= +this.editionnoteform.get('ammounts').value.at(i).montantttcexo
                  const mttcsus= +this.editionnoteform.get('ammounts').value.at(i).montantttcsus
                  const mttcexp= +this.editionnoteform.get('ammounts').value.at(i).montantttcexp
                  const mdt= +this.editionnoteform.get('ammounts').value.at(i).montantdt
                  console.log()
                  if(mttcexo!=0)
                  {
                    const montanthtexo=+((mttcexo-mdt)).toFixed(3)
                    const montanttvaexo=(mttcexo-mdt).toFixed(3)
                    ammounts.at(i).patchValue({
                     montanthtexo:montanthtexo,
                     montanttvaexo:montanttvaexo
                    })
                  }
                  if(mttcsus!=0)
                  {
                    const montanthtsus=+((mttcsus-mdt)).toFixed(3)
                    const montanttvasus=(mttcsus-mdt).toFixed(3)
                    ammounts.at(i).patchValue({
                     montanthtsus:montanthtsus,
                     montanttvasus:montanttvasus
                    })
                  }
                  if(mttcexp!=0)
                  {
                    const montanthtexp=+((mttcexp-mdt)).toFixed(3)
                    const montanttvaexp=(mttcexp-mdt).toFixed(3)
                    ammounts.at(i).patchValue({
                     montanthtexp:montanthtexp,
                     montanttvaexp:montanttvaexp
                    })
                  }
                  if(mttc!=0&&mttc19==0)
                  {
                  const montantht=+((mttc-mdt)/(1+ +this.tauxtva)).toFixed(3)
                  const montanttva=(mttc-mdt-montantht).toFixed(3)
                  ammounts.at(i).patchValue({
                   montantht:montantht,
                   montanttva:montanttva
                  })
                  }
                  else if(mttc==0&&mttc19!=0)
                  {
                   const montantht19=+((mttc19-mdt)/(1+ 0.19)).toFixed(3)
                  const montanttva19=(mttc19-mdt-montantht19).toFixed(3)
                  ammounts.at(i).patchValue({
                   montantht19:montantht19,
                   montanttva19:montanttva19
                  })
                  }
                  else
                  {
                  const montantht=+((mttc-mdt)/(1+ +this.tauxtva)).toFixed(3)
                  const montanttva=(mttc-mdt-montantht).toFixed(3)
                  ammounts.at(i).patchValue({
                   montantht:montantht,
                   montanttva:montanttva
                  })
                   const montantht19=+((mttc19)/(1+ 0.19)).toFixed(3)
                  const montanttva19=(mttc19-montantht19).toFixed(3)
                  ammounts.at(i).patchValue({
                   montantht19:montantht19,
                   montanttva19:montanttva19
                  })
                  }*/
                  }
                  setht2(i: number) {
                    let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
                    this.recettejournaliereform.get('ammounts2').value.at(i).montantdt=this.tauxdt
                    const mrecette= +this.recettejournaliereform.get('ammounts2').value.at(i).recette
                    const mrecette19= +this.recettejournaliereform.get('ammounts2').value.at(i).recette19
                    const mrecette13= +this.recettejournaliereform.get('ammounts2').value.at(i).recette13
            
                    const mrecetteexo= +this.recettejournaliereform.get('ammounts2').value.at(i).recetteexo
                    const mrecettesus= +this.recettejournaliereform.get('ammounts2').value.at(i).recettesus
                    const mrecetteexp= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexp
                    const mrecetteexpdevise= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexpdevise
                    const devise= (this.recettejournaliereform.get('ammounts2').value).at(i).devise
            
                    const mtimbre= 0   
                    const montantttcexo=+(mrecetteexo+mtimbre).toFixed(3) 
                    const montanthtexo=+((+montantttcexo-mtimbre)).toFixed(3)
                    const montanttvaexo=+(montantttcexo-montanthtexo-mtimbre).toFixed(3)
                      const montantttc19=+(mrecette19+(mtimbre/2)).toFixed(3) 
                      const montantht19=+((+montantttc19-(mtimbre/2))/(1+ 0.19)).toFixed(3)
                      const montanttva19=+(montantttc19-montantht19-(mtimbre/2)).toFixed(3)
                      const montantttc13=+(mrecette13+(mtimbre/2)).toFixed(3) 
                      const montantht13=+((+montantttc13-(mtimbre/2))/(1+ 0.13)).toFixed(3)
                      const montanttva13=+(montantttc13-montantht13-(mtimbre/2)).toFixed(3)
                      const montantttcsus=+(mrecettesus+mtimbre).toFixed(3) 
                      const montanthtsus=+((+montantttcsus-mtimbre)).toFixed(3)
                      const montanttvasus=+(montantttcsus-montanthtsus-mtimbre).toFixed(3)
                      const montantttcexp=+(mrecetteexp+mtimbre).toFixed(3) 
                      const montanthtexp=+((+montantttcexp-mtimbre)).toFixed(3)
                      const montanttvaexp=+(montantttcexp-montanthtexp-mtimbre).toFixed(3)
                      this.realht2sus=this.totalht2sus
                      this.realht219=this.totalht219
                      this.realht213=this.totalht213
            
                    this.realht2=this.totalht2
                    this.realht2exo=this.totalht2exo
                    this.realht2exp=this.totalht2exp
                    ammounts2.at(i).patchValue({
                     montanthtexo:montanthtexo,
                     montanttvaexo:montanttvaexo,
                     montantttcexo:montantttcexo,
                    })
                    ammounts2.at(i).patchValue({
                      montanthtsus:montanthtsus,
                      montanttvasus:montanttvasus,
                      montantttcsus:montantttcsus,
                     })
                     ammounts2.at(i).patchValue({
                      montanthtexp:montanthtexp,
                      montanttvaexp:montanttvaexp,
                      montantttcexp:montantttcexp,
                     })
                     ammounts2.at(i).patchValue({
                      montantht19:montantht19,
                      montanttva19:montanttva19,
                      montantttc19:montantttc19,
                     })
                     ammounts2.at(i).patchValue({
                      montantht13:montantht13,
                      montanttva13:montanttva13,
                      montantttc13:montantttc13,
                     })
            
                     if(mrecetteexpdevise==0&&devise!='tnd')
                     {
                      const montantttc=+(mrecette+mrecette19+mrecette13+mrecetteexo+mrecettesus).toFixed(3) 
                      const montantht=+((+mrecette)/(1+ +this.tauxtva)+(+mrecette19)/(1+ 0.19)+(+mrecette13)/(1+ 0.13)+(+mrecetteexo)+(+mrecettesus)).toFixed(3)
                      const montanttva=+((montantttc-montantht)).toFixed(3)
                        ammounts2.at(i).patchValue({
                        montantttc:montantttc,
                        montantht:montantht,
                        montanttva:montanttva,
                        recetteexp:0,
                        recetteexpdevise:0,
                       })
                     }
                     else if(mrecetteexp!=0)
                     {
                       ammounts2.at(i).patchValue({
                         recette:0,
                         recette19:0,
                         recette13:0,
                         recetteexo:0,
                         montanthtsus:0,
                         montanttva:0,
                         montanttva19:0,
                         montanttva13:0,
                         montantdt:0,
                         montantttc:mrecetteexp,
                         montantht:mrecetteexp
                        })
                     }
                     else if(mrecetteexpdevise!=0)
                     {
                       this.settservice.getCarouselalldata()
                           this.settservice.carousels$.subscribe(
                             (settings) => {
                              let currentdate=this.option1Value+'-'+this.option2Value+'-'+(this.recettejournaliereform.getRawValue().ammounts2)[i].jour                  
                              let filtredsorted=[]
                              let sorted=[]
                              this.settings = settings; 
                              this.cours=(this.settings.filter(p => p.cours.length>0))[0].cours
                              const presorted=this.cours.sort(this.commun.sortByDate);
                             
            this.option2Value=='01'||this.option2Value=='03'||this.option2Value=='05'||this.option2Value=='07'
            ||this.option2Value=='08'||this.option2Value=='10'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value+1)+'-'+'01'))
            :this.option2Value=='12'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date((+this.option1Value+1)+'-'+'01'+'-'+'01'))
            :this.option2Value=='09'||this.option2Value=='11'||this.option2Value=='04'||this.option2Value=='06'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'31'))
            :this.option2Value=='02'&&+this.option1Value % 4 !=0?
              sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'29'))
              :sorted= presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'30'))
            sorted.length>0?
            currentdate&&(this.recettejournaliereform.getRawValue().ammounts)[i].jour?filtredsorted=sorted.filter(p =>new Date(p.jour)<=new Date(currentdate)):filtredsorted=sorted
            :filtredsorted=presorted
                               devise=='euro'?
                               (
                                 
                               ammounts2.at(i).patchValue({
                                 recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                 //@ts-ignore
                                 recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].euro).toFixed(3),
                                 //@ts-ignore
                                 montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].euro).toFixed(3),
                                 //@ts-ignore
                                 montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].euro).toFixed(3),
                                 recette19:0,
                                 recette13:0,
                                 recetteexo:0,
                                 recettesus:0,
                                 montanttva:0,
                                 montanttva19:0,
                                 montanttva13:0,
                                 montantdt:0,
                                 //@ts-ignore
                                 montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].euro).toFixed(3),
                                                     //@ts-ignore
                                 montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].euro).toFixed(3)
                                })
                               )
                               :devise=='dollar'?
                               (
                                ammounts2.at(i).patchValue({
                                  recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                  //@ts-ignore
                                  recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dollar).toFixed(3),
                                  //@ts-ignore
                                  montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dollar).toFixed(3),
                                  //@ts-ignore
                                  montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dollar).toFixed(3),
                                  recette19:0,
                                  recette13:0,
                                  recetteexo:0,
                                  recettesus:0,
                                  montanttva:0,
                                  montanttva19:0,
                                  montanttva13:0,
                                  montantdt:0,
                                  //@ts-ignore
                                  montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dollar).toFixed(3),
                                                      //@ts-ignore
                                  montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dollar).toFixed(3)
                                 }),
                                  console.log('4')
                                 )
                                 :devise=='dzd'?
                                 (
                                  ammounts2.at(i).patchValue({
                                    recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                    //@ts-ignore
                                    recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dzd).toFixed(3),
                                    //@ts-ignore
                                    montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dzd).toFixed(3),
                                    //@ts-ignore
                                    montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dzd).toFixed(3),
                                    recette19:0,
                                    recette13:0,
                                    recetteexo:0,
                                    recettesus:0,
                                    montanttva:0,
                                    montanttva19:0,
                                    montanttva13:0,
                                    montantdt:0,
                                    //@ts-ignore
                                    montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dzd).toFixed(3),
                                                        //@ts-ignore
                                    montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dzd).toFixed(3)
                                   }),
                                    console.log('4')
                                   )
                                   :devise=='sar'?
                                   (
                                    ammounts2.at(i).patchValue({
                                      recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                      //@ts-ignore
                                      recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sar).toFixed(3),
                                      //@ts-ignore
                                      montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sar).toFixed(3),
                                      //@ts-ignore
                                      montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sar).toFixed(3),
                                      recette19:0,
                                      recette13:0,
                                      recetteexo:0,
                                      recettesus:0,
                                      montanttva:0,
                                      montanttva19:0,
                                      montanttva13:0,
                                      montantdt:0,
                                      //@ts-ignore
                                      montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sar).toFixed(3),
                                                          //@ts-ignore
                                      montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sar).toFixed(3)
                                     }),
                                      console.log('4')
                                     )
                                     :devise=='cad'?
                                     (
                                      ammounts2.at(i).patchValue({
                                        recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                        //@ts-ignore
                                        recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cad).toFixed(3),
                                        //@ts-ignore
                                        montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cad).toFixed(3),
                                        //@ts-ignore
                                        montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cad).toFixed(3),
                                        recette19:0,
                                        recette13:0,
                                        recetteexo:0,
                                        recettesus:0,
                                        montanttva:0,
                                        montanttva19:0,
                                        montanttva13:0,
                                        montantdt:0,
                                        //@ts-ignore
                                        montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cad).toFixed(3),
                                                            //@ts-ignore
                                        montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cad).toFixed(3)
                                       }),
                                        console.log('4')
                                       )
                                       :devise=='dkk'?
                                       (
                                        ammounts2.at(i).patchValue({
                                          recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                          //@ts-ignore
                                          recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dkk).toFixed(3),
                                          //@ts-ignore
                                          montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dkk).toFixed(3),
                                          //@ts-ignore
                                          montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dkk).toFixed(3),
                                          recette19:0,
                                          recette13:0,
                                          recetteexo:0,
                                          recettesus:0,
                                          montanttva:0,
                                          montanttva19:0,
                                          montanttva13:0,
                                          montantdt:0,
                                          //@ts-ignore
                                          montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dkk).toFixed(3),
                                                              //@ts-ignore
                                          montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].dkk).toFixed(3)
                                         }),
                                          console.log('4')
                                         )
                                         :devise=='jpy'?
                                         (
                                          ammounts2.at(i).patchValue({
                                            recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                            //@ts-ignore
                                            recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].jpy).toFixed(3),
                                            //@ts-ignore
                                            montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].jpy).toFixed(3),
                                            //@ts-ignore
                                            montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].jpy).toFixed(3),
                                            recette19:0,
                                            recette13:0,
                                            recetteexo:0,
                                            recettesus:0,
                                            montanttva:0,
                                            montanttva19:0,
                                            montanttva13:0,
                                            montantdt:0,
                                            //@ts-ignore
                                            montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].jpy).toFixed(3),
                                                                //@ts-ignore
                                            montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].jpy).toFixed(3)
                                           }),
                                            console.log('4')
                                           )
                                           :devise=='mad'?
                                           (
                                            ammounts2.at(i).patchValue({
                                              recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                              //@ts-ignore
                                              recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mad).toFixed(3),
                                              //@ts-ignore
                                              montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mad).toFixed(3),
                                              //@ts-ignore
                                              montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mad).toFixed(3),
                                              recette19:0,
                                              recette13:0,
                                              recetteexo:0,
                                              recettesus:0,
                                              montanttva:0,
                                              montanttva19:0,
                                              montanttva13:0,
                                              montantdt:0,
                                              //@ts-ignore
                                              montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mad).toFixed(3),
                                                                  //@ts-ignore
                                              montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mad).toFixed(3)
                                             }),
                                              console.log('4')
                                             )
                                             :devise=='nok'?
                                             (
                                              ammounts2.at(i).patchValue({
                                                recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                //@ts-ignore
                                                recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].nok).toFixed(3),
                                                //@ts-ignore
                                                montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].nok).toFixed(3),
                                                //@ts-ignore
                                                montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].nok).toFixed(3),
                                                recette19:0,
                                                recette13:0,
                                                recetteexo:0,
                                                recettesus:0,
                                                montanttva:0,
                                                montanttva19:0,
                                                montanttva13:0,
                                                montantdt:0,
                                                //@ts-ignore
                                                montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].nok).toFixed(3),
                                                                    //@ts-ignore
                                                montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].nok).toFixed(3)
                                               }),
                                                console.log('4')
                                               )
                                               :devise=='sek'?
                                               (
                                                ammounts2.at(i).patchValue({
                                                  recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                  //@ts-ignore
                                                  recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sek).toFixed(3),
                                                  //@ts-ignore
                                                  montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sek).toFixed(3),
                                                  //@ts-ignore
                                                  montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sek).toFixed(3),
                                                  recette19:0,
                                                  recette13:0,
                                                  recetteexo:0,
                                                  recettesus:0,
                                                  montanttva:0,
                                                  montanttva19:0,
                                                  montanttva13:0,
                                                  montantdt:0,
                                                  //@ts-ignore
                                                  montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sek).toFixed(3),
                                                                      //@ts-ignore
                                                  montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].sek).toFixed(3)
                                                 }),
                                                  console.log('4')
                                                 )
                                               :devise=='chf'?
                                               (
                                                ammounts2.at(i).patchValue({
                                                  recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                  //@ts-ignore
                                                  recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].chf).toFixed(3),
                                                  //@ts-ignore
                                                  montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].chf).toFixed(3),
                                                  //@ts-ignore
                                                  montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].chf).toFixed(3),
                                                  recette19:0,
                                                  recette13:0,
                                                  recetteexo:0,
                                                  recettesus:0,
                                                  montanttva:0,
                                                  montanttva19:0,
                                                  montanttva13:0,
                                                  montantdt:0,
                                                  //@ts-ignore
                                                  montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].chf).toFixed(3),
                                                                      //@ts-ignore
                                                  montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].chf).toFixed(3)
                                                 }),
                                                  console.log('4')
                                                 )
                                                 :devise=='kwd'?
                                                 (
                                                  ammounts2.at(i).patchValue({
                                                    recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                    //@ts-ignore
                                                    recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                                    //@ts-ignore
                                                    montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                                    //@ts-ignore
                                                    montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                                    recette19:0,
                                                    recette13:0,
                                                    recetteexo:0,
                                                    recettesus:0,
                                                    montanttva:0,
                                                    montanttva19:0,
                                                    montanttva13:0,
                                                    montantdt:0,
                                                    //@ts-ignore
                                                    montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                                                        //@ts-ignore
                                                    montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].kwd).toFixed(3)
                                                   }),
                                                    console.log('4')
                                                   )
                                                   :devise=='aed'?
                                                   (
                                                    ammounts2.at(i).patchValue({
                                                      recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                      //@ts-ignore
                                                      recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].aed).toFixed(3),
                                                      //@ts-ignore
                                                      montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].aed).toFixed(3),
                                                      //@ts-ignore
                                                      montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].aed).toFixed(3),
                                                      recette19:0,
                                                      recette13:0,
                                                      recetteexo:0,
                                                      recettesus:0,
                                                      montanttva:0,
                                                      montanttva19:0,
                                                      montanttva13:0,
                                                      montantdt:0,
                                                      //@ts-ignore
                                                      montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].aed).toFixed(3),
                                                                          //@ts-ignore
                                                      montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].aed).toFixed(3)
                                                     }),
                                                      console.log('4')
                                                     )
                                                     :devise=='lyd'?
                                                     (
                                                      ammounts2.at(i).patchValue({
                                                        recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                        //@ts-ignore
                                                        recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                                        //@ts-ignore
                                                        montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                                        //@ts-ignore
                                                        montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                                        recette19:0,
                                                        recette13:0,
                                                        recetteexo:0,
                                                        recettesus:0,
                                                        montanttva:0,
                                                        montanttva19:0,
                                                        montanttva13:0,
                                                        montantdt:0,
                                                        //@ts-ignore
                                                        montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                                                            //@ts-ignore
                                                        montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].lyd).toFixed(3)
                                                       }),
                                                        console.log('4')
                                                       )
                                                       :devise=='mru'?
                                                       (
                                                        ammounts2.at(i).patchValue({
                                                          recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                          //@ts-ignore
                                                          recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mru).toFixed(3),
                                                          //@ts-ignore
                                                          montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mru).toFixed(3),
                                                          //@ts-ignore
                                                          montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mru).toFixed(3),
                                                          recette19:0,
                                                          recette13:0,
                                                          recetteexo:0,
                                                          recettesus:0,
                                                          montanttva:0,
                                                          montanttva19:0,
                                                          montanttva13:0,
                                                          montantdt:0,
                                                          //@ts-ignore
                                                          montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mru).toFixed(3),
                                                                              //@ts-ignore
                                                          montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].mru).toFixed(3)
                                                         }),
                                                          console.log('4')
                                                         )
                                                         :devise=='bhd'?
                                                         (
                                                          ammounts2.at(i).patchValue({
                                                            recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                            //@ts-ignore
                                                            recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                                            //@ts-ignore
                                                            montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                                            //@ts-ignore
                                                            montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                                            recette19:0,
                                                            recette13:0,
                                                            recetteexo:0,
                                                            recettesus:0,
                                                            montanttva:0,
                                                            montanttva19:0,
                                                            montanttva13:0,
                                                            montantdt:0,
                                                            //@ts-ignore
                                                            montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                                                                //@ts-ignore
                                                            montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].bhd).toFixed(3)
                                                           }),
                                                            console.log('4')
                                                           )
                                                           :devise=='qar'?
                                                           (
                                                            ammounts2.at(i).patchValue({
                                                              recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                              //@ts-ignore
                                                              recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].qar).toFixed(3),
                                                              //@ts-ignore
                                                              montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].qar).toFixed(3),
                                                              //@ts-ignore
                                                              montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].qar).toFixed(3),
                                                              recette19:0,
                                                              recette13:0,
                                                              recetteexo:0,
                                                              recettesus:0,
                                                              montanttva:0,
                                                              montanttva19:0,
                                                              montanttva13:0,
                                                              montantdt:0,
                                                              //@ts-ignore
                                                              montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].qar).toFixed(3),
                                                                                  //@ts-ignore
                                                              montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].qar).toFixed(3)
                                                             }),
                                                              console.log('4')
                                                             )
                                                             :devise=='cny'?
                                                             (
                                                              ammounts2.at(i).patchValue({
                                                                recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                                                //@ts-ignore
                                                                recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                                //@ts-ignore
                                                                montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                                //@ts-ignore
                                                                montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                                recette19:0,
                                                                recette13:0,
                                                                recetteexo:0,
                                                                recettesus:0,
                                                                montanttva:0,
                                                                montanttva19:0,
                                                                montanttva13:0,
                                                                montantdt:0,
                                                                //@ts-ignore
                                                                montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                                                    //@ts-ignore
                                                                montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].cny).toFixed(3)
                                                               }),
                                                                console.log('4')
                                                               )
                                 :
                                 (
                                  ammounts2.at(i).patchValue({
                                    recetteexpdevise:+(this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise,
                                    //@ts-ignore
                                    recetteexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].gbp).toFixed(3),
                                    //@ts-ignore
                                    montantttcexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].gbp).toFixed(3),
                                    //@ts-ignore
                                    montanthtexp:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].gbp).toFixed(3),
                                    recette19:0,
                                 recette13:0,
                                 recetteexo:0,
                                 recettesus:0,
                                 montanttva:0,
                                 montanttva19:0,
                                 montanttva13:0,
                                 montantdt:0,
                                    //@ts-ignore
                                    montantht:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].gbp).toFixed(3),
                                                        //@ts-ignore
                                    montantttc:+((this.recettejournaliereform.getRawValue().ammounts2)[i].recetteexpdevise* +filtredsorted[0].gbp).toFixed(3)
                                   }),
                                    console.log('5')
                                   )
                                   this.totalht2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexo || 0);
                                    return acc;
                                  },0);
                                  this.totaltva2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montanttvaexo || 0);
                                    return acc;
                                  },0);
                                 
                                  this.totalttc2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montantttcexo || 0);
                                    return acc;
                                  },0);
                                  this.totalrecette2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.recetteexo || 0);
                                    return acc;
                                  },0);
                                 
                                  
                                  this.totalht2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtsus || 0);
                                    return acc;
                                  },0);
                                  this.totaltva2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montanttvasus || 0);
                                    return acc;
                                  },0);
                                 
                                  this.totalttc2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.montantttcsus || 0);
                                    return acc;
                                  },0);
                                  this.totalrecette2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                                    acc += +(curr.recettesus || 0);
                                    return acc;
                                  },0);
                                
                                 
                                  this.totalht2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexp || 0);
                                    return acc;
                                  },0);
                               
                                 
                                  this.totalttc2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                                    acc += +(curr.montantttcexp || 0);
                                    return acc;
                                  },0);
                                  this.totalrecette2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                                    acc += +(curr.recetteexp || 0);
                                    return acc;
                                  },0);
                                  
                                   
                                    this.totalht219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montantht19 || 0);
                                      return acc;
                                    },0);
                                    this.totaltva219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montanttva19 || 0);
                                      return acc;
                                    },0);
                                    this.totalttc219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montantttc19 || 0);
                                      return acc;
                                    },0);
                                    this.totalrecette19 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.recette19 || 0);
                                      return acc;
                                    },0);
                                    this.totalht213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montantht13 || 0);
                                      return acc;
                                    },0);
                                    this.totaltva213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montanttva13 || 0);
                                      return acc;
                                    },0);
                                    this.totalttc213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.montantttc13 || 0);
                                      return acc;
                                    },0);
                                    this.totalrecette13 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                      acc += +(curr.recette13 || 0);
                                      return acc;
                                    },0);
                                    
                                   this.totalht2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                    acc += +(curr.montantht || 0);
                                    return acc;
                                  },0);
                                  this.totaltva2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                    acc += +(curr.montanttva || 0);
                                    return acc;
                                  },0);
                                  this.totalttc2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                    acc += +(curr.montantttc || 0);
                                    return acc;
                                  },0);
                                  this.totalrecette = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                                    acc += +(curr.recette || 0);
                                    return acc;
                                  },0); 
                            }
                           )
                     }
                     else if(mrecette!=0||mrecette19!=0||mrecette13!=0||mrecetteexo!=0||mrecettesus!=0)
                     {
                       const montantttc=+(mrecette+mrecette19+mrecette13+mrecetteexo+mrecettesus).toFixed(3) 
                       const montantht=+((+mrecette)/(1+ +this.tauxtva)+(+mrecette19)/(1+ 0.19)+(+mrecette13)/(1+ 0.13)+(+mrecetteexo)+(+mrecettesus)).toFixed(3)
                       const montanttva=+((montantttc-montantht)).toFixed(3)
                        ammounts2.at(i).patchValue({
                        montantttc:montantttc,
                        montantht:montantht,
                        montanttva:montanttva,
                        recetteexp:0,
                       })
                     }
                     else
                     {
                      ammounts2.at(i).patchValue({
                        recette:0,
                        recette19:0,
                        recette13:0,
            
                        recetteexo:0,
                        recetteexp:0,
                        recetteexpdevise:0,
                        montanttva:0,
                        montanttva19:0,
                        montanttva13:0,
            
                        montantttc:0,
                        montantht:0
                       })
                     }
                    this.totalht2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexo || 0);
                      return acc;
                    },0);
                    this.totaltva2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montanttvaexo || 0);
                      return acc;
                    },0);
                   
                    this.totalttc2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montantttcexo || 0);
                      return acc;
                    },0);
                    this.totalrecette2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.recetteexo || 0);
                      return acc;
                    },0);
                   
                    
                    this.totalht2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montanthtsus || 0);
                      return acc;
                    },0);
                    this.totaltva2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montanttvasus || 0);
                      return acc;
                    },0);
                   
                    this.totalttc2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.montantttcsus || 0);
                      return acc;
                    },0);
                    this.totalrecette2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                      acc += +(curr.recettesus || 0);
                      return acc;
                    },0);
                  
                   
                    this.totalht2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexp || 0);
                      return acc;
                    },0);
                 
                   
                    this.totalttc2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                      acc += +(curr.montantttcexp || 0);
                      return acc;
                    },0);
                    this.totalrecette2exp = +(this.recettejournaliereform.getRawValue().ammounts2).reduce((acc,curr)=>{
                      acc += +(curr.recetteexp || 0);
                      return acc;
                    },0);
                    
                     
                      this.totalht219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montantht19 || 0);
                        return acc;
                      },0);
                      this.totaltva219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montanttva19 || 0);
                        return acc;
                      },0);
                      this.totalttc219 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montantttc19 || 0);
                        return acc;
                      },0);
                      this.totalrecette19 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.recette19 || 0);
                        return acc;
                      },0);
                      
                      this.totalht213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montantht13 || 0);
                        return acc;
                      },0);
                      this.totaltva213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montanttva13 || 0);
                        return acc;
                      },0);
                      this.totalttc213 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.montantttc13 || 0);
                        return acc;
                      },0);
                      this.totalrecette13 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                        acc += +(curr.recette13 || 0);
                        return acc;
                      },0);
                     this.totalht2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);
                    this.totaltva2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                      acc += +(curr.montanttva || 0);
                      return acc;
                    },0);
                    this.totalttc2 = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                      acc += +(curr.montantttc || 0);
                      return acc;
                    },0);
                    this.totalrecette = +this.recettejournaliereform.getRawValue().ammounts2.reduce((acc,curr)=>{
                      acc += +(curr.recette || 0);
                      return acc;
                    },0);        
                    }
                    setht3(i: number) {
                      let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
                      let factureammounts=[]
                      let avoirammounts=[]
                      let totalht3avoir=0
                      let totalht3facture=0
                      let totalht3deviseavoir=0
                      let totalht3devisefacture=0
                      let totaltva3avoir=0
                      let totaltva3facture=0
                      let totaldt3avoir=0
                      let totaldt3facture=0
                      let totalttc3avoir=0
                      let totalttc3facture=0
                      
                      this.factureachatform.getRawValue().ammounts3.filter((item)=>
                      {
                        item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                      })
                       const mttc= this.factureachatform.get('ammounts3').value.at(i).montantttc
                       const mdt= this.factureachatform.get('ammounts3').value.at(i).montantdt           
                       const montantht=+((mttc-mdt)/(1+ +this.tauxtva)).toFixed(3)
                       const montanttva=+(mttc-montantht-mdt).toFixed(3)
                       const montantdt=(mttc-montantht-montanttva).toFixed(3)
                       ammounts3.at(i).patchValue({
                        montantht:montantht,
                        montanttva:montanttva,
                        montantdt:montantdt,
                       })
                       totalht3facture = +(factureammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);
                      totalht3avoir = +(avoirammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);      
                this.totalht3 = totalht3facture - totalht3avoir
                totalht3devisefacture = +(factureammounts).reduce((acc,curr)=>{
                  acc += +(curr.montanthtachat || 0);
                  return acc;
                },0);
                totalht3deviseavoir = +(avoirammounts).reduce((acc,curr)=>{
                  acc += +(curr.montanthtachat || 0);
                  return acc;
                },0);      
            this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
                totaltva3facture= +(factureammounts).reduce((acc,curr)=>{
                  acc += +(curr.montanttva || 0);
                  return acc;
                },0);
                totaltva3avoir= +(avoirammounts).reduce((acc,curr)=>{
                  acc += +(curr.montanttva || 0);
                  return acc;
                },0);
                this.totaltva3 = totaltva3facture - totaltva3avoir
                totaldt3facture= +(factureammounts).reduce((acc,curr)=>{
                  acc += +(curr.montantdt || 0);
                  return acc;
                },0);
                totaldt3avoir= +(avoirammounts).reduce((acc,curr)=>{
                  acc += +(curr.montantdt || 0);
                  return acc;
                },0);
                this.totaldt3 = totaldt3facture - totaldt3avoir
                totalttc3facture= +(factureammounts).reduce((acc,curr)=>{
                  acc += +(curr.montantttc || 0);
                  return acc;
                },0);
                totalttc3avoir=+(avoirammounts).reduce((acc,curr)=>{
                  acc += +(curr.montantttc || 0);
                  return acc;
                },0);
                this.totalttc3 = totalttc3facture - totalttc3avoir   
                      }
                      setttc0(i: number) {
                        let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                         const mht= +(this.editionnotepastform.get('ammounts0').value).at(i).montantht
                         const mht19= +(this.editionnotepastform.get('ammounts0').value).at(i).montantht19
                         const mtva= +(this.editionnotepastform.get('ammounts0').value.at(i).montanttva)
                         const mtva19= +(this.editionnotepastform.get('ammounts0').value.at(i).montanttva19)
                         const mdt= +(this.editionnotepastform.get('ammounts0').value).at(i).montantdt
                  
                         console.log(mht)
                         if(mht!=0&&mht19==0)
                         {
                          const montantttc=(mht+ +mtva+mdt).toFixed(3)
                         ammounts0.at(i).patchValue({
                          montantttc:montantttc
                         })
                        
                         }
                         else if(mht==0&&mht19!=0)
                         {
                          const montantttc19=(mht19+ +mtva19+mdt).toFixed(3)
                  
                          ammounts0.at(i).patchValue({
                           montantttc19:montantttc19
                          })
                         }
                         else
                         {
                          const montantttc=(mht+ +mtva+(mdt/2)).toFixed(3)
                         ammounts0.at(i).patchValue({
                          montantttc:montantttc
                         })
                         const montantttc19=(mht19+ +mtva19+(mdt/2)).toFixed(3)
                  
                          ammounts0.at(i).patchValue({
                           montantttc19:montantttc19
                          })
                         
                         }
                        
                         ammounts0.controls[i].get('tauxtva').disable();
                        }
                        changedevise(i:number)
                        {
                          let ammounts = this.editionnoteform.get('ammounts') as FormArray;
                          let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
                          let devise:string
                          let devise2:string
                          let mrecette:number
                          let mrecette19:number
                          let mrecette13:number
            
                          let mrecetteexo:number
                          let mrecettesus:number
                          let mrecetteexp:number
                          let mrecetteexpdevise:number
                          let mht:number
                          let mht19:number
                          let mht13:number
            
                          let mhtexo:number
                          let mhtsus:number
                          let mhtexp:number
                          let mtva:number
                          let mtva19:number
                          let mtva13:number
            
                          let mtvaexo:number
                          let mtvasus:number
                          let mtvaexp:number
                          let mdt:number
                          if(i>0)
                          {
                            (this.recettejournaliereform.get('ammounts2').value).at(i)?
                            (devise2=(this.recettejournaliereform.get('ammounts2').value).at(i).devise,
                            console.log('devise ammounts 2'),
                           mrecette= +this.recettejournaliereform.get('ammounts2').value.at(i).recette
                            ,mrecette19= +this.recettejournaliereform.get('ammounts2').value.at(i).recette19
                            ,mrecette13= +this.recettejournaliereform.get('ammounts2').value.at(i).recette13
                            , mrecetteexo= +this.recettejournaliereform.get('ammounts2').value.at(i).recetteexo
                            ,mrecettesus= +this.recettejournaliereform.get('ammounts2').value.at(i).recettesus
                            , mrecetteexp= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexp
                            , mrecetteexpdevise= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexpdevise)
                            :(devise=(this.editionnoteform.get('ammounts').value).at(i).devise,
                            console.log('devise ammounts'),
                             mht= +(this.editionnoteform.get('ammounts').value).at(i).montantht
                            , mht19= +(this.editionnoteform.get('ammounts').value).at(i).montantht19
                            , mht13= +(this.editionnoteform.get('ammounts').value).at(i).montantht13
                            , mhtexo= +(this.editionnoteform.get('ammounts').value).at(i).montanthtexo
                            , mhtsus= +(this.editionnoteform.get('ammounts').value).at(i).montanthtsus
                            , mhtexp= +(this.editionnoteform.get('ammounts').value).at(i).montanthtexp
                            , mtva= +(this.editionnoteform.get('ammounts').value.at(i).montanttva)
                            , mtva19= +(this.editionnoteform.get('ammounts').value.at(i).montanttva19)
                            , mtva13= +(this.editionnoteform.get('ammounts').value.at(i).montanttva13)
            
                            , mtvaexo= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexo)
                            , mtvasus= +(this.editionnoteform.get('ammounts').value.at(i).montanttvasus)
                            , mtvaexp= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexp)
                            , mdt= +(this.editionnoteform.get('ammounts').value).at(i).montantdt,
                            console.log(mht,mtva,mtva19,mdt)
              
                            )
                            const montantttc19=+(mht19+ +mtva19).toFixed(3)
                            const montantttc13=+(mht13+ +mtva13).toFixed(3)
            
                            if(ammounts.at(i))
                            {
                              ammounts.at(i).patchValue({
                                montantttc19:montantttc19,
                                montantttc13:montantttc13
                               })
                               const montantttcexo=+(mhtexo+ +mtvaexo).toFixed(3)
                               ammounts.at(i).patchValue({
                                montantttcexo:montantttcexo
                               })
                               const montantttcsus=+(mhtsus+ +mtvasus).toFixed(3)
                               ammounts.at(i).patchValue({
                                montantttcsus:montantttcsus
                               })
                               const montantttc=+(mht+ +mtva -mtva19 -mtva13 +mdt+ +montantttc19 +montantttc13+ +montantttcexo+ +montantttcsus).toFixed(3)
                               
                               ammounts.at(i).patchValue({
                                 montanthtexpdevise:0,
                                 montanthtexp:0,
                                 montantttc:montantttc
                                })
                 devise=='tnd'?
                 (ammounts.controls[i].get('montanthtexpdevise').disable(),
                 ammounts.controls[i].get('montanthtexp').enable())
                 :devise=='euro'?
                 (ammounts.controls[i].get('montanthtexpdevise').enable(),
                 ammounts.controls[i].get('montanthtexp').disable())
                 :devise=='dollar'||devise=='dzd'||devise=='sar'||devise=='cad'||devise=='dkk'||
                 devise=='jpy'||devise=='mad'||devise=='nok'||devise=='sek'||devise=='chf'||devise=='kwd'||
                 devise=='aed'||devise=='lyd'||devise=='mru'||devise=='bhd'||devise=='qar'||devise=='cny'?
                 (ammounts.controls[i].get('montanthtexpdevise').enable(),
                 ammounts.controls[i].get('montanthtexp').disable())
                 :devise=='gbp'?
                 (ammounts.controls[i].get('montanthtexpdevise').enable(),
                 ammounts.controls[i].get('montanthtexp').disable())
                 :''
                            }
              else
              {  
              //change devise recette
              const montantttcexo2=+(mrecetteexo).toFixed(3) 
              const montanthtexo=+((+montantttcexo2)).toFixed(3)
              const montantttc192=+(mrecette19).toFixed(3) 
              const montantttc132=+(mrecette13).toFixed(3) 
            
              const montantht192=+((+montantttc19)/(1+ 0.19)).toFixed(3)
              const montanttva192=+(montantttc192-montantht192).toFixed(3)
              const montantht132=+((+montantttc13)/(1+ 0.13)).toFixed(3)
              const montanttva132=+(montantttc132-montantht132).toFixed(3)
              const montantttcsus2=+(mrecettesus).toFixed(3) 
              const montanthtsus2=+((+montantttcsus2)).toFixed(3)
              ammounts2.at(i).patchValue({
                montanthtexo:montanthtexo,
                montanttvaexo:0,
                montantttcexo:montantttcexo2,
               })
               ammounts2.at(i).patchValue({
                 montanthtsus:montanthtsus2,
                 montanttvasus:0,
                 montantttcsus:montantttcsus2,
                })
                ammounts2.at(i).patchValue({
                 montantht19:montantht192,
                 montanttva19:montanttva192,
                 montantttc19:montantttc192,
                 montantht13:montantht132,
                 montanttva13:montanttva132,
                 montantttc13:montantttc132,
                })
              const montantttc2=+(mrecette+mrecette19+mrecette13+mrecetteexo+mrecettesus).toFixed(3) 
              const montantht2=+((+mrecette)/(1+ +this.tauxtva)+(+mrecette19)/(1+ 0.19)+(+mrecette13)/(1+ 0.13)+(+mrecetteexo)+(+mrecettesus)).toFixed(3)
              const montanttva2=+((montantttc2-montantht2)).toFixed(3)
              ammounts2.at(i).patchValue({
                montantttc:montantttc2,
                montantht:montantht2,
                montanttva:montanttva2,
                recetteexp:0,
                recetteexpdevise:0
               })
               devise2=='tnd'?
              (ammounts2.controls[i].get('recetteexpdevise').disable(),
              ammounts2.controls[i].get('recetteexp').enable())
              :devise2=='euro'?
              (ammounts2.controls[i].get('recetteexpdevise').enable(),
              ammounts2.controls[i].get('recetteexp').disable())
              :devise2=='dollar'||devise2=='dzd'||devise2=='sar'||devise2=='cad'||devise2=='dkk'||
              devise2=='jpy'||devise2=='mad'||devise2=='nok'||devise2=='sek'||devise2=='chf'||devise2=='kwd'||
              devise2=='aed'||devise2=='lyd'||devise2=='mru'||devise2=='bhd'||devise2=='qar'||devise2=='cny'?
              (ammounts2.controls[i].get('recetteexpdevise').enable(),
              ammounts2.controls[i].get('recetteexp').disable())
              :devise2=='gbp'?
              (ammounts2.controls[i].get('recetteexpdevise').enable(),
              ammounts2.controls[i].get('recetteexp').disable())
              :''
              }
                          }
            else
            {
              devise2=(this.recettejournaliereform.get('ammounts2').value).at(i).devise,
              console.log('devise ammounts 2'),
             mrecette= +this.recettejournaliereform.get('ammounts2').value.at(i).recette
              ,mrecette19= +this.recettejournaliereform.get('ammounts2').value.at(i).recette19
              ,mrecette13= +this.recettejournaliereform.get('ammounts2').value.at(i).recette13
              , mrecetteexo= +this.recettejournaliereform.get('ammounts2').value.at(i).recetteexo
              ,mrecettesus= +this.recettejournaliereform.get('ammounts2').value.at(i).recettesus
              , mrecetteexp= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexp
              , mrecetteexpdevise= +this.recettejournaliereform.getRawValue().ammounts2.at(i).recetteexpdevise
              devise=(this.editionnoteform.get('ammounts').value).at(i).devise,
              console.log('devise ammounts'),
               mht= +(this.editionnoteform.get('ammounts').value).at(i).montantht
              , mht19= +(this.editionnoteform.get('ammounts').value).at(i).montantht19
              , mht13= +(this.editionnoteform.get('ammounts').value).at(i).montantht13
              , mhtexo= +(this.editionnoteform.get('ammounts').value).at(i).montanthtexo
              , mhtsus= +(this.editionnoteform.get('ammounts').value).at(i).montanthtsus
              , mhtexp= +(this.editionnoteform.get('ammounts').value).at(i).montanthtexp
              , mtva= +(this.editionnoteform.get('ammounts').value.at(i).montanttva)
              , mtva19= +(this.editionnoteform.get('ammounts').value.at(i).montanttva19)
              , mtva13= +(this.editionnoteform.get('ammounts').value.at(i).montanttva13)
              , mtvaexo= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexo)
              , mtvasus= +(this.editionnoteform.get('ammounts').value.at(i).montanttvasus)
              , mtvaexp= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexp)
              , mdt= +(this.editionnoteform.get('ammounts').value).at(i).montantdt,
              console.log(mht,mtva,mtva19,mdt)
              
              const montantttc19=+(mht19+ +mtva19).toFixed(3)
              const montantttc13=+(mht13+ +mtva13).toFixed(3)
            
                ammounts.at(i).patchValue({
                  montantttc19:montantttc19,
                  montantttc13:montantttc13
                 })
                 const montantttcexo=+(mhtexo+ +mtvaexo).toFixed(3)
                 ammounts.at(i).patchValue({
                  montantttcexo:montantttcexo
                 })
                 const montantttcsus=+(mhtsus+ +mtvasus).toFixed(3)
                 ammounts.at(i).patchValue({
                  montantttcsus:montantttcsus
                 })
                 const montantttc=+(mht+ +mtva -mtva19 -mtva13 +mdt+ +montantttc19+ +montantttc13+ +montantttcexo+ +montantttcsus).toFixed(3)
                 
                 ammounts.at(i).patchValue({
                   montanthtexpdevise:0,
                   montanthtexp:0,
                   montantttc:montantttc
                  })
            devise=='tnd'?
            (ammounts.controls[i].get('montanthtexpdevise').disable(),
            ammounts.controls[i].get('montanthtexp').enable())
            :devise=='euro'?
            (ammounts.controls[i].get('montanthtexpdevise').enable(),
            ammounts.controls[i].get('montanthtexp').disable())
            :devise=='dollar'||devise=='dzd'||devise=='sar'||devise=='cad'||devise=='dkk'||
            devise=='jpy'||devise=='mad'||devise=='nok'||devise=='sek'||devise=='chf'||devise=='kwd'||
            devise=='aed'||devise=='lyd'||devise=='mru'||devise=='bhd'||devise=='qar'||devise=='cny'?
            (ammounts.controls[i].get('montanthtexpdevise').enable(),
            ammounts.controls[i].get('montanthtexp').disable())
            :devise=='gbp'?
            (ammounts.controls[i].get('montanthtexpdevise').enable(),
            ammounts.controls[i].get('montanthtexp').disable())
            :''
              
            
            
            //change devise recette
            const montantttcexo2=+(mrecetteexo).toFixed(3) 
            const montanthtexo=+((+montantttcexo2)).toFixed(3)
            const montantttc192=+(mrecette19).toFixed(3) 
            const montantht192=+((+montantttc19)/(1+ 0.19)).toFixed(3)
            const montanttva192=+(montantttc192-montantht192).toFixed(3)
            
            const montantttc132=+(mrecette13).toFixed(3) 
            const montantht132=+((+montantttc13)/(1+ 0.13)).toFixed(3)
            const montanttva132=+(montantttc132-montantht132).toFixed(3)
            const montantttcsus2=+(mrecettesus).toFixed(3) 
            const montanthtsus2=+((+montantttcsus2)).toFixed(3)
            ammounts2.at(i).patchValue({
            montanthtexo:montanthtexo,
            montanttvaexo:0,
            montantttcexo:montantttcexo2,
            })
            ammounts2.at(i).patchValue({
            montanthtsus:montanthtsus2,
            montanttvasus:0,
            montantttcsus:montantttcsus2,
            })
            ammounts2.at(i).patchValue({
            montantht19:montantht192,
            montanttva19:montanttva192,
            montantttc19:montantttc192,
            montantht13:montantht132,
            montanttva13:montanttva132,
            montantttc13:montantttc132,
            })
            const montantttc2=+(mrecette+mrecette19+mrecette13+mrecetteexo+mrecettesus).toFixed(3) 
            const montantht2=+((+mrecette)/(1+ +this.tauxtva)+(+mrecette19)/(1+ 0.19)+(+mrecette13)/(1+ 0.13)+(+mrecetteexo)+(+mrecettesus)).toFixed(3)
            const montanttva2=+((montantttc2-montantht2)).toFixed(3)
            ammounts2.at(i).patchValue({
            montantttc:montantttc2,
            montantht:montantht2,
            montanttva:montanttva2,
            recetteexp:0,
            recetteexpdevise:0
            })
            devise2=='tnd'?
            (ammounts2.controls[i].get('recetteexpdevise').disable(),
            ammounts2.controls[i].get('recetteexp').enable())
            :devise2=='euro'?
            (ammounts2.controls[i].get('recetteexpdevise').enable(),
            ammounts2.controls[i].get('recetteexp').disable())
            :devise2=='dollar'||devise2=='dzd'||devise2=='sar'||devise2=='cad'||devise2=='dkk'||
            devise2=='jpy'||devise2=='mad'||devise2=='nok'||devise2=='sek'||devise2=='chf'||devise2=='kwd'||
            devise2=='aed'||devise2=='lyd'||devise2=='mru'||devise2=='bhd'||devise2=='qar'||devise2=='cny'?
            (ammounts2.controls[i].get('recetteexpdevise').enable(),
            ammounts2.controls[i].get('recetteexp').disable())
            :devise2=='gbp'?
            (ammounts2.controls[i].get('recetteexpdevise').enable(),
            ammounts2.controls[i].get('recetteexp').disable())
            :''
            }
            
                        }
                        //devise releve manuel
                        changedevise4(empindex: number,skillindex:number)
                        {
                          let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
                          let employees = this.relevemanuelform.get('employees') as FormArray;
                          let banque= employees.controls[empindex].get('banque').value
                                let numerocompte= employees.controls[empindex].get('numerocompte').value    
                                let identiccomptes:any[]=[]
                                let identiccompte:any
                                let employeessliced=employees.getRawValue().slice(0,-1)
                          employees.controls[empindex].get('creditdevise').reset(),
                             employees.controls[empindex].get('debitdevise').reset(),
                             employees.controls[empindex].get('credit').reset(),
                             employees.controls[empindex].get('debit').reset(),
                             skills.controls[0].get('creditdevise').reset(),
                             skills.controls[0].get('debitdevise').reset(),
                             skills.controls[0].get('credit').reset(),
                             skills.controls[0].get('debit').reset()
                             if(banque&&numerocompte)
                        {
                        if (employeessliced.find(element => element.banque==banque&&element.numerocompte==numerocompte))
                        return (alert('le compte bancaire existe déjà'),
                        employees.at(empindex).patchValue(
                          {
                            banque:'',
                            numerocompte:''
                          }
                        ))
                        if(this.deccompt.deccomptabilites.length>0)
                        {

                          let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)<new Date(this.deccomptabilite.created)&&!item.source)
                          desireddeccomptables.forEach(element => identiccomptes.push(element.autre4.find(e =>e.banque==banque&&e.numerocompte==numerocompte)))
                          identiccomptes.filter(e =>e!=undefined)
                          identiccompte=identiccomptes[identiccomptes.length-1]
                        if(identiccompte)
                        {
                          employees.at(empindex).patchValue(
                            {
                              devise:identiccompte.devise,
                            }
                          )
                          console.log(identiccompte.soldefinmoisdinar)
                        if(identiccompte.soldefinmoisdinar<0)
                        {
                          console.log(empindex)
                          employees.at(empindex).patchValue(
                            {
                              debit:-identiccompte.soldefinmoisdinar,
                              creditdevise:identiccompte.soldefinmoisdevise
                            }
                          )
                        }
                        else if(identiccompte.soldefinmoisdinar>=0)
                        {
                          employees.at(empindex).patchValue(
                            {
                              credit:identiccompte.soldefinmoisdinar,
                              creditdevise:identiccompte.soldefinmoisdevise
                            }
                          )
                        
                        }
                          employees.controls[empindex].get('devise').disable();
                        }
                        else
                        {
                          employees.controls[empindex].get('devise').enable();
                        }
                        }
                        }
                                      let devise:string            
                                      let debitgeneral:number
                                      let creditgeneral:number
                                      let debit:number
                                      let credit:number
                                devise=this.relevemanuelform.getRawValue().employees.at(empindex).devise
                                debitgeneral= +this.relevemanuelform.getRawValue().employees.at(empindex).debit
                                creditgeneral= +this.relevemanuelform.getRawValue().employees.at(empindex).credit  
                             devise=='tnd'?
                             (employees.controls[empindex].get('creditdevise').disable(),
                             employees.controls[empindex].get('debitdevise').disable(),
                             employees.controls[empindex].get('credit').enable(),
                             employees.controls[empindex].get('debit').enable(),
                             skills.controls[0].get('creditdevise').disable(),
                             skills.controls[0].get('debitdevise').disable(),
                             skills.controls[0].get('credit').enable(),
                             skills.controls[0].get('debit').enable())
                             :devise=='euro'?
                             (employees.controls[empindex].get('creditdevise').enable(),
                             employees.controls[empindex].get('debitdevise').disable(),
                             employees.controls[empindex].get('credit').disable(),
                             employees.controls[empindex].get('debit').disable(),
                             skills.controls[0].get('creditdevise').enable(),
                             skills.controls[0].get('debitdevise').enable(),
                             skills.controls[0].get('credit').disable(),
                             skills.controls[0].get('debit').disable()
                             )
                             :devise=='dollar'||devise=='dzd'||devise=='sar'||devise=='cad'||devise=='dkk'||
                             devise=='jpy'||devise=='mad'||devise=='nok'||devise=='sek'||devise=='chf'||devise=='kwd'||
                             devise=='aed'||devise=='lyd'||devise=='mru'||devise=='bhd'||devise=='qar'||devise=='cny'?
                             (employees.controls[empindex].get('creditdevise').enable(),
                             employees.controls[empindex].get('debitdevise').disable(),
                             employees.controls[empindex].get('credit').disable(),
                             employees.controls[empindex].get('debit').disable(),
                             skills.controls[0].get('creditdevise').enable(),
                             skills.controls[0].get('debitdevise').enable(),
                             skills.controls[0].get('credit').disable(),
                             skills.controls[0].get('debit').disable())
                             :devise=='gbp'?
                             (employees.controls[empindex].get('creditdevise').enable(),
                             employees.controls[empindex].get('debitdevise').disable(),
                             employees.controls[empindex].get('credit').disable(),
                             employees.controls[empindex].get('debit').disable(),
                             skills.controls[0].get('creditdevise').enable(),
                             skills.controls[0].get('debitdevise').enable(),
                             skills.controls[0].get('credit').disable(),
                             skills.controls[0].get('debit').disable())
                             :''
                        }
//devise achat
changedevise3(i: number)
{
  let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
  let devise:string
  let mht:number
  let mhtexp:number
  let mhtexpdevise:number
  let mtva:number
  let mdt:number
  let mttc:number
  ammounts3.at(i).patchValue({
    montanthtachat:0,
    montanthtdevise:0
   })                    
 devise=='euro'?
  (ammounts3.controls[i].get('montanthtdevise').enable(),
  ammounts3.controls[i].get('montanthtachat').disable())
  :devise=='dollar'||devise=='dzd'||devise=='sar'||devise=='cad'||devise=='dkk'||
  devise=='jpy'||devise=='mad'||devise=='nok'||devise=='sek'||devise=='chf'||devise=='kwd'||
  devise=='aed'||devise=='lyd'||devise=='mru'||devise=='bhd'||devise=='qar'||devise=='cny'?
  (ammounts3.controls[i].get('montanthtdevise').enable(),
  ammounts3.controls[i].get('montanthtachat').disable())
  :devise=='gbp'?
  (ammounts3.controls[i].get('montanthtdevise').enable(),
  ammounts3.controls[i].get('montanthtachat').disable())
  :''
}
setttc(i: number) {
  let ammounts = this.editionnoteform.get('ammounts') as FormArray;
  let factureammounts=[]
  let avoirammounts=[]
  let totalhtavoir=0
    let totalhtfacture=0
    let totaltvaavoir=0
    let totaltvafacture=0
    let totaldtavoir=0
    let totaldtfacture=0
    let totalttcavoir=0
    let totalttcfacture=0
    let totalht19avoir=0
    let totalht19facture=0
    let totaltva19avoir=0
    let totaltva19facture=0
    let totalttc19avoir=0
    let totalttc19facture=0

    let totalht13avoir=0
    let totalht13facture=0
    let totaltva13avoir=0
    let totaltva13facture=0
    let totalttc13avoir=0
    let totalttc13facture=0
    let totalhtexoavoir=0
    let totalhtexofacture=0       
    let totalttcexoavoir=0
    let totalttcexofacture=0
    let totalhtsusavoir=0
    let totalhtsusfacture=0      
    let totalttcsusavoir=0
    let totalttcsusfacture=0
    let totalhtexpavoir=0
    let totalhtexpfacture=0      
    let totalttcexpavoir=0
    let totalttcexpfacture=0
   const mht= +(this.editionnoteform.get('ammounts').value).at(i).montantht
   const mht19= +(this.editionnoteform.get('ammounts').value).at(i).montantht19
   const mht13= +(this.editionnoteform.get('ammounts').value).at(i).montantht13

   const mhtexo= +(this.editionnoteform.get('ammounts').value).at(i).montanthtexo
   const mhtsus= +(this.editionnoteform.get('ammounts').value).at(i).montanthtsus
   const mhtexp= +(this.editionnoteform.getRawValue().ammounts)[i].montanthtexp
   const mhtexpdevise= +(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise
   const devise= (this.editionnoteform.get('ammounts').value).at(i).devise
   const mtva= +(this.editionnoteform.get('ammounts').value.at(i).montanttva)
   const mtva19= +(this.editionnoteform.get('ammounts').value.at(i).montanttva19)
   const mtva13= +(this.editionnoteform.get('ammounts').value.at(i).montanttva13)

   const mtvaexo= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexo)
   const mtvasus= +(this.editionnoteform.get('ammounts').value.at(i).montanttvasus)
   const mtvaexp= +(this.editionnoteform.get('ammounts').value.at(i).montanttvaexp)
   let mdt= +this.tauxdt
   const montantttc19=+(mht19+ +mtva19).toFixed(3)
    ammounts.at(i).patchValue({
     montantttc19:montantttc19
    })
    const montantttc13=+(mht13+ +mtva13).toFixed(3)
    ammounts.at(i).patchValue({
     montantttc13:montantttc13
    })
    const montantttcexo=+(mhtexo+ +mtvaexo).toFixed(3)
    ammounts.at(i).patchValue({
     montantttcexo:montantttcexo
    })
    const montantttcsus=+(mhtsus+ +mtvasus).toFixed(3)
    ammounts.at(i).patchValue({
     montantttcsus:montantttcsus
    })
    const montantttcexp=+(mhtexp+ +mtvaexp).toFixed(3)
    ammounts.at(i).patchValue({
     montantttcexp:montantttcexp
    })
    if(mhtexpdevise==0&&devise!='tnd')
    {
      
      mht!=0||mht19!=0||mht13!=0||mhtexo!=0||mhtsus!=0?(mdt=this.tauxdt):(mdt=0)
      
      const montantttc=+(mht+ +mtva -mtva19 -mtva13 +mdt+ +montantttc19+ +montantttc13+ +montantttcexo+ +montantttcsus).toFixed(3)
      ammounts.at(i).patchValue({
       montantttc:montantttc,
       montanthtexp:0,
       montanthtexpdevise:0,
       montantdt:mdt
      })
    }
    else if(mhtexp!=0&&mhtexpdevise==0)
    {
      console.log('1')
      ammounts.at(i).patchValue({
        montantht:0,
        montantht19:0,
        montantht13:0,

        montanthtexo:0,
        montanthtsus:0,
        montanttva:0,
        montanttva19:0,
        montanttva13:0,

        montantdt:0,
        montantttc:mhtexp
       })
    }
    else if(mhtexpdevise!=0)
    {
      this.settservice.getCarouselalldata()
          this.settservice.carousels$.subscribe(
            (settings) => {
              let currentdate=this.option1Value+'-'+this.option2Value+'-'+(this.editionnoteform.getRawValue().ammounts)[i].jour
              
              let filtredsorted=[]
              let sorted=[]
              this.settings = settings; 
              this.cours=(this.settings.filter(p => p.cours.length>0))[0].cours
              const presorted=this.cours.sort(this.commun.sortByDate);                 
this.option2Value=='01'||this.option2Value=='03'||this.option2Value=='05'||this.option2Value=='07'
||this.option2Value=='08'||this.option2Value=='10'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value+1)+'-'+'01'))
:this.option2Value=='12'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date((+this.option1Value+1)+'-'+'01'+'-'+'01'))
:this.option2Value=='09'||this.option2Value=='11'||this.option2Value=='04'||this.option2Value=='06'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'31'))
:this.option2Value=='02'&&+this.option1Value % 4 !=0?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'29'))
:sorted= presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'30'))
sorted.length>0?
currentdate&&(this.editionnoteform.getRawValue().ammounts)[i].jour?filtredsorted=sorted.filter(p =>new Date(p.jour)<=new Date(currentdate)):filtredsorted=sorted
:filtredsorted=presorted
console.log(presorted)
console.log(sorted)
console.log(filtredsorted)   
              devise=='euro'?
              (
                
              ammounts.at(i).patchValue({
                montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                //@ts-ignore
                montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].euro).toFixed(3),
                //@ts-ignore
                montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].euro).toFixed(3),
                montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                    //@ts-ignore
                montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].euro).toFixed(3)
               })
              )
              :devise=='dollar'?
              (
                ammounts.at(i).patchValue({
                  montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                      //@ts-ignore
                  montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dollar).toFixed(3),
                                      //@ts-ignore
                  montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dollar).toFixed(3),
                  montantht:0,
                  montantht19:0,
                  montantht13:0,

                  montanthtexo:0,
                  montanthtsus:0,
                  montanttva:0,
                  montanttva19:0,
                  montanttva13:0,

                  montantdt:0,
                                      //@ts-ignore
                  montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dollar).toFixed(3)
                 }),
                 console.log('4')
                )
:devise=='dzd'?
                (
                  ammounts.at(i).patchValue({
                    montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                        //@ts-ignore
                    montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dzd).toFixed(3),
                                        //@ts-ignore
                    montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dzd).toFixed(3),
                    montantht:0,
                    montantht19:0,
                    montantht13:0,

                    montanthtexo:0,
                    montanthtsus:0,
                    montanttva:0,
                    montanttva19:0,
                    montanttva13:0,

                    montantdt:0,
                                        //@ts-ignore
                    montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dzd).toFixed(3)
                   }),
                   console.log('4')
                  )
                  :devise=='sar'?
                  (
                    ammounts.at(i).patchValue({
                      montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                          //@ts-ignore
                      montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sar).toFixed(3),
                                          //@ts-ignore
                      montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sar).toFixed(3),
                      montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                          //@ts-ignore
                      montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sar).toFixed(3)
                     }),
                     console.log('4')
                    )
                    :devise=='cad'?
                    (
                      ammounts.at(i).patchValue({
                        montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                            //@ts-ignore
                        montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cad).toFixed(3),
                                            //@ts-ignore
                        montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cad).toFixed(3),
                        montantht:0,
                        montantht19:0,
                        montantht13:0,
    
                        montanthtexo:0,
                        montanthtsus:0,
                        montanttva:0,
                        montanttva19:0,
                        montanttva13:0,
    
                        montantdt:0,
                                            //@ts-ignore
                        montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cad).toFixed(3)
                       }),
                       console.log('4')
                      )
                      :devise=='dkk'?
                      (
                        ammounts.at(i).patchValue({
                          montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                              //@ts-ignore
                          montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dkk).toFixed(3),
                                              //@ts-ignore
                          montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dkk).toFixed(3),
                          montantht:0,
                          montantht19:0,
                          montantht13:0,
      
                          montanthtexo:0,
                          montanthtsus:0,
                          montanttva:0,
                          montanttva19:0,
                          montanttva13:0,
      
                          montantdt:0,
                                              //@ts-ignore
                          montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].dkk).toFixed(3)
                         }),
                         console.log('4')
                        )
                        :devise=='jpy'?
                        (
                          ammounts.at(i).patchValue({
                            montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                //@ts-ignore
                            montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].jpy).toFixed(3),
                                                //@ts-ignore
                            montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].jpy).toFixed(3),
                            montantht:0,
                            montantht19:0,
                            montantht13:0,
        
                            montanthtexo:0,
                            montanthtsus:0,
                            montanttva:0,
                            montanttva19:0,
                            montanttva13:0,
        
                            montantdt:0,
                                                //@ts-ignore
                            montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].jpy).toFixed(3)
                           }),
                           console.log('4')
                          )
                          :devise=='mad'?
                          (
                            ammounts.at(i).patchValue({
                              montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                  //@ts-ignore
                              montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mad).toFixed(3),
                                                  //@ts-ignore
                              montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mad).toFixed(3),
                              montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                                  //@ts-ignore
                              montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mad).toFixed(3)
                             }),
                             console.log('4')
                            )
                            :devise=='nok'?
                            (
                              ammounts.at(i).patchValue({
                                montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                    //@ts-ignore
                                montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].nok).toFixed(3),
                                                    //@ts-ignore
                                montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].nok).toFixed(3),
                                montantht:0,
                                montantht19:0,
                                montantht13:0,
            
                                montanthtexo:0,
                                montanthtsus:0,
                                montanttva:0,
                                montanttva19:0,
                                montanttva13:0,
            
                                montantdt:0,
                                                    //@ts-ignore
                                montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].nok).toFixed(3)
                               }),
                               console.log('4')
                              )
                              :devise=='sek'?
                              (
                                ammounts.at(i).patchValue({
                                  montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                      //@ts-ignore
                                  montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sek).toFixed(3),
                                                      //@ts-ignore
                                  montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sek).toFixed(3),
                                  montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                                      //@ts-ignore
                                  montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].sek).toFixed(3)
                                 }),
                                 console.log('4')
                                )
                              :devise=='chf'?
                              (
                                ammounts.at(i).patchValue({
                                  montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                      //@ts-ignore
                                  montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].chf).toFixed(3),
                                                      //@ts-ignore
                                  montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].chf).toFixed(3),
                                  montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                                      //@ts-ignore
                                  montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].chf).toFixed(3)
                                 }),
                                 console.log('4')
                                )
                                :devise=='kwd'?
                                (
                                  ammounts.at(i).patchValue({
                                    montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                        //@ts-ignore
                                    montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                                        //@ts-ignore
                                    montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].kwd).toFixed(3),
                                    montantht:0,
                                    montantht19:0,
                                    montantht13:0,
                
                                    montanthtexo:0,
                                    montanthtsus:0,
                                    montanttva:0,
                                    montanttva19:0,
                                    montanttva13:0,
                
                                    montantdt:0,
                                                        //@ts-ignore
                                    montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].kwd).toFixed(3)
                                   }),
                                   console.log('4')
                                  )
                                  :devise=='aed'?
                                  (
                                    ammounts.at(i).patchValue({
                                      montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                          //@ts-ignore
                                      montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].aed).toFixed(3),
                                                          //@ts-ignore
                                      montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].aed).toFixed(3),
                                      montantht:0,
                                      montantht19:0,
                                      montantht13:0,
                  
                                      montanthtexo:0,
                                      montanthtsus:0,
                                      montanttva:0,
                                      montanttva19:0,
                                      montanttva13:0,
                  
                                      montantdt:0,
                                                          //@ts-ignore
                                      montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].aed).toFixed(3)
                                     }),
                                     console.log('4')
                                    )
                                    :devise=='lyd'?
                                    (
                                      ammounts.at(i).patchValue({
                                        montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                            //@ts-ignore
                                        montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                                            //@ts-ignore
                                        montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].lyd).toFixed(3),
                                        montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                                            //@ts-ignore
                                        montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].lyd).toFixed(3)
                                       }),
                                       console.log('4')
                                      )
                                      :devise=='mru'?
                                      (
                                        ammounts.at(i).patchValue({
                                          montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                              //@ts-ignore
                                          montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mru).toFixed(3),
                                                              //@ts-ignore
                                          montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mru).toFixed(3),
                                          montantht:0,
                                          montantht19:0,
                                          montantht13:0,
                      
                                          montanthtexo:0,
                                          montanthtsus:0,
                                          montanttva:0,
                                          montanttva19:0,
                                          montanttva13:0,
                      
                                          montantdt:0,
                                                              //@ts-ignore
                                          montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].mru).toFixed(3)
                                         }),
                                         console.log('4')
                                        )
                                        :devise=='bhd'?
                                        (
                                          ammounts.at(i).patchValue({
                                            montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                                //@ts-ignore
                                            montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                                                //@ts-ignore
                                            montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].bhd).toFixed(3),
                                            montantht:0,
                                            montantht19:0,
                                            montantht13:0,
                        
                                            montanthtexo:0,
                                            montanthtsus:0,
                                            montanttva:0,
                                            montanttva19:0,
                                            montanttva13:0,
                        
                                            montantdt:0,
                                                                //@ts-ignore
                                            montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].bhd).toFixed(3)
                                           }),
                                           console.log('4')
                                          )
                                          :devise=='qar'?
                                          (
                                            ammounts.at(i).patchValue({
                                              montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                                  //@ts-ignore
                                              montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].qar).toFixed(3),
                                                                  //@ts-ignore
                                              montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].qar).toFixed(3),
                                              montantht:0,
                                              montantht19:0,
                                              montantht13:0,
                          
                                              montanthtexo:0,
                                              montanthtsus:0,
                                              montanttva:0,
                                              montanttva19:0,
                                              montanttva13:0,
                          
                                              montantdt:0,
                                                                  //@ts-ignore
                                              montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].qar).toFixed(3)
                                             }),
                                             console.log('4')
                                            )
                                            :devise=='cny'?
                                            (
                                              ammounts.at(i).patchValue({
                                                montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                                                    //@ts-ignore
                                                montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                                    //@ts-ignore
                                                montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cny).toFixed(3),
                                                montantht:0,
                montantht19:0,
                montantht13:0,

                montanthtexo:0,
                montanthtsus:0,
                montanttva:0,
                montanttva19:0,
                montanttva13:0,

                montantdt:0,
                                                                    //@ts-ignore
                                                montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].cny).toFixed(3)
                                               }),
                                               console.log('4')
                                              )
                :
                (
                  ammounts.at(i).patchValue({
                    montanthtexpdevise:+(this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise,
                                        //@ts-ignore
                    montanthtexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].gbp).toFixed(3),
                                        //@ts-ignore
                    montantttcexp:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].gbp).toFixed(3),
                    montantht:0,
                    montantht19:0,
                    montantht13:0,

                    montanthtexo:0,
                    montanthtsus:0,
                    montanttva:0,
                    montanttva19:0,
                    montanttva13:0,

                    montantdt:0,
                    //@ts-ignore
                    montantttc:+((this.editionnoteform.getRawValue().ammounts)[i].montanthtexpdevise* +filtredsorted[0].gbp).toFixed(3)
                   }),
                   console.log('5')
                  )
                  this.editionnoteform.getRawValue().ammounts.filter((item)=>
{
item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
})
totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantht || 0);
return acc;
},0);
totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantht || 0);
return acc;
},0);      
this.totalht = totalhtfacture - totalhtavoir
totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva || 0);
return acc;
},0);
totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva || 0);
return acc;
},0);
this.totaltva = totaltvafacture - totaltvaavoir
totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantdt || 0);
return acc;
},0);
totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantdt || 0);
return acc;
},0);
this.totaldt = totaldtfacture - totaldtavoir
totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc || 0);
return acc;
},0);
totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc || 0);
return acc;
},0);

this.totalttc = totalttcfacture-totalttcavoir
this.realht1=this.totalht
this.realdt1=this.totaldt

totalht19facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantht19 || 0);
return acc;
},0);
totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantht19 || 0);
return acc;
},0);
this.totalht19 = totalht19facture-totalht19avoir
totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva19 || 0);
return acc;
},0);
totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva19 || 0);
return acc;
},0);
this.totaltva19 = totaltva19facture-totaltva19avoir
totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc19 || 0);
return acc;
},0);
totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc19 || 0);
return acc;
},0);
this.totalttc19 = totalttc19facture-totalttc19avoir
this.realht119=this.totalht19
this.realdt119=this.totaldt19

totalht13facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantht13 || 0);
return acc;
},0);
totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantht13 || 0);
return acc;
},0);
this.totalht13 = totalht13facture-totalht13avoir
totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva13 || 0);
return acc;
},0);
totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanttva13 || 0);
return acc;
},0);
this.totaltva13 = totaltva13facture-totaltva13avoir
totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc13 || 0);
return acc;
},0);
totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montantttc13 || 0);
return acc;
},0);
this.totalttc13 = totalttc13facture-totalttc13avoir
this.realht113=this.totalht13
this.realdt113=this.totaldt13
totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtexo || 0);
return acc;
},0);
totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtexo || 0);
return acc;
},0);
this.totalhtexo = totalhtexofacture-totalhtexoavoir
totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtsus || 0);
return acc;
},0);
totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtsus || 0);
return acc;
},0);
this.totalhtsus = totalhtsusfacture-totalhtsusavoir
totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtexp || 0);
return acc;
},0);
totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
acc += +(curr.montanthtexp || 0);
return acc;
},0);
this.totalhtexp = totalhtexpfacture-totalhtexpavoir
this.realht1exp=this.totalhtexp     
this.realht1exo=this.totalhtexo
this.realht1sus=this.totalhtsus
                                  }
          )

    }
    else if(mht!=0||mht19!=0||mht13!=0||mhtexo!=0||mhtsus!=0)
    {
      console.log('2')
      const mdt=this.tauxdt
      const montantttc=+(mht+ +mtva -mtva19 -mtva13 +mdt+ +montantttc19+ +montantttc13+ +montantttcexo+ +montantttcsus).toFixed(3)
      ammounts.at(i).patchValue({
       montantttc:montantttc,
       montanthtexp:0,
       montantdt:this.tauxdt
      })
    }
    else
    {
     console.log('11')
     ammounts.at(i).patchValue({
      montantht:0,
      montantht19:0,
      montantht13:0,

      montanthtexo:0,
      montanthtsus:0,
      montanthtexp:0,
      montanthtexpdevise:0,
      montanttva:0,
      montanttva19:0,
      montanttva13:0,

      montantdt:0,
      montantttc:0
      })
    }


   ammounts.controls[i].get('tauxtva').disable();
  }
 
    setttc3(i: number) {
      let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
      let factureammounts=[]
      let avoirammounts=[]
      let totalht3avoir=0
      let totalht3facture=0
      let totalht3deviseavoir=0
      let totalht3devisefacture=0
      let totaltva3avoir=0
      let totaltva3facture=0
      let totaldt3avoir=0
      let totaldt3facture=0
      let totalttc3avoir=0
      let totalttc3facture=0
      const mhtdevise= +(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise
      const mht= +(this.factureachatform.get('ammounts3').value).at(i).montantht
      const mhtachat= +(this.factureachatform.get('ammounts3').value).at(i).montanthtachat
      const mtva= +(this.factureachatform.getRawValue().ammounts3)[i].montanttva
      const mdt= +(this.factureachatform.getRawValue().ammounts3)[i].montantdt 
      const devise= (this.factureachatform.get('ammounts3').value).at(i).devise

      //
      if(mhtdevise!=0)
      {
        ammounts3.controls[i].get('montanttva').disable()
        ammounts3.controls[i].get('montantdt').disable()
        this.settservice.getCarouselalldata()
            this.settservice.carousels$.subscribe(
              (settings) => {
                let currentdate=this.option1Value+'-'+this.option2Value+'-'+(this.factureachatform.getRawValue().ammounts3)[i].jour                   
                let filtredsorted=[]
                let sorted=[]
                this.settings = settings; 
                this.cours=(this.settings.filter(p => p.cours.length>0))[0].cours
                const presorted=this.cours.sort(this.commun.sortByDate);                 
this.option2Value=='01'||this.option2Value=='03'||this.option2Value=='05'||this.option2Value=='07'
||this.option2Value=='08'||this.option2Value=='10'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value+1)+'-'+'01'))
:this.option2Value=='12'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date((+this.option1Value+1)+'-'+'01'+'-'+'01'))
:this.option2Value=='09'||this.option2Value=='11'||this.option2Value=='04'||this.option2Value=='06'?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'31'))
:this.option2Value=='02'&&+this.option1Value % 4 !=0?
sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'29'))
:sorted= presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'30'))
sorted.length>0?
currentdate&&(this.factureachatform.getRawValue().ammounts3)[i].jour?filtredsorted=sorted.filter(p =>new Date(p.jour)<=new Date(currentdate)):filtredsorted=sorted
:filtredsorted=presorted
console.log(sorted)
console.log(filtredsorted)   
                devise=='euro'?
                (
                  
                ammounts3.at(i).patchValue({
                  montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                  //@ts-ignore
                  montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].euro).toFixed(3),
                  montantht:0,
                
                  montanttva:0,
                  montantdt:0,
                                      //@ts-ignore
                  montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].euro).toFixed(3)
                 })
                )
                :devise=='dollar'?
                (
                  ammounts3.at(i).patchValue({
                    montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                    //@ts-ignore
                    montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dollar).toFixed(3),
                    montantht:0,
                   
                    montanttva:0,
                    montantdt:0,
                                        //@ts-ignore
                   montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dollar).toFixed(3)
                                      }),
                   console.log('4')
                  )
                  :devise=='dzd'?
                      (
                        ammounts3.at(i).patchValue({
                          montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                          //@ts-ignore
                          montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dzd).toFixed(3),
                          montantht:0,
                         
                          montanttva:0,
                          montantdt:0,
                                              //@ts-ignore
                         montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dzd).toFixed(3)
                                            }),
                         console.log('4')
                        )
                        :devise=='sar'?
                        (
                          ammounts3.at(i).patchValue({
                            montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                            //@ts-ignore
                            montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].sar).toFixed(3),
                            montantht:0,
                           
                            montanttva:0,
                            montantdt:0,
                                                //@ts-ignore
                           montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].sar).toFixed(3)
                                              }),
                           console.log('4')
                          )
                          :devise=='cad'?
                          (
                            ammounts3.at(i).patchValue({
                              montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                              //@ts-ignore
                              montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].cad).toFixed(3),
                              montantht:0,
                             
                              montanttva:0,
                              montantdt:0,
                                                  //@ts-ignore
                             montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].cad).toFixed(3)
                                                }),
                             console.log('4')
                            )
                            :devise=='dkk'?
                            (
                              ammounts3.at(i).patchValue({
                                montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                //@ts-ignore
                                montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dkk).toFixed(3),
                                montantht:0,
                               
                                montanttva:0,
                                montantdt:0,
                                                    //@ts-ignore
                               montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].dkk).toFixed(3)
                                                  }),
                               console.log('4')
                              )
                              :devise=='jpy'?
                              (
                                ammounts3.at(i).patchValue({
                                  montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                  //@ts-ignore
                                  montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].jpy).toFixed(3),
                                  montantht:0,
                                 
                                  montanttva:0,
                                  montantdt:0,
                                                      //@ts-ignore
                                 montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].jpy).toFixed(3)
                                                    }),
                                 console.log('4')
                                )
                                :devise=='mad'?
                                (
                                  ammounts3.at(i).patchValue({
                                    montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                    //@ts-ignore
                                    montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].mad).toFixed(3),
                                    montantht:0,
                                   
                                    montanttva:0,
                                    montantdt:0,
                                                        //@ts-ignore
                                   montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].mad).toFixed(3)
                                                      }),
                                   console.log('4')
                                  )
                                  :devise=='nok'?
                                  (
                                    ammounts3.at(i).patchValue({
                                      montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                      //@ts-ignore
                                      montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].nok).toFixed(3),
                                      montantht:0,
                                     
                                      montanttva:0,
                                      montantdt:0,
                                                          //@ts-ignore
                                     montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].nok).toFixed(3)
                                                        }),
                                     console.log('4')
                                    )
                                    :devise=='sek'?
                                    (
                                      ammounts3.at(i).patchValue({
                                        montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                        //@ts-ignore
                                        montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].sek).toFixed(3),
                                        montantht:0,
                                       
                                        montanttva:0,
                                        montantdt:0,
                                                            //@ts-ignore
                                       montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].sek).toFixed(3)
                                                          }),
                                       console.log('4')
                                      )
                                    :devise=='chf'?
                                    (
                                      ammounts3.at(i).patchValue({
                                        montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                        //@ts-ignore
                                        montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].chf).toFixed(3),
                                        montantht:0,
                                       
                                        montanttva:0,
                                        montantdt:0,
                                                            //@ts-ignore
                                       montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].chf).toFixed(3)
                                                          }),
                                       console.log('4')
                                      )
                                      :devise=='kwd'?
                                      (
                                        ammounts3.at(i).patchValue({
                                          montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                          //@ts-ignore
                                          montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].kwd).toFixed(3),
                                          montantht:0,
                                         
                                          montanttva:0,
                                          montantdt:0,
                                                              //@ts-ignore
                                         montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].kwd).toFixed(3)
                                                            }),
                                         console.log('4')
                                        )
                                        :devise=='aed'?
                                        (
                                          ammounts3.at(i).patchValue({
                                            montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                            //@ts-ignore
                                            montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].aed).toFixed(3),
                                            montantht:0,
                                           
                                            montanttva:0,
                                            montantdt:0,
                                                                //@ts-ignore
                                           montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].aed).toFixed(3)
                                                              }),
                                           console.log('4')
                                          )
                                          :devise=='lyd'?
                                          (
                                            ammounts3.at(i).patchValue({
                                              montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                              //@ts-ignore
                                              montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].lyd).toFixed(3),
                                              montantht:0,
                                             
                                              montanttva:0,
                                              montantdt:0,
                                                                  //@ts-ignore
                                             montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].lyd).toFixed(3)
                                                                }),
                                             console.log('4')
                                            )
                                            :devise=='mru'?
                                            (
                                              ammounts3.at(i).patchValue({
                                                montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                                //@ts-ignore
                                                montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].mru).toFixed(3),
                                                montantht:0,
                                               
                                                montanttva:0,
                                                montantdt:0,
                                                                    //@ts-ignore
                                               montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].mru).toFixed(3)
                                                                  }),
                                               console.log('4')
                                              )
                                              :devise=='bhd'?
                                              (
                                                ammounts3.at(i).patchValue({
                                                  montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                                  //@ts-ignore
                                                  montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].bhd).toFixed(3),
                                                  montantht:0,
                                                 
                                                  montanttva:0,
                                                  montantdt:0,
                                                                      //@ts-ignore
                                                 montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].bhd).toFixed(3)
                                                                    }),
                                                 console.log('4')
                                                )
                                                :devise=='qar'?
                                                (
                                                  ammounts3.at(i).patchValue({
                                                    montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                                    //@ts-ignore
                                                    montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].qar).toFixed(3),
                                                    montantht:0,
                                                   
                                                    montanttva:0,
                                                    montantdt:0,
                                                                        //@ts-ignore
                                                   montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].qar).toFixed(3)
                                                                      }),
                                                   console.log('4')
                                                  )
                                                  :devise=='cny'?
                                                  (
                                                    ammounts3.at(i).patchValue({
                                                      montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                                                      //@ts-ignore
                                                      montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].cny).toFixed(3),
                                                      montantht:0,
                                                     
                                                      montanttva:0,
                                                      montantdt:0,
                                                                          //@ts-ignore
                                                     montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].cny).toFixed(3)
                                                                        }),
                                                     console.log('4')
                                                    )
                  :
                  (
                    ammounts3.at(i).patchValue({
                      montanthtdevise:+(this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise,
                    //@ts-ignore
                    montanthtachat:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].gbp).toFixed(3),
                      montantht:0,
                     
                      montanttva:0,
                      montantdt:0,
                      //@ts-ignore
                      montantttc:+((this.factureachatform.getRawValue().ammounts3)[i].montanthtdevise* +filtredsorted[0].gbp).toFixed(3)
                    }),
                     console.log('5')
                    )
                    this.factureachatform.getRawValue().ammounts3.filter((item)=>
                    {
                      item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                    })
                  
                     totalht3facture = +(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);
                    totalht3avoir = +(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);      
              this.totalht3 = totalht3facture - totalht3avoir
              totalht3devisefacture = +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montanthtachat || 0);
                return acc;
              },0);
              totalht3deviseavoir = +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montanthtachat || 0);
                return acc;
              },0);      
        this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
              totaltva3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montanttva || 0);
                return acc;
              },0);
              totaltva3avoir= +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montanttva || 0);
                return acc;
              },0);
              this.totaltva3 = totaltva3facture - totaltva3avoir
              totaldt3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montantdt || 0);
                return acc;
              },0);
              totaldt3avoir= +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montantdt || 0);
                return acc;
              },0);
              this.totaldt3 = totaldt3facture - totaldt3avoir
              totalttc3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montantttc || 0);
                return acc;
              },0);
              totalttc3avoir=+(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montantttc || 0);
                return acc;
              },0);
              this.totalttc3 = totalttc3facture - totalttc3avoir 
                                    }
            )

      }
      else if(mht!=0)
      {
        ammounts3.controls[i].get('montanttva').enable()
        ammounts3.controls[i].get('montantdt').enable()
        console.log(mht,mtva ,mdt)
        const montantttc=+(mht+ +mtva  +mdt).toFixed(3)
        ammounts3.at(i).patchValue({
         montantttc:montantttc,
         montanthtachat:0,
         montantdt:mdt
        })
        this.factureachatform.getRawValue().ammounts3.filter((item)=>
        {
          item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
        })
      
         totalht3facture = +(factureammounts).reduce((acc,curr)=>{
          acc += +(curr.montantht || 0);
          return acc;
        },0);
        totalht3avoir = +(avoirammounts).reduce((acc,curr)=>{
          acc += +(curr.montantht || 0);
          return acc;
        },0);      
  this.totalht3 = totalht3facture - totalht3avoir
  totalht3devisefacture = +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtachat || 0);
    return acc;
  },0);
  totalht3deviseavoir = +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtachat || 0);
    return acc;
  },0);      
this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
  totaltva3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva || 0);
    return acc;
  },0);
  totaltva3avoir= +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva || 0);
    return acc;
  },0);
  this.totaltva3 = totaltva3facture - totaltva3avoir
  totaldt3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantdt || 0);
    return acc;
  },0);
  totaldt3avoir= +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantdt || 0);
    return acc;
  },0);
  this.totaldt3 = totaldt3facture - totaldt3avoir
  totalttc3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc || 0);
    return acc;
  },0);
  totalttc3avoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc || 0);
    return acc;
  },0);
  this.totalttc3 = totalttc3facture - totalttc3avoir 
      }
      else
      {
       console.log('11')
       ammounts3.at(i).patchValue({
        montantht:0,
        montanthtachat:0,
        montanthtdevise:0,
        montanttva:0,
        montantdt:0,
        montantttc:0
        })
        this.factureachatform.getRawValue().ammounts3.filter((item)=>
        {
          item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
        })
      
         totalht3facture = +(factureammounts).reduce((acc,curr)=>{
          acc += +(curr.montantht || 0);
          return acc;
        },0);
        totalht3avoir = +(avoirammounts).reduce((acc,curr)=>{
          acc += +(curr.montantht || 0);
          return acc;
        },0);      
  this.totalht3 = totalht3facture - totalht3avoir
  totalht3devisefacture = +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtachat || 0);
    return acc;
  },0);
  totalht3deviseavoir = +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanthtachat || 0);
    return acc;
  },0);      
this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
  totaltva3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva || 0);
    return acc;
  },0);
  totaltva3avoir= +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montanttva || 0);
    return acc;
  },0);
  this.totaltva3 = totaltva3facture - totaltva3avoir
  totaldt3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantdt || 0);
    return acc;
  },0);
  totaldt3avoir= +(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantdt || 0);
    return acc;
  },0);
  this.totaldt3 = totaldt3facture - totaldt3avoir
  totalttc3facture= +(factureammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc || 0);
    return acc;
  },0);
  totalttc3avoir=+(avoirammounts).reduce((acc,curr)=>{
    acc += +(curr.montantttc || 0);
    return acc;
  },0);
  this.totalttc3 = totalttc3facture - totalttc3avoir 
      }
      //
     
      }
                     /* setimposable(i: number) {
                        let ammounts6 = this.salaireform.get('ammounts6') as FormArray;
                         const mbrut= +(this.salaireform.get('ammounts6').value).at(i).salairebrut
                         const mcnss= (this.salaireform.get('ammounts6').value.at(i).montantcnss)
                  
                         
                         const montantimposable=(+mbrut- +mcnss).toFixed(3)
                  
                         ammounts6.at(i).patchValue({
                          montantimposable:montantimposable
                         })
                       
                        }
                        setnet(i: number) {
                          let ammounts6 = this.salaireform.get('ammounts6') as FormArray;
                           const mimposable= +(this.salaireform.get('ammounts6').value).at(i).montantimposable
                           const mretenue= +(this.salaireform.get('ammounts6').value.at(i).montantretenue)
                           const mavance= +(this.salaireform.get('ammounts6').value).at(i).montantavance
                           const mabrut= +(this.salaireform.get('ammounts6').value).at(i).salairebrut
                           const mcnss= +(this.salaireform.get('ammounts6').value).at(i).montantcnss
            
                           
                           const salairenet=(+mabrut- +mcnss- +mretenue- +mavance).toFixed(3)
                    
                           ammounts6.at(i).patchValue({
                            salairenet:salairenet
                           })
                         
                          }
                          setbrut(i: number) {
                            let ammounts6 = this.salaireform.get('ammounts6') as FormArray;
                            const mimposable= +(this.salaireform.get('ammounts6').value).at(i).montantimposable
                           const mretenue= +(this.salaireform.get('ammounts6').value.at(i).montantretenue)
                           const mavance= +(this.salaireform.get('ammounts6').value).at(i).montantavance
                           const mcnss= +(this.salaireform.get('ammounts6').value.at(i).montantcnss)
            
                      
                             
                             const salairebrut=(+mimposable+ +mretenue+ +mavance+ +mcnss).toFixed(3)
                      
                             ammounts6.at(i).patchValue({
                              salairebrut:salairebrut
                             })
                           
                            }*/
                           
                            onChange0(i: number){
                              let ammounts0=this.editionnotepastform.get('ammounts0') as FormArray
                              var text1 = document.getElementById('copbanque0'+`${i}`);
                              var text2 = document.getElementById('copnumero0'+`${i}`);
                              var text3 = document.getElementById('echeance0'+`${i}`);
                              var text4 = document.getElementById('datevirement0'+`${i}`);
                              let factureammounts0=[]
                            let avoirammounts0=[]
                            let totalhtavoir=0
                              let totalhtfacture=0
                              let totaltvaavoir=0
                              let totaltvafacture=0
                              let totaldtavoir=0
                              let totaldtfacture=0
                              let totalttcavoir=0
                              let totalttcfacture=0
                              let totalht19avoir=0
                              let totalht19facture=0
                              let totaltva19avoir=0
                              let totaltva19facture=0
                              let totalttc19avoir=0
                              let totalttc19facture=0
            
                              let totalht13avoir=0
                              let totalht13facture=0
                              let totaltva13avoir=0
                              let totaltva13facture=0
                              let totalttc13avoir=0
                              let totalttc13facture=0
                              let totalhtexoavoir=0
                              let totalhtexofacture=0       
                              let totalttcexoavoir=0
                              let totalttcexofacture=0
                              let totalhtsusavoir=0
                              let totalhtsusfacture=0      
                              let totalttcsusavoir=0
                              let totalttcsusfacture=0
                              let totalhtexpavoir=0
                              let totalhtexpfacture=0      
                              let totalttcexpavoir=0
                              let totalttcexpfacture=0
                              this.editionnotepastform.getRawValue().ammounts0.filter((item)=>
                              {
                                item.typepiece=='facture'?factureammounts0.push(item):avoirammounts0.push(item)
                              })
                              const totalht = (this.editionnotepastform.get('ammounts0').value.at(i).montantht || 0)
                              const totaltva = (this.editionnotepastform.get('ammounts0').value.at(i).montanttva || 0)
                              const totaldt = (this.editionnotepastform.get('ammounts0').value.at(i).montantdt || 0)
                              const totalttc = (this.editionnotepastform.get('ammounts0').value.at(i).montantttc || 0)
                              if(ammounts0.value.at(i).client=='Autre')
                              {
                                /*this.clients.push({
                                  cl:ammounts0.value.at(i).autreclient
                                }
                                )
                      
                                this.settedclients= this.clients.filter((obj, index) => {
                                  return index === this.clients.findIndex(o => obj.cl === o.cl);
                                });
                                this.settedclients=this.settedclients.sort()*/
                                ammounts0.at(i).patchValue(
                                  {
                                    client:ammounts0.value.at(i).autreclient
                              
                                  }
                                )
                              }
                              if(ammounts0.value.at(i).nature=='Autre')
                              {
                                this.copnatures.push(
                                  ammounts0.value.at(i).autrenature
                                
                                )
                      
                                this.settedcopnatures= this.copnatures.filter((obj, index) => {
                                  return index === this.copnatures.findIndex(o => obj === o);
                                });
                                this.settedcopnatures=this.settedcopnatures.sort()
                                ammounts0.at(i).patchValue(
                                  {
                                    nature:ammounts0.value.at(i).autrenature
                              
                                  }
                                )
                              }
                              if(ammounts0.value.at(i).objet=='Autre')
                              {
                                this.copobjets.push(
                                  ammounts0.value.at(i).autreobjet
                                
                                )
                      
                                this.settedcopobjets= this.copobjets.filter((obj, index) => {
                                  return index === this.copobjets.findIndex(o => obj === o);
                                });
                                this.settedcopobjets=this.settedcopobjets.sort()
                                ammounts0.at(i).patchValue(
                                  {
                                    objet:ammounts0.value.at(i).autreobjet
                              
                                  }
                                )
                              }
                              
                          
                   
                             
                              totalhtfacture = +(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht || 0);
                                return acc;
                              },0);
                              totalhtavoir = +(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht || 0);
                                return acc;
                              },0);      
                              this.totalht0 = totalhtfacture - totalhtavoir
                              totaltvafacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                              },0);
                              totaltvaavoir= +(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                              },0);
                              this.totaltva0 = totaltvafacture - totaltvaavoir
                              totaldtfacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                              },0);
                              totaldtavoir= +(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                              },0);
                              this.totaldt0 = totaldtfacture - totaldtavoir
                              totalttcfacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                              },0);
                              totalttcavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                              },0);
                              
                              this.totalttc0 = totalttcfacture-totalttcavoir
                              this.realht0=this.totalht0
                              this.realdt0=this.totaldt0
                              
                              totalht19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantht19 || 0);
                              return acc;
                              },0);
                              totalht19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantht19 || 0);
                              return acc;
                              },0);
                              this.totalht019 = totalht19facture-totalht19avoir
                              totaltva19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva19 || 0);
                              return acc;
                              },0);
                              totaltva19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva19 || 0);
                              return acc;
                              },0);
                              this.totaltva019 = totaltva19facture-totaltva19avoir
                              totalttc19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc19 || 0);
                              return acc;
                              },0);
                              totalttc19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc19 || 0);
                              return acc;
                              },0);
                              this.totalttc019 = totalttc19facture-totalttc19avoir
                              this.realht019=this.totalht019
                              this.realdt019=this.totaldt019
            
                              totalht13facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht13 || 0);
                                return acc;
                                },0);
                                totalht13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht13 || 0);
                                return acc;
                                },0);
                                this.totalht013 = totalht13facture-totalht13avoir
                                totaltva13facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva13 || 0);
                                return acc;
                                },0);
                                totaltva13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva13 || 0);
                                return acc;
                                },0);
                                this.totaltva013 = totaltva13facture-totaltva13avoir
                                totalttc13facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc13 || 0);
                                return acc;
                                },0);
                                totalttc13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc13 || 0);
                                return acc;
                                },0);
                                this.totalttc013 = totalttc13facture-totalttc13avoir
                                this.realht013=this.totalht013
                                this.realdt013=this.totaldt013
                              /*totalhtexofacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexo || 0);
                              return acc;
                              },0);
                              totalhtexoavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexo || 0);
                              return acc;
                              },0);
                              this.totalhtexo = totalhtexofacture-totalhtexoavoir
                              totalhtsusfacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtsus || 0);
                              return acc;
                              },0);
                              totalhtsusavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtsus || 0);
                              return acc;
                              },0);
                              this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                              totalhtexpfacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexp || 0);
                              return acc;
                              },0);
                              totalhtexpavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexp || 0);
                              return acc;
                              },0);
                              this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                              this.realht1exp=this.totalhtexp     
                              this.realht1exo=this.totalhtexo
                              this.realht1sus=this.totalhtsus*/
                      
                              
                            }
                            onchangenature(i:number)
                            {
                              let ammounts=this.editionnoteform.get('ammounts') as FormArray
                              var text1 = document.getElementById('copbanque'+`${i}`);
                              var text2 = document.getElementById('copnumero'+`${i}`);
                              var text3 = document.getElementById('echeance'+`${i}`);
                              var text4 = document.getElementById('datevirement'+`${i}`);
                              switch (ammounts.value.at(i).nature)
                              {
                               case 'chèque': (text1.style.display='block', text2.style.display='block',ammounts.at(i).patchValue(
                                 {
                                   echeance:'',
                                   datevirement:''
                            
                                 }
                               ),text3.style.display='none',text4.style.display='none')
                               
                           break;
                         case 'traite': (text3.style.display='block',ammounts.at(i).patchValue(
                           {
                             banque:'',
                             numerocompte:''
                           }
                         ),text1.style.display='none', text2.style.display='none', text4.style.display='none')
                           break;
                           case 'virement': (text4.style.display='block',ammounts.at(i).patchValue(
                             {
                               banque:'',
                               numerocompte:'',
                               echeance:''
                             }
                           ),text1.style.display='none', text2.style.display='none', text3.style.display='none')
                             break;    
                             default:
                                      
                             (ammounts.at(i).patchValue(
                               {
                                 banque:'',
                                 numerocompte:'',
                                 echeance:'',
                                 datevirement:''
                               }
                             ),text4.style.display='none',text1.style.display='none', text2.style.display='none', text3.style.display='none')
                              }
                            }
                            onchangenature0(i:number)
                            {
                              let ammounts0=this.editionnotepastform.get('ammounts0') as FormArray
                              var text1 = document.getElementById('copbanque0'+`${i}`);
                              var text2 = document.getElementById('copnumero0'+`${i}`);
                              var text3 = document.getElementById('echeance0'+`${i}`);
                              var text4 = document.getElementById('datevirement0'+`${i}`);
                              switch (ammounts0.value.at(i).nature)
                              {
                               case 'chèque': (text1.style.display='block', text2.style.display='block',ammounts0.at(i).patchValue(
                                 {
                                   echeance:'',
                                   datevirement:''
                            
                                 }
                               ),text3.style.display='none',text4.style.display='none')
                               
                           break;
                         case 'traite': (text3.style.display='block',ammounts0.at(i).patchValue(
                           {
                             banque:'',
                             numerocompte:''
                           }
                         ),text1.style.display='none', text2.style.display='none', text4.style.display='none')
                           break;
                           case 'virement': (text4.style.display='block',ammounts0.at(i).patchValue(
                             {
                               banque:'',
                               numerocompte:'',
                               echeance:''
                             }
                           ),text1.style.display='none', text2.style.display='none', text3.style.display='none')
                             break;    
                             default:
                                      
                             (ammounts0.at(i).patchValue(
                               {
                                 banque:'',
                                 numerocompte:'',
                                 echeance:'',
                                 datevirement:''
                               }
                             ),text4.style.display='none',text1.style.display='none', text2.style.display='none', text3.style.display='none')
                              }
                            }
                            onannule(i: number){
                              let ammounts=this.editionnoteform.get('ammounts') as FormArray
                              let annule=ammounts.value.at(i).annule
                              if(annule && i!=undefined )
                              {
                                ammounts.at(i).patchValue({
                                  montantht:0,
                                  montantht19:0,
                                  montantht13:0,
            
                                  montanthtexo:0,
                                  montanthtsus:0,
                                  montanthtexp:0,
                                  montanttva:0,
                                  montanttva19:0,
                                  montanttva13:0,
            
                                  montantdt:0,
                                  montantttc:0
                                 })
                                 let factureammounts=[]
                                let avoirammounts=[]
                                 let totalhtavoir=0
                                   let totalhtfacture=0
                                   let totaltvaavoir=0
                                   let totaltvafacture=0
                                   let totaldtavoir=0
                                   let totaldtfacture=0
                                   let totalttcavoir=0
                                   let totalttcfacture=0
                                   let totalht19avoir=0
                                   let totalht19facture=0
                                   let totaltva19avoir=0
                                   let totaltva19facture=0
                                   let totalttc19avoir=0
                                   let totalttc19facture=0
            
                                   let totalht13avoir=0
                                   let totalht13facture=0
                                   let totaltva13avoir=0
                                   let totaltva13facture=0
                                   let totalttc13avoir=0
                                   let totalttc13facture=0
                                   let totalhtexoavoir=0
                                   let totalhtexofacture=0       
                                   let totalttcexoavoir=0
                                   let totalttcexofacture=0
                                   let totalhtsusavoir=0
                                   let totalhtsusfacture=0      
                                   let totalttcsusavoir=0
                                   let totalttcsusfacture=0
                                   let totalhtexpavoir=0
                                   let totalhtexpfacture=0      
                                   let totalttcexpavoir=0
                                   let totalttcexpfacture=0
                                   this.editionnoteform.getRawValue().ammounts.filter((item)=>
                                   {
                                     item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                                   }) 
                                   totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                                     acc += +(curr.montantht || 0);
                                     return acc;
                                   },0);
                                   totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                                     acc += +(curr.montantht || 0);
                                     return acc;
                                   },0);      
                                   this.totalht = totalhtfacture - totalhtavoir
                                   totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanttva || 0);
                                   return acc;
                                   },0);
                                   totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanttva || 0);
                                   return acc;
                                   },0);
                                   this.totaltva = totaltvafacture - totaltvaavoir
                                   totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantdt || 0);
                                   return acc;
                                   },0);
                                   totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantdt || 0);
                                   return acc;
                                   },0);
                                   this.totaldt = totaldtfacture - totaldtavoir
                                   totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantttc || 0);
                                   return acc;
                                   },0);
                                   totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantttc || 0);
                                   return acc;
                                   },0);
                                   
                                   this.totalttc = totalttcfacture-totalttcavoir
                                   this.realht1=this.totalht
                                   this.realdt1=this.totaldt
                                   
                                   totalht19facture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantht19 || 0);
                                   return acc;
                                   },0);
                                   totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantht19 || 0);
                                   return acc;
                                   },0);
                                   this.totalht19 = totalht19facture-totalht19avoir
                                   totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanttva19 || 0);
                                   return acc;
                                   },0);
                                   totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanttva19 || 0);
                                   return acc;
                                   },0);
                                   this.totaltva19 = totaltva19facture-totaltva19avoir
                                   totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantttc19 || 0);
                                   return acc;
                                   },0);
                                   totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montantttc19 || 0);
                                   return acc;
                                   },0);
                                   this.totalttc19 = totalttc19facture-totalttc19avoir
                                   this.realht119=this.totalht19
                                   this.realdt119=this.totaldt19
            
                                   totalht13facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantht13 || 0);
                                    return acc;
                                    },0);
                                    totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantht13 || 0);
                                    return acc;
                                    },0);
                                    this.totalht13 = totalht13facture-totalht13avoir
                                    totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva13 || 0);
                                    return acc;
                                    },0);
                                    totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva13 || 0);
                                    return acc;
                                    },0);
                                    this.totaltva13 = totaltva13facture-totaltva13avoir
                                    totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc13 || 0);
                                    return acc;
                                    },0);
                                    totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc13 || 0);
                                    return acc;
                                    },0);
                                    this.totalttc13 = totalttc13facture-totalttc13avoir
                                    this.realht113=this.totalht13
                                    this.realdt113=this.totaldt13
                                   totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtexo || 0);
                                   return acc;
                                   },0);
                                   totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtexo || 0);
                                   return acc;
                                   },0);
                                   this.totalhtexo = totalhtexofacture-totalhtexoavoir
                                   totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtsus || 0);
                                   return acc;
                                   },0);
                                   totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtsus || 0);
                                   return acc;
                                   },0);
                                   this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                                   totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtexp || 0);
                                   return acc;
                                   },0);
                                   totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
                                   acc += +(curr.montanthtexp || 0);
                                   return acc;
                                   },0);
                                   this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                                   this.realht1exp=this.totalhtexp     
                                   this.realht1exo=this.totalhtexo
                                   this.realht1sus=this.totalhtsus
                              }
                            } 
                            onannule0(i: number){
                              let ammounts0=this.editionnotepastform.get('ammounts0') as FormArray
                              let annule0=ammounts0.value.at(i).annule
                              if(annule0 && i!=undefined )
                              {
                                ammounts0.at(i).patchValue({
                                  montantht:0,
                                  montantht19:0,
                                  montantht13:0,
            
                                  montanthtexo:0,
                                  montanthtsus:0,
                                  montanthtexp:0,
                                  montanttva:0,
                                  montanttva19:0,
                                  montanttva13:0,
            
                                  montantdt:0,
                                  montantttc:0
                                 })
                                 let factureammounts=[]
                                 let avoirammounts=[]
                                  let totalhtavoir=0
                                    let totalhtfacture=0
                                    let totaltvaavoir=0
                                    let totaltvafacture=0
                                    let totaldtavoir=0
                                    let totaldtfacture=0
                                    let totalttcavoir=0
                                    let totalttcfacture=0
                                    let totalht19avoir=0
                                    let totalht19facture=0
                                    let totaltva19avoir=0
                                    let totaltva19facture=0
                                    let totalttc19avoir=0
                                    let totalttc19facture=0
                                    let totalhtexoavoir=0
                                    let totalhtexofacture=0       
                                    let totalttcexoavoir=0
                                    let totalttcexofacture=0
                                    let totalhtsusavoir=0
                                    let totalhtsusfacture=0      
                                    let totalttcsusavoir=0
                                    let totalttcsusfacture=0
                                    let totalhtexpavoir=0
                                    let totalhtexpfacture=0      
                                    let totalttcexpavoir=0
                                    let totalttcexpfacture=0
                                    this.editionnotepastform.getRawValue().ammounts0.filter((item)=>
                                    {
                                      item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                                    }) 
                                    totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht || 0);
                                      return acc;
                                    },0);
                                    totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht || 0);
                                      return acc;
                                    },0);      
                                    this.totalht0 = totalhtfacture - totalhtavoir
                                    totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva || 0);
                                    return acc;
                                    },0);
                                    totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva || 0);
                                    return acc;
                                    },0);
                                    this.totaltva0 = totaltvafacture - totaltvaavoir
                                    totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantdt || 0);
                                    return acc;
                                    },0);
                                    totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantdt || 0);
                                    return acc;
                                    },0);
                                    this.totaldt0 = totaldtfacture - totaldtavoir
                                    totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc || 0);
                                    return acc;
                                    },0);
                                    totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc || 0);
                                    return acc;
                                    },0);
                                    
                                    this.totalttc0 = totalttcfacture-totalttcavoir
                                    this.realht0=this.totalht0
                                    this.realdt0=this.totaldt0
                                    
                              }
                            }         
                  onChange(i: number){
                    let ammounts=this.editionnoteform.get('ammounts') as FormArray
                    let factureammounts=[]
                   
            
                  let avoirammounts=[]
                  let totalhtavoir=0
                    let totalhtfacture=0
                    let totaltvaavoir=0
                    let totaltvafacture=0
                    let totaldtavoir=0
                    let totaldtfacture=0
                    let totalttcavoir=0
                    let totalttcfacture=0
                    let totalht19avoir=0
                    let totalht19facture=0
                    let totaltva19avoir=0
                    let totaltva19facture=0
                    let totalttc19avoir=0
                    let totalttc19facture=0
            
                    let totalht13avoir=0
                    let totalht13facture=0
                    let totaltva13avoir=0
                    let totaltva13facture=0
                    let totalttc13avoir=0
                    let totalttc13facture=0
                    let totalhtexoavoir=0
                    let totalhtexofacture=0       
                    let totalttcexoavoir=0
                    let totalttcexofacture=0
                    let totalhtsusavoir=0
                    let totalhtsusfacture=0      
                    let totalttcsusavoir=0
                    let totalttcsusfacture=0
                    let totalhtexpavoir=0
                    let totalhtexpfacture=0      
                    let totalttcexpavoir=0
                    let totalttcexpfacture=0
                    this.editionnoteform.getRawValue().ammounts.filter((item)=>
                    {
                      item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                    })
                    const totalht = (this.editionnoteform.get('ammounts').value.at(i).montantht || 0)
                    const totaltva = (this.editionnoteform.get('ammounts').value.at(i).montanttva || 0)
                    const totaldt = (this.editionnoteform.get('ammounts').value.at(i).montantdt || 0)
                    const totalttc = (this.editionnoteform.get('ammounts').value.at(i).montantttc || 0)
                    if(ammounts.value.at(i).client=='Autre'&&this.user.activite!='Syndic des copropriétaires')
                    {
                      this.clients.push({
                        cl:ammounts.value.at(i).autreclient
                      }
                      )
            
                      this.settedclients= this.clients.filter((obj, index) => {
                        return index === this.clients.findIndex(o => obj.cl === o.cl);
                      });
                      this.settedclients=this.settedclients.sort()
                      ammounts.at(i).patchValue(
                        {
                          client:ammounts.value.at(i).autreclient
                    
                        }
                      )
                    }
                    if(ammounts.value.at(i).nature=='Autre')
                    {
                      this.copnatures.push(
                        ammounts.value.at(i).autrenature
                      
                      )
            
                      this.settedcopnatures= this.copnatures.filter((obj, index) => {
                        return index === this.copnatures.findIndex(o => obj === o);
                      });
                      this.settedcopnatures=this.settedcopnatures.sort()
                      ammounts.at(i).patchValue(
                        {
                          nature:ammounts.value.at(i).autrenature
                    
                        }
                      )
                    }
                    if(ammounts.value.at(i).objet=='Autre')
                    {
                      this.copobjets.push(
                        ammounts.value.at(i).autreobjet
                      
                      )
            
                      this.settedcopobjets= this.copobjets.filter((obj, index) => {
                        return index === this.copobjets.findIndex(o => obj === o);
                      });
                      this.settedcopobjets=this.settedcopobjets.sort()
                      ammounts.at(i).patchValue(
                        {
                          objet:ammounts.value.at(i).autreobjet
                    
                        }
                      )
                    }
                  
                   
                  
                    totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);
                    totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);      
                    this.totalht = totalhtfacture - totalhtavoir
                    totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanttva || 0);
                    return acc;
                    },0);
                    totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanttva || 0);
                    return acc;
                    },0);
                    this.totaltva = totaltvafacture - totaltvaavoir
                    totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantdt || 0);
                    return acc;
                    },0);
                    totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantdt || 0);
                    return acc;
                    },0);
                    this.totaldt = totaldtfacture - totaldtavoir
                    totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantttc || 0);
                    return acc;
                    },0);
                    totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantttc || 0);
                    return acc;
                    },0);
                    
                    this.totalttc = totalttcfacture-totalttcavoir
                    this.realht1=this.totalht
                    this.realdt1=this.totaldt
                    
                    totalht19facture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantht19 || 0);
                    return acc;
                    },0);
                    totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantht19 || 0);
                    return acc;
                    },0);
                    this.totalht19 = totalht19facture-totalht19avoir
                    totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanttva19 || 0);
                    return acc;
                    },0);
                    totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanttva19 || 0);
                    return acc;
                    },0);
                    this.totaltva19 = totaltva19facture-totaltva19avoir
                    totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantttc19 || 0);
                    return acc;
                    },0);
                    totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montantttc19 || 0);
                    return acc;
                    },0);
                    this.totalttc19 = totalttc19facture-totalttc19avoir
                    this.realht119=this.totalht19
                    this.realdt119=this.totaldt19
            
                    totalht13facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht13 || 0);
                      return acc;
                      },0);
                      totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht13 || 0);
                      return acc;
                      },0);
                      this.totalht13 = totalht13facture-totalht13avoir
                      totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva13 || 0);
                      return acc;
                      },0);
                      totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva13 || 0);
                      return acc;
                      },0);
                      this.totaltva13 = totaltva13facture-totaltva13avoir
                      totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc13 || 0);
                      return acc;
                      },0);
                      totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc13 || 0);
                      return acc;
                      },0);
                      this.totalttc13 = totalttc13facture-totalttc13avoir
                      this.realht113=this.totalht13
                      this.realdt113=this.totaldt13
                    totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtexo || 0);
                    return acc;
                    },0);
                    totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtexo || 0);
                    return acc;
                    },0);
                    this.totalhtexo = totalhtexofacture-totalhtexoavoir
                    totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtsus || 0);
                    return acc;
                    },0);
                    totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtsus || 0);
                    return acc;
                    },0);
                    this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                    totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtexp || 0);
                    return acc;
                    },0);
                    totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
                    acc += +(curr.montanthtexp || 0);
                    return acc;
                    },0);
                    this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                    this.realht1exp=this.totalhtexp     
                    this.realht1exo=this.totalhtexo
                    this.realht1sus=this.totalhtsus
                  }
                  onChange2(i: number){
                    
            
              this.totalht2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantht || 0);
                return acc;
              },0);
              this.totaltva2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttva || 0);
                return acc;
              },0);
              this.totalttc2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttc || 0);
                return acc;
              },0);
              this.realht2=this.totalht2
              this.totalht219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantht19 || 0);
                return acc;
              },0);
              this.totaltva219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttva19 || 0);
                return acc;
              },0);
             
              this.totalttc219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttc19 || 0);
                return acc;
              },0);
              this.realht219=this.totalht219
            
              this.totalht213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantht13 || 0);
                return acc;
              },0);
              this.totaltva213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttva13 || 0);
                return acc;
              },0);
             
              this.totalttc213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttc13 || 0);
                return acc;
              },0);
              this.realht213=this.totalht213
              this.totalht2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanthtexo || 0);
                return acc;
              },0);
              this.totaltva2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttvaexo || 0);
                return acc;
              },0);
             
              this.totalttc2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttcexo || 0);
                return acc;
              },0);
              this.totalrecetteexo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.recetteexo || 0);
                return acc;
              },0);
              this.realht2exo=this.totalht2exo
              this.totalht2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanthtsus || 0);
                return acc;
              },0);
              this.totaltva2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttvasus || 0);
                return acc;
              },0);
             
              this.totalttc2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttcsus || 0);
                return acc;
              },0);
              this.totalrecettesus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.recettesus || 0);
                return acc;
              },0);
              this.realht2sus=this.totalht2sus
              this.totalht2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanthtexp || 0);
                return acc;
              },0);
              this.totaltva2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montanttvaexp || 0);
                return acc;
              },0);
             
              this.totalttc2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.montantttcexp || 0);
                return acc;
              },0);
              this.totalrecetteexp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
                acc += +(curr.recetteexp || 0);
                return acc;
              },0);
              this.realht2exp=this.totalht2exp      
                  }
                  onchangetypefacture(i: number,ammounts:FormArray)
                  {
                    ammounts.value.at(i).typepiece=='facture'?      
                    ammounts.controls[i].get('numerofacture').enable()        
                    :
                    ammounts.controls[i].get('numerofacture').disable()
                  }
                  filtrednumfactures(i:number,specific:FormArray):any
                  {
                    let ammounts=this.facturefournisseurform.getRawValue().ammounts9
                    let ammounts2=[]
                    let total=[]
                    let selectedfour:any
                    let filtredammount=[]
                    let chosenfiltred:any
                    specific.value.at(i).typepiece=='facture'?selectedfour=specific.value.at(i).fournisseur:selectedfour=specific.value.at(i).beneficiaire
                    this.deccompt.deccomptabilites.forEach(element => ammounts2.push(element.autre9))
            total=total.concat(ammounts,ammounts2)
            specific.value.at(i).typepiece=='facture'?filtredammount=total.filter(e=>e.fournisseur==selectedfour&&e.reglement==''):filtredammount=total.filter(e=>e.beneficiaire==selectedfour&&e.reglement=='')
            if(filtredammount.length>0)
            {
            chosenfiltred=filtredammount.find(e=>e.fournisseur==selectedfour&&e.numerofacture==specific.value.at(i).numerofacture)
            if(chosenfiltred)
            {
              specific.at(i).patchValue(
                {
                  natureachat:chosenfiltred.natureachat,
                  montantht:chosenfiltred.montantht
                }
              )
              this.totalht8 = +(this.factureachatchequeform.get('ammounts8').value).reduce((acc,curr)=>{
                acc += +(curr.montantht || 0);
                return acc;
              },0);
            this.realht8=this.totalht8  
            this.totalht11 = +(this.factureachatespeceform.get('ammounts11').value).reduce((acc,curr)=>{
                acc += +(curr.montantht || 0);
                return acc;
              },0);
            this.realht11=this.totalht11
            }
             
            }
            return filtredammount
            /*{specific.at(i).patchValue(
              {
                numerofacture:filtredammount.numerofacture
              }
            )}
            else
            {specific.at(i).patchValue(
              {
                numerofacture:''
              }
            )}*/
            }
                  async onChange3(i: number,ammounts:FormArray,facture:any){
                  
                    let factureammounts=[]
                    let avoirammounts=[]
                    let totalht3avoir=0
                    let totalht3facture=0
                    let totalht3deviseavoir=0
                    let totalht3devisefacture=0
                    let totaltva3avoir=0
                    let totaltva3facture=0
                    let totaldt3avoir=0
                    let totaldt3facture=0
                    let totalttc3avoir=0
                    let totalttc3facture=0
                    let factureammounts9=[]
                    let avoirammounts9=[]
                    let totalht9avoir=0
                    let totalht9facture=0
                    if(ammounts.value.at(i).natureachat=='Autre')
                    {
                      this.natures.push({
                        nat:ammounts.value.at(i).autrenatureachat
                      }
                      )
                      this.settednatures= this.natures.filter((obj, index) => {
                        return index === this.natures.findIndex(o => obj.nat === o.nat);
                      });
                     this.settednatures=this.settednatures.sort()
                      ammounts.at(i).patchValue(
                        {
                          natureachat:ammounts.value.at(i).autrenatureachat 
                    
                        }
                      )
                    }
                    if(ammounts.value.at(i).fournisseur=='Autre')
                    {
                      this.fournisseurs.push({
                        four:ammounts.value.at(i).autrefournisseur
                      }
                      )
                      this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
                        return index === this.fournisseurs.findIndex(o => obj.four === o.four);
                      });
                      this.settedfournisseurs=this.settedfournisseurs.sort()
                      ammounts.at(i).patchValue(
                        {
                          fournisseur:ammounts.value.at(i).autrefournisseur
                    
                        }
                      )
                    }
                    if(ammounts.value.at(i).beneficiaire=='Autre')
                    {
                      this.beneficiaires.push({
                        four:ammounts.value.at(i).autrebeneficiaire
                      }
                      )
                      this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
                        return index === this.beneficiaires.findIndex(o => obj.four === o.four);
                      });
                      this.settedbeneficiaires=this.settedbeneficiaires.sort()
                      ammounts.at(i).patchValue(
                        {
                          beneficiaire:ammounts.value.at(i).autrebeneficiaire
                    
                        }
                      )
                    }
                    ammounts.value.at(i).type=='9'?  
                    facture.filter((item)=>
                    {
                      item.typepiece=='facture'?factureammounts9.push(item):avoirammounts9.push(item)
                    }):
                      facture.filter((item)=>
                      {
                        item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                      })
                      let tva=ammounts.at(i).value.montanttva
                      let dt=ammounts.at(i).value.montantdt
                      let ht=ammounts.at(i).value.montantht
                     
                      if(+ht<+tva+ +dt)
                  try {
                    Swal.fire({
                      title: 'le Montant TVA ne doit pas dépasser le montant HT sauf s\'il s\'agit d\'une opération en devise, veuillez choisir entre les deux options',
                      
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Montant en devise',
                      cancelButtonText: 'Montant en dinars',
                    }).then((result) => {
                      if (result.value) {          
                        this.loading = false;
                      }
                      else{
                        this.loading = false;
                        ammounts.controls[i].patchValue({ montantht: '',montanttva:'',montantttc:''});  
                      }
                    }).catch(() => {
                      Swal.fire('opération non aboutie!');
                    });
                    } catch {
                      Swal.fire('opération non aboutie!');
                    }
                     
                    totalht3facture = +(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);
                    totalht3avoir = +(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht || 0);
                      return acc;
                    },0);      
              this.totalht3 = totalht3facture - totalht3avoir
              totalht3devisefacture = +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montanthtachat || 0);
                return acc;
              },0);
              totalht3deviseavoir = +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montanthtachat || 0);
                return acc;
              },0);      
              this.totalht3devise = totalht3devisefacture - totalht3deviseavoir
              totaltva3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montanttva || 0);
                return acc;
              },0);
              totaltva3avoir= +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montanttva || 0);
                return acc;
              },0);
              this.totaltva3 = totaltva3facture - totaltva3avoir
              totaldt3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montantdt || 0);
                return acc;
              },0);
              totaldt3avoir= +(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montantdt || 0);
                return acc;
              },0);
              this.totaldt3 = totaldt3facture - totaldt3avoir
              totalttc3facture= +(factureammounts).reduce((acc,curr)=>{
                acc += +(curr.montantttc || 0);
                return acc;
              },0);
              totalttc3avoir=+(avoirammounts).reduce((acc,curr)=>{
                acc += +(curr.montantttc || 0);
                return acc;
              },0);
              this.totalttc3 = totalttc3facture - totalttc3avoir 
                      this.realht3=this.totalht3
                      this.realtva3=this.totaltva3
              
              this.totalht8 = +(this.factureachatchequeform.get('ammounts8').value).reduce((acc,curr)=>{
                acc += +(curr.montantht || 0);
                return acc;
              },0);
            this.realht8=this.totalht8
            
            totalht9facture = +(factureammounts9).reduce((acc,curr)=>{
              acc += +(curr.montantht || 0);
              return acc;
            },0);
            totalht9avoir = +(avoirammounts9).reduce((acc,curr)=>{
              acc += +(curr.montantht || 0);
              return acc;
            },0);      
            this.totalht9 = totalht9facture - totalht9avoir
                 
              this.totalht11 = +(this.factureachatespeceform.get('ammounts11').value).reduce((acc,curr)=>{
                acc += +(curr.montantht || 0);
                return acc;
              },0);
            this.realht11=this.totalht11
                  }
                  getTotaldebit(empindex: number,skillindex:number): number{
                    let totalPrice: number
                    let debitmois=+this.relevemanuelform.getRawValue().employees.at(empindex).debit
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
            
                    totalPrice= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debit || 0);
                      return acc;
                    },0);
                    totalPrice=totalPrice+debitmois
                    return totalPrice;
                  }
                  getTotaldebitdevise(empindex: number,skillindex:number): number{
                    let totalPricedevise: number
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
                    totalPricedevise= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debitdevise || 0);
                      return acc;
                    },0);
                    return totalPricedevise;
                  }
                  getTotalcredit(empindex: number,skillindex:number): number{
                    let totalPrice: number
                    let creditmois=+this.relevemanuelform.getRawValue().employees.at(empindex).credit
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
            
                    totalPrice= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.credit || 0);
                      return acc;
                    },0);
                    totalPrice=totalPrice+creditmois
                    return totalPrice;
                  }
                  getTotalcreditdevise(empindex: number,skillindex:number): number{
                    let totalPricedevise: number
                    let creditmoisdevise=+this.relevemanuelform.getRawValue().employees.at(empindex).creditdevise
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
            
                    totalPricedevise= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.creditdevise || 0);
                      return acc;
                    },0);
                    totalPricedevise=totalPricedevise+creditmoisdevise
                    return totalPricedevise;
                  }
                  getTotal(empindex: number,skillindex:number): number{
                    let totalPrice: number
                    let totalPricedebit: number
                    let totalPricecredit: number
                    let debitmois=+this.relevemanuelform.getRawValue().employees.at(empindex).debit
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
                    let employees = this.relevemanuelform.get('employees') as FormArray;
                    let devise= employees.controls[empindex].get('devise').value
                    totalPricedebit= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debit || 0);
                      return acc;
                    },0);
                    totalPricedebit=totalPricedebit+debitmois
                    let creditmois=+this.relevemanuelform.getRawValue().employees.at(empindex).credit
                    totalPricecredit= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.credit || 0);
                      return acc;
                    },0);
                    totalPricecredit=totalPricecredit+creditmois
                    totalPrice=totalPricecredit-totalPricedebit               
                    return totalPrice;
                  }
                  getTotaldevise(empindex: number,skillindex:number): number{
                    let totalPricedevise: number
                    let totalPricedebitdevise: number
                    let totalPricecreditdevise: number
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
                    let employees = this.relevemanuelform.get('employees') as FormArray;
                    totalPricedebitdevise= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debitdevise || 0);
                      return acc;
                    },0);
                    let creditmoisdevise=+this.relevemanuelform.getRawValue().employees.at(empindex).creditdevise
                    totalPricecreditdevise= +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.creditdevise || 0);
                      return acc;
                    },0);
                    totalPricecreditdevise=totalPricecreditdevise+creditmoisdevise
                    totalPricedevise=totalPricecreditdevise-totalPricedebitdevise
                    return totalPricedevise;
                  }
                  onChange4(empindex: number,skillindex:number){
             
            this.settservice.getCarouselalldata()
            this.settservice.carousels$.subscribe(
              (settings) => {
            //general varaibles
            let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
            let employees = this.relevemanuelform.get('employees') as FormArray;
            
            var text1 = document.getElementById('relevemanuelfournisseur'+`${empindex}`+`${skillindex}`);
            var text2 = document.getElementById('relevemanuelclient'+`${empindex}`+`${skillindex}`);
            let totaldebitbis:any
            let totalcreditbis:any
            let totaldebitbisdevise:any
            let totalcreditbisdevise:any
            let debitdevise= +employees.controls[empindex].get('debitdevise').value
            let creditdevise= +employees.controls[empindex].get('creditdevise').value
            let mouvementdebitdevise= skills.value.at(skillindex).debitdevise
            let mouvementcreditdevise= skills.value.at(skillindex).creditdevise
            let jourmouvement= skills.value.at(skillindex).jour
            let devise= employees.controls[empindex].get('devise').value
            //compte variables    
                let today:string
                let currentdatecompte:string
                this.option2Value=='01'||this.option2Value=='02'||this.option2Value=='04'||this.option2Value=='06'||this.option2Value=='08'||this.option2Value=='09'||this.option2Value=='11'?
                today='01':
                this.option2Value=='05'||this.option2Value=='07'||this.option2Value=='10'||this.option2Value=='12'?
                today='31':
                this.option2Value=='03'&&+this.option1Value % 4 !=0?
                today='29':
                today='30'
            this.option2Value=='01'?currentdatecompte=(+this.option1Value)+'-'+'01'+'-'+today
            :currentdatecompte=this.option1Value+'-'+(+this.option2Value-1)+'-'+today
                                  let filtredsortedcompte=[]
                                  let sortedcompte=[]
                                  this.settings = settings; 
                                  this.cours=(this.settings.filter(p => p.cours.length>0))[0].cours
                                  const presortedcompte=this.cours.sort(this.commun.sortByDate);                 
            this.option2Value=='01'||this.option2Value=='02'||this.option2Value=='04'||this.option2Value=='06'||this.option2Value=='08'||this.option2Value=='09'||this.option2Value=='11'?
            sortedcompte = presortedcompte.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'01'))
            :this.option2Value=='05'||this.option2Value=='07'||this.option2Value=='10'||this.option2Value=='12'?
            sortedcompte = presortedcompte.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value-1)+'-'+'31'))
            :this.option2Value=='03'&&+this.option1Value % 4 !=0?
            sortedcompte = presortedcompte.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value-1)+'-'+'29'))
            :sortedcompte= presortedcompte.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value-1)+'-'+'30'))
                sortedcompte.length>0?
                currentdatecompte?filtredsortedcompte=sortedcompte.filter(p =>new Date(p.jour)<=new Date(currentdatecompte)):filtredsortedcompte=sortedcompte
                :filtredsortedcompte=presortedcompte 
                //mouvement variables
                let currentdate=this.option1Value+'-'+this.option2Value+'-'+jourmouvement         
                let filtredsorted=[]
                let sorted=[]
                this.settings = settings; 
                this.cours=(this.settings.filter(p => p.cours.length>0))[0].cours
                const presorted=this.cours.sort(this.commun.sortByDate);
               
            this.option2Value=='01'||this.option2Value=='03'||this.option2Value=='05'||this.option2Value=='07'
            ||this.option2Value=='08'||this.option2Value=='10'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value+1)+'-'+'01'))
            :this.option2Value=='12'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date((+this.option1Value+1)+'-'+'01'+'-'+'01'))
            :this.option2Value=='09'||this.option2Value=='11'||this.option2Value=='04'||this.option2Value=='06'?
            sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'31'))
            :this.option2Value=='02'&&+this.option1Value % 4 !=0?
              sorted = presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'29'))
              :sorted= presorted.filter(p =>new Date(p.jour)<=new Date(this.option1Value+'-'+(+this.option2Value)+'-'+'30'))
              sorted.length>0?
            currentdate&&jourmouvement?filtredsorted=sorted.filter(p =>new Date(p.jour)<=new Date(currentdate)):filtredsorted=sorted
            :filtredsorted=presorted 
                //debut devise compte
                debitdevise!=0?           
                devise=='euro'?
                          employees.at(empindex).patchValue({
                  //@ts-ignore
                  debit:+(debitdevise* +filtredsortedcompte[0].euro).toFixed(3),
                 })
                :devise=='dollar'?
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    debit:+(debitdevise* +filtredsortedcompte[0].dollar).toFixed(3),
                                       
                   })
                   :devise=='dzd'?
       employees.at(empindex).patchValue({
                             //@ts-ignore
         debit:+(debitdevise* +filtredsortedcompte[0].dzd).toFixed(3),
                            
        })
        :devise=='sar'?
        employees.at(empindex).patchValue({
                              //@ts-ignore
          debit:+(debitdevise* +filtredsortedcompte[0].sar).toFixed(3),
                             
         })
         :devise=='cad'?
         employees.at(empindex).patchValue({
                               //@ts-ignore
           debit:+(debitdevise* +filtredsortedcompte[0].cad).toFixed(3),
                              
          })
          :devise=='dkk'?
          employees.at(empindex).patchValue({
                                //@ts-ignore
            debit:+(debitdevise* +filtredsortedcompte[0].dkk).toFixed(3),
                               
           })
           :devise=='jpy'?
           employees.at(empindex).patchValue({
                                 //@ts-ignore
             debit:+(debitdevise* +filtredsortedcompte[0].jpy).toFixed(3),
                                
            })
            :devise=='mad'?
            employees.at(empindex).patchValue({
                                  //@ts-ignore
              debit:+(debitdevise* +filtredsortedcompte[0].mad).toFixed(3),
                                 
             })
             :devise=='nok'?
             employees.at(empindex).patchValue({
                                   //@ts-ignore
               debit:+(debitdevise* +filtredsortedcompte[0].nok).toFixed(3),
                                  
              })
              :devise=='sek'?
             employees.at(empindex).patchValue({
                                   //@ts-ignore
               debit:+(debitdevise* +filtredsortedcompte[0].sek).toFixed(3),
                                  
              })
              :devise=='chf'?
              employees.at(empindex).patchValue({
                                    //@ts-ignore
                debit:+(debitdevise* +filtredsortedcompte[0].chf).toFixed(3),
                                   
               })
               :devise=='kwd'?
               employees.at(empindex).patchValue({
                                     //@ts-ignore
                 debit:+(debitdevise* +filtredsortedcompte[0].kwd).toFixed(3),
                                    
                })
                :devise=='aed'?
                employees.at(empindex).patchValue({
                                      //@ts-ignore
                  debit:+(debitdevise* +filtredsortedcompte[0].aed).toFixed(3),
                                     
                 })
                 :devise=='lyd'?
                 employees.at(empindex).patchValue({
                                       //@ts-ignore
                   debit:+(debitdevise* +filtredsortedcompte[0].lyd).toFixed(3),
                                      
                  })
                  :devise=='mru'?
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    debit:+(debitdevise* +filtredsortedcompte[0].mru).toFixed(3),
                                       
                   })
                   :devise=='bhd'?
                   employees.at(empindex).patchValue({
                                         //@ts-ignore
                     debit:+(debitdevise* +filtredsortedcompte[0].bhd).toFixed(3),
                                        
                    })
                    :devise=='qar'?
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      debit:+(debitdevise* +filtredsortedcompte[0].qar).toFixed(3),
                                         
                     })
                     :devise=='cny'?
                     employees.at(empindex).patchValue({
                                           //@ts-ignore
                       debit:+(debitdevise* +filtredsortedcompte[0].cny).toFixed(3),
                                          
                      })
                  :
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      debit:+(debitdevise* +filtredsortedcompte[0].gbp).toFixed(3),                 
                     })                     
            :creditdevise?             
                devise=='euro'?
                
                  
                employees.at(empindex).patchValue({
                  //@ts-ignore
                  credit:+(creditdevise* +filtredsortedcompte[0].euro).toFixed(3),
                 })
                
                :devise=='dollar'?
                
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    credit:+(creditdevise* +filtredsortedcompte[0].dollar).toFixed(3),
                                       
                   })
                   :devise=='dzd'?
       employees.at(empindex).patchValue({
                             //@ts-ignore
         credit:+(creditdevise* +filtredsortedcompte[0].dzd).toFixed(3),
                            
        })
        :devise=='sar'?
        employees.at(empindex).patchValue({
                              //@ts-ignore
          credit:+(creditdevise* +filtredsortedcompte[0].sar).toFixed(3),
                             
         })
         :devise=='cad'?
         employees.at(empindex).patchValue({
                               //@ts-ignore
           credit:+(creditdevise* +filtredsortedcompte[0].cad).toFixed(3),
                              
          })
          :devise=='dkk'?
          employees.at(empindex).patchValue({
                                //@ts-ignore
            credit:+(creditdevise* +filtredsortedcompte[0].dkk).toFixed(3),
                               
           })
           :devise=='jpy'?
           employees.at(empindex).patchValue({
                                 //@ts-ignore
             credit:+(creditdevise* +filtredsortedcompte[0].jpy).toFixed(3),
                                
            })
            :devise=='mad'?
            employees.at(empindex).patchValue({
                                  //@ts-ignore
              credit:+(creditdevise* +filtredsortedcompte[0].mad).toFixed(3),
                                 
             })
             :devise=='nok'?
             employees.at(empindex).patchValue({
                                   //@ts-ignore
               credit:+(creditdevise* +filtredsortedcompte[0].nok).toFixed(3),
                                  
              })
              :devise=='sek'?
             employees.at(empindex).patchValue({
                                   //@ts-ignore
               credit:+(creditdevise* +filtredsortedcompte[0].sek).toFixed(3),
                                  
              })
              :devise=='chf'?
              employees.at(empindex).patchValue({
                                    //@ts-ignore
                credit:+(creditdevise* +filtredsortedcompte[0].chf).toFixed(3),
                                   
               })
               :devise=='kwd'?
               employees.at(empindex).patchValue({
                                     //@ts-ignore
                 credit:+(creditdevise* +filtredsortedcompte[0].kwd).toFixed(3),
                                    
                })
                :devise=='aed'?
                employees.at(empindex).patchValue({
                                      //@ts-ignore
                  credit:+(creditdevise* +filtredsortedcompte[0].aed).toFixed(3),
                                     
                 })
                 :devise=='lyd'?
                 employees.at(empindex).patchValue({
                                       //@ts-ignore
                   credit:+(creditdevise* +filtredsortedcompte[0].lyd).toFixed(3),
                                      
                  })
                  :devise=='mru'?
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    credit:+(creditdevise* +filtredsortedcompte[0].mru).toFixed(3),
                                       
                   })
                   :devise=='bhd'?
                   employees.at(empindex).patchValue({
                                         //@ts-ignore
                     credit:+(creditdevise* +filtredsortedcompte[0].bhd).toFixed(3),
                                        
                    })
                    :devise=='qar'?
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      credit:+(creditdevise* +filtredsortedcompte[0].qar).toFixed(3),
                                         
                     })
                     :devise=='cny'?
                     employees.at(empindex).patchValue({
                                           //@ts-ignore
                       credit:+(creditdevise* +filtredsortedcompte[0].cny).toFixed(3),
                                          
                      }) 
                  :
                  
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      credit:+(creditdevise* +filtredsortedcompte[0].gbp).toFixed(3),                 
                     })        
            :''
            //Fin devise compte
            //debut mouvement devise
            mouvementdebitdevise!=0&&skillindex!=undefined&&mouvementdebitdevise!=null?
            devise=='euro'?  
            skills.at(skillindex).patchValue({
              //@ts-ignore
              debit:+(mouvementdebitdevise* +filtredsorted[0].euro).toFixed(3),
             })                
            :devise=='dollar'?               
              skills.at(skillindex).patchValue({
                                    //@ts-ignore
                debit:+(mouvementdebitdevise* +filtredsorted[0].dollar).toFixed(3),                                      
               })
               :devise=='dzd'?
               employees.at(empindex).patchValue({
                                     //@ts-ignore
                 debit:+(mouvementdebitdevise* +filtredsortedcompte[0].dzd).toFixed(3),
                                    
                })
                :devise=='sar'?
                employees.at(empindex).patchValue({
                                      //@ts-ignore
                  debit:+(mouvementdebitdevise* +filtredsortedcompte[0].sar).toFixed(3),
                                     
                 })
                 :devise=='cad'?
                 employees.at(empindex).patchValue({
                                       //@ts-ignore
                   debit:+(mouvementdebitdevise* +filtredsortedcompte[0].cad).toFixed(3),
                                      
                  })
                  :devise=='dkk'?
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    debit:+(mouvementdebitdevise* +filtredsortedcompte[0].dkk).toFixed(3),
                                       
                   })
                   :devise=='jpy'?
                   employees.at(empindex).patchValue({
                                         //@ts-ignore
                     debit:+(mouvementdebitdevise* +filtredsortedcompte[0].jpy).toFixed(3),
                                        
                    })
                    :devise=='mad'?
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      debit:+(mouvementdebitdevise* +filtredsortedcompte[0].mad).toFixed(3),
                                         
                     })
                     :devise=='nok'?
                     employees.at(empindex).patchValue({
                                           //@ts-ignore
                       debit:+(mouvementdebitdevise* +filtredsortedcompte[0].nok).toFixed(3),
                                          
                      })
                      :devise=='sek'?
                     employees.at(empindex).patchValue({
                                           //@ts-ignore
                       debit:+(mouvementdebitdevise* +filtredsortedcompte[0].sek).toFixed(3),
                                          
                      })
                      :devise=='chf'?
                      employees.at(empindex).patchValue({
                                            //@ts-ignore
                        debit:+(mouvementdebitdevise* +filtredsortedcompte[0].chf).toFixed(3),
                                           
                       })
                       :devise=='kwd'?
                       employees.at(empindex).patchValue({
                                             //@ts-ignore
                         debit:+(mouvementdebitdevise* +filtredsortedcompte[0].kwd).toFixed(3),
                                            
                        })
                        :devise=='aed'?
                        employees.at(empindex).patchValue({
                                              //@ts-ignore
                          debit:+(mouvementdebitdevise* +filtredsortedcompte[0].aed).toFixed(3),
                                             
                         })
                         :devise=='lyd'?
                         employees.at(empindex).patchValue({
                                               //@ts-ignore
                           debit:+(mouvementdebitdevise* +filtredsortedcompte[0].lyd).toFixed(3),
                                              
                          })
                          :devise=='mru'?
                          employees.at(empindex).patchValue({
                                                //@ts-ignore
                            debit:+(mouvementdebitdevise* +filtredsortedcompte[0].mru).toFixed(3),
                                               
                           })
                           :devise=='bhd'?
                           employees.at(empindex).patchValue({
                                                 //@ts-ignore
                             debit:+(mouvementdebitdevise* +filtredsortedcompte[0].bhd).toFixed(3),
                                                
                            })
                            :devise=='qar'?
                            employees.at(empindex).patchValue({
                                                  //@ts-ignore
                              debit:+(mouvementdebitdevise* +filtredsortedcompte[0].qar).toFixed(3),
                                                 
                             })
                             :devise=='cny'?
                             employees.at(empindex).patchValue({
                                                   //@ts-ignore
                               debit:+(mouvementdebitdevise* +filtredsortedcompte[0].cny).toFixed(3),
                                                  
                              })
              :                
                skills.at(skillindex).patchValue({
                                      //@ts-ignore
                  debit:+(mouvementdebitdevise* +filtredsorted[0].gbp).toFixed(3),                 
                 })    
                   
                                                   
            :mouvementcreditdevise!=0&&skillindex!=undefined&&mouvementcreditdevise!=null?     
             devise=='euro'?
                              (
                                
                              skills.at(skillindex).patchValue({
                                //@ts-ignore
                                credit:+(mouvementcreditdevise* +filtredsorted[0].euro).toFixed(3),
                               })
                              )
                              :devise=='dollar'?
                              
                                skills.at(skillindex).patchValue({
                                                      //@ts-ignore
                                  credit:+(mouvementcreditdevise* +filtredsorted[0].dollar).toFixed(3),
                                                     
                                 })
                                 :devise=='dzd'?
       employees.at(empindex).patchValue({
                             //@ts-ignore
         credit:+(mouvementcreditdevise* +filtredsortedcompte[0].dzd).toFixed(3),
                            
        })
        :devise=='sar'?
        employees.at(empindex).patchValue({
                              //@ts-ignore
          credit:+(mouvementcreditdevise* +filtredsortedcompte[0].sar).toFixed(3),
                             
         })
         :devise=='cad'?
         employees.at(empindex).patchValue({
                               //@ts-ignore
           credit:+(mouvementcreditdevise* +filtredsortedcompte[0].cad).toFixed(3),
                              
          })
          :devise=='dkk'?
          employees.at(empindex).patchValue({
                                //@ts-ignore
            credit:+(mouvementcreditdevise* +filtredsortedcompte[0].dkk).toFixed(3),
                               
           })
           :devise=='jpy'?
           employees.at(empindex).patchValue({
                                 //@ts-ignore
             credit:+(mouvementcreditdevise* +filtredsortedcompte[0].jpy).toFixed(3),
                                
            })
            :devise=='mad'?
            employees.at(empindex).patchValue({
                                  //@ts-ignore
              credit:+(mouvementcreditdevise* +filtredsortedcompte[0].mad).toFixed(3),
                                 
             })
             :devise=='nok'?
             employees.at(empindex).patchValue({
                                   //@ts-ignore
               credit:+(mouvementcreditdevise* +filtredsortedcompte[0].nok).toFixed(3),
                                  
              })
              :devise=='sek'?
              employees.at(empindex).patchValue({
                                    //@ts-ignore
                credit:+(mouvementcreditdevise* +filtredsortedcompte[0].sek).toFixed(3),
                                   
               })
              :devise=='chf'?
              employees.at(empindex).patchValue({
                                    //@ts-ignore
                credit:+(mouvementcreditdevise* +filtredsortedcompte[0].chf).toFixed(3),
                                   
               })
               :devise=='kwd'?
               employees.at(empindex).patchValue({
                                     //@ts-ignore
                 credit:+(mouvementcreditdevise* +filtredsortedcompte[0].kwd).toFixed(3),
                                    
                })
                :devise=='aed'?
                employees.at(empindex).patchValue({
                                      //@ts-ignore
                  credit:+(mouvementcreditdevise* +filtredsortedcompte[0].aed).toFixed(3),
                                     
                 })
                 :devise=='lyd'?
                 employees.at(empindex).patchValue({
                                       //@ts-ignore
                   credit:+(mouvementcreditdevise* +filtredsortedcompte[0].lyd).toFixed(3),
                                      
                  })
                  :devise=='mru'?
                  employees.at(empindex).patchValue({
                                        //@ts-ignore
                    credit:+(mouvementcreditdevise* +filtredsortedcompte[0].mru).toFixed(3),
                                       
                   })
                   :devise=='bhd'?
                   employees.at(empindex).patchValue({
                                         //@ts-ignore
                     credit:+(mouvementcreditdevise* +filtredsortedcompte[0].bhd).toFixed(3),
                                        
                    })
                    :devise=='qar'?
                    employees.at(empindex).patchValue({
                                          //@ts-ignore
                      credit:+(mouvementcreditdevise* +filtredsortedcompte[0].qar).toFixed(3),
                                         
                     })
                     :devise=='cny'?
                     employees.at(empindex).patchValue({
                                           //@ts-ignore
                       credit:+(mouvementcreditdevise* +filtredsortedcompte[0].cny).toFixed(3),
                                          
                      })
                                :
                                
                                  skills.at(skillindex).patchValue({
                                                        //@ts-ignore
                                    credit:+(mouvementcreditdevise* +filtredsorted[0].gbp).toFixed(3)               
                                   })
                    
            :skillindex!=undefined&&devise!='tnd'?skills.at(skillindex).patchValue({
              //@ts-ignore
            credit:0,
            debit:0             
            })
            :''
            //
            if(employees.controls[empindex].get('debit').value&&employees.controls[empindex].get('debit').value!=0)
            {
              employees.controls[empindex].get('credit').reset()
            }
            if(employees.controls[empindex].get('credit').value&&employees.controls[empindex].get('credit').value!=0)
            {
              employees.controls[empindex].get('debit').reset()
            }
            
            if(employees.controls[empindex].get('banque').value=='Autre')
            {
              this.banques.push(
                {
                  bq:employees.controls[empindex].get('autrebanque').value
                }
              )
              this.settedbanques= this.banques.filter((obj, index) => {
                return index === this.banques.findIndex(o => obj.bq === o.bq);
              });
              this.settedbanques=this.settedbanques.sort()
              employees.at(empindex).patchValue(
                {
                  banque:employees.controls[empindex].get('autrebanque').value
                }
              )
            }
            if(employees.controls[empindex].get('numerocompte').value=='Autre')
            {
              this.numeros.push(
                {
                  nc:employees.controls[empindex].get('autrenumerocompte').value,
                  bq:employees.controls[empindex].get('banque').value
                }
              )
              this.settednumeros= this.numeros.filter((obj, index) => {
                return index === this.numeros.findIndex(o => obj.nc === o.nc);
              });
              this.settednumeros=this.settednumeros.sort()
              this.filternumeros(empindex)
              employees.at(empindex).patchValue(
                {
                  numerocompte:employees.controls[empindex].get('autrenumerocompte').value
                }
              )
            }
            if(skillindex!=undefined)
            {
              if(skills.getRawValue()[skillindex].debit&&skills.getRawValue()[skillindex].debit!=0&&skillindex!=undefined
              ||skills.value.at(skillindex).debitdevise&&skills.value.at(skillindex).debitdevise!=0&&skillindex!=undefined)
              { 
              skills.controls[skillindex].get('objetcredit').reset()
              skills.controls[skillindex].get('objetcredit').disable();
              skills.controls[skillindex].get('objetdebit').enable();
              skills.controls[skillindex].get('credit').reset()
              skills.controls[skillindex].get('creditdevise').reset()
              }
              if(skills.getRawValue()[skillindex].credit&&skills.getRawValue()[skillindex].credit!=0&&skillindex!=undefined
              ||skills.value.at(skillindex).creditdevise&&skills.value.at(skillindex).creditdevise!=0&&skillindex!=undefined)
              { 
                skills.controls[skillindex].get('objetdebit').reset();
                skills.controls[skillindex].get('objetdebit').disable();
                skills.controls[skillindex].get('objetcredit').enable();
                skills.controls[skillindex].get('debit').reset()
                skills.controls[skillindex].get('debitdevise').reset()
              }
            
              if(skills.value.at(skillindex).contientauxiliaire==true&&skills.getRawValue()[skillindex].debit!=null&&skillindex!=undefined&&skills.getRawValue()[skillindex].objetdebit!='impayé client')
              {
                text1.style.display = "block";
                text2.style.display = "none";
              }
              if(skills.value.at(skillindex).contientauxiliaire==true&&skills.getRawValue()[skillindex].credit!=null&&skillindex!=undefined
              ||skills.value.at(skillindex).contientauxiliaire==true&&skills.getRawValue()[skillindex].objetdebit=='impayé client'&&skillindex!=undefined)
              {
                text1.style.display = "none";
                text2.style.display = "block";
              }
              if(skills.value.at(skillindex).contientauxiliaire==false&&skillindex!=undefined)
              {
                text1?text1.style.display = "none":"none";
                text2?text2.style.display = "none":"none";
              }
              if(skills.value.at(skillindex).objetdebit=='Autre'&&skillindex!=undefined)
            {
                this.objetsdebit.push(
                  skills.value.at(skillindex).autreobjetdebit  
                )
                this.settedobjetsdebit= this.objetsdebit.filter((obj, index) => {
                  return index === this.objetsdebit.findIndex(o => obj === o);
                });
                skills.at(skillindex).patchValue(
                  {
                    objetdebit:skills.value.at(skillindex).autreobjetdebit  
                  }
                )
            }
            if(skills.value.at(skillindex).objetcredit=='Autre'&&skillindex!=undefined)
            {
                this.objetscredit.push(
                  skills.value.at(skillindex).autreobjetcredit  
                )
                this.settedobjetscredit= this.objetscredit.filter((obj, index) => {
                  return index === this.objetscredit.findIndex(o => obj === o);
                });
              skills.at(skillindex).patchValue(
                {
                  objetcredit:skills.value.at(skillindex).autreobjetcredit  
                }
              )
            }
            if(skills.value.at(skillindex).client=='Autre'&&skillindex!=undefined)
                    {
                      this.clients.push({
                        cl:skills.value.at(skillindex).autreclient
                      })
                      this.settedclients= this.clients.filter((obj, index) => {
                        return index === this.clients.findIndex(o => obj.cl === o.cl);
                      });
                      skills.at(skillindex).patchValue(
                        {
                          client:skills.value.at(skillindex).autreclient
                        }
                      )
                    }
            if(skills.value.at(skillindex).fournisseur=='Autre'&&skillindex!=undefined)
                    {
                      this.fournisseurs.push({
                        four:skills.value.at(skillindex).autrefournisseur
                      })
                      this.settedfournisseurs= this.fournisseurs.filter((obj, index) => {
                        return index === this.fournisseurs.findIndex(o => obj.four === o.four);
                      });
                      skills.at(skillindex).patchValue(
                        {
                          fournisseur:skills.value.at(skillindex).autrefournisseur
                        }
                      )
                    }
            }
            
            
                    totaldebitbis = +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debit || 0);
                      return acc;
                    },0);
                    this.totaldebit=+totaldebitbis+ +(employees.getRawValue()[empindex].debit)
        totaldebitbisdevise = +(skills.getRawValue()).reduce((acc,curr)=>{
          acc += +(curr.debitdevise || 0);
          return acc;
        },0);
        
        this.totalcredit = +totalcreditbis+ +(employees.getRawValue()[empindex].credit)
        totalcreditbisdevise = +(skills.getRawValue()).reduce((acc,curr)=>{
          acc += +(curr.creditdevise || 0);
          return acc;
        },0);
        this.totalcreditdevise = +totalcreditbisdevise+ +(employees.getRawValue()[empindex].creditdevise)
        this.totalsoldemois = this.totalcredit-this.totaldebit
        this.totalsoldemoisdevise = +totalcreditbisdevise+ +(employees.getRawValue()[empindex].creditdevise)- +totaldebitbisdevise
        totalcreditbis = +(skills.getRawValue()).reduce((acc,curr)=>{
          acc += +(curr.credit || 0);
          return acc;
        },0);
        employees.at(empindex).patchValue(
          {
            soldefinmoisdinar:+totalcreditbis+ +(employees.getRawValue()[empindex].credit) - +(+totaldebitbis+ +(employees.getRawValue()[empindex].debit)),
            soldefinmoisdevise:+totalcreditbisdevise+ +(employees.getRawValue()[empindex].creditdevise) -+(+totaldebitbisdevise)
          }
        )  
              }
            )
            
                  }
                 /* allnumeros()
                  {
                    
                    this.filtrednumeros=this.settednumeros
                  }*/
                  filternumeros(empindex: number):any
                  {
                    let filtrednumeros=[] 
                    let employees = this.relevemanuelform.get('employees') as FormArray;
                    let banque= employees.controls[empindex].get('banque').value
                    let numerocompte= employees.controls[empindex].get('numerocompte').value  
                    filtrednumeros=this.settednumeros.filter(item=>item.bq==banque)
            return filtrednumeros      }
                  controlbankaccount(empindex: number)
                  {
                    let employees = this.relevemanuelform.get('employees') as FormArray;
                    let banque= employees.controls[empindex].get('banque').value
                    let numerocompte= employees.controls[empindex].get('numerocompte').value    
                    let identiccomptes:any[]=[]
                    let identiccompte:any
                    let employeessliced=employees.getRawValue().slice(0,-1)
                    console.log(banque,numerocompte)
            if(banque&&numerocompte)
            {
            if (employeessliced.find(element => element.banque==banque&&element.numerocompte==numerocompte))
            return (alert('le compte bancaire existe déjà'),
            employees.at(empindex).patchValue(
              {
                banque:'',
                numerocompte:''
              }
            ))
            console.log(this.deccompt.deccomptabilites)
            if(this.deccompt.deccomptabilites.length>0)
            {
            let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)<new Date(this.deccomptabilite.created)&&!item.source)
            desireddeccomptables.forEach(element => identiccomptes.push(element.autre4.find(e =>e.banque==banque&&e.numerocompte==numerocompte)))
            identiccomptes.filter(e =>e!=undefined)
            identiccompte=identiccomptes[identiccomptes.length-1]
            console.log(identiccompte)
            if(identiccompte)
            {
              employees.at(empindex).patchValue(
                {
                  devise:identiccompte.devise,
                }
              )
              console.log(identiccompte.soldefinmoisdinar)
            if(identiccompte.soldefinmoisdinar<0)
            {
              console.log(empindex)
              employees.at(empindex).patchValue(
                {
                  debit:-identiccompte.soldefinmoisdinar,
                  creditdevise:identiccompte.soldefinmoisdevise
                }
              )
              console.log(employees.getRawValue())
            }
            else if(identiccompte.soldefinmoisdinar>=0)
            {
              employees.at(empindex).patchValue(
                {
                  credit:identiccompte.soldefinmoisdinar,
                  creditdevise:identiccompte.soldefinmoisdevise
                }
              )
            
            }
              employees.controls[empindex].get('devise').disable();
            }
            else
            {
              employees.controls[empindex].get('devise').enable();
            }
            }
            }
            console.log(employees.getRawValue())
            
                  }
                /*  controlsoldedebiteur(empindex: number,skillindex:number)
                  {
                    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
                    let employees = this.relevemanuelform.get('employees') as FormArray;
                    let totaldebitbisdevise:any
                    let totalcreditbisdevise:any
                    let devise= employees.controls[empindex].get('devise').value
                    totaldebitbisdevise = +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.debitdevise || 0);
                      return acc;
                    },0);
                    this.totaldebitdevise=+totaldebitbisdevise
                   
                    totalcreditbisdevise = +(skills.getRawValue()).reduce((acc,curr)=>{
                      acc += +(curr.creditdevise || 0);
                      return acc;
                    },0);
                    this.totalcreditdevise = +totalcreditbisdevise+ +(employees.getRawValue()[empindex].creditdevise)
                    this.totalsoldemoisdevise = this.totalcreditdevise-this.totaldebitdevise
                    console.log(skillindex)
                    if(devise!='tnd'&&this.totalsoldemoisdevise<0&&skillindex)
                    return (alert('solde du compte en devise ne peut pas être débiteur'),console.log(skills.at(skillindex)),
                    skills.at(skillindex).patchValue(
                      {
                        debit:0,
                        debitdevise:0
                      }
                    ))   
                  }*/
                  async onChange6(typeindex:number,i: number){
                    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
                    let typessalaire = this.salaireform.get('typessalaire') as FormArray;
            
                    /*let cnss=ammounts6.at(i).value.montantcnss
                    let salairebrut=ammounts6.at(i).value.salairebrut
                    let montantretenue=ammounts6.at(i).value.montantretenue
                    let montantavance=ammounts6.at(i).value.montantavance
                    let montantimposable=ammounts6.at(i).value.montantimposable*/
                    if(ammounts6.controls[i].get('salairebrut').value&&ammounts6.controls[i].get('salairebrut').value!=0)
                    {
                      ammounts6.controls[i].get('salairenet').reset()
                    }
                    if(ammounts6.controls[i].get('salairenet').value&&ammounts6.controls[i].get('salairenet').value!=0)
                    {
                      ammounts6.controls[i].get('salairebrut').reset()
                    }
                   /* if(+cnss>+salairebrut)
                try {
                    const result = await Swal.fire({
                      title: 'Montant CNSS ne doit pas dépasser le montant Salaire Brut',
                      icon: 'error',
                      confirmButtonColor: '#3085d6',
                    });
                    this.loading = false;
                    ammounts6.controls[i].patchValue({ montantcnss: '',salairebrut:'',montantimposable:''});  
                  } catch {
                    Swal.fire('opération non aboutie!');
                  }
                  if(+montantavance+ +montantretenue>+montantimposable)
                  try {
                      const result = await Swal.fire({
                        title: 'Les Montants introduits ne peuvent pas dépasser la salaire imposable',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                      });
                      this.loading = false;
                      ammounts6.controls[i].patchValue({ montantretenue: '',montantimposable: '',montantavance:''});  
                    } catch {
                      Swal.fire('opération non aboutie!');
                    }*/
                    this.totalsalairebrut = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.salairebrut || 0);
                      return acc;
                    },0);
                    /*this.totalcnss = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.montantcnss || 0);
                      return acc;
                    },0);
                    this.totalsalaireimposable = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.montantimposable || 0);
                      return acc;
                    },0);
                    this.totalretenueimpot = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.montantretenue || 0);
                      return acc;
                    },0);
                    this.totalavancepret = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.montantavance || 0);
                      return acc;
                    },0);*/
                    this.totalsalairenet = +(ammounts6.value).reduce((acc,curr)=>{
                      acc += +(curr.salairenet || 0);
                      return acc;
                    },0);
                    if(typessalaire.controls[typeindex].get('typesalaire').value=='Autre')
                    {
                      this.typessalaires.push(            
                          typessalaire.controls[typeindex].get('autretypesalaire').value    
                      )
                      this.settedtypessalaires= this.typessalaires.filter((obj, index) => {
                        return index === this.typessalaires.findIndex(o => obj === obj);
                      });
                      this.settedtypessalaires=this.settedtypessalaires.sort()
                      typessalaire.at(typeindex).patchValue(            
                          typessalaire.controls[typeindex].get('autretypesalaire').value                
                      )
                    }
                   if(this.ajoutsalarieform.get('naturecontrat').value=='Autre')
            {
             //@ts-ignore
             this.naturescontrat.push({
              natcontrat:ammounts6.value.at(i).autrenaturecontrat
            }
            )
            this.settednaturecontrats= this.naturescontrat.filter((obj, index) => {
                        //@ts-ignore
            
              return index === this.naturescontrat.findIndex(o => obj.natcontrat === o.natcontrat);
            });
            this.settednaturecontrats=this.settednaturecontrats.sort()
              this.ajoutsalarieform.patchValue(
                {
                  naturecontrat:this.ajoutsalarieform.get('autrenaturecontrat').value
                }
              )
            }
                    this.realsalairebrut6=this.totalsalairebrut
                    this.realsalaireimposable6=this.totalsalaireimposable
                    this.realretenue6=this.totalretenueimpot
                const salairesbrutstfp=this.realsalairebrut6
                const tauxtfp=0.02
                const tauxfoprolos=0.01
               const basetfp=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
                  const montanttfpmois=+ Math.trunc((+basetfp* +tauxtfp)*1000)/1000;
                  this.tfpapayer=+ Math.trunc((+montanttfpmois)*1000)/1000;
                  const basefoprolos=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
                  const montantfoprolosmois=+ Math.trunc((+basefoprolos* +tauxfoprolos)*1000)/1000;
                  this.foprolosapayer=+ Math.trunc((+montantfoprolosmois)*1000)/1000;
                  }
                  async verifymatricule(typeindex:number,i: number){
                    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
                    let matricule=ammounts6.value.at(i).matricule
                    let ammounts6sliced=ammounts6.getRawValue().slice(0,-1)
            
                let salaires=[]
            
                this.alldeccomptabilites.find(objet => objet.autre6.forEach(element => element.salarie!=null))?      
                this.alldeccomptabilites.forEach(element => element.autre6.forEach(element => element.salarie.forEach(e => salaires.push(e))))
                :''
                    let filtredsalaire= salaires.find(e=>e.matricule==matricule)
                    this.ajoutsalarieform.reset()
                    if(i>0)
                    {
                      if(ammounts6sliced.find(e=>e.matricule==matricule))
                      return (
                        Swal.fire({
                        title: 'maricule déjà saisi',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                      }).then((result) => {ammounts6.at(i).patchValue({matricule:''})
                      }).catch(() => {
                        Swal.fire('opération non aboutie!')
                      }))
                    }
                    if(filtredsalaire)
                    {
                      ammounts6.controls[i].patchValue(
                        {
                         nomprenom: filtredsalaire.nomprenom,
                         naturecontrat: filtredsalaire.naturecontrat,
                         ficheUrl:filtredsalaire.ficheUrl,
                  autrenaturecontrat:filtredsalaire.naturecontrat,
                  sexe:filtredsalaire.sexe,
                  naissance:filtredsalaire.naissance,
                  etatcivil:filtredsalaire.etatcivil,
                  salairenet:'0',
                  salairebrut:'0',
                  cin:filtredsalaire.cin,
                  delivre:filtredsalaire.delivre,
                  adresse:filtredsalaire.adresse,
                  mobile:filtredsalaire.mobile,
                  mail:filtredsalaire.mail,
                  chef:filtredsalaire.chef==true?'oui':'non',
                  enfant:filtredsalaire.enfant,
                  image:'',
                  contientfiche:filtredsalaire.contientfiche,
                        }
                        );  
                    }
                    else
                    {
                      this.displaysalaire="block"
                      this.ajoutsalarieform.patchValue(
                        {
                         matricule: matricule,
                        }
                        );  
                    }
                  }
                  async modifyemploye(typeindex:number,i: number){
                    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
                    this.displaysalaire="block"
                    this.ajoutsalarieform.patchValue(
                      {
                  matricule: ammounts6.value.at(i).matricule,
                  nomprenom: ammounts6.value.at(i).nomprenom,
                  naturecontrat:ammounts6.value.at(i).naturecontrat,
                  autrenaturecontrat:ammounts6.value.at(i).naturecontrat,
                  sexe:ammounts6.value.at(i).sexe,
                  naissance:ammounts6.value.at(i).naissance,
                  etatcivil:ammounts6.value.at(i).etatcivil,
                  salairenet:'0',
                  salairebrut:'0',
                  cin:ammounts6.value.at(i).cin,
                  delivre:ammounts6.value.at(i).delivre,
                  adresse:ammounts6.value.at(i).adresse,
                  mobile:ammounts6.value.at(i).mobile,
                  mail:ammounts6.value.at(i).mail,
                  chef:ammounts6.value.at(i).chef==true?'oui':'non',
                  enfant:ammounts6.value.at(i).enfant,
                  image:'',
                  ficheUrl:ammounts6.value.at(i).ficheUrl,
                  contientfiche:ammounts6.value.at(i).contientfiche,
                      }
                    )
                  }
                  async ajoutersalarie(typeindex:number,i: number){
                    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
                    ammounts6.at(i).patchValue(
                      {
                  matricule: this.ajoutsalarieform.get('matricule').value,
                  nomprenom: this.ajoutsalarieform.get('nomprenom').value,
                  naturecontrat:this.ajoutsalarieform.get('naturecontrat').value,
                  autrenaturecontrat:this.ajoutsalarieform.get('naturecontrat').value,
                  sexe:this.ajoutsalarieform.get('sexe').value,
                  naissance:this.ajoutsalarieform.get('naissance').value,
                  etatcivil:this.ajoutsalarieform.get('etatcivil').value,
                  salairenet:'0',
                  salairebrut:'0',
                  cin:this.ajoutsalarieform.get('cin').value,
                  delivre:this.ajoutsalarieform.get('delivre').value,
                  adresse:this.ajoutsalarieform.get('adresse').value,
                  mobile:this.ajoutsalarieform.get('mobile').value,
                  mail:this.ajoutsalarieform.get('mail').value,
                  chef:this.ajoutsalarieform.get('chef').value==true?'oui':'non',
                  enfant:this.ajoutsalarieform.get('enfant').value,
                  image:'',
                  ficheUrl:this.ajoutsalarieform.get('ficheUrl').value,
                  contientfiche:this.ajoutsalarieform.get('contientfiche').value,
                      }
                      );
                      console.log(ammounts6.at(i).value)
                      this.displaysalaire="none"
              
                  }
                        keyPressNumbers(event) {
                          var charCode = (event.which) ? event.which : event.keyCode;
                          // Only Numbers 0-9
                          if ((charCode < 48 || charCode > 57)) {
                            event.preventDefault();
                            return false;
                          } else {
                            return true;
                          }
                        }
                        //Ajout de formulaire de création  edition note + recette journaliere
                        get ammountControls0() {
                          return this.editionnotepastform.get('ammounts0')['controls'];
                        }
                        get ammountControls() {
                          return this.editionnoteform.get('ammounts')['controls'];
                        }
                        get ammountControls2() {
                          return this.recettejournaliereform.get('ammounts2')['controls'];
                        }
                        get ammountControls3() {
                          return this.factureachatform.get('ammounts3')['controls'];
                        }
                        get ammountControls8() {
                          return this.factureachatchequeform.get('ammounts8')['controls'];
                        }
                        get ammountControls9() {
                          return this.facturefournisseurform.get('ammounts9')['controls'];
                        }
                        get ammountControls10() {
                          return this.ajoutdccopform.get('ammounts10')['controls'];
                        }
                        get ammountControls11() {
                          return this.factureachatespeceform.get('ammounts11')['controls'];
                        }
                        employees(): FormArray {
                          return this.relevemanuelform.get('employees') as FormArray;
                        }
                        employeeSkills(empIndex: number): FormArray {
                          return this.employees()
                            .at(empIndex)
                            .get('skills') as FormArray;
                        }
                        get ammountControls5() {
                          return this.relevejointform.get('ammounts5')['controls'];
                        }
                        typessalaire(): FormArray {
                          return this.salaireform.get('typessalaire') as FormArray;
                        }
                        salaires(typeindex: number): FormArray {
                          return this.typessalaire()
                            .at(typeindex)
                            .get('ammounts6') as FormArray;
                        }
                        createammount0() 
  : FormGroup { 
      return this.fb.group({
      type:'1',
      numeropiece: '',
      jour: ['', [Validators.pattern(/^\d+$/)]],
      date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
      typepiece: 'facture',
      typepiecesyndic: 'facture',
      numeronote: '',
      montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva:this.tauxtva,
      montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantdt:this.tauxdt,
      montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantht19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva19:'0.19',
      montanttva19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montantht13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva13:'0.13',
      montanttva13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthtfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthttpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttctpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      devise:'euro',
      montanthtexpdevise:'0',
      montanthtexp:'0',
      tauxtvaexp:'0',
      montanttvaexp:'0',
      montantttcexp:'0',
      objet:'',
      autreobjet:'',
      annule:'',
      nature:'',
      autrenature:'',
      banque:'',
      autrebanque:'',
      numerocompte: '',
      autrenumerocompte: '',
      echeance:'',
      datevirement:'',
      client:'',
      autreclient:'',
      });
    }
  createammount() 
  : FormGroup {
      return this.fb.group({
        type:'1',
        numeropiece: '',
        jour: ['', [Validators.pattern(/^\d+$/)]],
        date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
        typepiece: 'facture',
        typepiecesyndic: 'facture',
        numeronote: '',
        montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva:this.tauxtva,
      montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantdt:this.tauxdt,
      montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantht19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva19:'0.19',
      montanttva19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantht13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva13:'0.13',
      montanttva13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthttpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttctpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthtfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      devise:'euro',
      montanthtexpdevise:'0',
      montanthtexp:'0',
      tauxtvaexp:'0',
      montanttvaexp:'0',
      montantttcexp:'0',
      objet:'',
      autreobjet:'',
      annule:'',
      nature:'',
      autrenature:'',
      banque:'',
      autrebanque:'',
      numerocompte: '',
      autrenumerocompte: '',
      echeance:'',
      datevirement:'',

        client:'',
        autreclient:'',

      });
    }
  createammount2() 
  : FormGroup {
    
    return  this.fb.group({
      type:'2',
      numeropiece: '',
      jour: ['', [Validators.pattern(/^\d+$/)]],
      date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
      recette:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      recette19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      recette13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      recetteexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      recettesus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      recettefodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],


      recettetpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      devise:'euro',
      recetteexp:[{ value: '0', disabled: true }],
      recetteexpdevise:'0',
      montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva:this.tauxtva,
      montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantdt:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantht19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva19:'0.19',
      montanttva19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc19:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montantht13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtva13:'0.13',
      montanttva13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttc13:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvaexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcexo:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvasus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcsus:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthttpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvatpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttctpe:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],

      montanthtfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      tauxtvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanttvafodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montantttcfodec:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
      montanthtexp:'0',
      tauxtvaexp:'0',
      montanttvaexp:'0',
      montantttcexp:'0',
    });
  }
                        createammount3() 
                        : FormGroup {
                          
                          return  this.fb.group({
                            type:'3',
                            numeropiece: '',
                            jour: ['', [Validators.pattern(/^\d+$/)]],
                            date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
                            devise: 'euro',
                      
                            fournisseur:'',
                            autrefournisseur:'',
                            typepiece:'facture',
                            numerofacture:'',
                            natureachat:'',
                            autrenatureachat:'',
                            montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtachat:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtdevise:'0',
                            montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantdt:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            reglement:'',
                            image:'',
                            ficheUrl:'',
                            contientfiche:false
                          });
                        }
                        createammount8() 
                        : FormGroup {
                          
                          return  this.fb.group({
                            type:'8',
                            numeropiece: '',
                            jour: ['', [Validators.pattern(/^\d+$/)]],
                            date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
                            devise: 'euro',
                            beneficiaire:'',
                            autrebeneficiaire:'',
                            fournisseur:'',
                            autrefournisseur:'',
                            typepiece:'facture',
                            numerofacture:'',
                            natureachat:'',
                            autrenatureachat:'',
                            montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtachat:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtdevise:'0',
                            montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantdt:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            reglement:'Chèque',
                            image:'',
                            ficheUrl:'',
                            numerocheque:'',
                            banque:'',
                            autrebanque:'',
                            contientfiche:false
                          });
                        }
                        createammount9() 
                        : FormGroup {
                          
                          return  this.fb.group({
                            type:'9',
                            numeropiece: '',
                            jour: ['', [Validators.pattern(/^\d+$/)]],
                            date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
                            devise: 'euro',
                            beneficiaire:'',
                            autrebeneficiaire:'',
                            fournisseur:'',
                            autrefournisseur:'',
                            typepiece:'facture',
                            numerofacture:'',
                            natureachat:'',
                            autrenatureachat:'',
                            montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtachat:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtdevise:'0',
                            montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantdt:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            reglement:'',
                            image:'',
                            ficheUrl:'',
                            contientfiche:false
                          });
                        }
                        createammount11() 
                        : FormGroup {
                          
                          return  this.fb.group({
                            type:'11',
                            numeropiece: '',
                            jour: ['', [Validators.pattern(/^\d+$/)]],
                            date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
                            devise: 'euro',
                            beneficiaire:'',
                            autrebeneficiaire:'',
                            fournisseur:'',
                            autrefournisseur:'',
                            typepiece:'facture',
                            numerofacture:'',
                            natureachat:'',
                            autrenatureachat:'',
                            montantht:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtachat:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montanthtdevise:'0',
                            montanttva:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantdt:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            montantttc:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            reglement:'Espèce',
                            image:'',
                            ficheUrl:'',
                            contientfiche:false
                          });
                        }
                        newEmployee(): FormGroup {
                          return this.fb.group({
                            type:'4',
                            banque:'',
                            autrebanque:'',
                            numerocompte: '',
                            devise:'euro',
                            autrenumerocompte: '',
                            debit:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            debitdevise:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            credit:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            creditdevise:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            soldefinmoisdinar:['0', [Validators.pattern(/^-?\d+(\.\d+)?$/)]],
                            soldefinmoisdevise:['0', [Validators.pattern(/^-?\d+(\.\d+)?$/)]],
                            skills: this.fb.array([this.newSkill()])
                          });
                        }
                        newSkill(): FormGroup {
                          return this.fb.group({
                            jour: ['', [Validators.pattern(/^\d+$/)]],
                            numeropiece:'1',
                            date: ['', [Validators.pattern(/^[0-9\/]+$/)]],
                            designation: '',
                            debit:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            debitdevise:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            credit:[{ value: '0', disabled: true }, [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            creditdevise:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            objetcredit: '',
                            autreobjetcredit: '',
                            objetdebit: '',
                            autreobjetdebit: '',
                            contientauxiliaire:'',
                            fournisseur:'',
                            autrefournisseur:'',
                            client:'',
                            autreclient:'',
                          });
                        }
                        createammount5() 
                        : FormGroup {
                          
                          return  this.fb.group({
                            type:'5',
                            annee: ['0', [Validators.pattern(/^\d+$/)]],
                            mois: ['0', [Validators.pattern(/^\d+$/)]],
                            image:'',
                            ficheUrl:'',
                            contientfiche:false
                      
                          });
                        }
                        newtypesalaire(): FormGroup {
                          return this.fb.group({
                            type:'6',
                            typesalaire:'',
                            autretypesalaire:'',
                            ammounts6: this.fb.array([this.createammount6()])
                          });
                        }
                       
                        createammount6() 
                        : FormGroup {
                          return  this.fb.group({
                            type:'6',
                            matricule: '',
                            nomprenom: '',
                            naturecontrat:'',
                            autrenaturecontrat:'',
                            sexe:'',
                            naissance:'',
                            etatcivil:'',
                            salairenet:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            salairebrut:['0', [Validators.pattern(/^(\d+\.?\d*|\.\d+|\d+)$/)]],
                            cin:'',
                            delivre:'',
                            adresse:'',
                            mobile:'',
                            mail:'',
                            chef:'',
                            enfant:'',
                            image:'0',
                            ficheUrl:'',
                            contientfiche:false
                          });
                        }
                        createammount10() 
                        : FormGroup {
                          return  this.fb.group({
                          numero:'',
                          coproprietaire:'',
                          annee:this.option1Value,
                          periodicite: '',
                          autreperiodicite: '',
                          montantecheance: '',
                          });
                        }
                          addammount0(){
                            this.ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                            const i=this.ammounts0.length
                            let specificarray=[]
                            let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>!item.source)
                            desireddeccomptables.forEach(element=> element.annee==`${this.option1Value}`? specificarray.push(element.autre0) :'')
                            let numeronotes=[]
                            let factureammounts0=[]
                              let avoirammounts0=[]
                              let totalhtavoir=0
                              let totalhtfacture=0
                              let totaltvaavoir=0
                              let totaltvafacture=0
                              let totaldtavoir=0
                              let totaldtfacture=0
                              let totalttcavoir=0
                              let totalttcfacture=0
                              let totalht19avoir=0
                              let totalht19facture=0
                              let totaltva19avoir=0
                              let totaltva19facture=0
                              let totalttc19avoir=0
                              let totalttc19facture=0
                      
                              let totalht13avoir=0
                              let totalht13facture=0
                              let totaltva13avoir=0
                              let totaltva13facture=0
                              let totalttc13avoir=0
                              let totalttc13facture=0
                              let totalhtexoavoir=0
                              let totalhtexofacture=0       
                              let totalttcexoavoir=0
                              let totalttcexofacture=0
                              let totalhtsusavoir=0
                              let totalhtsusfacture=0      
                              let totalttcsusavoir=0
                              let totalttcsusfacture=0
                              let totalhtexpavoir=0
                              let totalhtexpfacture=0      
                              let totalttcexpavoir=0
                              let totalttcexpfacture=0
                            this.ammounts0.value.filter((item)=>
                                {
                                  item.typepiece=='facture'?factureammounts0.push(item):avoirammounts0.push(item)
                                })
                            this.ammounts0.value.filter((item)=>
                            {
                              item.typepiece=='facture'?numeronotes.push(item.numeronote):''
                            })
                           if(this.contientnumeroother==true)
                           {
                            let result=this.verifyfacture(i,desireddeccomptables,specificarray,
                              this.ammounts0.at(i-1).value.typepiece=='facture'?
                              +(this.ammounts0.at(i-1).value.numeronote)+1
                               :numeronotes.length>0?+(numeronotes[numeronotes.length-1])+1:this.getNumeronote(this.user, this.option1Value, this.deccomptabilite))
                            if(!result)
                            {
                              this.ammounts0.push(this.createammount0());
                              this.ammounts0.at(i-1).value.typepiece=='facture'?
                                this.ammounts0.at(i).patchValue({
                                  numeronote:+(this.ammounts0.at(i-1).value.numeronote)+1,
                                  numeropiece:+(this.ammounts0.at(i-1).value.numeropiece)+1
                                 })
                                 : this.ammounts0.at(i).patchValue({
                                  numeronote:numeronotes.length>0?+(numeronotes[numeronotes.length-1])+1:this.getNumeronote(this.user, this.option1Value, this.deccomptabilite),
                                  numeropiece:+(this.ammounts0.at(i-1).value.numeropiece)+1
                                 })
                                 totalhtfacture = +(factureammounts0).reduce((acc,curr)=>{
                                  acc += +(curr.montantht || 0);
                                  return acc;
                                },0);
                                totalhtavoir = +(avoirammounts0).reduce((acc,curr)=>{
                                  acc += +(curr.montantht || 0);
                                  return acc;
                                },0);      
                                this.totalht0 = totalhtfacture - totalhtavoir
                                totaltvafacture= +(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva || 0);
                                return acc;
                                },0);
                                totaltvaavoir= +(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva || 0);
                                return acc;
                                },0);
                                this.totaltva0 = totaltvafacture - totaltvaavoir
                                totaldtfacture= +(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantdt || 0);
                                return acc;
                                },0);
                                totaldtavoir= +(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantdt || 0);
                                return acc;
                                },0);
                                this.totaldt0 = totaldtfacture - totaldtavoir
                                totalttcfacture= +(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc || 0);
                                return acc;
                                },0);
                                totalttcavoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc || 0);
                                return acc;
                                },0);
                                
                                this.totalttc0 = totalttcfacture-totalttcavoir
                                this.realht0=this.totalht0
                                this.realdt0=this.totaldt0
                                
                                totalht19facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht19 || 0);
                                return acc;
                                },0);
                                totalht19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht19 || 0);
                                return acc;
                                },0);
                                this.totalht019 = totalht19facture-totalht19avoir
                                totaltva19facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva19 || 0);
                                return acc;
                                },0);
                                totaltva19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montanttva19 || 0);
                                return acc;
                                },0);
                                this.totaltva019 = totaltva19facture-totaltva19avoir
                                totalttc19facture=+(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc19 || 0);
                                return acc;
                                },0);
                                totalttc19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantttc19 || 0);
                                return acc;
                                },0);
                                this.totalttc019 = totalttc19facture-totalttc19avoir
                  this.realht019=this.totalht019
                  this.realdt019=this.totaldt019
                  totalht13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantht13 || 0);
                    return acc;
                    },0);
                    totalht13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantht13 || 0);
                    return acc;
                    },0);
                    this.totalht013 = totalht13facture-totalht13avoir
                    totaltva13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montanttva13 || 0);
                    return acc;
                    },0);
                    totaltva13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montanttva13 || 0);
                    return acc;
                    },0);
                    this.totaltva013 = totaltva13facture-totaltva13avoir
                    totalttc13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantttc13 || 0);
                    return acc;
                    },0);
                    totalttc13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantttc13 || 0);
                    return acc;
                    },0);
                    this.totalttc013 = totalttc13facture-totalttc13avoir
                    this.realht013=this.totalht013
                    this.realdt013=this.totaldt013
                            }
                          
                           }
                          else
                          {
                            this.ammounts0.push(this.createammount0());
                            this.ammounts0.at(i-1).value.typepiece=='facture'?
                              this.ammounts0.at(i).patchValue({
                                numeropiece:+(this.ammounts0.at(i-1).value.numeropiece)+1
                               })
                               : this.ammounts0.at(i).patchValue({
                                numeropiece:+(this.ammounts0.at(i-1).value.numeropiece)+1
                               })
                               totalhtfacture = +(factureammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht || 0);
                                return acc;
                              },0);
                              totalhtavoir = +(avoirammounts0).reduce((acc,curr)=>{
                                acc += +(curr.montantht || 0);
                                return acc;
                              },0);      
                              this.totalht0 = totalhtfacture - totalhtavoir
                              totaltvafacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                              },0);
                              totaltvaavoir= +(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                              },0);
                              this.totaltva0 = totaltvafacture - totaltvaavoir
                              totaldtfacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                              },0);
                              totaldtavoir= +(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                              },0);
                              this.totaldt0 = totaldtfacture - totaldtavoir
                              totalttcfacture= +(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                              },0);
                              totalttcavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                              },0);
                              
                              this.totalttc0 = totalttcfacture-totalttcavoir
                              this.realht0=this.totalht0
                              this.realdt0=this.totaldt0
                              
                              totalht19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantht19 || 0);
                              return acc;
                              },0);
                              totalht19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantht19 || 0);
                              return acc;
                              },0);
                              this.totalht019 = totalht19facture-totalht19avoir
                              totaltva19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva19 || 0);
                              return acc;
                              },0);
                              totaltva19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanttva19 || 0);
                              return acc;
                              },0);
                              this.totaltva019 = totaltva19facture-totaltva19avoir
                              totalttc19facture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc19 || 0);
                              return acc;
                              },0);
                              totalttc19avoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montantttc19 || 0);
                              return acc;
                              },0);
                              this.totalttc019 = totalttc19facture-totalttc19avoir
                  this.realht019=this.totalht019
                  this.realdt019=this.totaldt019
                  totalht13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantht13 || 0);
                    return acc;
                    },0);
                    totalht13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantht13 || 0);
                    return acc;
                    },0);
                    this.totalht013 = totalht13facture-totalht13avoir
                    totaltva13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montanttva13 || 0);
                    return acc;
                    },0);
                    totaltva13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montanttva13 || 0);
                    return acc;
                    },0);
                    this.totaltva013 = totaltva13facture-totaltva13avoir
                    totalttc13facture=+(factureammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantttc13 || 0);
                    return acc;
                    },0);
                    totalttc13avoir=+(avoirammounts0).reduce((acc,curr)=>{
                    acc += +(curr.montantttc13 || 0);
                    return acc;
                    },0);
                    this.totalttc013 = totalttc13facture-totalttc13avoir
                    this.realht013=this.totalht013
                    this.realdt013=this.totaldt013
                             /* totalhtexofacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexo || 0);
                              return acc;
                              },0);
                              totalhtexoavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexo || 0);
                              return acc;
                              },0);
                              this.totalhtexo = totalhtexofacture-totalhtexoavoir
                              totalhtsusfacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtsus || 0);
                              return acc;
                              },0);
                              totalhtsusavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtsus || 0);
                              return acc;
                              },0);
                              this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                              totalhtexpfacture=+(factureammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexp || 0);
                              return acc;
                              },0);
                              totalhtexpavoir=+(avoirammounts0).reduce((acc,curr)=>{
                              acc += +(curr.montanthtexp || 0);
                              return acc;
                              },0);
                              this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                              this.realht1exp=this.totalhtexp     
                              this.realht1exo=this.totalhtexo
                              this.realht1sus=this.totalhtsus */
                          }
                         
                        
                          }
                           addammount(){
                            this.ammounts = this.editionnoteform.get('ammounts') as FormArray;
                            const i=this.ammounts.length
                            let specificarray=[]
                            let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>!item.source)
                            desireddeccomptables.forEach(element=> element.annee==`${this.option1Value}`? specificarray.push(element.autre0) :'')
                            let numeronotes=[]
                            let factureammounts=[]
                              let avoirammounts=[]
                              let totalhtavoir=0
                                let totalhtfacture=0
                                let totaltvaavoir=0
                                let totaltvafacture=0
                                let totaldtavoir=0
                                let totaldtfacture=0
                                let totalttcavoir=0
                                let totalttcfacture=0
                                let totalht19avoir=0
                                let totalht19facture=0
                                let totaltva19avoir=0
                                let totaltva19facture=0
                                let totalttc19avoir=0
                                let totalttc19facture=0
                                let totalht13avoir=0
        let totalht13facture=0
        let totaltva13avoir=0
        let totaltva13facture=0
        let totalttc13avoir=0
        let totalttc13facture=0
                                let totalhtexoavoir=0
                                let totalhtexofacture=0       
                                let totalttcexoavoir=0
                                let totalttcexofacture=0
                                let totalhtsusavoir=0
                                let totalhtsusfacture=0      
                                let totalttcsusavoir=0
                                let totalttcsusfacture=0
                                let totalhtexpavoir=0
                                let totalhtexpfacture=0      
                                let totalttcexpavoir=0
                                let totalttcexpfacture=0
                            this.ammounts.value.filter((item)=>
                                {
                                  item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                                })
                            this.ammounts.value.filter((item)=>
                            {
                              item.typepiece=='facture'?numeronotes.push(item.numeronote):''
                            })
                            
                        
                            if(this.contientnumero==true)
                            {
                              let result=this.verifyfacture(i,desireddeccomptables,specificarray,
                                this.ammounts.at(i-1).value.typepiece=='facture'?
                                +(this.ammounts.at(i-1).value.numeronote)+1
                                 :numeronotes.length>0?+(numeronotes[numeronotes.length-1])+1:this.getNumeronote(this.user, this.option1Value, this.deccomptabilite))
                                 if(!result)
                                 {
                                  this.ammounts.push(this.createammount());
                                  this.ammounts.at(i-1).value.typepiece=='facture'?
                                    this.ammounts.at(i).patchValue({
                                      numeronote:+(this.ammounts.at(i-1).value.numeronote)+1,
                                      numeropiece:+(this.ammounts.at(i-1).value.numeropiece)+1
                                     })
                                     : this.ammounts.at(i).patchValue({
                                      numeronote:numeronotes.length>0?+(numeronotes[numeronotes.length-1])+1:this.getNumeronote(this.user, this.option1Value, this.deccomptabilite),
                                      numeropiece:+(this.ammounts.at(i-1).value.numeropiece)+1
                                     })
                                     totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht || 0);
                                      return acc;
                                    },0);
                                    totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht || 0);
                                      return acc;
                                    },0);      
                                    this.totalht = totalhtfacture - totalhtavoir
                                    totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva || 0);
                                    return acc;
                                    },0);
                                    totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva || 0);
                                    return acc;
                                    },0);
                                    this.totaltva = totaltvafacture - totaltvaavoir
                                    totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantdt || 0);
                                    return acc;
                                    },0);
                                    totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantdt || 0);
                                    return acc;
                                    },0);
                                    this.totaldt = totaldtfacture - totaldtavoir
                                    totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc || 0);
                                    return acc;
                                    },0);
                                    totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc || 0);
                                    return acc;
                                    },0);
                                    
                                    this.totalttc = totalttcfacture-totalttcavoir
                                    this.realht1=this.totalht
                                    this.realdt1=this.totaldt
                                    
                                    totalht19facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantht19 || 0);
                                    return acc;
                                    },0);
                                    totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantht19 || 0);
                                    return acc;
                                    },0);
                                    this.totalht19 = totalht19facture-totalht19avoir
                                    totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva19 || 0);
                                    return acc;
                                    },0);
                                    totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanttva19 || 0);
                                    return acc;
                                    },0);
                                    this.totaltva19 = totaltva19facture-totaltva19avoir
                                    totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc19 || 0);
                                    return acc;
                                    },0);
                                    totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montantttc19 || 0);
                                    return acc;
                                    },0);
                                    this.totalttc19 = totalttc19facture-totalttc19avoir
                                    this.realht119=this.totalht19
                                    this.realdt119=this.totaldt19
                                    totalht13facture=+(factureammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht13 || 0);
                                      return acc;
                                      },0);
                                      totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantht13 || 0);
                                      return acc;
                                      },0);
                                      this.totalht13 = totalht13facture-totalht13avoir
                                      totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montanttva13 || 0);
                                      return acc;
                                      },0);
                                      totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montanttva13 || 0);
                                      return acc;
                                      },0);
                                      this.totaltva13 = totaltva13facture-totaltva13avoir
                                      totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantttc13 || 0);
                                      return acc;
                                      },0);
                                      totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
                                      acc += +(curr.montantttc13 || 0);
                                      return acc;
                                      },0);
                                      this.totalttc13 = totalttc13facture-totalttc13avoir
                                      this.realht113=this.totalht13
                                      this.realdt113=this.totaldt13
                                    totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexo || 0);
                                    return acc;
                                    },0);
                                    totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexo || 0);
                                    return acc;
                                    },0);
                                    this.totalhtexo = totalhtexofacture-totalhtexoavoir
                                    totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtsus || 0);
                                    return acc;
                                    },0);
                                    totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtsus || 0);
                                    return acc;
                                    },0);
                                    this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                                    totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexp || 0);
                                    return acc;
                                    },0);
                                    totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
                                    acc += +(curr.montanthtexp || 0);
                                    return acc;
                                    },0);
                                    this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                                    this.realht1exp=this.totalhtexp     
                                    this.realht1exo=this.totalhtexo
                                    this.realht1sus=this.totalhtsus
                                 }
                         
                            }
                            else{
                             this.ammounts.push(this.createammount());
                             this.ammounts.at(i-1).value.typepiece=='facture'?
                               this.ammounts.at(i).patchValue({
                                 numeropiece:+(this.ammounts.at(i-1).value.numeropiece)+1
                                })
                                : this.ammounts.at(i).patchValue({
                                 numeropiece:+(this.ammounts.at(i-1).value.numeropiece)+1
                                })
                                totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                                 acc += +(curr.montantht || 0);
                                 return acc;
                               },0);
                               totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                                 acc += +(curr.montantht || 0);
                                 return acc;
                               },0);      
                               this.totalht = totalhtfacture - totalhtavoir
                               totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanttva || 0);
                               return acc;
                               },0);
                               totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanttva || 0);
                               return acc;
                               },0);
                               this.totaltva = totaltvafacture - totaltvaavoir
                               totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantdt || 0);
                               return acc;
                               },0);
                               totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantdt || 0);
                               return acc;
                               },0);
                               this.totaldt = totaldtfacture - totaldtavoir
                               totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantttc || 0);
                               return acc;
                               },0);
                               totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantttc || 0);
                               return acc;
                               },0);
                               
                               this.totalttc = totalttcfacture-totalttcavoir
                               this.realht1=this.totalht
                               this.realdt1=this.totaldt
                               
                               totalht19facture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantht19 || 0);
                               return acc;
                               },0);
                               totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantht19 || 0);
                               return acc;
                               },0);
                               this.totalht19 = totalht19facture-totalht19avoir
                               totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanttva19 || 0);
                               return acc;
                               },0);
                               totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanttva19 || 0);
                               return acc;
                               },0);
                               this.totaltva19 = totaltva19facture-totaltva19avoir
                               totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantttc19 || 0);
                               return acc;
                               },0);
                               totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montantttc19 || 0);
                               return acc;
                               },0);
                               this.totalttc19 = totalttc19facture-totalttc19avoir
                               this.realht119=this.totalht19
                               this.realdt119=this.totaldt19
                               
      totalht13facture=+(factureammounts).reduce((acc,curr)=>{
        acc += +(curr.montantht13 || 0);
        return acc;
        },0);
        totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
        acc += +(curr.montantht13 || 0);
        return acc;
        },0);
        this.totalht13 = totalht13facture-totalht13avoir
        totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
        acc += +(curr.montanttva13 || 0);
        return acc;
        },0);
        totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
        acc += +(curr.montanttva13 || 0);
        return acc;
        },0);
        this.totaltva13 = totaltva13facture-totaltva13avoir
        totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
        acc += +(curr.montantttc13 || 0);
        return acc;
        },0);
        totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
        acc += +(curr.montantttc13 || 0);
        return acc;
        },0);
        this.totalttc13 = totalttc13facture-totalttc13avoir
        this.realht113=this.totalht13
        this.realdt113=this.totaldt13
                               totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtexo || 0);
                               return acc;
                               },0);
                               totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtexo || 0);
                               return acc;
                               },0);
                               this.totalhtexo = totalhtexofacture-totalhtexoavoir
                               totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtsus || 0);
                               return acc;
                               },0);
                               totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtsus || 0);
                               return acc;
                               },0);
                               this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                               totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtexp || 0);
                               return acc;
                               },0);
                               totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
                               acc += +(curr.montanthtexp || 0);
                               return acc;
                               },0);
                               this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                               this.realht1exp=this.totalhtexp     
                               this.realht1exo=this.totalhtexo
                               this.realht1sus=this.totalhtsus
                            }
                           
                         
                            
                          }
                        addammount2(): void {
                          this.ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
                          this.ammounts2.push(this.createammount2());
                          const i=this.ammounts2.length
                          console.log(this.ammounts2.at(i-2).value.jour)
                          if(this.option2Value=='02')
                                {
                                  if(+this.option1Value % 4 ==0&&this.ammounts2.at(i-2).value.jour>=27)
                                  return (alert('vous ne pouvez pas effectuer cette action'),this.removeammount2(i-1))
                                  else  if(this.ammounts2.at(i-2).value.jour>=28)
                      
                                  return (alert('vous ne pouvez pas effectuer cette action'),this.removeammount2(i-1))   
                                } 
                      
                          if(this.option2Value==='04'&&this.ammounts2.at(i-1).value.jour>=29||this.option2Value==='06'&&this.ammounts2.at(i-2).value.jour>=29
                            ||this.option2Value==='09'&&this.ammounts2.at(i-2).value.jour>=29||this.option2Value==='11'&&this.ammounts2.at(i-2).value.jour>=29)
                          return (alert('vous ne pouvez pas effectuer cette action'),this.removeammount2(i-1)) 
                      
                          if (this.ammounts2.at(i-2).value.jour>=30)
                          return (alert('vous ne pouvez pas effectuer cette action'),this.removeammount2(i-1))
                      
                             
                              this.ammounts2.at(i-1).patchValue({
                              numeropiece:+(this.ammounts2.at(i-2).value.numeropiece)+1,
                              jour:+(this.ammounts2.at(i-2).value.jour)+1
                             })
                             this.setdate2(i-1)
                           
                          /*const i=this.ammounts2.length
                          if(+(this.recettejournaliereform.getRawValue().ammounts2)[i].tauxtva===0.07||!(this.recettejournaliereform.getRawValue().ammounts2)[i].tauxtva)
                          {
                            this.totalht2 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.07')).reduce((acc,curr)=>{
                              acc += +(curr.montantht || 0);
                              return acc;
                            },0);
                            this.totaltva2 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.07')).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                            },0);
                            this.totaldt2 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.07')).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                            },0);
                            this.totalttc2 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.07')).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                            },0);
                          }
                          else if(+(this.recettejournaliereform.getRawValue().ammounts2)[i].tauxtva===0.19)
                          {
                            this.totalht219 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.19')).reduce((acc,curr)=>{
                              acc += +(curr.montantht || 0);
                              return acc;
                            },0);
                            this.totaltva219 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.19')).reduce((acc,curr)=>{
                              acc += +(curr.montanttva || 0);
                              return acc;
                            },0);
                            this.totaldt219 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.19')).reduce((acc,curr)=>{
                              acc += +(curr.montantdt || 0);
                              return acc;
                            },0);
                            this.totalttc219 = +(this.DeccomptabiliteService.filterByValue(this.recettejournaliereform.getRawValue().ammounts2,'0.19')).reduce((acc,curr)=>{
                              acc += +(curr.montantttc || 0);
                              return acc;
                            },0);
                          }*/
                        }
                        addammount3(){
                          this.fileUploaded=false
                          this.ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
                          let i =this.ammounts3.length
                          if (this.ammounts3.value.at(i-1).montantht=='0'&&this.ammounts3.value.at(i-1).montanthtdevise=='0'
                          ||this.ammounts3.value.at(i-1).montantht==''&&this.ammounts3.value.at(i-1).montanthtdevise=='')                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type achat avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                          this.ammounts3.push(this.createammount3());
                          this.ammounts3.at(i).patchValue({
                              numeropiece:+(this.ammounts3.at(i-1).value.numeropiece)+1
                             })
                        
                          this.ammounts3.controls[i-1].get('fournisseur').disable();
                          this.ammounts3.controls[i-1].get('numerofacture').disable();
                          this.ammounts3.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('achat'+`${i-1}`);
                      text2.style.display='none'
                           this.totalht3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);
                          this.totaltva3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
                            acc += +(curr.montanttva || 0);
                            return acc;
                          },0);
                          this.totaldt3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
                            acc += +(curr.montantdt || 0);
                            return acc;
                          },0);
                          this.totalttc3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
                            acc += +(curr.montantttc || 0);
                            return acc;
                          },0);
                        }
                        addammount8(){
                          this.fileUploaded=false
                          this.ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
                          let i =this.ammounts8.length
                          if (this.ammounts8.value.at(i-1).montantht=='0'&&this.ammounts8.value.at(i-1).montanthtdevise=='0'
                          ||this.ammounts8.value.at(i-1).montantht==''&&this.ammounts8.value.at(i-1).montanthtdevise=='')
                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type achat avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                     /* if(this.ammounts8.value.at(i-1).numerofacture==''&&this.ammounts8.value.at(i-1).typepiece=='facture')
                      return (
                        Swal.fire({
                        title: 'vous ne pouvez pas laisser le numéro de facture vide!Si vous ne trouvez pas le bon numéro de facture pour règlement veuillez l\'ajouter à travers la rubrique factures fournisseurs',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                      }).then((result) => {this.ammounts8.at(i-1).patchValue({fournisseur:'',beneficiaire:''})
                      }).catch(() => {
                        Swal.fire('opération non aboutie!')
                      }))*/
                          this.ammounts8.push(this.createammount8());
                          this.ammounts8.at(i).patchValue({
                              numeropiece:+(this.ammounts8.at(i-1).value.numeropiece)+1
                             })
                        
                          this.ammounts8.controls[i-1].get('fournisseur').disable();
                          //this.ammounts8.controls[i-1].get('numerofacture').disable();
                          this.ammounts8.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('achatcheque'+`${i-1}`);
                      text2.style.display='none'
                      this.totalht8 = +(this.factureachatchequeform.get('ammounts8').value).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);
                      
                        }
                        addammount9(){
                          let factureammounts9=[]
                          let avoirammounts9=[]
                          let totalht9avoir=0
                          let totalht9facture=0
                          this.fileUploaded=false
                          this.ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
                          let i =this.ammounts9.length
                          if (this.ammounts9.value.at(i-1).montantht=='0'&&this.ammounts9.value.at(i-1).montanthtdevise=='0'
                          ||this.ammounts9.value.at(i-1).montantht==''&&this.ammounts9.value.at(i-1).montanthtdevise=='')
                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type achat avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                          this.ammounts9.push(this.createammount9());
                          this.ammounts9.at(i).patchValue({
                              numeropiece:+(this.ammounts9.at(i-1).value.numeropiece)+1
                             })
                        
                          this.ammounts9.controls[i-1].get('fournisseur').disable();
                          this.ammounts9.controls[i-1].get('numerofacture').disable();
                          this.ammounts9.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('facturefournisseur'+`${i-1}`);
                      text2.style.display='none'
                      
                      this.ammounts9.value.filter((item)=>
                      {
                        item.typepiece=='facture'?factureammounts9.push(item):avoirammounts9.push(item)
                      })
                      totalht9facture = +(factureammounts9).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);
                      totalht9avoir = +(avoirammounts9).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);      
                      this.totalht9 = totalht9facture - totalht9avoir
                       
                        }
                        addammount11(){
                          this.fileUploaded=false
                          this.ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
                          let i =this.ammounts11.length
                          if (this.ammounts11.value.at(i-1).montantht=='0'&&this.ammounts11.value.at(i-1).montanthtdevise=='0'
                          ||this.ammounts11.value.at(i-1).montantht==''&&this.ammounts11.value.at(i-1).montanthtdevise=='')
                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type achat avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                    /*  if(this.ammounts11.value.at(i-1).numerofacture==''&&this.ammounts11.value.at(i-1).typepiece=='facture')
                      return (
                        Swal.fire({
                        title: 'vous ne pouvez paslaisser le numéro de facture vide!Si vous ne trouvez pas le bon numéro de facture pour règlement veuillez l\'ajouter à travers la rubrique factures fournisseurs',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                      }).then((result) => {this.ammounts11.at(i-1).patchValue({fournisseur:'',beneficiaire:''})
                      }).catch(() => {
                        Swal.fire('opération non aboutie!')
                      }))*/
                          this.ammounts11.push(this.createammount11());
                          this.ammounts11.at(i).patchValue({
                              numeropiece:+(this.ammounts11.at(i-1).value.numeropiece)+1
                             })
                        
                          this.ammounts11.controls[i-1].get('fournisseur').disable();
                          this.ammounts11.controls[i-1].get('numerofacture').disable();
                          this.ammounts11.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('achatespece'+`${i-1}`);
                      text2.style.display='none'   
                      this.totalht11 = +(this.factureachatespeceform.get('ammounts11').value).reduce((acc,curr)=>{
                        acc += +(curr.montantht || 0);
                        return acc;
                      },0);
                        }
                        addEmployee() {
                          this.employees().push(this.newEmployee());
                        }
                        addEmployeeSkill(empIndex: number) {
                          this.skills = this.employees().at(empIndex).get('skills') as FormArray;
                          let devise=this.relevemanuelform.getRawValue().employees.at(empIndex).devise
                          let i =this.skills.length
                          this.employeeSkills(empIndex).push(this.newSkill());
                          this.skills.at(i).patchValue({
                            numeropiece:+(this.skills.at(i-1).value.numeropiece)+1
                           })
                           devise=='tnd'?
                           (
                           this.skills.controls[i].get('creditdevise').disable(),
                           this.skills.controls[i].get('debitdevise').disable(),
                           this.skills.controls[i].get('credit').enable(),
                           this.skills.controls[i].get('debit').enable())
                           :devise=='euro'?
                           (
                           this.skills.controls[i].get('creditdevise').enable(),
                           this.skills.controls[i].get('debitdevise').enable(),
                           this.skills.controls[i].get('credit').disable(),
                           this.skills.controls[i].get('debit').disable()
                           )
                           :devise=='dollar'||devise=='dzd'||devise=='sar'||devise=='cad'||devise=='dkk'||
                           devise=='jpy'||devise=='mad'||devise=='nok'||devise=='sek'||devise=='chf'||devise=='kwd'||
                           devise=='aed'||devise=='lyd'||devise=='mru'||devise=='bhd'||devise=='qar'||devise=='cny'?
                           (
                           this.skills.controls[i].get('creditdevise').enable(),
                           this.skills.controls[i].get('debitdevise').enable(),
                           this.skills.controls[i].get('credit').disable(),
                           this.skills.controls[i].get('debit').disable())
                           :devise=='gbp'?
                           (
                           this.skills.controls[i].get('creditdevise').enable(),
                           this.skills.controls[i].get('debitdevise').enable(),
                           this.skills.controls[i].get('credit').disable(),
                           this.skills.controls[i].get('debit').disable())
                           :''
                        }
                        addammount5(){
                          this.ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
                          if (this.ammounts5.value.at(0).mois=='0'||this.ammounts5.value.at(0).mois=='')
                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type banque avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                      let i =this.ammounts5.length
                          this.ammounts5.push(this.createammount5());
                          this.ammounts5.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('releve'+`${i-1}`);
                      text2.style.display='none'
                        }
                        addtypesalaire() {
                          this.typessalaire().push(this.newtypesalaire());
                        }
                        addammount6(typeindex:number){
                          this.ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
                          if (this.ammounts6.value.at(0).matricule=='')
                      {
                        return (
                          Swal.fire({
                          title: 'veuillez saisir des données valides pour le type salaire avant d\'ajouter une autre ligne',
                          icon: 'error',
                          confirmButtonColor: '#3085d6',
                        }).then((result) => {this.loading=false
                        }).catch(() => {
                          Swal.fire('opération non aboutie!')
                        }))
                      }
                      let i =this.ammounts6.length
                          this.ammounts6.push(this.createammount6());
                          this.ammounts6.controls[i-1].get('contientfiche').disable();
                          var text2 = document.getElementById('salaire'+`${typeindex}`+`${i-1}`);
                      text2.style.display='none'
                          this.totalsalairebrut = +(this.ammounts6.value).reduce((acc,curr)=>{
                            acc += +(curr.salairebrut || 0);
                            return acc;
                          },0);
                          /*this.totalcnss = +(this.ammounts6.value.value).reduce((acc,curr)=>{
                            acc += +(curr.montantcnss || 0);
                            return acc;
                          },0);
                          this.totalsalaireimposable = +(ammounts6.value).reduce((acc,curr)=>{
                            acc += +(curr.montantimposable || 0);
                            return acc;
                          },0);
                          this.totalretenueimpot = +(ammounts6.value).reduce((acc,curr)=>{
                            acc += +(curr.montantretenue || 0);
                            return acc;
                          },0);
                          this.totalavancepret = +(ammounts6.value).reduce((acc,curr)=>{
                            acc += +(curr.montantavance || 0);
                            return acc;
                          },0);*/
                          this.totalsalairenet = +(this.ammounts6.value).reduce((acc,curr)=>{
                            acc += +(curr.salairenet || 0);
                            return acc;
                          },0);
                        }
                        removeammount0(i: number) {
                          this.ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                          const j =this.ammounts0.length
                          j>1?this.ammounts0.removeAt(j-1):'';
                          this.totalht0 = +(this.editionnotepastform.get('ammounts0').value).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);
                          this.totaltva0 = +(this.editionnotepastform.get('ammounts0').value).reduce((acc,curr)=>{
                            acc += +(curr.montanttva || 0);
                            return acc;
                          },0);
                          this.totaldt0 = +(this.editionnotepastform.get('ammounts0').value).reduce((acc,curr)=>{
                            acc += +(curr.montantdt || 0);
                            return acc;
                          },0);
                          this.totalttc0 = +(this.editionnotepastform.get('ammounts0').value).reduce((acc,curr)=>{
                            acc += +(curr.montantttc || 0);
                            return acc;
                          },0);
                        }
                        removeammount(i: number) {
                          this.ammounts = this.editionnoteform.get('ammounts') as FormArray;
                          const j =this.ammounts.length
                          let factureammounts=[]
                          let avoirammounts=[]
                          let totalhtavoir=0
                          let totalhtfacture=0
                          let totaltvaavoir=0
                          let totaltvafacture=0
                          let totaldtavoir=0
                          let totaldtfacture=0
                          let totalttcavoir=0
                          let totalttcfacture=0
                          let totalht19avoir=0
                          let totalht19facture=0
                          let totaltva19avoir=0
                          let totaltva19facture=0
                          let totalttc19avoir=0
                          let totalttc19facture=0
                          let totalht13avoir=0
                          let totalht13facture=0
                          let totaltva13avoir=0
                          let totaltva13facture=0
                          let totalttc13avoir=0
                          let totalttc13facture=0
                          let totalhtexoavoir=0
                          let totalhtexofacture=0
                          
                          let totalttcexoavoir=0
                          let totalttcexofacture=0
                          let totalhtsusavoir=0
                          let totalhtsusfacture=0
                         
                          let totalttcsusavoir=0
                          let totalttcsusfacture=0
                          let totalhtexpavoir=0
                          let totalhtexpfacture=0
                          
                          let totalttcexpavoir=0
                          let totalttcexpfacture=0
                          j>1?this.ammounts.removeAt(j-1):'';     
                          this.ammounts.value.filter((item)=>
                          {
                            item.typepiece=='facture'?factureammounts.push(item):avoirammounts.push(item)
                          })
                          totalhtfacture = +(factureammounts).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);
                          totalhtavoir = +(avoirammounts).reduce((acc,curr)=>{
                            acc += +(curr.montantht || 0);
                            return acc;
                          },0);      
                      this.totalht = totalhtfacture - totalhtavoir
                      totaltvafacture= +(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva || 0);
                      return acc;
                      },0);
                      totaltvaavoir= +(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva || 0);
                      return acc;
                      },0);
                      this.totaltva = totaltvafacture - totaltvaavoir
                      totaldtfacture= +(factureammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantdt || 0);
                        return acc;
                      },0);
                      totaldtavoir= +(avoirammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantdt || 0);
                        return acc;
                      },0);
                      this.totaldt = totaldtfacture - totaldtavoir
                      totalttcfacture= +(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc || 0);
                      return acc;
                      },0);
                      totalttcavoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc || 0);
                      return acc;
                      },0);
                      this.totalttc = totalttcfacture-totalttcavoir
                      this.realht1=this.totalht
                      this.realdt1=this.totaldt
                      
                      totalht19facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht19 || 0);
                      return acc;
                      },0);
                      totalht19avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantht19 || 0);
                      return acc;
                      },0);
                      this.totalht19 = totalht19facture-totalht19avoir
                      totaltva19facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva19 || 0);
                      return acc;
                      },0);
                      totaltva19avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanttva19 || 0);
                      return acc;
                      },0);
                      this.totaltva19 = totaltva19facture-totaltva19avoir
                      totalttc19facture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc19 || 0);
                      return acc;
                      },0);
                      totalttc19avoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montantttc19 || 0);
                      return acc;
                      },0);
                      this.totalttc19 = totalttc19facture-totalttc19avoir
                      this.realht119=this.totalht19
                      this.realdt119=this.totaldt19
                      totalht13facture=+(factureammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantht13 || 0);
                        return acc;
                        },0);
                        totalht13avoir=+(avoirammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantht13 || 0);
                        return acc;
                        },0);
                        this.totalht13 = totalht13facture-totalht13avoir
                        totaltva13facture=+(factureammounts).reduce((acc,curr)=>{
                        acc += +(curr.montanttva13 || 0);
                        return acc;
                        },0);
                        totaltva13avoir=+(avoirammounts).reduce((acc,curr)=>{
                        acc += +(curr.montanttva13 || 0);
                        return acc;
                        },0);
                        this.totaltva13 = totaltva13facture-totaltva13avoir
                        totalttc13facture=+(factureammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantttc13 || 0);
                        return acc;
                        },0);
                        totalttc13avoir=+(avoirammounts).reduce((acc,curr)=>{
                        acc += +(curr.montantttc13 || 0);
                        return acc;
                        },0);
                        this.totalttc13 = totalttc13facture-totalttc13avoir
                        this.realht113=this.totalht13
                        this.realdt113=this.totaldt13
                      totalhtexofacture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexo || 0);
                      return acc;
                      },0);
                      totalhtexoavoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexo || 0);
                      return acc;
                      },0);
                      this.totalhtexo = totalhtexofacture-totalhtexoavoir
                      totalhtsusfacture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtsus || 0);
                      return acc;
                      },0);
                      totalhtsusavoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtsus || 0);
                      return acc;
                      },0);
                      this.totalhtsus = totalhtsusfacture-totalhtsusavoir
                      totalhtexpfacture=+(factureammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexp || 0);
                      return acc;
                      },0);
                      totalhtexpavoir=+(avoirammounts).reduce((acc,curr)=>{
                      acc += +(curr.montanthtexp || 0);
                      return acc;
                      },0);
                      this.totalhtexp = totalhtexpfacture-totalhtexpavoir
                      
                          this.realht1=this.totalht
                          this.realdt1=this.totaldt
                          this.realht119=this.totalht19
                          this.realdt119=this.totaldt19
                          this.realht1exo=this.totalhtexo
                          this.realht1sus=this.totalhtsus
                          this.realht1exp=this.totalhtexp
                      
                        }
  removeammount2(i: number) {
    this.ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
    this.ammounts2.removeAt(i);
      for (let j = 1; j < this.ammounts2.length; j++)
      {
        this.ammounts2.at(j).patchValue({
          numeropiece:+(this.ammounts2.at(j-1).value.numeropiece)+1,
          jour:+(this.ammounts2.at(j-1).value.jour)+1
         })
         this.setdate2(j)
      }
    this.totalht2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);
    this.totaltva2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttva || 0);
      return acc;
    },0);
    this.totalttc2 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttc || 0);
      return acc;
    },0);
    this.realht2=this.totalht2
    this.totalht219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantht19 || 0);
      return acc;
    },0);
    this.totaltva219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttva19 || 0);
      return acc;
    },0);
   
    this.totalttc219 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttc19 || 0);
      return acc;
    },0);
    this.totalrecette19 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recette19 || 0);
      return acc;
    },0);
    this.realht219=this.totalht219
    this.totalht213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantht13 || 0);
      return acc;
    },0);
    this.totaltva213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttva13 || 0);
      return acc;
    },0);
   
    this.totalttc213 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttc13 || 0);
      return acc;
    },0);
    this.totalrecette13 = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recette13 || 0);
      return acc;
    },0);
    this.realht213=this.totalht213
    this.totalrecette = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recette || 0);
      return acc;
    },0);
    this.totalht2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanthtexo || 0);
      return acc;
    },0);
    this.totaltva2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttvaexo || 0);
      return acc;
    },0);
   
    this.totalttc2exo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttcexo || 0);
      return acc;
    },0);
    this.totalrecetteexo = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recetteexo || 0);
      return acc;
    },0);
    this.realht2exo=this.totalht2exo
    this.totalht2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanthtsus || 0);
      return acc;
    },0);
    this.totaltva2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttvasus || 0);
      return acc;
    },0);
   
    this.totalttc2sus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttcsus || 0);
      return acc;
    },0);
    this.totalrecettesus = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recettesus || 0);
      return acc;
    },0);
    this.realht2sus=this.totalht2sus
    this.totalht2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanthtexp || 0);
      return acc;
    },0);
    this.totaltva2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montanttvaexp || 0);
      return acc;
    },0);
   
    this.totalttc2exp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.montantttcexp || 0);
      return acc;
    },0);
    this.totalrecetteexp = +(this.recettejournaliereform.get('ammounts2').value).reduce((acc,curr)=>{
      acc += +(curr.recetteexp || 0);
      return acc;
    },0);
    this.realht2exp=this.totalht2exp
  }
  removeammount3(i: number) {
    this.ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
    this.ammounts3.removeAt(i);
    this.totalht3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);
    this.totaltva3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
      acc += +(curr.montanttva || 0);
      return acc;
    },0);
    this.totaldt3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
      acc += +(curr.montantdt || 0);
      return acc;
    },0);
    this.totalttc3 = +(this.factureachatform.get('ammounts3').value).reduce((acc,curr)=>{
      acc += +(curr.montantttc || 0);
      return acc;
    },0);
    this.realht3=this.totalht3
    this.realtva3=this.totaltva3
    for (let j = i; j < this.ammounts3.length; j++)
    {
      this.ammounts3.at(j).patchValue({
        numeropiece:+(this.ammounts3.at(j-1).value.numeropiece)+1,
       })
    }
  }
  removeammount8(i: number) {
    this.ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
    this.ammounts8.removeAt(i);
    this.totalht8 = +(this.factureachatchequeform.get('ammounts8').value).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);
   
    this.realht8=this.totalht8
    this.realtva8=this.totaltva8
    for (let j = i; j < this.ammounts8.length; j++)
    {
      this.ammounts8.at(j).patchValue({
        numeropiece:+(this.ammounts8.at(j-1).value.numeropiece)+1,
       })
    }
  }
  removeammount9(i: number) {
    this.ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
    let factureammounts9=[]
    let avoirammounts9=[]
    let totalht9avoir=0
    let totalht9facture=0
    this.ammounts9.removeAt(i);
    this.ammounts9.value.filter((item)=>
    {
      item.typepiece=='facture'?factureammounts9.push(item):avoirammounts9.push(item)
    })
    totalht9facture = +(factureammounts9).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);
    totalht9avoir = +(avoirammounts9).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);      
this.totalht9 = totalht9facture - totalht9avoir
    this.realtva9=this.totaltva9
    for (let j = i; j < this.ammounts9.length; j++)
    {
      this.ammounts9.at(j).patchValue({
        numeropiece:+(this.ammounts9.at(j-1).value.numeropiece)+1,
       })
    }
  }
  removeammount11(i: number) {
    this.ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
    this.ammounts11.removeAt(i);
    this.totalht11 = +(this.factureachatespeceform.get('ammounts11').value).reduce((acc,curr)=>{
      acc += +(curr.montantht || 0);
      return acc;
    },0);
    
    this.realht11=this.totalht11
    this.realtva11=this.totaltva11
    for (let j = i; j < this.ammounts11.length; j++)
    {
      this.ammounts11.at(j).patchValue({
        numeropiece:+(this.ammounts11.at(j-1).value.numeropiece)+1,
       })
    }
  }
  removeEmployee(empindex: number) {
    this.employees().removeAt(empindex);
  }
  removeEmployeeSkill(empindex: number, skillIndex: number) {
    let skills = this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills') as FormArray;
let employees = this.relevemanuelform.get('employees') as FormArray;
    this.employeeSkills(empindex).removeAt(skillIndex);
    let totaldebitbis:any
    let totalcreditbis:any
    
    totaldebitbis = +(skills.getRawValue()).reduce((acc,curr)=>{
      acc += +(curr.debit || 0);
      return acc;
    },0);
    this.totaldebit=+totaldebitbis+ +(employees.getRawValue()[empindex].debit)
    totalcreditbis = +(this.relevemanuelform.get('employees')['controls'].at(empindex).get('skills').value).reduce((acc,curr)=>{
      acc += +(curr.credit || 0);
      return acc;
    },0);
    this.totalcredit = +totalcreditbis+ +(employees.getRawValue()[empindex].credit)
    this.totalsoldemois = this.totalcredit-this.totaldebit
  }
  removeammount5(i: number) {
    this.ammounts5.removeAt(i);
    
  }
  removetypesalaire(typeindex: number) {
    this.typessalaire().removeAt(typeindex);
  }
  removeammount6(typeindex:number,i: number) {
    this.ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
    this.ammounts6.removeAt(i);
    this.totalsalairebrut = +(this.ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.salairebrut || 0);
      return acc;
    },0);
   /* this.totalcnss = +(this.ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.montantcnss || 0);
      return acc;
    },0);
    this.totalsalaireimposable = +(ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.montantimposable || 0);
      return acc;
    },0);
    this.totalretenueimpot = +(ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.montantretenue || 0);
      return acc;
    },0);
    this.totalavancepret = +(ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.montantavance || 0);
      return acc;
    },0);*/
    this.totalsalairenet = +(this.ammounts6.value).reduce((acc,curr)=>{
      acc += +(curr.salairenet || 0);
      return acc;
    },0);
    this.realsalairebrut6=this.totalsalairebrut
    this.realsalaireimposable6=this.totalsalaireimposable
    this.realretenue6=this.totalretenueimpot
    const salairesbrutstfp=this.realsalairebrut6
              const tauxtfp=0.02
              const tauxfoprolos=0.01
             const basetfp=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
                const montanttfpmois=+ Math.trunc((+basetfp* +tauxtfp)*1000)/1000;
                this.tfpapayer=+ Math.trunc((+montanttfpmois)*1000)/1000;
                const basefoprolos=+ Math.trunc((+salairesbrutstfp)*1000)/1000;
                const montantfoprolosmois=+ Math.trunc((+basefoprolos* +tauxfoprolos)*1000)/1000;
                this.foprolosapayer=+ Math.trunc((+montantfoprolosmois)*1000)/1000;
  }
  //resetformsfunctions
  restartform()
  {
    this.loading = true;
    this.restartcapast()
this.resetcaall()
this.resetachatall()
this.resetbanqueall()
this.resetsalaireall()
this.resetachatchequeall()
this.resetachatespeceall()
this.resetfacturefournisseurall()

  this.loading=false
  this.showcatab=false;
  this.option3Value=false;
  this.showachattab=false;
  this.option14Value=false;
  this.showachatchequetab=false;
  this.option12Value=false;
  this.showachatespecetab=false;
  this.option13Value=false;
  this.showfacturefournisseurtab=false;
  this.option4Value=false;
  this.showbanquetab=false;
  this.option5Value=false;
  this.showsalairetab=false;
  this.option6Value=false;
  }
  restartcapast()
  {
    let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
    if(ammounts0.length>0)
    {  
      ammounts0.at(0).patchValue({
       type:'1',
        jour: '',
        date: '',
        numeronote: '',
        montantht:'0',
      tauxtva:this.tauxtva,
      montanttva:'0',
      montantdt:this.tauxdt,
      montantttc:'0',
      montantht19:'0',
      tauxtva19:'0.19',
      montanttva19:'0',
      montantttc19:'0',
      montantht13:'0',
      tauxtva13:'0.13',
      montanttva13:'0',
      montantttc13:'0',
        client:'',
        autreclient:'',
       })
      for (let i = 0; i < ammounts0.length; i++)
    {
this.removeammount0(i)
    }
    this.totalht0=0
    this.totalht019=0
    this.totalht013=0

    this.realht0=0
    this.realht019=0
    this.realht013=0

    this.totaltva0=0
    this.totaltva019=0
    this.totaltva013=0

    this.totaldt0=0
    this.totaldt019=0
    this.totaldt013=0

    this.realdt0=0
    this.realdt019=0
    this.realdt013=0

    this.totalttc0=0
this.totalttc019=0
this.totalttc013=0

    }
  }
  resetcaall()
  {    
      let ammounts = this.editionnoteform.get('ammounts') as FormArray;
      let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray; 
      ammounts.reset()
      while (ammounts.length > 1) 
      {
        ammounts.removeAt(0)
      }
      while (ammounts2.length > 0) 
      {
        ammounts2.removeAt(0)
      }
      
        this.totalht=0.000
        this.totalht19=0
        this.totalht13=0
        this.totalhtexo=0.000
        this.totalhtsus=0.000
        this.totalhtexp=0.000
        this.realht119=0
        this.realht113=0
        this.realht1=0
        this.totalht2=0
        this.totalht219=0
        this.totalht213=0

        this.totalht2exo=0
        this.totalht2exp=0
        this.totalht2sus=0
        this.realht2=0
        this.realht219=0
        this.realht213=0

        this.realht2exo=0
        this.realht2exp=0
        this.realht2sus=0
   
        this.totaltva=0.000
        this.totaltva19=0
        this.totaltva13=0

        this.totaltvaexo=0.000
        this.totaltvasus=0.000
        this.totaltvaexp=0.000
        this.totaltva2=0
        this.totaltva219=0
        this.totaltva213=0

        this.totaltva2exo=0
        this.totaltva2exp=0
        this.totaltva2sus=0

        this.totaldt=0.000
        this.totaldt19=0
        this.realdt119=0
        this.totaldt13=0
        this.realdt113=0
        this.realdt1=0
        this.totaldt2=0
        this.totaldt219=0
        this.totaldt213=0

        this.realdt2=0
        this.realdt219=0
        this.realdt213=0

        this.totalttc=0.000
        this.totalttc19=0
        this.totalttc13=0

        this.totalttcexo=0.000
        this.totalttcsus=0.000
        this.totalttcexp=0.000
        this.totalttc2=0
        this.totalttc219=0
        this.totalttc213=0

        this.totalttc2exo=0
        this.totalttc2exp=0
        this.totalttc2sus=0

 
  }
  resetachatall()
  {
    
      let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
      ammounts3.reset()
      while (ammounts3.length > 1) 
      {
        ammounts3.removeAt(0)
      }
      this.uploadFilesautre3=[]
      this.totalht3=0.000
      this.realht3=0
      this.totalht3devise=0.000
      this.totaltva3=0.000
      this.totaldt3=0.000
      this.totalttc3=0.000
  }
  resetachatchequeall()
  {
    
      let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
      ammounts8.reset()
      while (ammounts8.length > 1) 
      {
        ammounts8.removeAt(0)
      }
      this.uploadFilesautre8=[]
        this.totalht8=0.000
        this.realht8=0
        this.totalht8devise=0.000
        this.totaltva8=0.000
        this.totaldt8=0.000
        this.totalttc8=0.000
  }
  resetfacturefournisseurall()
  {
    
      let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
      ammounts9.reset()
      while (ammounts9.length > 1) 
      {
        ammounts9.removeAt(0)
      }
      this.uploadFilesautre9=[]
        this.totalht9=0.000
        this.realht9=0
        this.totalht9devise=0.000
        this.totaltva9=0.000
        this.totaldt9=0.000
        this.totalttc9=0.000
  }
  resetachatespeceall()
  {
    
      let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
      ammounts11.reset()
      while (ammounts11.length > 1) 
      {
        ammounts11.removeAt(0)
      }
      this.uploadFilesautre11=[]
        this.totalht11=0.000
        this.realht11=0
        this.totalht11devise=0.000
        this.totaltva11=0.000
        this.totaldt11=0.000
        this.totalttc11=0.000
  }
  resetbanqueall()
  {
      let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
      let ammounts4 = this.relevemanuelform.get('employees') as FormArray;
      while (ammounts4.length > 0) 
      {
        ammounts4.removeAt(0)
      }
      ammounts5.reset()
      while (ammounts5.length > 1) 
      {
        ammounts5.removeAt(0)
      }
      this.uploadFilesautre5=[]
  }
  resetsalaireall()
  {
   
      let ammounts6 = this.salaireform.get('typessalaire') as FormArray;
      ammounts6.reset()
      while (ammounts6.length > 1) 
      {
        ammounts6.removeAt(0)
      }
      this.totalsalairebrut=0.000
      this.totalcnss=0.000
      this.totalsalaireimposable=0.000
      this.totalretenueimpot=0.000
      this.totalavancepret=0.000
      this.totalsalairenet=0.000
      this.uploadFilesautre6=[]
  }
  ajouterdccop()
  {
  let actifdeccomptable=[]  
  this.deccompt.deccomptabilites?actifdeccomptable=this.deccompt.deccomptabilites.filter(e=>!e.source):actifdeccomptable=[]  
  if(this.option0Value==''||!this.option0Value)
  {
    return (
      Swal.fire({
      title: 'veuillez indiquer la périodicité',
      icon: 'error',
      confirmButtonColor: '#3085d6',
    }).then((result) => {this.loading=false
    }).catch(() => {
      Swal.fire('opération non aboutie!')
    }))
  }
  if(actifdeccomptable.length==0)
  {
    let ammounts10=this.ajoutdccopform.get('ammounts10') as FormArray
    let modifiedcops= ammounts10.value.filter(e=>e.montantecheance)
    this.user.coproprietaires.forEach(
      element=>{
        modifiedcops.forEach(
          element2=>{
            if (element2.numero==element.numero)
            {
              element.donneescomptable?
              element.donneescomptable.push(
                {
                  periodicite:element2.periodicite,
                  montant:element2.montantecheance,
                  annee:element2.annee
                }
              )
              :
              (
                element.donneescomptable=[],
                element.donneescomptable.push(
                  {
                    periodicite:element2.periodicite,
                    montant:element2.montantecheance,
                    annee:element2.annee
                  }
                )
              )
            }
           
          }
        )
      }
    )
    const newuser= new User
    newuser.coproprietaires=this.user.coproprietaires
  
    this.userservice.modifyUserById(this.user._id, newuser).then(  
      () => {
        this.loading = false;
        Swal.fire("copropriétaires mis à jour avec succès")
        this.tokenStorage.saved=true
        this.reloadPage()
      },
      (error) => {
        this.loading = false
        
      }
    );
  }
  else{
    this.commun.getcurrenttime().then(
      async (time:any) => {
        Swal.fire({
          title: 'vous êtes sur le point de modifier tous les fichiers comptable de l\'année'+`${this.option1Value}`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#555',
          confirmButtonText: 'Accepter et continuer',
          cancelButtonText: 'Annuler',      
          }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading=true
            for await (const element of actifdeccomptable) {
              this.DecfiscmensService.geexistenttdecfiscmens(this.user._id,element.annee,element.mois,'').then(
                (data:Decfiscmens[]) => {
                  console.log(data)
                 if (data.length>0)
               {
                this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?data[0].statutcollab.push
                    //@ts-ignore
                    ({
                      statutclient:'déposé pour le compte du client',
                      motifclient:'',
                      datefin:time,
                       duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                    }):
                    data[0].statutcollab.push
                    //@ts-ignore
                    ({
                      statutclient:'déposé par le client',
                      motifclient:'',
                      datefin:time,
                      duree:'',     
                    })
                data[0].affecte = ''
                this.DecfiscmensService.completedecfiscmensreqById(data[0]._id,data[0]).then(
                  (data:any) => {
                  this.tokenStorage.saved=true;
                  this.loading = false;
                  this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?element.statutcollab.push
                  //@ts-ignore
                  ({
                    statutclient:'déposé pour le compte du client',
                    motifclient:'',
                    datefin:time,
                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                  }):
                  element.statutcollab.push
                  //@ts-ignore
                  ({
                    statutclient:'déposé par le client',
                    motifclient:'',
                    datefin:time,
                    duree:'',     
                  })
                   element.affecte=''
                  this.deccompt.completedeccomptabilitereqById(element._id,element).then(
                    (data:any) => {
                      this.tokenStorage.saved=true;
                      this.loading = false;
                      //this.router.navigate(['user-board']);
                    },
                    (error) => {
                      this.loading = false                      
                    }
                  )
                  //this.router.navigate(['modify-decfiscmens/'+data.data._id])
                  },
                  (error) => {
                  this.loading = false;
                  
                  }
                  )                  
                }
                  else
                  {
                    this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?element.statutcollab.push
                    //@ts-ignore
                    ({
                      statutclient:'déposé pour le compte du client',
                      motifclient:'',
                      datefin:time,
                       duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                    }):
                    element.statutcollab.push
                    //@ts-ignore
                    ({
                      statutclient:'déposé par le client',
                      motifclient:'',
                      datefin:time,
                      duree:'',     
                    })
                     element.affecte=''
                    this.deccompt.completedeccomptabilitereqById(element._id,element).then(
                      (data:any) => {
                        this.tokenStorage.saved=true;
                        this.loading = false;
                        //this.router.navigate(['user-board']);
                      },
                      (error) => {
                        this.loading = false                      
                      }
                    )                    
                  }
                    }
              )  
                      };         
          let ammounts10=this.ajoutdccopform.get('ammounts10') as FormArray
            let modifiedcops= ammounts10.value.filter(e=>e.montantecheance)
            this.user.coproprietaires.forEach(
              element=>{
                modifiedcops.forEach(
                  element2=>{
                    if (element2.numero==element.numero)
                    {
                      element.donneescomptable?
                      element.donneescomptable.push(
                        {
                          periodicite:element2.periodicite,
                          montant:element2.montantecheance,
                          annee:element2.annee
                        }
                      )
                      :
                      (
                        element.donneescomptable=[],
                        element.donneescomptable.push(
                          {
                            periodicite:element2.periodicite,
                            montant:element2.montantecheance,
                            annee:element2.annee
                          }
                        )
                      )
                    }
                   
                  }
                )
              }
            )
            const newuser= new User
            newuser.coproprietaires=this.user.coproprietaires
          
            this.userservice.modifyUserById(this.user._id, newuser).then(  
              () => {
                this.loading = false;
                Swal.fire("copropriétaires mis à jour avec succès")
                this.tokenStorage.saved=true
                this.reloadPage()
              },
              (error) => {
                this.loading = false
                
              }
            );
      
          }
          else
          {
            return(
              this.closecopPopup()
            )
            
           
          }
    
          
          })
      }
    )
  }


 

  }
  //save method to only accounting file
  onSubmit(event:any) {
    this.loading = true;
    const deccomptabilite:Deccomptabilite = this.deccomptabilite;
    if(this.editionnoteform.invalid||this.editionnotepastform.invalid||this.recettejournaliereform.invalid||this.factureachatform.invalid||this.factureachatchequeform.invalid||
      this.facturefournisseurform.invalid||this.factureachatespeceform.invalid||this.relevejointform.invalid||this.ajoutdccopform.invalid||this.ajoutsalarieform.invalid||this.ajoutdccopform.invalid
      ||this.relevemanuelform.invalid||this.salaireform.invalid)
    {
      return (
        Swal.fire({
          title: 'vous avez une erreur de saisie, veuillez vérifier l\'emplacement de l\'erreur et la corriger avant de sauvegarder!',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {{this.loading=false,event.target.disabled=false
        }}).catch(() => {
          Swal.fire('opération non aboutie!')
        })
        )
    }
    else 
    {
      this.commun.getcurrenttime().then(
        async (data:any) => {
          this.DecfiscmensService.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
            (decfiscmens: Decfiscmens[]) => {
              if(decfiscmens.length>0)
              {
                this.userservice.getUserById(this.deccomptabilite.userId).then(
                  (user:User)=>
                  {
                    deccomptabilite.userId = user._id;
                    deccomptabilite.activite=user.activite;
                    deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
                    deccomptabilite.sousactivite=user.sousactivite;
                    deccomptabilite.codepostal=user.codepostal;
                    deccomptabilite.adresse=user.adresseactivite
                    deccomptabilite.firstname=user.firstname
                    deccomptabilite.lastname=user.lastname
                    deccomptabilite.raisonsociale=user.raisonsociale
                    deccomptabilite.codegenre=user.codegenre
                    deccomptabilite.codetva=user.codetva
                    deccomptabilite.matriculefiscale=user.matriculefiscale
                    deccomptabilite.registrecommerce=user.registrecommerce
                    deccomptabilite.datearretactivite=user.datearretactivite
                    deccomptabilite.totalht=this.totalht
      deccomptabilite.totaltva=this.totaltva
      deccomptabilite.totalhtexo=this.totalhtexo
      deccomptabilite.totaltvaexo=this.totaltvaexo
      deccomptabilite.totalhtsus=this.totalhtsus
      deccomptabilite.totaltvasus=this.totaltvasus

      deccomptabilite.totalhtfodec=this.totalhtfodec
      deccomptabilite.totaltvafodec=this.totaltvafodec

      deccomptabilite.totalhttpe=this.totalhttpe
      deccomptabilite.totaltvatpe=this.totaltvatpe
      deccomptabilite.totalhtexp=this.totalhtexp
      deccomptabilite.totaltvaexp=this.totaltvaexp
      deccomptabilite.totaldt=this.totaldt
      deccomptabilite.totalttc=this.totalttc
      deccomptabilite.totalttcexo=this.totalttcexo
      deccomptabilite.totalttcsus=this.totalttcsus

      deccomptabilite.totalttctpe=this.totalttctpe

      deccomptabilite.totalttcfodec=this.totalttcfodec

      deccomptabilite.totalttcexp=this.totalttcexp
      deccomptabilite.totalht19=this.totalht19
      deccomptabilite.totaltva19=this.totaltva19
      deccomptabilite.totaldt19=this.totaldt19
      deccomptabilite.totalttc19=this.totalttc19
      deccomptabilite.totalht019=this.totalht019
      deccomptabilite.totaltva019=this.totaltva019
      deccomptabilite.totaldt019=this.totaldt019
      deccomptabilite.totalttc019=this.totalttc019
      deccomptabilite.totalht13=this.totalht13
      deccomptabilite.totaltva13=this.totaltva13
      deccomptabilite.totaldt13=this.totaldt13
      deccomptabilite.totalttc13=this.totalttc13
      deccomptabilite.totalht013=this.totalht013
      deccomptabilite.totaltva013=this.totaltva013
      deccomptabilite.totaldt013=this.totaldt013
      deccomptabilite.totalttc013=this.totalttc013
      deccomptabilite.totalht2=this.totalht2
      deccomptabilite.totaltva2=this.totaltva2
  
      deccomptabilite.totalht2exo=this.totalht2exo
      deccomptabilite.totaltva2exo=this.totaltva2exo
      deccomptabilite.totalht2sus=this.totalht2sus
      deccomptabilite.totaltva2sus=this.totaltva2sus

      deccomptabilite.totalht2fodec=this.totalht2fodec
      deccomptabilite.totaltva2fodec=this.totaltva2fodec

      deccomptabilite.totalht2tpe=this.totalht2tpe
      deccomptabilite.totaltva2tpe=this.totaltva2tpe
      deccomptabilite.totalht2exp=this.totalht2exp
      deccomptabilite.totaltva2exp=this.totaltva2exp
      deccomptabilite.totalttc2exo=this.totalttc2exo
      deccomptabilite.totalttc2exp=this.totalttc2exp
      deccomptabilite.totalttc2sus=this.totalttc2sus

      deccomptabilite.totalttc2tpe=this.totalttc2tpe


      deccomptabilite.totalttc2fodec=this.totalttc2fodec

     
  
  
      deccomptabilite.totaldt2=this.totaldt2
      deccomptabilite.totalttc2=this.totalttc2
      deccomptabilite.totalrecette=this.totalrecette
      deccomptabilite.totalrecetteexo=this.totalrecetteexo
      deccomptabilite.totalrecettesus=this.totalrecettesus

      deccomptabilite.totalrecettefodec=this.totalrecettefodec


      deccomptabilite.totalrecettetpe=this.totalrecettetpe

      deccomptabilite.totalrecetteexp=this.totalrecetteexp
  
      deccomptabilite.totalht219=this.totalht219
      deccomptabilite.totaltva219=this.totaltva219
      deccomptabilite.totaldt219=this.totaldt219
      deccomptabilite.totalttc219=this.totalttc219
      deccomptabilite.totalrecette19=this.totalrecette19
      deccomptabilite.totalht213=this.totalht213
      deccomptabilite.totaltva213=this.totaltva213
      deccomptabilite.totaldt213=this.totaldt213
      deccomptabilite.totalttc213=this.totalttc213
      deccomptabilite.totalrecette13=this.totalrecette13
      deccomptabilite.totalht3=this.totalht3
      deccomptabilite.totalht3devise=this.totalht3devise
      deccomptabilite.totaltva3=this.totaltva3
      deccomptabilite.totaldt3=this.totaldt3
      deccomptabilite.totalttc3=this.totalttc3
      deccomptabilite.totaldebit=this.totaldebit
      deccomptabilite.totalcredit=this.totalcredit
      deccomptabilite.totalsoldemois=this.totalsoldemois
      deccomptabilite.totalsalairebrut=this.totalsalairebrut
      deccomptabilite.totalcnss=this.totalcnss
      deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
      deccomptabilite.totalretenueimpot=this.totalretenueimpot
      deccomptabilite.totalavancepret=this.totalavancepret
      deccomptabilite.totalsalairenet=this.totalsalairenet
      deccomptabilite.source=''
      deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
      deccomptabilite.contientnumero=this.contientnumero
      deccomptabilite.contientnumeroother=this.contientnumeroother
                    deccomptabilite.autre0=[]
                    deccomptabilite.autre1=[]
                    deccomptabilite.autre2=[]
                    deccomptabilite.autre3=[]
                    deccomptabilite.autre4=[]
                    deccomptabilite.autre5=[]
                    deccomptabilite.autre6=[]
                    deccomptabilite.autre8=[]
      deccomptabilite.autre9=[]
      deccomptabilite.autre11=[]
                    deccomptabilite.autre2bis=[]
                    if(this.option1Value==''||this.option2Value=='')
                    {
                      return (
                        Swal.fire({
                        title: 'veuillez indiquer le mois et l\'année de la déclaration',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                      }).then((result) => {this.loading=false
                      }).catch(() => {
                        Swal.fire('opération non aboutie!')
                      }))
                    }
                
                    if (this.option4Value) 
                    {
                      let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
                      for (let i = 0; i < ammounts3.length; i++)
                      {        
                ammounts3.controls[i].get('fournisseur').enable();
                ammounts3.controls[i].get('numerofacture').enable();
                ammounts3.controls[i].get('contientfiche').enable();    
      
                const item = ammounts3.getRawValue()[i];
                if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
                {
                  deccomptabilite.autre3.push({
                    type: '3',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    fournisseur: item.autrefournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                    montanthtachat:item.montanthtachat,
                    montanthtdevise:item.montanthtdevise,
                  montantht:item.montantht,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  contientfiche:item.contientfiche
                })
                }
               else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
                {
                  deccomptabilite.autre3.push({
                    type: '3',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    devise:item.devise,
                    date: item.date,
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.autrenatureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  contientfiche:item.contientfiche
                })
                }
               else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
                {
                  deccomptabilite.autre3.push({
                    type: '3',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    devise:item.devise,
  
                    date: item.date,
                    fournisseur: item.autrefournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.autrenatureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  contientfiche:item.contientfiche
                })
                }
                else{
                  deccomptabilite.autre3.push({
                    type: '3',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise:item.devise,
  
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  contientfiche:item.contientfiche
                })
                }
                      }   
                    }
                    if (this.option14Value) 
                    {
                      let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
                      for (let i = 0; i < ammounts8.length; i++)
                      {        
                ammounts8.controls[i].get('fournisseur').enable();
                ammounts8.controls[i].get('numerofacture').enable();
                ammounts8.controls[i].get('contientfiche').enable();    
                const item = ammounts8.getRawValue()[i];
               
                  deccomptabilite.autre8.push({
                    type: '8',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.autrebeneficiaire,
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  numerocheque:item.numerocheque,
                  banque:item.banque,
                  contientfiche:item.contientfiche
                })
                
                console.log(deccomptabilite.autre8)    
                      }   
                    }
                    if (this.option12Value) 
                    {
                      let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
                      for (let i = 0; i < ammounts11.length; i++)
                      {        
                ammounts11.controls[i].get('fournisseur').enable();
                ammounts11.controls[i].get('numerofacture').enable();
                ammounts11.controls[i].get('contientfiche').enable();    
                const item = ammounts11.getRawValue()[i];
                
                  deccomptabilite.autre11.push({
                    type: '11',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.autrebeneficiaire,
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                  contientfiche:item.contientfiche
                })
                
                console.log(deccomptabilite.autre11)    
                      }   
                    }
                    if (this.option13Value) 
                    {
                      let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
                      for (let i = 0; i < ammounts9.length; i++)
                      {        
                ammounts9.controls[i].get('fournisseur').enable();
                ammounts9.controls[i].get('numerofacture').enable();
                ammounts9.controls[i].get('contientfiche').enable();    
                const item = ammounts9.getRawValue()[i];
                if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
                {
                  deccomptabilite.autre9.push({
                    type: '9',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.beneficiaire,
                    fournisseur: item.autrefournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                
                  contientfiche:item.contientfiche
                })
                }
               else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
                {
                  deccomptabilite.autre9.push({
                    type: '9',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.beneficiaire,
                    date: item.date,
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.autrenatureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtdevise:item.montanthtdevise,
                  montanthtachat:item.montanthtachat,
                
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
               
                  contientfiche:item.contientfiche
                })
                }
               else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
                {
                  deccomptabilite.autre9.push({
                    type: '9',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.beneficiaire,
                    fournisseur: item.autrefournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.autrenatureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtdevise:item.montanthtdevise,
                  montanthtachat:item.montanthtachat,
                
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                 
                  contientfiche:item.contientfiche
                })
                }
                else{
                  deccomptabilite.autre9.push({
                    type: '9',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    devise: item.devise,
                    beneficiaire: item.beneficiaire,
                    autrebeneficiaire: item.beneficiaire,
                    fournisseur: item.fournisseur,
                    autrefournisseur: item.autrefournisseur,
                    typepiece:item.typepiece,
                    numerofacture:item.numerofacture,
                    natureachat:item.natureachat,
                    autrenatureachat:item.autrenatureachat,
                  montantht:item.montantht,
                  montanthtachat:item.montanthtachat,
                
                  montanthtdevise:item.montanthtdevise,
                  montanttva:item.montanttva,
                  montantdt:item.montantdt,
                  montantttc:item.montantttc,
                  reglement:item.reglement,
                  ficheUrl:item.ficheUrl,
                
                  contientfiche:item.contientfiche
                })
                }
                console.log(deccomptabilite.autre9)    
                      }   
                    }
                      if(this.option3Value)
                      {
                        let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                        for (let i = 0; i < ammounts0.length; i++)
                       
                        {
                          
                          const item = ammounts0.getRawValue()[i];
                          if( this.user.activite=='Syndic des copropriétaires')
                          {
                            if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                            {
                              return (
                                Swal.fire({
                                title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                                  icon: 'error',
                                confirmButtonColor: '#3085d6',
                              }).then((result) => {this.loading=false,event.target.disabled=false
                
                              }).catch(() => {
                                Swal.fire('opération non aboutie!')
                              }))
                            }
                          }
                          if (item.client==='Autre')
                          {
                            deccomptabilite.autre0.push({
                              type:'1',
                              numeropiece: item.numeropiece,
                              jour: item.jour,
                              date: item.date,
                              typepiece:item.typepiece,
                              devise: item.devise,
                              montanthtexpdevise:item.montanthtexpdevise,
                              numeronote:item.numeronote,
                              montantht:item.montantht,
                              montantht19:item.montantht19,
                              montantht13:item.montantht13,
                      
                              montanthtexo:item.montanthtexo,
                              montanthtsus:item.montanthtsus,
                      
                              montanthttpe:item.montanthttpe,
                      
                              montanthtfodec:item.montanthtfodec,
                      
                              montanthtexp:item.montanthtexp,
                              montanttva:item.montanttva,
                              montanttva19:item.montanttva19,
                              montanttva13:item.montanttva13,
                      
                              montanttvaexo:item.montanttvaexo,
                              montanttvasus:item.montanttvasus,
                      
                              montanttvafodec:item.montanttvafodec,
                      
                              montanttvatpe:item.montanttvatpe,
                      
                              montanttvaexp:item.montanttvaexp,
                              montantdt:item.montantdt,
                              montantttc:item.montantttc,
                              montantttc19:item.montantttc19,
                              montantttc13:item.montantttc13,
                      
                              montantttcexo:item.montantttcexo,
                              montantttcsus:item.montantttcsus,
                              montantttctpe:item.montantttctpe,
                              montantttcfodec:item.montantttcfodec,
                      
                              montantttcexp:item.montantttcexp,
                              client:item.autreclient,
                              autreclient:item.autreclient,
                              objet:item.objet,
                              autreobjet:item.autreobjet,
                              annule:item.annule,
                          
                              nature:item.nature,
                              autrenature:item.autrenature,
                              banque:item.banque,
                              autrebanque:item.autrebanque,
                              numerocompte: item.numerocompte,
                              autrenumerocompte: item.autrenumerocompte,
                              echeance:item.echeance,
                              datevirement:item.datevirement,
                            })
                          }
                          else
                          {
                            deccomptabilite.autre0.push({
                              type:'1',
                              numeropiece: item.numeropiece,
                              jour: item.jour,
                              date: item.date,
                              typepiece:item.typepiece,
                              devise: item.devise,
                              montanthtexpdevise:item.montanthtexpdevise,
                              numeronote:item.numeronote,
                              montantht:item.montantht,
                              montantht19:item.montantht19,
                              montantht13:item.montantht13,
                      
                              montanthtexo:item.montanthtexo,
                              montanthtsus:item.montanthtsus,
                              montanthtfodec:item.montanthtfodec,
                              montanthttpe:item.montanthttpe,
                      
                              montanthtexp:item.montanthtexp,
                              montanttva:item.montanttva,
                              montanttva19:item.montanttva19,
                              montanttva13:item.montanttva13,
                      
                              montanttvaexo:item.montanttvaexo,
                              montanttvasus:item.montanttvasus,
                              montanttvatpe:item.montanttvatpe,
                              montanttvafodec:item.montanttvafodec,
                      
                              montanttvaexp:item.montanttvaexp,
                              montantdt:item.montantdt,
                              montantttc:item.montantttc,
                              montantttc19:item.montantttc19,
                              montantttc13:item.montantttc13,
                              montantttcexo:item.montantttcexo,
                              montantttcsus:item.montantttcsus,
                              montantttcfodec:item.montantttcfodec,
                              montantttctpe:item.montantttctpe,
                      
                              montantttcexp:item.montantttcexp,
                              client:item.client,
                              autreclient:item.autreclient,
                              objet:item.objet,
                              autreobjet:item.autreobjet,
                              annule:item.annule,
                          
                              nature:item.nature,
                              autrenature:item.autrenature,
                              banque:item.banque,
                              autrebanque:item.autrebanque,
                              numerocompte: item.numerocompte,
                              autrenumerocompte: item.autrenumerocompte,
                              echeance:item.echeance,
                              datevirement:item.datevirement,
                            })
                          }
                 
                 
                        } 
                        let ammounts = this.editionnoteform.get('ammounts') as FormArray;
                        for (let i = 0; i < ammounts.length; i++)
                       
                        {
                          
                          const item = ammounts.getRawValue()[i];
                          //@ts-ignore
                    if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))
  
  {
    if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
    {
      return (
        Swal.fire({
        title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false,event.target.disabled=false
  
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }))
    }
  }
             
  if (item.client==='Autre')
  {
    deccomptabilite.autre1.push({
      type:'1',
      numeropiece: item.numeropiece,
      jour: item.jour,
      date: item.date,
      typepiece:item.typepiece,
      devise: item.devise,
      montanthtexpdevise:item.montanthtexpdevise,
      numeronote:item.numeronote,
      montantht:item.montantht,
      montantht19:item.montantht19,
      montantht13:item.montantht13,

      montanthtexo:item.montanthtexo,
      montanthtsus:item.montanthtsus,
      montanthttpe:item.montanthttpe,
      montanthtfodec:item.montanthtfodec,

      montanthtexp:item.montanthtexp,
      montanttva:item.montanttva,
      montanttva19:item.montanttva19,
      montanttva13:item.montanttva13,

      montanttvaexo:item.montanttvaexo,
      montanttvasus:item.montanttvasus,
      montanttvafodec:item.montanttvafodec,
      montanttvatpe:item.montanttvatpe,

      montanttvaexp:item.montanttvaexp,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      montantttc19:item.montantttc19,
      montantttc13:item.montantttc13,
      montantttcexo:item.montantttcexo,
      montantttcsus:item.montantttcsus,
      montantttctpe:item.montantttctpe,
      montantttcfodec:item.montantttcfodec,

      montantttcexp:item.montantttcexp,
      client:item.autreclient,
      autreclient:item.autreclient,
      objet:item.objet,
      autreobjet:item.autreobjet,
      annule:item.annule,
  
      nature:item.nature,
      autrenature:item.autrenature,
      banque:item.banque,
      autrebanque:item.autrebanque,
      numerocompte: item.numerocompte,
      autrenumerocompte: item.autrenumerocompte,
      echeance:item.echeance,
      datevirement:item.datevirement,
  
    })
  }
  else
  {
    deccomptabilite.autre1.push({
      type:'1',
      numeropiece: item.numeropiece,
      jour: item.jour,
      date: item.date,
      typepiece:item.typepiece,
      devise: item.devise,
      montanthtexpdevise:item.montanthtexpdevise,
      numeronote:item.numeronote,
      montantht:item.montantht,
      montantht19:item.montantht19,
      montantht13:item.montantht13,

      montanthtexo:item.montanthtexo,
      montanthtsus:item.montanthtsus,
      montanthtfodec:item.montanthtfodec,
      montanthttpe:item.montanthttpe,

      montanthtexp:item.montanthtexp,
      montanttva:item.montanttva,
      montanttva19:item.montanttva19,
      montanttva13:item.montanttva13,

      montanttvaexo:item.montanttvaexo,
      montanttvasus:item.montanttvasus,
      montanttvatpe:item.montanttvatpe,
      montanttvafodec:item.montanttvafodec,

      montanttvaexp:item.montanttvaexp,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      montantttc19:item.montantttc19,
      montantttc13:item.montantttc13,
      montantttcexo:item.montantttcexo,
      montantttcsus:item.montantttcsus,
      montantttcfodec:item.montantttcfodec,
      montantttctpe:item.montantttctpe,

      montantttcexp:item.montantttcexp,
      client:item.client,
      autreclient:item.autreclient,
      objet:item.objet,
      autreobjet:item.autreobjet,
      annule:item.annule,
  
      nature:item.nature,
      autrenature:item.autrenature,
      banque:item.banque,
      autrebanque:item.autrebanque,
      numerocompte: item.numerocompte,
      autrenumerocompte: item.autrenumerocompte,
      echeance:item.echeance,
      datevirement:item.datevirement,
    })
  }
                 
                console.log(ammounts0.getRawValue()) 
                console.log(ammounts.getRawValue()) 
                
                        }  
          let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
          for (let i = 0; i < ammounts2.length; i+=1)
          {
            const item = ammounts2.getRawValue()[i];
            deccomptabilite.autre2.push({
              type:'2',
              numeropiece: item.numeropiece,
              jour: item.jour,
              date: item.date,
              devise: item.devise,
              recetteexpdevise:item.recetteexpdevise,
              recette:item.recette,
              recette19:item.recette19,
              recette13:item.recette13,
      
              recetteexo:item.recetteexo,
              recettesus:item.recettesus,
              recettetpe:item.recettetpe,
              recettefodec:item.recettefodec,
      
              recetteexp:item.recetteexp,
              montantht:item.montantht,
              montantht19:item.montantht19,
              montantht13:item.montantht13,
      
              montanthtexo:item.montanthtexo,
              montanthtsus:item.montanthtsus,
              montanthtfodec:item.montanthtfodec,
              montanthttpe:item.montanthttpe,
      
              montanthtexp:item.montanthtexp,
              montanttva:item.montanttva,
              montanttva19:item.montanttva19,
              montanttva13:item.montanttva13,
      
              montanttvaexo:item.montanttvaexo,
              montanttvasus:item.montanttvasus,
              montanttvatpe:item.montanttvatpe,
              montanttvafodec:item.montanttvafodec,
      
              montanttvaexp:item.montanttvaexp,
              montantdt:item.montantdt,
              montantttc:item.montantttc,
              montantttc19:item.montantttc19,
              montantttc13:item.montantttc13,
      
              montantttcexo:item.montantttcexo,
              montantttcsus:item.montantttcsus,
              montantttcfodec:item.montantttcfodec,
              montantttctpe:item.montantttctpe,
      
              montantttcexp:item.montantttcexp,
            })
          } 
  
        }
                    if (this.option5Value) 
                    {
                      let employees = this.relevemanuelform.get('employees') as FormArray
                      employees.getRawValue().forEach((element,index) => {
                        element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
                        console.log(element.skills)
                        element.skills.length==0&&element.devise!='tnd'?
                        deccomptabilite.autre4.push({
                          type: '4',
                          banque:element.banque,
                          numerocompte:element.numerocompte, 
                          devise:element.devise,
                          soldedebit:element.debit,
                          soldecredit:element.credit,
                          creditdevise:element.creditdevise,
                          soldefinmoisdevise:element.creditdevise,
                          soldefinmoisdinar:element.credit,
                          mouvements:element.skills
                            
                  }) 
                          :
                          element.skills==0&&element.credit!=0&&element.devise=='tnd'?
                          deccomptabilite.autre4.push({
                            type: '4',
                            banque:element.banque,
                            numerocompte:element.numerocompte, 
                            devise:element.devise,
                            soldedebit:element.debit,
                            soldecredit:element.credit,
                            creditdevise:element.creditdevise,
                            soldefinmoisdevise:element.creditdevise,
                            soldefinmoisdinar:element.credit,
                            mouvements:element.skills
                              
                    }) 
                            :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
                            deccomptabilite.autre4.push({
                              type: '4',
                              banque:element.banque,
                              numerocompte:element.numerocompte, 
                              devise:element.devise,
                              soldedebit:element.debit,
                              soldecredit:element.credit,
                              creditdevise:element.creditdevise,
                              soldefinmoisdevise:element.creditdevise,
                              soldefinmoisdinar:element.debit,
                              mouvements:element.skills
                                
                      }) 
                          :
                          deccomptabilite.autre4.push({
                            type: '4',
                            banque:element.banque,
                            numerocompte:element.numerocompte, 
                            devise:element.devise,
                            soldedebit:element.debit,
                            soldecredit:element.credit,
                            creditdevise:element.creditdevise,
                            soldefinmoisdevise:element.soldefinmoisdevise,
                            soldefinmoisdinar:element.soldefinmoisdinar,
                            mouvements:element.skills
                              
                    }) 
                  console.log(deccomptabilite.autre4)
                      }); 
                      let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
                      for (let i = 0; i < ammounts5.length; i++)
                      {
                        const item = ammounts5.getRawValue()[i];
                deccomptabilite.autre5.push({
                        type: '5',
                        annee: item.annee,
                        mois: item.mois,
                        ficheUrl:item.ficheUrl,
                        contientfiche:item.contientfiche
                
                })
                      } 
                    }
                    if (this.option6Value) 
      {
  
  
        let typessalaire = this.salaireform.get('typessalaire') as FormArray;
        typessalaire.getRawValue().forEach((element,index) => {
          element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
          deccomptabilite.autre6.push({
            type: '6',
            typesalaire:element.typesalaire,
            salarie:element.ammounts6,         
    }) 
  }
        )
      }
                    deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
                    deccomptabilite.affecte=this.deccomptabilite.affecte
                  
                          this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
                          //@ts-ignore
                          ({
                            statutclient:'modifié pour le compte du client',
                            motifclient:'',
                            datefin:data,
                             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                          }):
                          deccomptabilite.statutcollab.push
                          //@ts-ignore
                          ({
                            statutclient:'modifié par le client',
                            motifclient:'',
                            datefin:data,
                            duree:'',     
                          })
                    deccomptabilite.affecte=''
                   // deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
                    deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
                    deccomptabilite.autre2.map((e, i) => {
        //@ts-ignore
         e.numeropiece = i+1 });
     deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
      item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
      deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
      deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
      deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
      deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
      deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
      deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
                    
                
                       this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
                          (data:any) => {
                            this.tokenStorage.saved=true;
                            this.loading = false;
                            this.firsttimer=Date.now()
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: 'fichier comptable modifiée avec succès',
                              showConfirmButton: false,
                              timer: 3000
                            });
                          },
                          (error) => {
                            this.loading = false;
                            
                            window.scrollTo(0, 0);
                            
                          
                            
                          }
                        )
                        
      //modify rattached decfiscmens
      
      if(this.option1Value==''||this.option2Value=='')
      {
        return (
          Swal.fire({
          title: 'veuillez indiquer le mois et l\'année de la déclaration',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {this.loading=false
        }).catch(() => {
          Swal.fire('opération non aboutie!')
        }))
      }
      decfiscmens[0].impottype1.type='Retenue à la source'
      this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype3.type='TFP':''
      decfiscmens[0].impottype4.type='FOPROLOS'
      this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype2.type='TVA':''
      this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype6.type='TCL':''
      if (this.realsalairebrut6!=0) 
      {
      decfiscmens[0].impottype1.traitementetsalaire.salairebrut=this.realsalairebrut6.toString()
      decfiscmens[0].impottype1.traitementetsalaire.salaireimposable=this.realsalaireimposable6.toString()
      decfiscmens[0].impottype1.traitementetsalaire.retenuealasource=this.realretenue6.toString()
      }
      if(this.realsalairebrut6!=0)
      
      {
      decfiscmens[0].impottype3.tfppayer=this.tfpapayer.toString()
      decfiscmens[0].impottype3.basetfp=this.realsalairebrut6.toString()
      decfiscmens[0].impottype3.montanttfpmois=this.tfpapayer.toString()
      }
      if(this.realsalairebrut6!=0)
      {
      
      decfiscmens[0].impottype4.montantfoprolos=this.foprolosapayer.toString()
      decfiscmens[0].impottype4.basefoprolos=this.realsalairebrut6.toString()
      
      }
      if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
      {
      console.log(this.realht2)
      decfiscmens[0].impottype2.tvacollecter.type='TVA collecté'
      decfiscmens[0].impottype2.tvacollecter.chiffreaffaireht=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe))*1000)/1000).toString()
      decfiscmens[0].impottype2.tvacollecter19.chiffreaffaireht=(Math.trunc(((this.realht119+this.realht219))*1000)/1000).toString()
      
      decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.chiffreaffaireht=(Math.trunc(((this.realht113+this.realht213))*1000)/1000).toString():''
      decfiscmens[0].impottype2.tvacollecter.tvaammount=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva)*1000)/1000).toString()
      decfiscmens[0].impottype2.tvacollecter19.tvaammount=(Math.trunc(((this.realht119+this.realht219)*0.19)*1000)/1000).toString()
      decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.tvaammount=(Math.trunc(((this.realht113+this.realht213)*0.13)*1000)/1000).toString():''

      decfiscmens[0].impottype2.tvacollecter.ammountttc=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva)))*1000)/1000).toString()
      decfiscmens[0].impottype2.tvacollecter19.ammountttc=(Math.trunc((((this.realht119+this.realht219)+((this.realht119+this.realht219)*0.19)))*1000)/1000).toString()
      decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.ammountttc=(Math.trunc((((this.realht113+this.realht213)+((this.realht113+this.realht213)*0.13)))*1000)/1000).toString():''

    }
      if (this.realht3>0)
      
      {
      decfiscmens[0].impottype2.tvarecuperableautreachat.type='TVA récupérable pour les autres achats'
      decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxht=this.realht3.toString()
      decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxtva=this.realtva3.toString()
      }
      
      if(this.realdt1>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt119>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt113>0&&this.user.activite!='Syndic des copropriétaires')
      {     
        decfiscmens[0].impottype5.type='Droit de timbre'
        decfiscmens[0].impottype5.nombrenotehonoraire=(Math.floor((this.realdt1+this.realdt119+this.realdt113)/this.tauxdt)).toString();
        decfiscmens[0].impottype5.totaldroittimbre=(Math.round((this.realdt1+this.realdt119+this.realdt113)*1000)/1000).toString()
      }
      if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
      {
        decfiscmens[0].impottype6.chiffreaffairettc=(Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva+(this.realht119+this.realht219)*0.19+(this.realht113+this.realht213)*0.13)))*1000)/1000).toString()
        decfiscmens[0].impottype6.tclpayer=(Math.trunc((((Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva+(this.realht119+this.realht219)*+0.19+(this.realht113+this.realht213)*+0.13)))*1000)/1000))*0.002)*1000)/1000).toString()
      }
      if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
      this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
      ||this.activite=='Sage-femmes')
      {
        decfiscmens[0].impottype7.type='FSSP'
  
        if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
        {
        decfiscmens[0].impottype7.chiffreaffaireht=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213))*1000)/1000).toString()
        decfiscmens[0].impottype7.montantcontribution=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)*0.01)*1000)/1000).toString()
      } 
      }
      this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?decfiscmens[0].statutcollab.push
      //@ts-ignore
      ({
        statutclient:'modifié pour le compte du client',
        motifclient:'',
        datefin:data,
         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
      }):
      decfiscmens[0].statutcollab.push
      //@ts-ignore
      ({
        statutclient:'modifié par le client',
        motifclient:'',
        datefin:data,
        duree:'',     
      })
      decfiscmens[0].affecte=''
      this.DecfiscmensService.completedecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
      (data:any) => {
      this.tokenStorage.saved=true;
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'fichier comptable modifiée avec succès',
        showConfirmButton: false,
        timer: 6000  
      });
      //this.router.navigate(['modify-decfiscmens/'+data.data._id])
      },
      (error) => {
      this.loading = false;
      
      }
      )
                  }
                )
                    
              }
              else
              {
  
  
  //include question about decfiscmens genere automaique
  !this.forassiette?  Swal.fire({
    title: 'Nous avons remarqué que vous n\'avez pas générer une déclaration fiscale!veuillez choisir entre les options suivantes',
    icon: 'info',
    showDenyButton: this.user.activite!='Syndic des copropriétaires'||this.user.matriculefiscale? true:false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#555',
    confirmButtonText: 'ne pas générer une déclaration fiscale',
    cancelButtonText: 'Annuler',
    denyButtonText: 'générer la déclaration fiscale rattachée',
    
    }).then((result) => {
    if (result.isConfirmed) {
      this.userservice.getUserById(this.deccomptabilite.userId).then(
        (user:User)=>
        {
          deccomptabilite.userId = user._id;
          deccomptabilite.activite=user.activite;
          deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
          deccomptabilite.sousactivite=user.sousactivite;
          deccomptabilite.codepostal=user.codepostal;
          deccomptabilite.adresse=user.adresseactivite
          deccomptabilite.firstname=user.firstname
          deccomptabilite.lastname=user.lastname
          deccomptabilite.raisonsociale=user.raisonsociale
          deccomptabilite.codegenre=user.codegenre
          deccomptabilite.codetva=user.codetva
          deccomptabilite.matriculefiscale=user.matriculefiscale
          deccomptabilite.registrecommerce=user.registrecommerce
          deccomptabilite.datearretactivite=user.datearretactivite
          deccomptabilite.totalht=this.totalht
  deccomptabilite.totaltva=this.totaltva
  deccomptabilite.totalhtexo=this.totalhtexo
  deccomptabilite.totaltvaexo=this.totaltvaexo
  deccomptabilite.totalhtsus=this.totalhtsus
  deccomptabilite.totaltvasus=this.totaltvasus

  deccomptabilite.totalhttpe=this.totalhttpe
  deccomptabilite.totaltvatpe=this.totaltvatpe

  deccomptabilite.totalhtfodec=this.totalhtfodec
  deccomptabilite.totaltvafodec=this.totaltvafodec
  deccomptabilite.totalhtexp=this.totalhtexp
  deccomptabilite.totaltvaexp=this.totaltvaexp
  deccomptabilite.totaldt=this.totaldt
  deccomptabilite.totalttc=this.totalttc
  deccomptabilite.totalttcexo=this.totalttcexo
  deccomptabilite.totalttcsus=this.totalttcsus

  deccomptabilite.totalttcfodec=this.totalttcfodec


  deccomptabilite.totalttctpe=this.totalttctpe

  deccomptabilite.totalttcexp=this.totalttcexp
  deccomptabilite.totalht19=this.totalht19
  deccomptabilite.totaltva19=this.totaltva19
  deccomptabilite.totaldt19=this.totaldt19
  deccomptabilite.totalttc19=this.totalttc19
  deccomptabilite.totalht019=this.totalht019
  deccomptabilite.totaltva019=this.totaltva019
  deccomptabilite.totaldt019=this.totaldt019
  deccomptabilite.totalttc019=this.totalttc019
  deccomptabilite.totalht13=this.totalht13
  deccomptabilite.totaltva13=this.totaltva13
  deccomptabilite.totaldt13=this.totaldt13
  deccomptabilite.totalttc13=this.totalttc13
  deccomptabilite.totalht013=this.totalht013
  deccomptabilite.totaltva013=this.totaltva013
  deccomptabilite.totaldt013=this.totaldt013
  deccomptabilite.totalttc013=this.totalttc013
  deccomptabilite.totalht2=this.totalht2
  deccomptabilite.totaltva2=this.totaltva2
  
  deccomptabilite.totalht2exo=this.totalht2exo
  deccomptabilite.totaltva2exo=this.totaltva2exo
  deccomptabilite.totalht2sus=this.totalht2sus
  deccomptabilite.totaltva2sus=this.totaltva2sus

  deccomptabilite.totalht2tpe=this.totalht2tpe
  deccomptabilite.totaltva2tpe=this.totaltva2tpe

  deccomptabilite.totalht2fodec=this.totalht2fodec
  deccomptabilite.totaltva2fodec=this.totaltva2fodec
  deccomptabilite.totalht2exp=this.totalht2exp
  deccomptabilite.totaltva2exp=this.totaltva2exp
  deccomptabilite.totalttc2exo=this.totalttc2exo
  deccomptabilite.totalttc2exp=this.totalttc2exp
  deccomptabilite.totalttc2sus=this.totalttc2sus
  
  deccomptabilite.totalttc2fodec=this.totalttc2fodec

  deccomptabilite.totalttc2tpe=this.totalttc2tpe

  
  
  deccomptabilite.totaldt2=this.totaldt2
  deccomptabilite.totalttc2=this.totalttc2
  deccomptabilite.totalrecette=this.totalrecette
  deccomptabilite.totalrecetteexo=this.totalrecetteexo
  deccomptabilite.totalrecettesus=this.totalrecettesus

  deccomptabilite.totalrecettetpe=this.totalrecettetpe


  deccomptabilite.totalrecettefodec=this.totalrecettefodec

  deccomptabilite.totalrecetteexp=this.totalrecetteexp
  
  deccomptabilite.totalht219=this.totalht219
  deccomptabilite.totaltva219=this.totaltva219
  deccomptabilite.totaldt219=this.totaldt219
  deccomptabilite.totalttc219=this.totalttc219
  deccomptabilite.totalrecette19=this.totalrecette19
  deccomptabilite.totalht213=this.totalht213
          deccomptabilite.totaltva213=this.totaltva213
          deccomptabilite.totaldt213=this.totaldt213
          deccomptabilite.totalttc213=this.totalttc213
          deccomptabilite.totalrecette13=this.totalrecette13
  deccomptabilite.totalht3=this.totalht3
  deccomptabilite.totalht3devise=this.totalht3devise
  deccomptabilite.totaltva3=this.totaltva3
  deccomptabilite.totaldt3=this.totaldt3
  deccomptabilite.totalttc3=this.totalttc3
  deccomptabilite.totaldebit=this.totaldebit
  deccomptabilite.totalcredit=this.totalcredit
  deccomptabilite.totalsoldemois=this.totalsoldemois
  deccomptabilite.totalsalairebrut=this.totalsalairebrut
  deccomptabilite.totalcnss=this.totalcnss
  deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
  deccomptabilite.totalretenueimpot=this.totalretenueimpot
  deccomptabilite.totalavancepret=this.totalavancepret
  deccomptabilite.totalsalairenet=this.totalsalairenet
  deccomptabilite.source=''
  deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
  deccomptabilite.contientnumero=this.contientnumero
  deccomptabilite.contientnumeroother=this.contientnumeroother
          deccomptabilite.autre0=[]
          deccomptabilite.autre1=[]
          deccomptabilite.autre2=[]
          deccomptabilite.autre3=[]
          deccomptabilite.autre4=[]
          deccomptabilite.autre5=[]
          deccomptabilite.autre6=[]
          deccomptabilite.autre8=[]
  deccomptabilite.autre9=[]
  deccomptabilite.autre11=[]
          deccomptabilite.autre2bis=[]
  
          if(this.option1Value==''||this.option2Value=='')
          {
            return (
              Swal.fire({
              title: 'veuillez indiquer le mois et l\'année de la déclaration',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            }).then((result) => {this.loading=false
            }).catch(() => {
              Swal.fire('opération non aboutie!')
            }))
          }
      
          if (this.option4Value) 
          {
            let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
            for (let i = 0; i < ammounts3.length; i++)
            {        
      ammounts3.controls[i].get('fournisseur').enable();
      ammounts3.controls[i].get('numerofacture').enable(); 
      ammounts3.controls[i].get('contientfiche').enable();       
      const item = ammounts3.getRawValue()[i];
      if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
      else{
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
            }   
          }
          if (this.option14Value) 
          {
            let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
            for (let i = 0; i < ammounts8.length; i++)
            {        
      ammounts8.controls[i].get('fournisseur').enable();
      ammounts8.controls[i].get('numerofacture').enable();
      ammounts8.controls[i].get('contientfiche').enable();    
      const item = ammounts8.getRawValue()[i];
     
        deccomptabilite.autre8.push({
          type: '8',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.autrebeneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        numerocheque:item.numerocheque,
        banque:item.banque,
        contientfiche:item.contientfiche
      })
      
      console.log(deccomptabilite.autre8)    
            }   
          }
          if (this.option12Value) 
          {
            let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
            for (let i = 0; i < ammounts11.length; i++)
            {        
      ammounts11.controls[i].get('fournisseur').enable();
      ammounts11.controls[i].get('numerofacture').enable();
      ammounts11.controls[i].get('contientfiche').enable();    
      const item = ammounts11.getRawValue()[i];
      
        deccomptabilite.autre11.push({
          type: '11',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.autrebeneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      
      console.log(deccomptabilite.autre11)    
            }   
          }
          if (this.option13Value) 
          {
            let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
            for (let i = 0; i < ammounts9.length; i++)
            {        
      ammounts9.controls[i].get('fournisseur').enable();
      ammounts9.controls[i].get('numerofacture').enable();
      ammounts9.controls[i].get('contientfiche').enable();    
      const item = ammounts9.getRawValue()[i];
      if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
    
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          date: item.date,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtdevise:item.montanthtdevise,
        montanthtachat:item.montanthtachat,
      
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
    
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtdevise:item.montanthtdevise,
        montanthtachat:item.montanthtachat,
      
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
       
        contientfiche:item.contientfiche
      })
      }
      else{
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
      
        contientfiche:item.contientfiche
      })
      }
      console.log(deccomptabilite.autre9)    
            }   
          }
            if(this.option3Value)
            {
              let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
              for (let i = 0; i < ammounts0.length; i++)
             
              {
                
                const item = ammounts0.getRawValue()[i];
                if( this.user.activite=='Syndic des copropriétaires')
                {
                  if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                  {
                    return (
                      Swal.fire({
                      title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                        icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false,event.target.disabled=false
      
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  }
                }  
                if (item.client==='Autre')
                {
                  deccomptabilite.autre0.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
            
                    montanthttpe:item.montanthttpe,
            
                    montanthtfodec:item.montanthtfodec,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
            
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvatpe:item.montanttvatpe,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
            
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttctpe:item.montantttctpe,
                    montantttcfodec:item.montantttcfodec,
            
                    montantttcexp:item.montantttcexp,
                    client:item.autreclient,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
                else
                {
                  deccomptabilite.autre0.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthtfodec:item.montanthtfodec,
                    montanthttpe:item.montanthttpe,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvatpe:item.montanttvatpe,
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttcfodec:item.montantttcfodec,
                    montantttctpe:item.montantttctpe,
            
                    montantttcexp:item.montantttcexp,
                    client:item.client,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
       
       
              } 
              let ammounts = this.editionnoteform.get('ammounts') as FormArray;
              for (let i = 0; i < ammounts.length; i++)
             
              {
                
                const item = ammounts.getRawValue()[i];
                                        //@ts-ignore
                                                      if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))
  
                                        {
                                          if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
                                          {
                                            return (
                                              Swal.fire({
                                              title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                                              icon: 'error',
                                              confirmButtonColor: '#3085d6',
                                            }).then((result) => {this.loading=false,event.target.disabled=false
                                
                                            }).catch(() => {
                                              Swal.fire('opération non aboutie!')
                                            }))
                                          }
                                        }
            
                                        if (item.client==='Autre')
                                        {
                                          deccomptabilite.autre1.push({
                                            type:'1',
                                            numeropiece: item.numeropiece,
                                            jour: item.jour,
                                            date: item.date,
                                            typepiece:item.typepiece,
                                            devise: item.devise,
                                            montanthtexpdevise:item.montanthtexpdevise,
                                            numeronote:item.numeronote,
                                            montantht:item.montantht,
                                            montantht19:item.montantht19,
                                            montantht13:item.montantht13,
                                    
                                            montanthtexo:item.montanthtexo,
                                            montanthtsus:item.montanthtsus,
                                            montanthttpe:item.montanthttpe,
                                            montanthtfodec:item.montanthtfodec,
                                    
                                            montanthtexp:item.montanthtexp,
                                            montanttva:item.montanttva,
                                            montanttva19:item.montanttva19,
                                            montanttva13:item.montanttva13,
                                    
                                            montanttvaexo:item.montanttvaexo,
                                            montanttvasus:item.montanttvasus,
                                            montanttvafodec:item.montanttvafodec,
                                            montanttvatpe:item.montanttvatpe,
                                    
                                            montanttvaexp:item.montanttvaexp,
                                            montantdt:item.montantdt,
                                            montantttc:item.montantttc,
                                            montantttc19:item.montantttc19,
                                            montantttc13:item.montantttc13,
                                            montantttcexo:item.montantttcexo,
                                            montantttcsus:item.montantttcsus,
                                            montantttctpe:item.montantttctpe,
                                            montantttcfodec:item.montantttcfodec,
                                    
                                            montantttcexp:item.montantttcexp,
                                            client:item.autreclient,
                                            autreclient:item.autreclient,
                                            objet:item.objet,
                                            autreobjet:item.autreobjet,
                                            annule:item.annule,
                                        
                                            nature:item.nature,
                                            autrenature:item.autrenature,
                                            banque:item.banque,
                                            autrebanque:item.autrebanque,
                                            numerocompte: item.numerocompte,
                                            autrenumerocompte: item.autrenumerocompte,
                                            echeance:item.echeance,
                                            datevirement:item.datevirement,
                                        
                                          })
                                        }
                                        else
                                        {
                                          deccomptabilite.autre1.push({
                                            type:'1',
                                            numeropiece: item.numeropiece,
                                            jour: item.jour,
                                            date: item.date,
                                            typepiece:item.typepiece,
                                            devise: item.devise,
                                            montanthtexpdevise:item.montanthtexpdevise,
                                            numeronote:item.numeronote,
                                            montantht:item.montantht,
                                            montantht19:item.montantht19,
                                            montantht13:item.montantht13,
                                    
                                            montanthtexo:item.montanthtexo,
                                            montanthtsus:item.montanthtsus,
                                            montanthtfodec:item.montanthtfodec,
                                            montanthttpe:item.montanthttpe,
                                    
                                            montanthtexp:item.montanthtexp,
                                            montanttva:item.montanttva,
                                            montanttva19:item.montanttva19,
                                            montanttva13:item.montanttva13,
                                    
                                            montanttvaexo:item.montanttvaexo,
                                            montanttvasus:item.montanttvasus,
                                            montanttvatpe:item.montanttvatpe,
                                            montanttvafodec:item.montanttvafodec,
                                    
                                            montanttvaexp:item.montanttvaexp,
                                            montantdt:item.montantdt,
                                            montantttc:item.montantttc,
                                            montantttc19:item.montantttc19,
                                            montantttc13:item.montantttc13,
                                            montantttcexo:item.montantttcexo,
                                            montantttcsus:item.montantttcsus,
                                            montantttcfodec:item.montantttcfodec,
                                            montantttctpe:item.montantttctpe,
                                    
                                            montantttcexp:item.montantttcexp,
                                            client:item.client,
                                            autreclient:item.autreclient,
                                            objet:item.objet,
                                            autreobjet:item.autreobjet,
                                            annule:item.annule,
                                        
                                            nature:item.nature,
                                            autrenature:item.autrenature,
                                            banque:item.banque,
                                            autrebanque:item.autrebanque,
                                            numerocompte: item.numerocompte,
                                            autrenumerocompte: item.autrenumerocompte,
                                            echeance:item.echeance,
                                            datevirement:item.datevirement,
                                          })
                                        }
       
      console.log(ammounts0.getRawValue()) 
      console.log(ammounts.getRawValue()) 
      
              } 
  let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
  for (let i = 0; i < ammounts2.length; i+=1)
  {
  const item = ammounts2.getRawValue()[i];
  deccomptabilite.autre2.push({
    type:'2',
    numeropiece: item.numeropiece,
    jour: item.jour,
    date: item.date,
    devise: item.devise,
    recetteexpdevise:item.recetteexpdevise,
    recette:item.recette,
    recette19:item.recette19,
    recette13:item.recette13,

    recetteexo:item.recetteexo,
    recettesus:item.recettesus,
    recettetpe:item.recettetpe,
    recettefodec:item.recettefodec,

    recetteexp:item.recetteexp,
    montantht:item.montantht,
    montantht19:item.montantht19,
    montantht13:item.montantht13,

    montanthtexo:item.montanthtexo,
    montanthtsus:item.montanthtsus,
    montanthtfodec:item.montanthtfodec,
    montanthttpe:item.montanthttpe,

    montanthtexp:item.montanthtexp,
    montanttva:item.montanttva,
    montanttva19:item.montanttva19,
    montanttva13:item.montanttva13,

    montanttvaexo:item.montanttvaexo,
    montanttvasus:item.montanttvasus,
    montanttvatpe:item.montanttvatpe,
    montanttvafodec:item.montanttvafodec,

    montanttvaexp:item.montanttvaexp,
    montantdt:item.montantdt,
    montantttc:item.montantttc,
    montantttc19:item.montantttc19,
    montantttc13:item.montantttc13,

    montantttcexo:item.montantttcexo,
    montantttcsus:item.montantttcsus,
    montantttcfodec:item.montantttcfodec,
    montantttctpe:item.montantttctpe,

    montantttcexp:item.montantttcexp,
  })
  } 
  
  }
  if (this.option5Value) 
  {
  let employees = this.relevemanuelform.get('employees') as FormArray
  employees.getRawValue().forEach((element,index) => {
  element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
  console.log(element.skills)
  element.skills.length==0&&element.devise!='tnd'?
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.creditdevise,
    soldefinmoisdinar:element.credit,
    mouvements:element.skills
      
  }) 
    :
    element.skills==0&&element.credit!=0&&element.devise=='tnd'?
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.creditdevise,
      soldefinmoisdinar:element.credit,
      mouvements:element.skills
        
  }) 
      :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
      deccomptabilite.autre4.push({
        type: '4',
        banque:element.banque,
        numerocompte:element.numerocompte, 
        devise:element.devise,
        soldedebit:element.debit,
        soldecredit:element.credit,
        creditdevise:element.creditdevise,
        soldefinmoisdevise:element.creditdevise,
        soldefinmoisdinar:element.debit,
        mouvements:element.skills
          
  }) 
    :
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.soldefinmoisdevise,
      soldefinmoisdinar:element.soldefinmoisdinar,
      mouvements:element.skills
        
  }) 
  console.log(deccomptabilite.autre4)
  }); 
  let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
  for (let i = 0; i < ammounts5.length; i++)
  {
  const item = ammounts5.getRawValue()[i];
  deccomptabilite.autre5.push({
  type: '5',
  annee: item.annee,
  mois: item.mois,
  ficheUrl:item.ficheUrl,
  contientfiche:item.contientfiche
  
  })
  } 
  }
          if (this.option6Value) 
          {
      
      
            let typessalaire = this.salaireform.get('typessalaire') as FormArray;
            typessalaire.getRawValue().forEach((element,index) => {
              element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
              deccomptabilite.autre6.push({
                type: '6',
                typesalaire:element.typesalaire,
                salarie:element.ammounts6,         
        }) 
      }
            )
          }
          deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
          deccomptabilite.affecte=this.deccomptabilite.affecte
          this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
          //@ts-ignore
          ({
            statutclient:'modifié pour le compte du client',
            motifclient:'',
            datefin:data,
             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
          }):
          deccomptabilite.statutcollab.push
                //@ts-ignore
                ({
                  statutclient:'modifié par le client',
                  motifclient:'',
                  datefin:data,
                  duree:'',     
                })
          deccomptabilite.affecte=''
          //deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
          deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
          deccomptabilite.autre2.map((e, i) => {
  //@ts-ignore
   e.numeropiece = i+1 });
  deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
  item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
  deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
  deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
  deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));   
  deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
  deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
  deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
  this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
                (data:any) => {
                  this.tokenStorage.saved=true;
                  this.loading = false;
                  this.firsttimer=Date.now()
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'fichier comptable modifiée avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                },
                (error) => {
                  this.loading = false;
                  
                  window.scrollTo(0, 0);
                  
                
                  
                }
              )
        }
      )  
    }
    else if (result.isDenied)
    {
      this.userservice.getUserById(this.deccomptabilite.userId).then(
        (user:User)=>
        {
          deccomptabilite.userId = user._id;
          deccomptabilite.activite=user.activite;
          deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
          deccomptabilite.sousactivite=user.sousactivite;
          deccomptabilite.codepostal=user.codepostal;
          deccomptabilite.adresse=user.adresseactivite
          deccomptabilite.firstname=user.firstname
          deccomptabilite.lastname=user.lastname
          deccomptabilite.raisonsociale=user.raisonsociale
          deccomptabilite.codegenre=user.codegenre
          deccomptabilite.codetva=user.codetva
          deccomptabilite.matriculefiscale=user.matriculefiscale
          deccomptabilite.registrecommerce=user.registrecommerce
          deccomptabilite.datearretactivite=user.datearretactivite
          deccomptabilite.totalht=this.totalht
  deccomptabilite.totaltva=this.totaltva
  deccomptabilite.totalhtexo=this.totalhtexo
  deccomptabilite.totaltvaexo=this.totaltvaexo
  deccomptabilite.totalhtsus=this.totalhtsus
  deccomptabilite.totaltvasus=this.totaltvasus

  deccomptabilite.totalhtfodec=this.totalhtfodec
  deccomptabilite.totaltvafodec=this.totaltvafodec

  deccomptabilite.totalhttpe=this.totalhttpe
  deccomptabilite.totaltvatpe=this.totaltvatpe
  deccomptabilite.totalhtexp=this.totalhtexp
  deccomptabilite.totaltvaexp=this.totaltvaexp
  deccomptabilite.totaldt=this.totaldt
  deccomptabilite.totalttc=this.totalttc
  deccomptabilite.totalttcexo=this.totalttcexo
  deccomptabilite.totalttcsus=this.totalttcsus

  deccomptabilite.totalttctpe=this.totalttctpe


  deccomptabilite.totalttcfodec=this.totalttcfodec

  deccomptabilite.totalttcexp=this.totalttcexp
  deccomptabilite.totalht19=this.totalht19
  deccomptabilite.totaltva19=this.totaltva19
  deccomptabilite.totaldt19=this.totaldt19
  deccomptabilite.totalttc19=this.totalttc19
  deccomptabilite.totalht019=this.totalht019
  deccomptabilite.totaltva019=this.totaltva019
  deccomptabilite.totaldt019=this.totaldt019
  deccomptabilite.totalttc019=this.totalttc019
  deccomptabilite.totalht13=this.totalht13
  deccomptabilite.totaltva13=this.totaltva13
  deccomptabilite.totaldt13=this.totaldt13
  deccomptabilite.totalttc13=this.totalttc13
  deccomptabilite.totalht013=this.totalht013
  deccomptabilite.totaltva013=this.totaltva013
  deccomptabilite.totaldt013=this.totaldt013
  deccomptabilite.totalttc013=this.totalttc013
  deccomptabilite.totalht2=this.totalht2
  deccomptabilite.totaltva2=this.totaltva2
  
  deccomptabilite.totalht2exo=this.totalht2exo
  deccomptabilite.totaltva2exo=this.totaltva2exo
  deccomptabilite.totalht2sus=this.totalht2sus
  deccomptabilite.totaltva2sus=this.totaltva2sus

  deccomptabilite.totalht2fodec=this.totalht2fodec
  deccomptabilite.totaltva2fodec=this.totaltva2fodec

  deccomptabilite.totalht2tpe=this.totalht2tpe
  deccomptabilite.totaltva2tpe=this.totaltva2tpe
  deccomptabilite.totalht2exp=this.totalht2exp
  deccomptabilite.totaltva2exp=this.totaltva2exp
  deccomptabilite.totalttc2exo=this.totalttc2exo
  deccomptabilite.totalttc2exp=this.totalttc2exp
  deccomptabilite.totalttc2sus=this.totalttc2sus

  deccomptabilite.totalttc2tpe=this.totalttc2tpe


  deccomptabilite.totalttc2fodec=this.totalttc2fodec

  
  
  
  deccomptabilite.totaldt2=this.totaldt2
  deccomptabilite.totalttc2=this.totalttc2
  deccomptabilite.totalrecette=this.totalrecette
  deccomptabilite.totalrecetteexo=this.totalrecetteexo
  deccomptabilite.totalrecettesus=this.totalrecettesus

  deccomptabilite.totalrecettefodec=this.totalrecettefodec


  deccomptabilite.totalrecettetpe=this.totalrecettetpe

  deccomptabilite.totalrecetteexp=this.totalrecetteexp
  
  deccomptabilite.totalht219=this.totalht219
  deccomptabilite.totaltva219=this.totaltva219
  deccomptabilite.totaldt219=this.totaldt219
  deccomptabilite.totalttc219=this.totalttc219
  deccomptabilite.totalrecette19=this.totalrecette19
  deccomptabilite.totalht213=this.totalht213
          deccomptabilite.totaltva213=this.totaltva213
          deccomptabilite.totaldt213=this.totaldt213
          deccomptabilite.totalttc213=this.totalttc213
          deccomptabilite.totalrecette13=this.totalrecette13
  deccomptabilite.totalht3=this.totalht3
  deccomptabilite.totalht3devise=this.totalht3devise
  deccomptabilite.totaltva3=this.totaltva3
  deccomptabilite.totaldt3=this.totaldt3
  deccomptabilite.totalttc3=this.totalttc3
  deccomptabilite.totaldebit=this.totaldebit
  deccomptabilite.totalcredit=this.totalcredit
  deccomptabilite.totalsoldemois=this.totalsoldemois
  deccomptabilite.totalsalairebrut=this.totalsalairebrut
  deccomptabilite.totalcnss=this.totalcnss
  deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
  deccomptabilite.totalretenueimpot=this.totalretenueimpot
  deccomptabilite.totalavancepret=this.totalavancepret
  deccomptabilite.totalsalairenet=this.totalsalairenet
  deccomptabilite.source=''
  deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
  deccomptabilite.contientnumero=this.contientnumero
  deccomptabilite.contientnumeroother=this.contientnumeroother
          deccomptabilite.autre0=[]
          deccomptabilite.autre1=[]
          deccomptabilite.autre2=[]
          deccomptabilite.autre3=[]
          deccomptabilite.autre4=[]
          deccomptabilite.autre5=[]
          deccomptabilite.autre6=[]
          deccomptabilite.autre8=[]
  deccomptabilite.autre9=[]
  deccomptabilite.autre11=[]
          deccomptabilite.autre2bis=[]
  
          if(this.option1Value==''||this.option2Value=='')
          {
            return (
              Swal.fire({
              title: 'veuillez indiquer le mois et l\'année de la déclaration',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            }).then((result) => {this.loading=false
            }).catch(() => {
              Swal.fire('opération non aboutie!')
            }))
          }
      
          if (this.option4Value) 
          {
            let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
            for (let i = 0; i < ammounts3.length; i++)
            {        
      ammounts3.controls[i].get('fournisseur').enable();
      ammounts3.controls[i].get('numerofacture').enable(); 
      ammounts3.controls[i].get('contientfiche').enable();       
      const item = ammounts3.getRawValue()[i];
      if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
      {
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
      else{
        deccomptabilite.autre3.push({
          type: '3',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise:item.devise,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      }
            }   
          }
          if (this.option14Value) 
          {
            let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
            for (let i = 0; i < ammounts8.length; i++)
            {        
      ammounts8.controls[i].get('fournisseur').enable();
      ammounts8.controls[i].get('numerofacture').enable();
      ammounts8.controls[i].get('contientfiche').enable();    
      const item = ammounts8.getRawValue()[i];
     
        deccomptabilite.autre8.push({
          type: '8',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.autrebeneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        numerocheque:item.numerocheque,
        banque:item.banque,
        contientfiche:item.contientfiche
      })
      
      console.log(deccomptabilite.autre8)    
            }   
          }
          if (this.option12Value) 
          {
            let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
            for (let i = 0; i < ammounts11.length; i++)
            {        
      ammounts11.controls[i].get('fournisseur').enable();
      ammounts11.controls[i].get('numerofacture').enable();
      ammounts11.controls[i].get('contientfiche').enable();    
      const item = ammounts11.getRawValue()[i];
      
        deccomptabilite.autre11.push({
          type: '11',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.autrebeneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
        contientfiche:item.contientfiche
      })
      
      console.log(deccomptabilite.autre11)    
            }   
          }
          if (this.option13Value) 
          {
            let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
            for (let i = 0; i < ammounts9.length; i++)
            {        
      ammounts9.controls[i].get('fournisseur').enable();
      ammounts9.controls[i].get('numerofacture').enable();
      ammounts9.controls[i].get('contientfiche').enable();    
      const item = ammounts9.getRawValue()[i];
      if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
    
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          date: item.date,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtdevise:item.montanthtdevise,
        montanthtachat:item.montanthtachat,
      
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
    
        contientfiche:item.contientfiche
      })
      }
     else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
      {
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.autrefournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.autrenatureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtdevise:item.montanthtdevise,
        montanthtachat:item.montanthtachat,
      
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
       
        contientfiche:item.contientfiche
      })
      }
      else{
        deccomptabilite.autre9.push({
          type: '9',
          numeropiece: item.numeropiece,
          jour: item.jour,
          date: item.date,
          devise: item.devise,
          beneficiaire: item.beneficiaire,
          autrebeneficiaire: item.beneficiaire,
          fournisseur: item.fournisseur,
          autrefournisseur: item.autrefournisseur,
          typepiece:item.typepiece,
          numerofacture:item.numerofacture,
          natureachat:item.natureachat,
          autrenatureachat:item.autrenatureachat,
        montantht:item.montantht,
        montanthtachat:item.montanthtachat,
      
        montanthtdevise:item.montanthtdevise,
        montanttva:item.montanttva,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        reglement:item.reglement,
        ficheUrl:item.ficheUrl,
      
        contientfiche:item.contientfiche
      })
      }
      console.log(deccomptabilite.autre9)    
            }   
          }
            if(this.option3Value)
            {
              let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
              for (let i = 0; i < ammounts0.length; i++)
             
              {
                
                const item = ammounts0.getRawValue()[i];
                if( this.user.activite=='Syndic des copropriétaires')
                {
                  if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                  {
                    return (
                      Swal.fire({
                      title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                        icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false,event.target.disabled=false
      
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  }
                }  
                if (item.client==='Autre')
                {
                  deccomptabilite.autre0.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
            
                    montanthttpe:item.montanthttpe,
            
                    montanthtfodec:item.montanthtfodec,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
            
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvatpe:item.montanttvatpe,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
            
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttctpe:item.montantttctpe,
                    montantttcfodec:item.montantttcfodec,
            
                    montantttcexp:item.montantttcexp,
                    client:item.autreclient,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
                else
                {
                  deccomptabilite.autre0.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthtfodec:item.montanthtfodec,
                    montanthttpe:item.montanthttpe,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvatpe:item.montanttvatpe,
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttcfodec:item.montantttcfodec,
                    montantttctpe:item.montantttctpe,
            
                    montantttcexp:item.montantttcexp,
                    client:item.client,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
       
       
              } 
              let ammounts = this.editionnoteform.get('ammounts') as FormArray;
              for (let i = 0; i < ammounts.length; i++)
             
              {
                
                const item = ammounts.getRawValue()[i];
                                        //@ts-ignore
                                                      if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))
  
                                        {
                                          if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
                                          {
                                            return (
                                              Swal.fire({
                                              title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                                              icon: 'error',
                                              confirmButtonColor: '#3085d6',
                                            }).then((result) => {this.loading=false,event.target.disabled=false
                                
                                            }).catch(() => {
                                              Swal.fire('opération non aboutie!')
                                            }))
                                          }
                                        }
            
                                        if (item.client==='Autre')
                                        {
                                          deccomptabilite.autre1.push({
                                            type:'1',
                                            numeropiece: item.numeropiece,
                                            jour: item.jour,
                                            date: item.date,
                                            typepiece:item.typepiece,
                                            devise: item.devise,
                                            montanthtexpdevise:item.montanthtexpdevise,
                                            numeronote:item.numeronote,
                                            montantht:item.montantht,
                                            montantht19:item.montantht19,
                                            montantht13:item.montantht13,
                                    
                                            montanthtexo:item.montanthtexo,
                                            montanthtsus:item.montanthtsus,
                                            montanthttpe:item.montanthttpe,
                                            montanthtfodec:item.montanthtfodec,
                                    
                                            montanthtexp:item.montanthtexp,
                                            montanttva:item.montanttva,
                                            montanttva19:item.montanttva19,
                                            montanttva13:item.montanttva13,
                                    
                                            montanttvaexo:item.montanttvaexo,
                                            montanttvasus:item.montanttvasus,
                                            montanttvafodec:item.montanttvafodec,
                                            montanttvatpe:item.montanttvatpe,
                                    
                                            montanttvaexp:item.montanttvaexp,
                                            montantdt:item.montantdt,
                                            montantttc:item.montantttc,
                                            montantttc19:item.montantttc19,
                                            montantttc13:item.montantttc13,
                                            montantttcexo:item.montantttcexo,
                                            montantttcsus:item.montantttcsus,
                                            montantttctpe:item.montantttctpe,
                                            montantttcfodec:item.montantttcfodec,
                                    
                                            montantttcexp:item.montantttcexp,
                                            client:item.autreclient,
                                            autreclient:item.autreclient,
                                            objet:item.objet,
                                            autreobjet:item.autreobjet,
                                            annule:item.annule,
                                        
                                            nature:item.nature,
                                            autrenature:item.autrenature,
                                            banque:item.banque,
                                            autrebanque:item.autrebanque,
                                            numerocompte: item.numerocompte,
                                            autrenumerocompte: item.autrenumerocompte,
                                            echeance:item.echeance,
                                            datevirement:item.datevirement,
                                        
                                          })
                                        }
                                        else
                                        {
                                          deccomptabilite.autre1.push({
                                            type:'1',
                                            numeropiece: item.numeropiece,
                                            jour: item.jour,
                                            date: item.date,
                                            typepiece:item.typepiece,
                                            devise: item.devise,
                                            montanthtexpdevise:item.montanthtexpdevise,
                                            numeronote:item.numeronote,
                                            montantht:item.montantht,
                                            montantht19:item.montantht19,
                                            montantht13:item.montantht13,
                                    
                                            montanthtexo:item.montanthtexo,
                                            montanthtsus:item.montanthtsus,
                                            montanthtfodec:item.montanthtfodec,
                                            montanthttpe:item.montanthttpe,
                                    
                                            montanthtexp:item.montanthtexp,
                                            montanttva:item.montanttva,
                                            montanttva19:item.montanttva19,
                                            montanttva13:item.montanttva13,
                                    
                                            montanttvaexo:item.montanttvaexo,
                                            montanttvasus:item.montanttvasus,
                                            montanttvatpe:item.montanttvatpe,
                                            montanttvafodec:item.montanttvafodec,
                                    
                                            montanttvaexp:item.montanttvaexp,
                                            montantdt:item.montantdt,
                                            montantttc:item.montantttc,
                                            montantttc19:item.montantttc19,
                                            montantttc13:item.montantttc13,
                                            montantttcexo:item.montantttcexo,
                                            montantttcsus:item.montantttcsus,
                                            montantttcfodec:item.montantttcfodec,
                                            montantttctpe:item.montantttctpe,
                                    
                                            montantttcexp:item.montantttcexp,
                                            client:item.client,
                                            autreclient:item.autreclient,
                                            objet:item.objet,
                                            autreobjet:item.autreobjet,
                                            annule:item.annule,
                                        
                                            nature:item.nature,
                                            autrenature:item.autrenature,
                                            banque:item.banque,
                                            autrebanque:item.autrebanque,
                                            numerocompte: item.numerocompte,
                                            autrenumerocompte: item.autrenumerocompte,
                                            echeance:item.echeance,
                                            datevirement:item.datevirement,
                                          })
                                        }
       
      console.log(ammounts0.getRawValue()) 
      console.log(ammounts.getRawValue()) 
      
              } 
  let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
  for (let i = 0; i < ammounts2.length; i+=1)
  {
  const item = ammounts2.getRawValue()[i];
  deccomptabilite.autre2.push({
    type:'2',
    numeropiece: item.numeropiece,
    jour: item.jour,
    date: item.date,
    devise: item.devise,
    recetteexpdevise:item.recetteexpdevise,
    recette:item.recette,
    recette19:item.recette19,
    recette13:item.recette13,

    recetteexo:item.recetteexo,
    recettesus:item.recettesus,
    recettetpe:item.recettetpe,
    recettefodec:item.recettefodec,

    recetteexp:item.recetteexp,
    montantht:item.montantht,
    montantht19:item.montantht19,
    montantht13:item.montantht13,

    montanthtexo:item.montanthtexo,
    montanthtsus:item.montanthtsus,
    montanthtfodec:item.montanthtfodec,
    montanthttpe:item.montanthttpe,

    montanthtexp:item.montanthtexp,
    montanttva:item.montanttva,
    montanttva19:item.montanttva19,
    montanttva13:item.montanttva13,

    montanttvaexo:item.montanttvaexo,
    montanttvasus:item.montanttvasus,
    montanttvatpe:item.montanttvatpe,
    montanttvafodec:item.montanttvafodec,

    montanttvaexp:item.montanttvaexp,
    montantdt:item.montantdt,
    montantttc:item.montantttc,
    montantttc19:item.montantttc19,
    montantttc13:item.montantttc13,

    montantttcexo:item.montantttcexo,
    montantttcsus:item.montantttcsus,
    montantttcfodec:item.montantttcfodec,
    montantttctpe:item.montantttctpe,

    montantttcexp:item.montantttcexp,
  })
  } 
  
  }
  if (this.option5Value) 
  {
  let employees = this.relevemanuelform.get('employees') as FormArray
  employees.getRawValue().forEach((element,index) => {
  element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
  console.log(element.skills)
  element.skills.length==0&&element.devise!='tnd'?
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.creditdevise,
    soldefinmoisdinar:element.credit,
    mouvements:element.skills
      
  }) 
    :
    element.skills==0&&element.credit!=0&&element.devise=='tnd'?
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.creditdevise,
      soldefinmoisdinar:element.credit,
      mouvements:element.skills
        
  }) 
      :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
      deccomptabilite.autre4.push({
        type: '4',
        banque:element.banque,
        numerocompte:element.numerocompte, 
        devise:element.devise,
        soldedebit:element.debit,
        soldecredit:element.credit,
        creditdevise:element.creditdevise,
        soldefinmoisdevise:element.creditdevise,
        soldefinmoisdinar:element.debit,
        mouvements:element.skills
          
  }) 
    :
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.soldefinmoisdevise,
      soldefinmoisdinar:element.soldefinmoisdinar,
      mouvements:element.skills
        
  }) 
  console.log(deccomptabilite.autre4)
  }); 
  let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
  for (let i = 0; i < ammounts5.length; i++)
  {
  const item = ammounts5.getRawValue()[i];
  deccomptabilite.autre5.push({
  type: '5',
  annee: item.annee,
  mois: item.mois,
  ficheUrl:item.ficheUrl,
  contientfiche:item.contientfiche
  
  })
  } 
  }
          if (this.option6Value) 
          {
      
      
            let typessalaire = this.salaireform.get('typessalaire') as FormArray;
            typessalaire.getRawValue().forEach((element,index) => {
              element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
              deccomptabilite.autre6.push({
                type: '6',
                typesalaire:element.typesalaire,
                salarie:element.ammounts6,         
        }) 
      }
            )
          }
          deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
          deccomptabilite.affecte=this.deccomptabilite.affecte
          this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
          //@ts-ignore
          ({
            statutclient:'modifié pour le compte du client',
            motifclient:'',
            datefin:data,
             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
          }):
          deccomptabilite.statutcollab.push
                //@ts-ignore
                ({
                  statutclient:'modifié par le client',
                  motifclient:'',
                  datefin:data,
                  duree:'',     
                })
          deccomptabilite.affecte=''
          //deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
          deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
          deccomptabilite.autre2.map((e, i) => {
  //@ts-ignore
   e.numeropiece = i+1 });
  deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
  item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
  deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
  deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
  deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));   
  deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
  deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
  deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
  this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
                (data:any) => {
                  this.tokenStorage.saved=true;
                  this.loading = false;
                  this.firsttimer=Date.now()
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'fichier comptable modifiée avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  const decfiscmens:Decfiscmens = new Decfiscmens();
                  decfiscmens.impottype1={ type: '', traitementetsalaire: { salairebrut:'', salaireimposable: '', retenuealasource: '',contributionsociale: '', }, 
                  location1: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location2: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
                  location3: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location4: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
                   honoraire1: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, honoraire2: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
                   honoraire3: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10001: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
                   montant10002: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10003: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, 
                  montant10004: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, autre: []}
                  decfiscmens.impottype2={ type: '',reporttvamoisprecedent:'',tvacollecter:{
                    type:'',
                    chiffreaffaireht:'',
                    tvaammount:'',
                    ammountttc:'',
                    
                    },tvacollecter19:{
                      type:'',
                      chiffreaffaireht:'',
                      tvaammount:'',
                      ammountttc:'',
                      
                      },tvacollecter13:{
                        type:'',
                        chiffreaffaireht:'',
                        tvaammount:'',
                        ammountttc:'',
                        
                        },tvarecuperableimmobilier:{
                        type:'',
                    achatlocauxht:'',
                    achatlocauxtva:'',
                    
                    
                    },
                    tvarecuperableequipement:{
                        type:'',
                    achatlocauxht:'',
                    achatlocauxtva:'',
                    achatimporteht:'',
                    achatimportetva:'',
                    
                    
                    },
                    tvarecuperableautreachat:{
                        type:'',
                    achatlocauxht:'',
                    achatlocauxtva:'',
                    achatimporteht:'',
                    achatimportetva:'',
                    
                    
                    },
                    locationhabitationmeuble:{
                        type:'',
                        htammount:'',
                        tvaammount:'',
                        ttcammount:'',
                        },
                    locationusagecommercial:{
                        type:'',
                            htammount:'',
                            tvaammount:'',
                            ttcammount:'',
                            },
                    operationlotissement:{
                        type:'',
                                htammount:'',
                                tvaammount:'',
                                ttcammount:'',
                                },
                    interetpercue:{
                        type:'',
                                    htammount:'',
                                    tvaammount:'',
                                    ttcammount:'',
                                    },
                    autretvaspecial:{
                        type:'',
                                        htammount:'',
                                        tvaammount:'',
                                        ttcammount:'',
                                        taux:'',
                                        }    }
                                        decfiscmens.impottype3={ type:'',
                                          basetfp:'',
                                          tfpsalairebrut:'',
                                          montanttfpmois:'',
                                          reporttfpmoisprecedent:'',
                                          montantavance:'',
                                          tfppayer:'',
                                          tfpreporter:'',
                                          salairesnonsoumistfp:''}
                                          decfiscmens.impottype4={ type:'',
                                          foprolossalairebrut:'',
                                          basefoprolos:'',
                                          montantfoprolos:'',
                                          salairesnonsoumisfoprolos:''}
                                          decfiscmens.impottype5={ type:'',
                                          nombrenotehonoraire:'',
                              totaldroittimbre:'',}
                              decfiscmens.impottype6={ type:'',
                              chiffreaffairettc:'',
                              tclpayer:'',}
                              decfiscmens.impottype7={ type:'',
                              chiffreaffaireht:'',
                              montantcontribution:'',}
                  decfiscmens.userId = this.user._id;
                  decfiscmens.activite=this.activite;
                  decfiscmens.regimefiscalimpot=this.user.regimefiscalimpot;
                  decfiscmens.sousactivite=this.sousactivite;
                  decfiscmens.codepostal=this.user.codepostal;
                  decfiscmens.adresse=this.user.adresseactivite
                  decfiscmens.firstname=this.user.firstname
                  decfiscmens.lastname=this.user.lastname
                  decfiscmens.raisonsociale=this.user.raisonsociale
                  decfiscmens.codegenre=this.user.codegenre
                  decfiscmens.codetva=this.user.codetva
                  decfiscmens.matriculefiscale=this.user.matriculefiscale
                  decfiscmens.registrecommerce=this.user.registrecommerce
                  decfiscmens.datearretactivite=this.user.datearretactivite
                  decfiscmens.annee=this.option1Value
                  decfiscmens.mois=this.option2Value
                  decfiscmens.nature='Déclaration Mensuelle'
                  decfiscmens.source=''
                  decfiscmens.origine='généré automatiquement'
                  decfiscmens.impottype1.type='Retenue à la source'
                  this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype3.type='TFP':''
                  decfiscmens.impottype4.type='FOPROLOS'
                  this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype2.type='TVA':''
                  this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype6.type='TCL':''
                  decfiscmens.statutcollab=[]
                  if(this.option1Value==''||this.option2Value=='')
                  {
                    return (
                      Swal.fire({
                      title: 'veuillez indiquer le mois et l\'année de la déclaration',
                      icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  } 
                  if (this.realsalairebrut6!=0) 
                  {    
              decfiscmens.impottype1.traitementetsalaire.salairebrut=this.realsalairebrut6.toString()
              decfiscmens.impottype1.traitementetsalaire.salaireimposable=this.realsalaireimposable6.toString()
              decfiscmens.impottype1.traitementetsalaire.retenuealasource=this.realretenue6.toString() 
              }
              if(this.realsalairebrut6!=0)
              {
                decfiscmens.impottype3.tfppayer=this.tfpapayer.toString()
                decfiscmens.impottype3.basetfp=this.realsalairebrut6.toString()
                decfiscmens.impottype3.montanttfpmois=this.tfpapayer.toString()
              }
              if(this.realsalairebrut6!=0)
              {
                decfiscmens.impottype4.montantfoprolos=this.foprolosapayer.toString()
                decfiscmens.impottype4.basefoprolos=this.realsalairebrut6.toString()
              }
              if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
                    {
                    console.log(this.realht2)
                    decfiscmens.impottype2.tvacollecter.type='TVA collecté'
                    decfiscmens.impottype2.tvacollecter.chiffreaffaireht=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec))*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter19.chiffreaffaireht=(Math.trunc(((this.realht119+this.realht219))*1000)/1000).toString()
                    
                    decfiscmens.impottype2.tvacollecter13.chiffreaffaireht=(Math.trunc(((this.realht113+this.realht213))*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter.tvaammount=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva)*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter19.tvaammount=(Math.trunc(((this.realht119+this.realht219)*0.19)*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter13.tvaammount=(Math.trunc(((this.realht113+this.realht213)*0.13)*1000)/1000).toString()

                    decfiscmens.impottype2.tvacollecter.ammountttc=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva)))*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter19.ammountttc=(Math.trunc((((this.realht119+this.realht219)+((this.realht119+this.realht219)*0.19)))*1000)/1000).toString()
                    decfiscmens.impottype2.tvacollecter13.ammountttc=(Math.trunc((((this.realht113+this.realht213)+((this.realht113+this.realht213)*0.13)))*1000)/1000).toString()

                  }
              if (this.realht3>0)
              {
              //decfiscmens.impottype2.type='TVA'
              decfiscmens.impottype2.tvarecuperableautreachat.type='TVA récupérable pour les autres achats'
              decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxht=this.realht3.toString()
              decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxtva=this.realtva3.toString()
              }
              if(this.realdt1>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt119>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt113>0&&this.user.activite!='Syndic des copropriétaires')
              {     
                decfiscmens[0].impottype5.type='Droit de timbre'
                decfiscmens[0].impottype5.nombrenotehonoraire=(Math.floor((this.realdt1+this.realdt119+this.realdt113)/this.tauxdt)).toString();
                decfiscmens[0].impottype5.totaldroittimbre=(Math.round((this.realdt1+this.realdt119+this.realdt113)*1000)/1000).toString()
              }
              if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
              {
                decfiscmens[0].impottype6.chiffreaffairettc=(Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva+(this.realht119+this.realht219)*0.19+(this.realht113+this.realht213)*0.13)))*1000)/1000).toString()
                decfiscmens[0].impottype6.tclpayer=(Math.trunc((((Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva+(this.realht119+this.realht219)*+0.19+(this.realht113+this.realht213)*+0.13)))*1000)/1000))*0.002)*1000)/1000).toString()
              }
              if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
              this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
              ||this.activite=='Sage-femmes')
              {
                decfiscmens.impottype7.type='FSSP'
            
                if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
                {
                decfiscmens.impottype7.chiffreaffaireht=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213))*1000)/1000).toString()
                decfiscmens.impottype7.montantcontribution=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)*0.01)*1000)/1000).toString()
              } 
              }
              this.currentUser.role=='admin'||this.currentUser.role=='supervisor'? decfiscmens.statutcollab.push
                //@ts-ignore
                ({
                  statutclient:'modifié pour le compte du client',
                  motifclient:'',
                  datefin:data,
                   duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                }):decfiscmens.statutcollab.push
                //@ts-ignore
                ({
                  statutclient:'modifié par le client',
                  motifclient:'',
                  datefin:data,
                  duree:'',     
                })
                decfiscmens.affecte=''
              this.DecfiscmensService.create(decfiscmens).then(
                (data:any) => {
                  this.tokenStorage.saved=true;
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'déclaration sauvegardée avec succès! un email vous a été envoyer pour confirmer la réception de votre déclaration. vous pouvez désormais modifier/compléter votre déclaration à travers votre tableau de bord',
                    showConfirmButton: false,
                    timer: 6000 
                  });
                //  this.router.navigate(['modify-deccomptabilite/'+datacomptab.data._id])
                },
                (error) => {
                  this.loading = false;
                  
                }
              )
                },
                (error) => {
                  this.loading = false;
                  
                  window.scrollTo(0, 0);
                  
                
                  
                }
              )
        }
      )  
    }
    else if(result.isDismissed)
    {
      this.loading=false
    }
    }):''
       
              }
            }
          )
        }
      )
    }
  
  }
  onSend(event:any) {
    this.loading = true;
    const deccomptabilite:Deccomptabilite = this.deccomptabilite;
    if(this.editionnoteform.invalid||this.editionnotepastform.invalid||this.recettejournaliereform.invalid||this.factureachatform.invalid||this.factureachatchequeform.invalid||
      this.facturefournisseurform.invalid||this.factureachatespeceform.invalid||this.relevejointform.invalid||this.ajoutdccopform.invalid||this.ajoutsalarieform.invalid||this.ajoutdccopform.invalid
      ||this.relevemanuelform.invalid||this.salaireform.invalid)
    {
      return (
        Swal.fire({
          title: 'vous avez une erreur de saisie, veuillez vérifier l\'emplacement de l\'erreur et la corriger avant de sauvegarder!',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {{this.loading=false,event.target.disabled=false
        }}).catch(() => {
          Swal.fire('opération non aboutie!')
        })
        )
    }
    else
    {

    
    this.commun.getcurrenttime().then(
      async (data:any) => {
        this.DecfiscmensService.geexistenttdecfiscmens(this.deccomptabilite.userId,this.deccomptabilite.annee,this.deccomptabilite.mois,this.deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              this.userservice.getUserById(this.deccomptabilite.userId).then(
                (user:User)=>
                {
                  deccomptabilite.userId = user._id;
                  deccomptabilite.activite=user.activite;
                  deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
                  deccomptabilite.sousactivite=user.sousactivite;
                  deccomptabilite.codepostal=user.codepostal;
                  deccomptabilite.adresse=user.adresseactivite
                  deccomptabilite.firstname=user.firstname
                  deccomptabilite.lastname=user.lastname
                  deccomptabilite.raisonsociale=user.raisonsociale
                  deccomptabilite.codegenre=user.codegenre
                  deccomptabilite.codetva=user.codetva
                  deccomptabilite.matriculefiscale=user.matriculefiscale
                  deccomptabilite.registrecommerce=user.registrecommerce
                  deccomptabilite.datearretactivite=user.datearretactivite
                  deccomptabilite.totalht=this.totalht
    deccomptabilite.totaltva=this.totaltva
    deccomptabilite.totalhtexo=this.totalhtexo
    deccomptabilite.totaltvaexo=this.totaltvaexo
    deccomptabilite.totalhtsus=this.totalhtsus
    deccomptabilite.totaltvasus=this.totaltvasus

    deccomptabilite.totalhttpe=this.totalhttpe
    deccomptabilite.totaltvatpe=this.totaltvatpe

    deccomptabilite.totalhtfodec=this.totalhtfodec
    deccomptabilite.totaltvafodec=this.totaltvafodec
    deccomptabilite.totalhtexp=this.totalhtexp
    deccomptabilite.totaltvaexp=this.totaltvaexp
    deccomptabilite.totaldt=this.totaldt
    deccomptabilite.totalttc=this.totalttc
    deccomptabilite.totalttcexo=this.totalttcexo
    deccomptabilite.totalttcsus=this.totalttcsus

    deccomptabilite.totalttcfodec=this.totalttcfodec


    deccomptabilite.totalttctpe=this.totalttctpe

    deccomptabilite.totalttcexp=this.totalttcexp
    deccomptabilite.totalht19=this.totalht19
    deccomptabilite.totaltva19=this.totaltva19
    deccomptabilite.totaldt19=this.totaldt19
    deccomptabilite.totalttc19=this.totalttc19
    deccomptabilite.totalht019=this.totalht019
    deccomptabilite.totaltva019=this.totaltva019
    deccomptabilite.totaldt019=this.totaldt019
    deccomptabilite.totalttc019=this.totalttc019
    deccomptabilite.totalht13=this.totalht13
    deccomptabilite.totaltva13=this.totaltva13
    deccomptabilite.totaldt13=this.totaldt13
    deccomptabilite.totalttc13=this.totalttc13
    deccomptabilite.totalht013=this.totalht013
    deccomptabilite.totaltva013=this.totaltva013
    deccomptabilite.totaldt013=this.totaldt013
    deccomptabilite.totalttc013=this.totalttc013
    deccomptabilite.totalht2=this.totalht2
    deccomptabilite.totaltva2=this.totaltva2

    deccomptabilite.totalht2exo=this.totalht2exo
    deccomptabilite.totaltva2exo=this.totaltva2exo
    deccomptabilite.totalht2sus=this.totalht2sus
    deccomptabilite.totaltva2sus=this.totaltva2sus

    deccomptabilite.totalht2tpe=this.totalht2tpe
    deccomptabilite.totaltva2tpe=this.totaltva2tpe

    deccomptabilite.totalht2fodec=this.totalht2fodec
    deccomptabilite.totaltva2fodec=this.totaltva2fodec
    deccomptabilite.totalht2exp=this.totalht2exp
    deccomptabilite.totaltva2exp=this.totaltva2exp
    deccomptabilite.totalttc2exo=this.totalttc2exo
    deccomptabilite.totalttc2exp=this.totalttc2exp
    deccomptabilite.totalttc2sus=this.totalttc2sus

    deccomptabilite.totalttc2fodec=this.totalttc2fodec

    deccomptabilite.totalttc2tpe=this.totalttc2tpe

   


    deccomptabilite.totaldt2=this.totaldt2
    deccomptabilite.totalttc2=this.totalttc2
    deccomptabilite.totalrecette=this.totalrecette
    deccomptabilite.totalrecetteexo=this.totalrecetteexo
    deccomptabilite.totalrecettesus=this.totalrecettesus

    deccomptabilite.totalrecettetpe=this.totalrecettetpe


    deccomptabilite.totalrecettefodec=this.totalrecettefodec

    deccomptabilite.totalrecetteexp=this.totalrecetteexp

    deccomptabilite.totalht219=this.totalht219
    deccomptabilite.totaltva219=this.totaltva219
    deccomptabilite.totaldt219=this.totaldt219
    deccomptabilite.totalttc219=this.totalttc219
    deccomptabilite.totalrecette19=this.totalrecette19
    deccomptabilite.totalht213=this.totalht213
          deccomptabilite.totaltva213=this.totaltva213
          deccomptabilite.totaldt213=this.totaldt213
          deccomptabilite.totalttc213=this.totalttc213
          deccomptabilite.totalrecette13=this.totalrecette13
    deccomptabilite.totalht3=this.totalht3
    deccomptabilite.totalht3devise=this.totalht3devise
    deccomptabilite.totaltva3=this.totaltva3
    deccomptabilite.totaldt3=this.totaldt3
    deccomptabilite.totalttc3=this.totalttc3
    deccomptabilite.totaldebit=this.totaldebit
    deccomptabilite.totalcredit=this.totalcredit
    deccomptabilite.totalsoldemois=this.totalsoldemois
    deccomptabilite.totalsalairebrut=this.totalsalairebrut
    deccomptabilite.totalcnss=this.totalcnss
    deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
    deccomptabilite.totalretenueimpot=this.totalretenueimpot
    deccomptabilite.totalavancepret=this.totalavancepret
    deccomptabilite.totalsalairenet=this.totalsalairenet
    deccomptabilite.source=''
    deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
    deccomptabilite.contientnumero=this.contientnumero
    deccomptabilite.contientnumeroother=this.contientnumeroother
    deccomptabilite.autre0=[]
                  deccomptabilite.autre1=[]
                  deccomptabilite.autre2=[]
                  deccomptabilite.autre3=[]
                  deccomptabilite.autre4=[]
                  deccomptabilite.autre5=[]
                  deccomptabilite.autre6=[]
                  deccomptabilite.autre8=[]
    deccomptabilite.autre9=[]
    deccomptabilite.autre11=[]
                  deccomptabilite.autre2bis=[]
    
                  if(this.option1Value==''||this.option2Value=='')
                  {
                    return (
                      Swal.fire({
                      title: 'veuillez indiquer le mois et l\'année de la déclaration',
                      icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  }
              
                  if (this.option4Value) 
                  {
                    let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
                    for (let i = 0; i < ammounts3.length; i++)
                    {        
              ammounts3.controls[i].get('fournisseur').enable();
              ammounts3.controls[i].get('numerofacture').enable();  
              ammounts3.controls[i].get('contientfiche').enable();    
  
              const item = ammounts3.getRawValue()[i];
              if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
              {
                deccomptabilite.autre3.push({
                  type: '3',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise:item.devise,
                  fournisseur: item.autrefournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                contientfiche:item.contientfiche
              })
              }
              else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
              {
                deccomptabilite.autre3.push({
                  type: '3',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise:item.devise,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.autrenatureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                contientfiche:item.contientfiche
              })
              }
             else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
              {
                deccomptabilite.autre3.push({
                  type: '3',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise:item.devise,
                  fournisseur: item.autrefournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.autrenatureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                contientfiche:item.contientfiche
              })
              }
              else{
                deccomptabilite.autre3.push({
                  type: '3',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise:item.devise,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                contientfiche:item.contientfiche
              })
              }
                    }   
                  }
                  if (this.option14Value) 
                  {
                    let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
                    for (let i = 0; i < ammounts8.length; i++)
                    {        
              ammounts8.controls[i].get('fournisseur').enable();
              ammounts8.controls[i].get('numerofacture').enable();
              ammounts8.controls[i].get('contientfiche').enable();    
              const item = ammounts8.getRawValue()[i];
            
                deccomptabilite.autre8.push({
                  type: '8',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.autrebeneficiaire,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
              
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                numerocheque:item.numerocheque,
                banque:item.banque,
                contientfiche:item.contientfiche
              })
              
              console.log(deccomptabilite.autre8)    
                    }   
                  }
                  if (this.option12Value) 
                  {
                    let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
                    for (let i = 0; i < ammounts11.length; i++)
                    {        
              ammounts11.controls[i].get('fournisseur').enable();
              ammounts11.controls[i].get('numerofacture').enable();
              ammounts11.controls[i].get('contientfiche').enable();    
              const item = ammounts11.getRawValue()[i];
             
             
                deccomptabilite.autre11.push({
                  type: '11',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.autrebeneficiaire,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
              
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
                contientfiche:item.contientfiche
              })
              
              console.log(deccomptabilite.autre11)    
                    }   
                  }
                  if (this.option13Value) 
                  {
                    let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
                    for (let i = 0; i < ammounts9.length; i++)
                    {        
              ammounts9.controls[i].get('fournisseur').enable();
              ammounts9.controls[i].get('numerofacture').enable();
              ammounts9.controls[i].get('contientfiche').enable();    
              const item = ammounts9.getRawValue()[i];
              if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
              {
                deccomptabilite.autre9.push({
                  type: '9',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.beneficiaire,
                  fournisseur: item.autrefournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
              
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
            
                contientfiche:item.contientfiche
              })
              }
             else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
              {
                deccomptabilite.autre9.push({
                  type: '9',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.beneficiaire,
                  date: item.date,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.autrenatureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtdevise:item.montanthtdevise,
                montanthtachat:item.montanthtachat,
              
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
             
                contientfiche:item.contientfiche
              })
              }
             else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
              {
                deccomptabilite.autre9.push({
                  type: '9',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.beneficiaire,
                  fournisseur: item.autrefournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.autrenatureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtdevise:item.montanthtdevise,
                montanthtachat:item.montanthtachat,
              
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
            
                contientfiche:item.contientfiche
              })
              }
              else{
                deccomptabilite.autre9.push({
                  type: '9',
                  numeropiece: item.numeropiece,
                  jour: item.jour,
                  date: item.date,
                  devise: item.devise,
                  beneficiaire: item.beneficiaire,
                  autrebeneficiaire: item.beneficiaire,
                  fournisseur: item.fournisseur,
                  autrefournisseur: item.autrefournisseur,
                  typepiece:item.typepiece,
                  numerofacture:item.numerofacture,
                  natureachat:item.natureachat,
                  autrenatureachat:item.autrenatureachat,
                montantht:item.montantht,
                montanthtachat:item.montanthtachat,
              
                montanthtdevise:item.montanthtdevise,
                montanttva:item.montanttva,
                montantdt:item.montantdt,
                montantttc:item.montantttc,
                reglement:item.reglement,
                ficheUrl:item.ficheUrl,
               
                contientfiche:item.contientfiche
              })
              }
              console.log(deccomptabilite.autre9)    
                    }   
                  }
                    if(this.option3Value)
                    {
                      let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
                      for (let i = 0; i < ammounts0.length; i++)
                     
                      {
                        
                        const item = ammounts0.getRawValue()[i];
                        if( this.user.activite=='Syndic des copropriétaires')
                        {
                          if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                          {
                            return (
                              Swal.fire({
                              title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                                icon: 'error',
                              confirmButtonColor: '#3085d6',
                            }).then((result) => {this.loading=false,event.target.disabled=false
              
                            }).catch(() => {
                              Swal.fire('opération non aboutie!')
                            }))
                          }
                        }
                        if (item.client==='Autre')
                        {
                          deccomptabilite.autre0.push({
                            type:'1',
                            numeropiece: item.numeropiece,
                            jour: item.jour,
                            date: item.date,
                            typepiece:item.typepiece,
                            devise: item.devise,
                            montanthtexpdevise:item.montanthtexpdevise,
                            numeronote:item.numeronote,
                            montantht:item.montantht,
                            montantht19:item.montantht19,
                            montantht13:item.montantht13,
                    
                            montanthtexo:item.montanthtexo,
                            montanthtsus:item.montanthtsus,
                    
                            montanthttpe:item.montanthttpe,
                    
                            montanthtfodec:item.montanthtfodec,
                    
                            montanthtexp:item.montanthtexp,
                            montanttva:item.montanttva,
                            montanttva19:item.montanttva19,
                            montanttva13:item.montanttva13,
                    
                            montanttvaexo:item.montanttvaexo,
                            montanttvasus:item.montanttvasus,
                    
                            montanttvafodec:item.montanttvafodec,
                    
                            montanttvatpe:item.montanttvatpe,
                    
                            montanttvaexp:item.montanttvaexp,
                            montantdt:item.montantdt,
                            montantttc:item.montantttc,
                            montantttc19:item.montantttc19,
                            montantttc13:item.montantttc13,
                    
                            montantttcexo:item.montantttcexo,
                            montantttcsus:item.montantttcsus,
                            montantttctpe:item.montantttctpe,
                            montantttcfodec:item.montantttcfodec,
                    
                            montantttcexp:item.montantttcexp,
                            client:item.autreclient,
                            autreclient:item.autreclient,
                            objet:item.objet,
                            autreobjet:item.autreobjet,
                            annule:item.annule,
                        
                            nature:item.nature,
                            autrenature:item.autrenature,
                            banque:item.banque,
                            autrebanque:item.autrebanque,
                            numerocompte: item.numerocompte,
                            autrenumerocompte: item.autrenumerocompte,
                            echeance:item.echeance,
                            datevirement:item.datevirement,
                          })
                        }
                        else
                        {
                          deccomptabilite.autre0.push({
                            type:'1',
                            numeropiece: item.numeropiece,
                            jour: item.jour,
                            date: item.date,
                            typepiece:item.typepiece,
                            devise: item.devise,
                            montanthtexpdevise:item.montanthtexpdevise,
                            numeronote:item.numeronote,
                            montantht:item.montantht,
                            montantht19:item.montantht19,
                            montantht13:item.montantht13,
                    
                            montanthtexo:item.montanthtexo,
                            montanthtsus:item.montanthtsus,
                            montanthtfodec:item.montanthtfodec,
                            montanthttpe:item.montanthttpe,
                    
                            montanthtexp:item.montanthtexp,
                            montanttva:item.montanttva,
                            montanttva19:item.montanttva19,
                            montanttva13:item.montanttva13,
                    
                            montanttvaexo:item.montanttvaexo,
                            montanttvasus:item.montanttvasus,
                            montanttvatpe:item.montanttvatpe,
                            montanttvafodec:item.montanttvafodec,
                    
                            montanttvaexp:item.montanttvaexp,
                            montantdt:item.montantdt,
                            montantttc:item.montantttc,
                            montantttc19:item.montantttc19,
                            montantttc13:item.montantttc13,
                            montantttcexo:item.montantttcexo,
                            montantttcsus:item.montantttcsus,
                            montantttcfodec:item.montantttcfodec,
                            montantttctpe:item.montantttctpe,
                    
                            montantttcexp:item.montantttcexp,
                            client:item.client,
                            autreclient:item.autreclient,
                            objet:item.objet,
                            autreobjet:item.autreobjet,
                            annule:item.annule,
                        
                            nature:item.nature,
                            autrenature:item.autrenature,
                            banque:item.banque,
                            autrebanque:item.autrebanque,
                            numerocompte: item.numerocompte,
                            autrenumerocompte: item.autrenumerocompte,
                            echeance:item.echeance,
                            datevirement:item.datevirement,
                          })
                        }
               
               
                      } 
                      let ammounts = this.editionnoteform.get('ammounts') as FormArray;
                      for (let i = 0; i < ammounts.length; i++)
                     
                      {
                        
                        const item = ammounts.getRawValue()[i];
                         //@ts-ignore
                                       if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))

                         {
                          if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
                          {
                            return (
                              Swal.fire({
                              title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                              icon: 'error',
                              confirmButtonColor: '#3085d6',
                            }).then((result) => {this.loading=false,event.target.disabled=false
                
                            }).catch(() => {
                              Swal.fire('opération non aboutie!')
                            }))
                          }
                         }
                      
                         if (item.client==='Autre')
                         {
                           deccomptabilite.autre1.push({
                             type:'1',
                             numeropiece: item.numeropiece,
                             jour: item.jour,
                             date: item.date,
                             typepiece:item.typepiece,
                             devise: item.devise,
                             montanthtexpdevise:item.montanthtexpdevise,
                             numeronote:item.numeronote,
                             montantht:item.montantht,
                             montantht19:item.montantht19,
                             montantht13:item.montantht13,
                     
                             montanthtexo:item.montanthtexo,
                             montanthtsus:item.montanthtsus,
                             montanthttpe:item.montanthttpe,
                             montanthtfodec:item.montanthtfodec,
                     
                             montanthtexp:item.montanthtexp,
                             montanttva:item.montanttva,
                             montanttva19:item.montanttva19,
                             montanttva13:item.montanttva13,
                     
                             montanttvaexo:item.montanttvaexo,
                             montanttvasus:item.montanttvasus,
                             montanttvafodec:item.montanttvafodec,
                             montanttvatpe:item.montanttvatpe,
                     
                             montanttvaexp:item.montanttvaexp,
                             montantdt:item.montantdt,
                             montantttc:item.montantttc,
                             montantttc19:item.montantttc19,
                             montantttc13:item.montantttc13,
                             montantttcexo:item.montantttcexo,
                             montantttcsus:item.montantttcsus,
                             montantttctpe:item.montantttctpe,
                             montantttcfodec:item.montantttcfodec,
                     
                             montantttcexp:item.montantttcexp,
                             client:item.autreclient,
                             autreclient:item.autreclient,
                             objet:item.objet,
                             autreobjet:item.autreobjet,
                             annule:item.annule,
                         
                             nature:item.nature,
                             autrenature:item.autrenature,
                             banque:item.banque,
                             autrebanque:item.autrebanque,
                             numerocompte: item.numerocompte,
                             autrenumerocompte: item.autrenumerocompte,
                             echeance:item.echeance,
                             datevirement:item.datevirement,
                         
                           })
                         }
                         else
                         {
                           deccomptabilite.autre1.push({
                             type:'1',
                             numeropiece: item.numeropiece,
                             jour: item.jour,
                             date: item.date,
                             typepiece:item.typepiece,
                             devise: item.devise,
                             montanthtexpdevise:item.montanthtexpdevise,
                             numeronote:item.numeronote,
                             montantht:item.montantht,
                             montantht19:item.montantht19,
                             montantht13:item.montantht13,
                     
                             montanthtexo:item.montanthtexo,
                             montanthtsus:item.montanthtsus,
                             montanthtfodec:item.montanthtfodec,
                             montanthttpe:item.montanthttpe,
                     
                             montanthtexp:item.montanthtexp,
                             montanttva:item.montanttva,
                             montanttva19:item.montanttva19,
                             montanttva13:item.montanttva13,
                     
                             montanttvaexo:item.montanttvaexo,
                             montanttvasus:item.montanttvasus,
                             montanttvatpe:item.montanttvatpe,
                             montanttvafodec:item.montanttvafodec,
                     
                             montanttvaexp:item.montanttvaexp,
                             montantdt:item.montantdt,
                             montantttc:item.montantttc,
                             montantttc19:item.montantttc19,
                             montantttc13:item.montantttc13,
                             montantttcexo:item.montantttcexo,
                             montantttcsus:item.montantttcsus,
                             montantttcfodec:item.montantttcfodec,
                             montantttctpe:item.montantttctpe,
                     
                             montantttcexp:item.montantttcexp,
                             client:item.client,
                             autreclient:item.autreclient,
                             objet:item.objet,
                             autreobjet:item.autreobjet,
                             annule:item.annule,
                         
                             nature:item.nature,
                             autrenature:item.autrenature,
                             banque:item.banque,
                             autrebanque:item.autrebanque,
                             numerocompte: item.numerocompte,
                             autrenumerocompte: item.autrenumerocompte,
                             echeance:item.echeance,
                             datevirement:item.datevirement,
                           })
                         }
               
              console.log(ammounts0.getRawValue()) 
              console.log(ammounts.getRawValue()) 
              
                      } 
        let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
        for (let i = 0; i < ammounts2.length; i+=1)
        {
          const item = ammounts2.getRawValue()[i];
          deccomptabilite.autre2.push({
            type:'2',
            numeropiece: item.numeropiece,
            jour: item.jour,
            date: item.date,
            devise: item.devise,
            recetteexpdevise:item.recetteexpdevise,
            recette:item.recette,
            recette19:item.recette19,
            recette13:item.recette13,
    
            recetteexo:item.recetteexo,
            recettesus:item.recettesus,
            recettetpe:item.recettetpe,
            recettefodec:item.recettefodec,
    
            recetteexp:item.recetteexp,
            montantht:item.montantht,
            montantht19:item.montantht19,
            montantht13:item.montantht13,
    
            montanthtexo:item.montanthtexo,
            montanthtsus:item.montanthtsus,
            montanthtfodec:item.montanthtfodec,
            montanthttpe:item.montanthttpe,
    
            montanthtexp:item.montanthtexp,
            montanttva:item.montanttva,
            montanttva19:item.montanttva19,
            montanttva13:item.montanttva13,
    
            montanttvaexo:item.montanttvaexo,
            montanttvasus:item.montanttvasus,
            montanttvatpe:item.montanttvatpe,
            montanttvafodec:item.montanttvafodec,
    
            montanttvaexp:item.montanttvaexp,
            montantdt:item.montantdt,
            montantttc:item.montantttc,
            montantttc19:item.montantttc19,
            montantttc13:item.montantttc13,
    
            montantttcexo:item.montantttcexo,
            montantttcsus:item.montantttcsus,
            montantttcfodec:item.montantttcfodec,
            montantttctpe:item.montantttctpe,
    
            montantttcexp:item.montantttcexp,
          })
        } 
    
      }
      if (this.option5Value) 
      {
        let employees = this.relevemanuelform.get('employees') as FormArray
        employees.getRawValue().forEach((element,index) => {
          element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
          console.log(element.skills)
          element.skills.length==0&&element.devise!='tnd'?
          deccomptabilite.autre4.push({
            type: '4',
            banque:element.banque,
            numerocompte:element.numerocompte, 
            devise:element.devise,
            soldedebit:element.debit,
            soldecredit:element.credit,
            creditdevise:element.creditdevise,
            soldefinmoisdevise:element.creditdevise,
            soldefinmoisdinar:element.credit,
            mouvements:element.skills
              
    }) 
            :
            element.skills==0&&element.credit!=0&&element.devise=='tnd'?
            deccomptabilite.autre4.push({
              type: '4',
              banque:element.banque,
              numerocompte:element.numerocompte, 
              devise:element.devise,
              soldedebit:element.debit,
              soldecredit:element.credit,
              creditdevise:element.creditdevise,
              soldefinmoisdevise:element.creditdevise,
              soldefinmoisdinar:element.credit,
              mouvements:element.skills
                
      }) 
              :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
              deccomptabilite.autre4.push({
                type: '4',
                banque:element.banque,
                numerocompte:element.numerocompte, 
                devise:element.devise,
                soldedebit:element.debit,
                soldecredit:element.credit,
                creditdevise:element.creditdevise,
                soldefinmoisdevise:element.creditdevise,
                soldefinmoisdinar:element.debit,
                mouvements:element.skills
                  
        }) 
            :
            deccomptabilite.autre4.push({
              type: '4',
              banque:element.banque,
              numerocompte:element.numerocompte, 
              devise:element.devise,
              soldedebit:element.debit,
              soldecredit:element.credit,
              creditdevise:element.creditdevise,
              soldefinmoisdevise:element.soldefinmoisdevise,
              soldefinmoisdinar:element.soldefinmoisdinar,
              mouvements:element.skills
                
      }) 
    console.log(deccomptabilite.autre4)
        }); 
        let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
        for (let i = 0; i < ammounts5.length; i++)
        {
          const item = ammounts5.getRawValue()[i];
  deccomptabilite.autre5.push({
          type: '5',
          annee: item.annee,
          mois: item.mois,
          ficheUrl:item.ficheUrl,
          contientfiche:item.contientfiche
  
  })
        } 
      }
                  if (this.option6Value) 
                  {
              
              
                    let typessalaire = this.salaireform.get('typessalaire') as FormArray;
                    typessalaire.getRawValue().forEach((element,index) => {
                      element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
                      deccomptabilite.autre6.push({
                        type: '6',
                        typesalaire:element.typesalaire,
                        salarie:element.ammounts6,         
                }) 
              }
                    )
                  }
                  deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
                  deccomptabilite.affecte=this.deccomptabilite.affecte
                  this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
                  //@ts-ignore
                  ({
                    statutclient:'déposé pour le compte du client',
                    motifclient:'',
                    datefin:data,
                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                  }):
                  deccomptabilite.statutcollab.push
                        //@ts-ignore
                        ({
                          statutclient:'déposé par le client',
                          motifclient:'',
                          datefin:data,
                          duree:'',     
                        })
                  deccomptabilite.affecte=''
                 // deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
                  deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
                  deccomptabilite.autre2.map((e, i) => {
          //@ts-ignore
           e.numeropiece = i+1 });
       deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
    item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
    deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));    
    deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
        deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
        deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
        
                    this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
                        (data:any) => {
                          this.tokenStorage.saved=true;
                          this.loading = false;
                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'fichier comptable modifiée avec succès',
                            showConfirmButton: false,
                            timer: 3000
                          });
                          this.router.navigate(['user-board']);
                        },
                        (error) => {
                          this.loading = false;
                          
                          window.scrollTo(0, 0);   
                        }
                      )
        //modify rattached decfiscmens
        
        if(this.option1Value==''||this.option2Value=='')
        {
          return (
            Swal.fire({
            title: 'veuillez indiquer le mois et l\'année de la déclaration',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          }).then((result) => {this.loading=false
          }).catch(() => {
            Swal.fire('opération non aboutie!')
          }))
        }


        decfiscmens[0].impottype1.type='Retenue à la source'
        this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype3.type='TFP':''
        decfiscmens[0].impottype4.type='FOPROLOS'
        this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype2.type='TVA':''
        this.user.activite!='Syndic des copropriétaires'? decfiscmens[0].impottype6.type='TCL':''
        if (this.realsalairebrut6!=0) 
        {
        decfiscmens[0].impottype1.traitementetsalaire.salairebrut=this.realsalairebrut6.toString()
        decfiscmens[0].impottype1.traitementetsalaire.salaireimposable=this.realsalaireimposable6.toString()
        decfiscmens[0].impottype1.traitementetsalaire.retenuealasource=this.realretenue6.toString()
        }
        if(this.realsalairebrut6!=0)
        
        {
        decfiscmens[0].impottype3.tfppayer=this.tfpapayer.toString()
        decfiscmens[0].impottype3.basetfp=this.realsalairebrut6.toString()
        decfiscmens[0].impottype3.montanttfpmois=this.tfpapayer.toString()
        }
        if(this.realsalairebrut6!=0)
        {
        
        decfiscmens[0].impottype4.montantfoprolos=this.foprolosapayer.toString()
        decfiscmens[0].impottype4.basefoprolos=this.realsalairebrut6.toString()
        
        }
        if(!this.option6Value)
        {
          decfiscmens[0].impottype1={ type: 'Retenue à la source', traitementetsalaire: { salairebrut:'', salaireimposable: '', retenuealasource: '',contributionsociale: '', }, 
          location1: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location2: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
          location3: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location4: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
           honoraire1: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, honoraire2: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
           honoraire3: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10001: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
           montant10002: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10003: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, 
          montant10004: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, autre: []}
        }
        if(!this.option4Value)
        {
          decfiscmens[0].impottype2.tvarecuperableautreachat.type=''
          decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxht=''
          decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxtva=''
        }
        if(!this.option3Value)
        {
          decfiscmens[0].impottype2.tvacollecter.type=''
          decfiscmens[0].impottype2.tvacollecter.chiffreaffaireht=''
          decfiscmens[0].impottype2.tvacollecter19.chiffreaffaireht=''
          
          decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.chiffreaffaireht='':''
          decfiscmens[0].impottype2.tvacollecter.tvaammount=''
          decfiscmens[0].impottype2.tvacollecter19.tvaammount=''
          decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.tvaammount='':''
  
          decfiscmens[0].impottype2.tvacollecter.ammountttc=''
          decfiscmens[0].impottype2.tvacollecter19.ammountttc=''
          decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.ammountttc='':''
  
        }
        if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
        {
        decfiscmens[0].impottype2.tvacollecter.type='TVA collecté'
        decfiscmens[0].impottype2.tvacollecter.chiffreaffaireht=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec))*1000)/1000).toString()
        decfiscmens[0].impottype2.tvacollecter19.chiffreaffaireht=(Math.trunc(((this.realht119+this.realht219))*1000)/1000).toString()
        
        decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.chiffreaffaireht=(Math.trunc(((this.realht113+this.realht213))*1000)/1000).toString():''
        decfiscmens[0].impottype2.tvacollecter.tvaammount=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva)*1000)/1000).toString()
        decfiscmens[0].impottype2.tvacollecter19.tvaammount=(Math.trunc(((this.realht119+this.realht219)*0.19)*1000)/1000).toString()
        decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.tvaammount=(Math.trunc(((this.realht113+this.realht213)*0.13)*1000)/1000).toString():''

        decfiscmens[0].impottype2.tvacollecter.ammountttc=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva)))*1000)/1000).toString()
        decfiscmens[0].impottype2.tvacollecter19.ammountttc=(Math.trunc((((this.realht119+this.realht219)+((this.realht119+this.realht219)*0.19)))*1000)/1000).toString()
        decfiscmens[0].impottype2.tvacollecter13?decfiscmens[0].impottype2.tvacollecter13.ammountttc=(Math.trunc((((this.realht113+this.realht213)+((this.realht113+this.realht213)*0.13)))*1000)/1000).toString():''

      }
        if (this.realht3>0)
        
        {
        decfiscmens[0].impottype2.tvarecuperableautreachat.type='TVA récupérable pour les autres achats'
        decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxht=this.realht3.toString()
        decfiscmens[0].impottype2.tvarecuperableautreachat.achatlocauxtva=this.realtva3.toString()
        }
        
    
        if(this.realdt1>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt119>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt113>0&&this.user.activite!='Syndic des copropriétaires')
        {     
          decfiscmens[0].impottype5.type='Droit de timbre'
          decfiscmens[0].impottype5.nombrenotehonoraire=(Math.floor((this.realdt1+this.realdt119+this.realdt113)/this.tauxdt)).toString();
          decfiscmens[0].impottype5.totaldroittimbre=(Math.round((this.realdt1+this.realdt119+this.realdt113)*1000)/1000).toString()
        }
        if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
        {
          decfiscmens[0].impottype6.chiffreaffairettc=(Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva+(this.realht119+this.realht219)*0.19+(this.realht113+this.realht213)*0.13)))*1000)/1000).toString()
          decfiscmens[0].impottype6.tclpayer=(Math.trunc((((Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)*+this.tauxtva+(this.realht119+this.realht219)*+0.19+(this.realht113+this.realht213)*+0.13)))*1000)/1000))*0.002)*1000)/1000).toString()
        }
        if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
        this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
        ||this.activite=='Sage-femmes')
        {
          decfiscmens[0].impottype7.type='FSSP'
          if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
          {
          decfiscmens[0].impottype7.chiffreaffaireht=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213))*1000)/1000).toString()
          decfiscmens[0].impottype7.montantcontribution=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2-this.realht2tpe-this.realht2fodec)+this.realht119+this.realht219+this.realht113+this.realht213)*0.01)*1000)/1000).toString()
        } 
        }
        this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?decfiscmens[0].statutcollab.push
                  //@ts-ignore
                  ({
                    statutclient:'déposé pour le compte du client',
                    motifclient:'',
                    datefin:data,
                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                  }):
        decfiscmens[0].statutcollab.push
        //@ts-ignore
        ({
          statutclient:'déposé par le client',
          motifclient:'',
          datefin:data,
          duree:'',     
        })
        decfiscmens[0].affecte=''
        decfiscmens[0].source=''
        console.log(decfiscmens[0])

       this.DecfiscmensService.completedecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
        (data:any) => {
        this.tokenStorage.saved=true;
        this.loading = false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'fichier comptable modifiée avec succès',
          showConfirmButton: false,
          timer: 6000 
        });
        //this.router.navigate(['modify-decfiscmens/'+data.data._id])
        },
        (error) => {
        this.loading = false;
        
        }
        ) 
                }
              )
               
            }
            else
            {
//ask about generating rattached decfiscmens
!this.forassiette?  Swal.fire({
  title: 'Nous avons remarqué que vous n\'avez pas générer une déclaration fiscale!veuillez choisir entre les options suivantes',
  icon: 'info',
  showDenyButton: this.user.activite!='Syndic des copropriétaires'||this.user.matriculefiscale? true:false,
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#555',
  confirmButtonText: 'ne pas générer une déclaration fiscale',
  cancelButtonText: 'Annuler',
  denyButtonText: 'générer la déclaration fiscale rattachée',
  
  }).then((result) => {
  if (result.isConfirmed) {
    this.userservice.getUserById(this.deccomptabilite.userId).then(
      (user:User)=>
      {
        deccomptabilite.userId = user._id;
        deccomptabilite.activite=user.activite;
        deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
        deccomptabilite.sousactivite=user.sousactivite;
        deccomptabilite.codepostal=user.codepostal;
        deccomptabilite.adresse=user.adresseactivite
        deccomptabilite.firstname=user.firstname
        deccomptabilite.lastname=user.lastname
        deccomptabilite.raisonsociale=user.raisonsociale
        deccomptabilite.codegenre=user.codegenre
        deccomptabilite.codetva=user.codetva
        deccomptabilite.matriculefiscale=user.matriculefiscale
        deccomptabilite.registrecommerce=user.registrecommerce
        deccomptabilite.datearretactivite=user.datearretactivite
        deccomptabilite.totalht=this.totalht
deccomptabilite.totaltva=this.totaltva
deccomptabilite.totalhtexo=this.totalhtexo
deccomptabilite.totaltvaexo=this.totaltvaexo
deccomptabilite.totalhtsus=this.totalhtsus
deccomptabilite.totaltvasus=this.totaltvasus

deccomptabilite.totalhtfodec=this.totalhtfodec
deccomptabilite.totaltvafodec=this.totaltvafodec

deccomptabilite.totalhttpe=this.totalhttpe
deccomptabilite.totaltvatpe=this.totaltvatpe
deccomptabilite.totalhtexp=this.totalhtexp
deccomptabilite.totaltvaexp=this.totaltvaexp
deccomptabilite.totaldt=this.totaldt
deccomptabilite.totalttc=this.totalttc
deccomptabilite.totalttcexo=this.totalttcexo
deccomptabilite.totalttcsus=this.totalttcsus

deccomptabilite.totalttctpe=this.totalttctpe


deccomptabilite.totalttcfodec=this.totalttcfodec

deccomptabilite.totalttcexp=this.totalttcexp
deccomptabilite.totalht19=this.totalht19
deccomptabilite.totaltva19=this.totaltva19
deccomptabilite.totaldt19=this.totaldt19
deccomptabilite.totalttc19=this.totalttc19
deccomptabilite.totalht019=this.totalht019
deccomptabilite.totaltva019=this.totaltva019
deccomptabilite.totaldt019=this.totaldt019
deccomptabilite.totalttc019=this.totalttc019
deccomptabilite.totalht13=this.totalht13
deccomptabilite.totaltva13=this.totaltva13
deccomptabilite.totaldt13=this.totaldt13
deccomptabilite.totalttc13=this.totalttc13
deccomptabilite.totalht013=this.totalht013
deccomptabilite.totaltva013=this.totaltva013
deccomptabilite.totaldt013=this.totaldt013
deccomptabilite.totalttc013=this.totalttc013
deccomptabilite.totalht2=this.totalht2
deccomptabilite.totaltva2=this.totaltva2

deccomptabilite.totalht2exo=this.totalht2exo
deccomptabilite.totaltva2exo=this.totaltva2exo
deccomptabilite.totalht2sus=this.totalht2sus
deccomptabilite.totaltva2sus=this.totaltva2sus

deccomptabilite.totalht2fodec=this.totalht2fodec
deccomptabilite.totaltva2fodec=this.totaltva2fodec

deccomptabilite.totalht2tpe=this.totalht2tpe
deccomptabilite.totaltva2tpe=this.totaltva2tpe
deccomptabilite.totalht2exp=this.totalht2exp
deccomptabilite.totaltva2exp=this.totaltva2exp
deccomptabilite.totalttc2exo=this.totalttc2exo
deccomptabilite.totalttc2exp=this.totalttc2exp
deccomptabilite.totalttc2sus=this.totalttc2sus

deccomptabilite.totalttc2tpe=this.totalttc2tpe

deccomptabilite.totalttc2fodec=this.totalttc2fodec




deccomptabilite.totaldt2=this.totaldt2
deccomptabilite.totalttc2=this.totalttc2
deccomptabilite.totalrecette=this.totalrecette
deccomptabilite.totalrecetteexo=this.totalrecetteexo
deccomptabilite.totalrecettesus=this.totalrecettesus

deccomptabilite.totalrecettefodec=this.totalrecettefodec

deccomptabilite.totalrecettetpe=this.totalrecettetpe

deccomptabilite.totalrecetteexp=this.totalrecetteexp

deccomptabilite.totalht219=this.totalht219
deccomptabilite.totaltva219=this.totaltva219
deccomptabilite.totaldt219=this.totaldt219
deccomptabilite.totalttc219=this.totalttc219
deccomptabilite.totalrecette19=this.totalrecette19
deccomptabilite.totalht213=this.totalht213
          deccomptabilite.totaltva213=this.totaltva213
          deccomptabilite.totaldt213=this.totaldt213
          deccomptabilite.totalttc213=this.totalttc213
          deccomptabilite.totalrecette13=this.totalrecette13
deccomptabilite.totalht3=this.totalht3
deccomptabilite.totalht3devise=this.totalht3devise
deccomptabilite.totaltva3=this.totaltva3
deccomptabilite.totaldt3=this.totaldt3
deccomptabilite.totalttc3=this.totalttc3
deccomptabilite.totaldebit=this.totaldebit
deccomptabilite.totalcredit=this.totalcredit
deccomptabilite.totalsoldemois=this.totalsoldemois
deccomptabilite.totalsalairebrut=this.totalsalairebrut
deccomptabilite.totalcnss=this.totalcnss
deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
deccomptabilite.totalretenueimpot=this.totalretenueimpot
deccomptabilite.totalavancepret=this.totalavancepret
deccomptabilite.totalsalairenet=this.totalsalairenet
deccomptabilite.source=''
deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
deccomptabilite.contientnumero=this.contientnumero
deccomptabilite.contientnumeroother=this.contientnumeroother
        deccomptabilite.autre0=[]
        deccomptabilite.autre1=[]
        deccomptabilite.autre2=[]
        deccomptabilite.autre3=[]
        deccomptabilite.autre4=[]
        deccomptabilite.autre5=[]
        deccomptabilite.autre6=[]
        deccomptabilite.autre8=[]
deccomptabilite.autre9=[]
deccomptabilite.autre11=[]
        deccomptabilite.autre2bis=[]

        if(this.option1Value==''||this.option2Value=='')
        {
          return (
            Swal.fire({
            title: 'veuillez indiquer le mois et l\'année de la déclaration',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          }).then((result) => {this.loading=false
          }).catch(() => {
            Swal.fire('opération non aboutie!')
          }))
        }
    
        if (this.option4Value) 
        {
          let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
          for (let i = 0; i < ammounts3.length; i++)
          {        
    ammounts3.controls[i].get('fournisseur').enable();
    ammounts3.controls[i].get('numerofacture').enable();   
    ammounts3.controls[i].get('contientfiche').enable();    

    const item = ammounts3.getRawValue()[i];
    if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
    else{
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
          }   
        }
        if (this.option14Value) 
        {
          let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
          for (let i = 0; i < ammounts8.length; i++)
          {        
    ammounts8.controls[i].get('fournisseur').enable();
    ammounts8.controls[i].get('numerofacture').enable();
    ammounts8.controls[i].get('contientfiche').enable();    
    const item = ammounts8.getRawValue()[i];
   
      deccomptabilite.autre8.push({
        type: '8',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      numerocheque:item.numerocheque,
      banque:item.banque,
      contientfiche:item.contientfiche
    })
    
    console.log(deccomptabilite.autre8)    
          }   
        }
        if (this.option12Value) 
        {
          let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
          for (let i = 0; i < ammounts11.length; i++)
          {        
    ammounts11.controls[i].get('fournisseur').enable();
    ammounts11.controls[i].get('numerofacture').enable();
    ammounts11.controls[i].get('contientfiche').enable();    
    const item = ammounts11.getRawValue()[i];
   
      deccomptabilite.autre11.push({
        type: '11',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    
    console.log(deccomptabilite.autre11)    
          }   
        }
        if (this.option13Value) 
        {
          let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
          for (let i = 0; i < ammounts9.length; i++)
          {        
    ammounts9.controls[i].get('fournisseur').enable();
    ammounts9.controls[i].get('numerofacture').enable();
    ammounts9.controls[i].get('contientfiche').enable();    
    const item = ammounts9.getRawValue()[i];
    if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
  
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.beneficiaire,
        date: item.date,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtdevise:item.montanthtdevise,
      montanthtachat:item.montanthtachat,
    
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
     
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.autrebeneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtdevise:item.montanthtdevise,
      montanthtachat:item.montanthtachat,
    
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
   
      contientfiche:item.contientfiche
    })
    }
    else{
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
    
      contientfiche:item.contientfiche
    })
    }
    console.log(deccomptabilite.autre9)    
          }   
        }
          if(this.option3Value)
          {
            let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
            for (let i = 0; i < ammounts0.length; i++)
           
            {
              
              const item = ammounts0.getRawValue()[i];
              if( this.user.activite=='Syndic des copropriétaires')
              {
                if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                {
                  return (
                    Swal.fire({
                    title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                      icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {this.loading=false,event.target.disabled=false
    
                  }).catch(() => {
                    Swal.fire('opération non aboutie!')
                  }))
                }
              }
              if (item.client==='Autre')
    {
      deccomptabilite.autre0.push({
        type:'1',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        typepiece:item.typepiece,
        devise: item.devise,
        montanthtexpdevise:item.montanthtexpdevise,
        numeronote:item.numeronote,
        montantht:item.montantht,
        montantht19:item.montantht19,
        montantht13:item.montantht13,

        montanthtexo:item.montanthtexo,
        montanthtsus:item.montanthtsus,

        montanthttpe:item.montanthttpe,

        montanthtfodec:item.montanthtfodec,

        montanthtexp:item.montanthtexp,
        montanttva:item.montanttva,
        montanttva19:item.montanttva19,
        montanttva13:item.montanttva13,

        montanttvaexo:item.montanttvaexo,
        montanttvasus:item.montanttvasus,

        montanttvafodec:item.montanttvafodec,

        montanttvatpe:item.montanttvatpe,

        montanttvaexp:item.montanttvaexp,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        montantttc19:item.montantttc19,
        montantttc13:item.montantttc13,

        montantttcexo:item.montantttcexo,
        montantttcsus:item.montantttcsus,
        montantttctpe:item.montantttctpe,
        montantttcfodec:item.montantttcfodec,

        montantttcexp:item.montantttcexp,
        client:item.autreclient,
        autreclient:item.autreclient,
        objet:item.objet,
        autreobjet:item.autreobjet,
        annule:item.annule,
    
        nature:item.nature,
        autrenature:item.autrenature,
        banque:item.banque,
        autrebanque:item.autrebanque,
        numerocompte: item.numerocompte,
        autrenumerocompte: item.autrenumerocompte,
        echeance:item.echeance,
        datevirement:item.datevirement,
      })
    }
    else
    {
      deccomptabilite.autre0.push({
        type:'1',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        typepiece:item.typepiece,
        devise: item.devise,
        montanthtexpdevise:item.montanthtexpdevise,
        numeronote:item.numeronote,
        montantht:item.montantht,
        montantht19:item.montantht19,
        montantht13:item.montantht13,

        montanthtexo:item.montanthtexo,
        montanthtsus:item.montanthtsus,
        montanthtfodec:item.montanthtfodec,
        montanthttpe:item.montanthttpe,

        montanthtexp:item.montanthtexp,
        montanttva:item.montanttva,
        montanttva19:item.montanttva19,
        montanttva13:item.montanttva13,

        montanttvaexo:item.montanttvaexo,
        montanttvasus:item.montanttvasus,
        montanttvatpe:item.montanttvatpe,
        montanttvafodec:item.montanttvafodec,

        montanttvaexp:item.montanttvaexp,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        montantttc19:item.montantttc19,
        montantttc13:item.montantttc13,
        montantttcexo:item.montantttcexo,
        montantttcsus:item.montantttcsus,
        montantttcfodec:item.montantttcfodec,
        montantttctpe:item.montantttctpe,

        montantttcexp:item.montantttcexp,
        client:item.client,
        autreclient:item.autreclient,
        objet:item.objet,
        autreobjet:item.autreobjet,
        annule:item.annule,
    
        nature:item.nature,
        autrenature:item.autrenature,
        banque:item.banque,
        autrebanque:item.autrebanque,
        numerocompte: item.numerocompte,
        autrenumerocompte: item.autrenumerocompte,
        echeance:item.echeance,
        datevirement:item.datevirement,
      })
    }
     
     
            } 
            let ammounts = this.editionnoteform.get('ammounts') as FormArray;
            for (let i = 0; i < ammounts.length; i++)
           
            {
              
              const item = ammounts.getRawValue()[i];
                //@ts-ignore
                              if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))

                {
                  if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
                  {
                    return (
                      Swal.fire({
                      title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                      icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false,event.target.disabled=false
        
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  }
                }

                if (item.client==='Autre')
                {
                  deccomptabilite.autre1.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthttpe:item.montanthttpe,
                    montanthtfodec:item.montanthtfodec,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvafodec:item.montanttvafodec,
                    montanttvatpe:item.montanttvatpe,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttctpe:item.montantttctpe,
                    montantttcfodec:item.montantttcfodec,
            
                    montantttcexp:item.montantttcexp,
                    client:item.autreclient,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                
                  })
                }
                else
                {
                  deccomptabilite.autre1.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthtfodec:item.montanthtfodec,
                    montanthttpe:item.montanthttpe,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvatpe:item.montanttvatpe,
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttcfodec:item.montantttcfodec,
                    montantttctpe:item.montantttctpe,
            
                    montantttcexp:item.montantttcexp,
                    client:item.client,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
     
    console.log(ammounts0.getRawValue()) 
    console.log(ammounts.getRawValue()) 
    
            } 
let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
for (let i = 0; i < ammounts2.length; i+=1)
{
const item = ammounts2.getRawValue()[i];
deccomptabilite.autre2.push({
  type:'2',
  numeropiece: item.numeropiece,
  jour: item.jour,
  date: item.date,
  devise: item.devise,
  recetteexpdevise:item.recetteexpdevise,
  recette:item.recette,
  recette19:item.recette19,
  recette13:item.recette13,

  recetteexo:item.recetteexo,
  recettesus:item.recettesus,
  recettetpe:item.recettetpe,
  recettefodec:item.recettefodec,

  recetteexp:item.recetteexp,
  montantht:item.montantht,
  montantht19:item.montantht19,
  montantht13:item.montantht13,

  montanthtexo:item.montanthtexo,
  montanthtsus:item.montanthtsus,
  montanthtfodec:item.montanthtfodec,
  montanthttpe:item.montanthttpe,

  montanthtexp:item.montanthtexp,
  montanttva:item.montanttva,
  montanttva19:item.montanttva19,
  montanttva13:item.montanttva13,

  montanttvaexo:item.montanttvaexo,
  montanttvasus:item.montanttvasus,
  montanttvatpe:item.montanttvatpe,
  montanttvafodec:item.montanttvafodec,

  montanttvaexp:item.montanttvaexp,
  montantdt:item.montantdt,
  montantttc:item.montantttc,
  montantttc19:item.montantttc19,
  montantttc13:item.montantttc13,

  montantttcexo:item.montantttcexo,
  montantttcsus:item.montantttcsus,
  montantttcfodec:item.montantttcfodec,
  montantttctpe:item.montantttctpe,

  montantttcexp:item.montantttcexp,
})
} 

}
if (this.option5Value) 
{
let employees = this.relevemanuelform.get('employees') as FormArray
employees.getRawValue().forEach((element,index) => {
element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
console.log(element.skills)
element.skills.length==0&&element.devise!='tnd'?
deccomptabilite.autre4.push({
  type: '4',
  banque:element.banque,
  numerocompte:element.numerocompte, 
  devise:element.devise,
  soldedebit:element.debit,
  soldecredit:element.credit,
  creditdevise:element.creditdevise,
  soldefinmoisdevise:element.creditdevise,
  soldefinmoisdinar:element.credit,
  mouvements:element.skills
    
}) 
  :
  element.skills==0&&element.credit!=0&&element.devise=='tnd'?
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.creditdevise,
    soldefinmoisdinar:element.credit,
    mouvements:element.skills
      
}) 
    :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.creditdevise,
      soldefinmoisdinar:element.debit,
      mouvements:element.skills
        
}) 
  :
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.soldefinmoisdevise,
    soldefinmoisdinar:element.soldefinmoisdinar,
    mouvements:element.skills
      
}) 
console.log(deccomptabilite.autre4)
}); 
let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
for (let i = 0; i < ammounts5.length; i++)
{
const item = ammounts5.getRawValue()[i];
deccomptabilite.autre5.push({
type: '5',
annee: item.annee,
mois: item.mois,
ficheUrl:item.ficheUrl,
contientfiche:item.contientfiche

})
} 
}
        if (this.option6Value) 
        {
    
    
          let typessalaire = this.salaireform.get('typessalaire') as FormArray;
          typessalaire.getRawValue().forEach((element,index) => {
            element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
            deccomptabilite.autre6.push({
              type: '6',
              typesalaire:element.typesalaire,
              salarie:element.ammounts6,         
      }) 
    }
          )
        }
        deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
        deccomptabilite.affecte=this.deccomptabilite.affecte
        this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
        //@ts-ignore
        ({
          statutclient:'déposé pour le compte du client',
          motifclient:'',
          datefin:data,
           duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
        }):
        deccomptabilite.statutcollab.push
              //@ts-ignore
              ({
                statutclient:'déposé par le client',
                motifclient:'',
                datefin:data,
                duree:'',     
              })
        deccomptabilite.affecte=''
       // deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
        deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
        deccomptabilite.autre2.map((e, i) => {
          //@ts-ignore
           e.numeropiece = i+1 });
       deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));              
deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
        deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
        deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
        this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
              (data:any) => {
                this.tokenStorage.saved=true;
                this.loading = false;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'fichier comptable modifiée avec succès',
                  showConfirmButton: false,
                  timer: 3000
                });
                this.router.navigate(['user-board']);
              },
              (error) => {
                this.loading = false;
                
                window.scrollTo(0, 0);   
              }
            )
      }
    )
  }
  else
  if(result.isDenied)
  {
    this.userservice.getUserById(this.deccomptabilite.userId).then(
      (user:User)=>
      {
        deccomptabilite.userId = user._id;
        deccomptabilite.activite=user.activite;
        deccomptabilite.regimefiscalimpot=user.regimefiscalimpot;
        deccomptabilite.sousactivite=user.sousactivite;
        deccomptabilite.codepostal=user.codepostal;
        deccomptabilite.adresse=user.adresseactivite
        deccomptabilite.firstname=user.firstname
        deccomptabilite.lastname=user.lastname
        deccomptabilite.raisonsociale=user.raisonsociale
        deccomptabilite.codegenre=user.codegenre
        deccomptabilite.codetva=user.codetva
        deccomptabilite.matriculefiscale=user.matriculefiscale
        deccomptabilite.registrecommerce=user.registrecommerce
        deccomptabilite.datearretactivite=user.datearretactivite
        deccomptabilite.totalht=this.totalht
deccomptabilite.totaltva=this.totaltva
deccomptabilite.totalhtexo=this.totalhtexo
deccomptabilite.totaltvaexo=this.totaltvaexo
deccomptabilite.totalhtsus=this.totalhtsus
deccomptabilite.totaltvasus=this.totaltvasus

deccomptabilite.totalhttpe=this.totalhttpe
deccomptabilite.totaltvatpe=this.totaltvatpe

deccomptabilite.totalhtfodec=this.totalhtfodec
deccomptabilite.totaltvafodec=this.totaltvafodec
deccomptabilite.totalhtexp=this.totalhtexp
deccomptabilite.totaltvaexp=this.totaltvaexp
deccomptabilite.totaldt=this.totaldt
deccomptabilite.totalttc=this.totalttc
deccomptabilite.totalttcexo=this.totalttcexo
deccomptabilite.totalttcsus=this.totalttcsus

deccomptabilite.totalttcfodec=this.totalttcfodec


deccomptabilite.totalttctpe=this.totalttctpe

deccomptabilite.totalttcexp=this.totalttcexp
deccomptabilite.totalht19=this.totalht19
deccomptabilite.totaltva19=this.totaltva19
deccomptabilite.totaldt19=this.totaldt19
deccomptabilite.totalttc19=this.totalttc19
deccomptabilite.totalht019=this.totalht019
deccomptabilite.totaltva019=this.totaltva019
deccomptabilite.totaldt019=this.totaldt019
deccomptabilite.totalttc019=this.totalttc019
deccomptabilite.totalht13=this.totalht13
deccomptabilite.totaltva13=this.totaltva13
deccomptabilite.totaldt13=this.totaldt13
deccomptabilite.totalttc13=this.totalttc13
deccomptabilite.totalht013=this.totalht013
deccomptabilite.totaltva013=this.totaltva013
deccomptabilite.totaldt013=this.totaldt013
deccomptabilite.totalttc013=this.totalttc013
deccomptabilite.totalht2=this.totalht2
deccomptabilite.totaltva2=this.totaltva2

deccomptabilite.totalht2exo=this.totalht2exo
deccomptabilite.totaltva2exo=this.totaltva2exo
deccomptabilite.totalht2sus=this.totalht2sus
deccomptabilite.totaltva2sus=this.totaltva2sus

deccomptabilite.totalht2tpe=this.totalht2tpe
deccomptabilite.totaltva2tpe=this.totaltva2tpe

deccomptabilite.totalht2fodec=this.totalht2fodec
deccomptabilite.totaltva2fodec=this.totaltva2fodec
deccomptabilite.totalht2exp=this.totalht2exp
deccomptabilite.totaltva2exp=this.totaltva2exp
deccomptabilite.totalttc2exo=this.totalttc2exo
deccomptabilite.totalttc2exp=this.totalttc2exp
deccomptabilite.totalttc2sus=this.totalttc2sus

deccomptabilite.totalttc2fodec=this.totalttc2fodec

deccomptabilite.totalttc2tpe=this.totalttc2tpe




deccomptabilite.totaldt2=this.totaldt2
deccomptabilite.totalttc2=this.totalttc2
deccomptabilite.totalrecette=this.totalrecette
deccomptabilite.totalrecetteexo=this.totalrecetteexo
deccomptabilite.totalrecettesus=this.totalrecettesus

deccomptabilite.totalrecettetpe=this.totalrecettetpe


deccomptabilite.totalrecettefodec=this.totalrecettefodec

deccomptabilite.totalrecetteexp=this.totalrecetteexp

deccomptabilite.totalht219=this.totalht219
deccomptabilite.totaltva219=this.totaltva219
deccomptabilite.totaldt219=this.totaldt219
deccomptabilite.totalttc219=this.totalttc219
deccomptabilite.totalrecette19=this.totalrecette19
deccomptabilite.totalht213=this.totalht213
          deccomptabilite.totaltva213=this.totaltva213
          deccomptabilite.totaldt213=this.totaldt213
          deccomptabilite.totalttc213=this.totalttc213
          deccomptabilite.totalrecette13=this.totalrecette13
deccomptabilite.totalht3=this.totalht3
deccomptabilite.totalht3devise=this.totalht3devise
deccomptabilite.totaltva3=this.totaltva3
deccomptabilite.totaldt3=this.totaldt3
deccomptabilite.totalttc3=this.totalttc3
deccomptabilite.totaldebit=this.totaldebit
deccomptabilite.totalcredit=this.totalcredit
deccomptabilite.totalsoldemois=this.totalsoldemois
deccomptabilite.totalsalairebrut=this.totalsalairebrut
deccomptabilite.totalcnss=this.totalcnss
deccomptabilite.totalsalaireimposable=this.totalsalaireimposable
deccomptabilite.totalretenueimpot=this.totalretenueimpot
deccomptabilite.totalavancepret=this.totalavancepret
deccomptabilite.totalsalairenet=this.totalsalairenet
deccomptabilite.source=''
deccomptabilite.showbuttonotheretatrecette=this.showbuttonotheretatrecette
deccomptabilite.contientnumero=this.contientnumero
deccomptabilite.contientnumeroother=this.contientnumeroother
        deccomptabilite.autre0=[]
        deccomptabilite.autre1=[]
        deccomptabilite.autre2=[]
        deccomptabilite.autre3=[]
        deccomptabilite.autre4=[]
        deccomptabilite.autre5=[]
        deccomptabilite.autre6=[]
        deccomptabilite.autre8=[]
deccomptabilite.autre9=[]
deccomptabilite.autre11=[]
        deccomptabilite.autre2bis=[]

        if(this.option1Value==''||this.option2Value=='')
        {
          return (
            Swal.fire({
            title: 'veuillez indiquer le mois et l\'année de la déclaration',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          }).then((result) => {this.loading=false
          }).catch(() => {
            Swal.fire('opération non aboutie!')
          }))
        }
    
        if (this.option4Value) 
        {
          let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
          for (let i = 0; i < ammounts3.length; i++)
          {        
    ammounts3.controls[i].get('fournisseur').enable();
    ammounts3.controls[i].get('numerofacture').enable();   
    ammounts3.controls[i].get('contientfiche').enable();    

    const item = ammounts3.getRawValue()[i];
    if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur==='Autre')
    {
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
    else{
      deccomptabilite.autre3.push({
        type: '3',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise:item.devise,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    }
          }   
        }
        if (this.option14Value) 
        {
          let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
          for (let i = 0; i < ammounts8.length; i++)
          {        
    ammounts8.controls[i].get('fournisseur').enable();
    ammounts8.controls[i].get('numerofacture').enable();
    ammounts8.controls[i].get('contientfiche').enable();    
    const item = ammounts8.getRawValue()[i];
   
      deccomptabilite.autre8.push({
        type: '8',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      numerocheque:item.numerocheque,
      banque:item.banque,
      contientfiche:item.contientfiche
    })
    
    console.log(deccomptabilite.autre8)    
          }   
        }
        if (this.option12Value) 
        {
          let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
          for (let i = 0; i < ammounts11.length; i++)
          {        
    ammounts11.controls[i].get('fournisseur').enable();
    ammounts11.controls[i].get('numerofacture').enable();
    ammounts11.controls[i].get('contientfiche').enable();    
    const item = ammounts11.getRawValue()[i];
   
      deccomptabilite.autre11.push({
        type: '11',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
      contientfiche:item.contientfiche
    })
    
    console.log(deccomptabilite.autre11)    
          }   
        }
        if (this.option13Value) 
        {
          let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
          for (let i = 0; i < ammounts9.length; i++)
          {        
    ammounts9.controls[i].get('fournisseur').enable();
    ammounts9.controls[i].get('numerofacture').enable();
    ammounts9.controls[i].get('contientfiche').enable();    
    const item = ammounts9.getRawValue()[i];
    if (item.fournisseur==='Autre'&&item.natureachat!='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
  
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur!='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.beneficiaire,
        date: item.date,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtdevise:item.montanthtdevise,
      montanthtachat:item.montanthtachat,
    
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
     
      contientfiche:item.contientfiche
    })
    }
   else if (item.natureachat==='Autre'&&item.fournisseur==='Autre'||item.natureachat==='Autre'&&item.beneficiaire==='Autre')
    {
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.autrebeneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.autrefournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.autrenatureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtdevise:item.montanthtdevise,
      montanthtachat:item.montanthtachat,
    
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
   
      contientfiche:item.contientfiche
    })
    }
    else{
      deccomptabilite.autre9.push({
        type: '9',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        devise: item.devise,
        beneficiaire: item.beneficiaire,
        autrebeneficiaire: item.autrebeneficiaire,
        fournisseur: item.fournisseur,
        autrefournisseur: item.autrefournisseur,
        typepiece:item.typepiece,
        numerofacture:item.numerofacture,
        natureachat:item.natureachat,
        autrenatureachat:item.autrenatureachat,
      montantht:item.montantht,
      montanthtachat:item.montanthtachat,
    
      montanthtdevise:item.montanthtdevise,
      montanttva:item.montanttva,
      montantdt:item.montantdt,
      montantttc:item.montantttc,
      reglement:item.reglement,
      ficheUrl:item.ficheUrl,
    
      contientfiche:item.contientfiche
    })
    }
    console.log(deccomptabilite.autre9)    
          }   
        }
          if(this.option3Value)
          {
            let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
            for (let i = 0; i < ammounts0.length; i++)
           
            {
              
              const item = ammounts0.getRawValue()[i];
              if( this.user.activite=='Syndic des copropriétaires')
              {
                if((item.montantht=='0'&&item.annule==false&&this.contientnumeroother==true||item.montantht==''&&item.annule==false&&this.contientnumeroother==true||item.montantht==null&&item.annule==false&&this.contientnumeroother==true)) 
                {
                  return (
                    Swal.fire({
                    title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                      icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {this.loading=false,event.target.disabled=false
    
                  }).catch(() => {
                    Swal.fire('opération non aboutie!')
                  }))
                }
              }
              if (item.client==='Autre')
    {
      deccomptabilite.autre0.push({
        type:'1',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        typepiece:item.typepiece,
        devise: item.devise,
        montanthtexpdevise:item.montanthtexpdevise,
        numeronote:item.numeronote,
        montantht:item.montantht,
        montantht19:item.montantht19,
        montantht13:item.montantht13,

        montanthtexo:item.montanthtexo,
        montanthtsus:item.montanthtsus,

        montanthttpe:item.montanthttpe,

        montanthtfodec:item.montanthtfodec,

        montanthtexp:item.montanthtexp,
        montanttva:item.montanttva,
        montanttva19:item.montanttva19,
        montanttva13:item.montanttva13,

        montanttvaexo:item.montanttvaexo,
        montanttvasus:item.montanttvasus,

        montanttvafodec:item.montanttvafodec,

        montanttvatpe:item.montanttvatpe,

        montanttvaexp:item.montanttvaexp,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        montantttc19:item.montantttc19,
        montantttc13:item.montantttc13,

        montantttcexo:item.montantttcexo,
        montantttcsus:item.montantttcsus,
        montantttctpe:item.montantttctpe,
        montantttcfodec:item.montantttcfodec,

        montantttcexp:item.montantttcexp,
        client:item.autreclient,
        autreclient:item.autreclient,
        objet:item.objet,
        autreobjet:item.autreobjet,
        annule:item.annule,
    
        nature:item.nature,
        autrenature:item.autrenature,
        banque:item.banque,
        autrebanque:item.autrebanque,
        numerocompte: item.numerocompte,
        autrenumerocompte: item.autrenumerocompte,
        echeance:item.echeance,
        datevirement:item.datevirement,
      })
    }
    else
    {
      deccomptabilite.autre0.push({
        type:'1',
        numeropiece: item.numeropiece,
        jour: item.jour,
        date: item.date,
        typepiece:item.typepiece,
        devise: item.devise,
        montanthtexpdevise:item.montanthtexpdevise,
        numeronote:item.numeronote,
        montantht:item.montantht,
        montantht19:item.montantht19,
        montantht13:item.montantht13,

        montanthtexo:item.montanthtexo,
        montanthtsus:item.montanthtsus,
        montanthtfodec:item.montanthtfodec,
        montanthttpe:item.montanthttpe,

        montanthtexp:item.montanthtexp,
        montanttva:item.montanttva,
        montanttva19:item.montanttva19,
        montanttva13:item.montanttva13,

        montanttvaexo:item.montanttvaexo,
        montanttvasus:item.montanttvasus,
        montanttvatpe:item.montanttvatpe,
        montanttvafodec:item.montanttvafodec,

        montanttvaexp:item.montanttvaexp,
        montantdt:item.montantdt,
        montantttc:item.montantttc,
        montantttc19:item.montantttc19,
        montantttc13:item.montantttc13,
        montantttcexo:item.montantttcexo,
        montantttcsus:item.montantttcsus,
        montantttcfodec:item.montantttcfodec,
        montantttctpe:item.montantttctpe,

        montantttcexp:item.montantttcexp,
        client:item.client,
        autreclient:item.autreclient,
        objet:item.objet,
        autreobjet:item.autreobjet,
        annule:item.annule,
    
        nature:item.nature,
        autrenature:item.autrenature,
        banque:item.banque,
        autrebanque:item.autrebanque,
        numerocompte: item.numerocompte,
        autrenumerocompte: item.autrenumerocompte,
        echeance:item.echeance,
        datevirement:item.datevirement,
      })
    }
     
     
            } 
            let ammounts = this.editionnoteform.get('ammounts') as FormArray;
            for (let i = 0; i < ammounts.length; i++)
           
            {
              
              const item = ammounts.getRawValue()[i];
                //@ts-ignore
                              if (this.user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||this.user.natureactivite=='société'||this.user.activite=='Syndic des copropriétaires'&&this.user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))

                {
                  if((item.montantht=='0'&&item.annule==false&&item.montantttc=='0'&&this.contientnumero==true||item.montantht==''&&item.annule==false&&item.montantttc==''&&this.contientnumero==true||item.montantht==null&&item.annule==false&&item.montantttc==null&&this.contientnumero==true)) 
                  {
                    return (
                      Swal.fire({
                      title: this.user.activite=='Syndic des copropriétaires'?'la ligne '+`${i+1}`+' de la rubrique '+' état de recettes '+' possède un montant vide, veuillez l\'annuler':' la ligne '+`${i+1}`+' de la rubrique '+' chiffre d\'affaires '+' possède un montant vide, veuillez l\'annuler',
                      icon: 'error',
                      confirmButtonColor: '#3085d6',
                    }).then((result) => {this.loading=false,event.target.disabled=false
        
                    }).catch(() => {
                      Swal.fire('opération non aboutie!')
                    }))
                  }
                }

                if (item.client==='Autre')
                {
                  deccomptabilite.autre1.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthttpe:item.montanthttpe,
                    montanthtfodec:item.montanthtfodec,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvafodec:item.montanttvafodec,
                    montanttvatpe:item.montanttvatpe,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttctpe:item.montantttctpe,
                    montantttcfodec:item.montantttcfodec,
            
                    montantttcexp:item.montantttcexp,
                    client:item.autreclient,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                
                  })
                }
                else
                {
                  deccomptabilite.autre1.push({
                    type:'1',
                    numeropiece: item.numeropiece,
                    jour: item.jour,
                    date: item.date,
                    typepiece:item.typepiece,
                    devise: item.devise,
                    montanthtexpdevise:item.montanthtexpdevise,
                    numeronote:item.numeronote,
                    montantht:item.montantht,
                    montantht19:item.montantht19,
                    montantht13:item.montantht13,
            
                    montanthtexo:item.montanthtexo,
                    montanthtsus:item.montanthtsus,
                    montanthtfodec:item.montanthtfodec,
                    montanthttpe:item.montanthttpe,
            
                    montanthtexp:item.montanthtexp,
                    montanttva:item.montanttva,
                    montanttva19:item.montanttva19,
                    montanttva13:item.montanttva13,
            
                    montanttvaexo:item.montanttvaexo,
                    montanttvasus:item.montanttvasus,
                    montanttvatpe:item.montanttvatpe,
                    montanttvafodec:item.montanttvafodec,
            
                    montanttvaexp:item.montanttvaexp,
                    montantdt:item.montantdt,
                    montantttc:item.montantttc,
                    montantttc19:item.montantttc19,
                    montantttc13:item.montantttc13,
                    montantttcexo:item.montantttcexo,
                    montantttcsus:item.montantttcsus,
                    montantttcfodec:item.montantttcfodec,
                    montantttctpe:item.montantttctpe,
            
                    montantttcexp:item.montantttcexp,
                    client:item.client,
                    autreclient:item.autreclient,
                    objet:item.objet,
                    autreobjet:item.autreobjet,
                    annule:item.annule,
                
                    nature:item.nature,
                    autrenature:item.autrenature,
                    banque:item.banque,
                    autrebanque:item.autrebanque,
                    numerocompte: item.numerocompte,
                    autrenumerocompte: item.autrenumerocompte,
                    echeance:item.echeance,
                    datevirement:item.datevirement,
                  })
                }
     
    console.log(ammounts0.getRawValue()) 
    console.log(ammounts.getRawValue()) 
    
            } 
let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
for (let i = 0; i < ammounts2.length; i+=1)
{
const item = ammounts2.getRawValue()[i];
deccomptabilite.autre2.push({
  type:'2',
  numeropiece: item.numeropiece,
  jour: item.jour,
  date: item.date,
  devise: item.devise,
  recetteexpdevise:item.recetteexpdevise,
  recette:item.recette,
  recette19:item.recette19,
  recette13:item.recette13,

  recetteexo:item.recetteexo,
  recettesus:item.recettesus,
  recettetpe:item.recettetpe,
  recettefodec:item.recettefodec,

  recetteexp:item.recetteexp,
  montantht:item.montantht,
  montantht19:item.montantht19,
  montantht13:item.montantht13,

  montanthtexo:item.montanthtexo,
  montanthtsus:item.montanthtsus,
  montanthtfodec:item.montanthtfodec,
  montanthttpe:item.montanthttpe,

  montanthtexp:item.montanthtexp,
  montanttva:item.montanttva,
  montanttva19:item.montanttva19,
  montanttva13:item.montanttva13,

  montanttvaexo:item.montanttvaexo,
  montanttvasus:item.montanttvasus,
  montanttvatpe:item.montanttvatpe,
  montanttvafodec:item.montanttvafodec,

  montanttvaexp:item.montanttvaexp,
  montantdt:item.montantdt,
  montantttc:item.montantttc,
  montantttc19:item.montantttc19,
  montantttc13:item.montantttc13,

  montantttcexo:item.montantttcexo,
  montantttcsus:item.montantttcsus,
  montantttcfodec:item.montantttcfodec,
  montantttctpe:item.montantttctpe,

  montantttcexp:item.montantttcexp,
})
} 

}
if (this.option5Value) 
{
let employees = this.relevemanuelform.get('employees') as FormArray
employees.getRawValue().forEach((element,index) => {
element.skills=element.skills.filter(item => (item.jour!='0'&&item.jour!=''&&item.jour!=null));
console.log(element.skills)
element.skills.length==0&&element.devise!='tnd'?
deccomptabilite.autre4.push({
  type: '4',
  banque:element.banque,
  numerocompte:element.numerocompte, 
  devise:element.devise,
  soldedebit:element.debit,
  soldecredit:element.credit,
  creditdevise:element.creditdevise,
  soldefinmoisdevise:element.creditdevise,
  soldefinmoisdinar:element.credit,
  mouvements:element.skills
    
}) 
  :
  element.skills==0&&element.credit!=0&&element.devise=='tnd'?
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.creditdevise,
    soldefinmoisdinar:element.credit,
    mouvements:element.skills
      
}) 
    :element.skills==0&&element.debit!=0&&element.devise=='tnd'?
    deccomptabilite.autre4.push({
      type: '4',
      banque:element.banque,
      numerocompte:element.numerocompte, 
      devise:element.devise,
      soldedebit:element.debit,
      soldecredit:element.credit,
      creditdevise:element.creditdevise,
      soldefinmoisdevise:element.creditdevise,
      soldefinmoisdinar:element.debit,
      mouvements:element.skills
        
}) 
  :
  deccomptabilite.autre4.push({
    type: '4',
    banque:element.banque,
    numerocompte:element.numerocompte, 
    devise:element.devise,
    soldedebit:element.debit,
    soldecredit:element.credit,
    creditdevise:element.creditdevise,
    soldefinmoisdevise:element.soldefinmoisdevise,
    soldefinmoisdinar:element.soldefinmoisdinar,
    mouvements:element.skills
      
}) 
console.log(deccomptabilite.autre4)
}); 
let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
for (let i = 0; i < ammounts5.length; i++)
{
const item = ammounts5.getRawValue()[i];
deccomptabilite.autre5.push({
type: '5',
annee: item.annee,
mois: item.mois,
ficheUrl:item.ficheUrl,
contientfiche:item.contientfiche

})
} 
}
        if (this.option6Value) 
        {
    
    
          let typessalaire = this.salaireform.get('typessalaire') as FormArray;
          typessalaire.getRawValue().forEach((element,index) => {
            element.ammounts6=element.ammounts6.filter(item => (item.matricule!='0'&&item.matricule!=''&&item.matricule!=null))
            deccomptabilite.autre6.push({
              type: '6',
              typesalaire:element.typesalaire,
              salarie:element.ammounts6,         
      }) 
    }
          )
        }
        deccomptabilite.statutcollab=this.deccomptabilite.statutcollab
        deccomptabilite.affecte=this.deccomptabilite.affecte
        this.currentUser.role=='admin'||this.currentUser.role=='supervisor'?deccomptabilite.statutcollab.push
        //@ts-ignore
        ({
          statutclient:'déposé pour le compte du client',
          motifclient:'',
          datefin:data,
           duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
        }):
        deccomptabilite.statutcollab.push
              //@ts-ignore
              ({
                statutclient:'déposé par le client',
                motifclient:'',
                datefin:data,
                duree:'',     
              })
        deccomptabilite.affecte=''
       // deccomptabilite.autre1=deccomptabilite.autre1.filter(item => (item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
        deccomptabilite.autre2=deccomptabilite.autre2.filter(item =>(item.montantttc!='0'&&item.montantttc!=''&&item.montantttc!=null));
        deccomptabilite.autre2.map((e, i) => {
          //@ts-ignore
           e.numeropiece = i+1 });
       deccomptabilite.autre3=deccomptabilite.autre3.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null||
item.montanthtdevise!='0'&&item.montanthtdevise!=''&&item.montanthtdevise!=null));
deccomptabilite.autre8=deccomptabilite.autre8.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre9=deccomptabilite.autre9.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));
deccomptabilite.autre11=deccomptabilite.autre11.filter(item => (item.montantht!='0'&&item.montantht!=''&&item.montantht!=null));              
deccomptabilite.autre4=deccomptabilite.autre4.filter(item => (item.numerocompte!='0'&&item.numerocompte!=''&&item.numerocompte!=null));
        deccomptabilite.autre5=deccomptabilite.autre5.filter(item => (item.mois!='0'&&item.mois!=''&&item.mois!=null));
        deccomptabilite.autre6=deccomptabilite.autre6.filter(item => (item.typesalaire!=''));
        this.deccompt.modify(this.deccomptabilite._id,deccomptabilite,this.uploadFilesautre3,this.uploadFilesautre5,this.uploadFilesautre6,this.uploadFilesautre8,this.uploadFilesautre9,this.uploadFilesautre11).then(
              (data:any) => {
                this.tokenStorage.saved=true;
                this.loading = false;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'fichier comptable modifiée avec succès',
                  showConfirmButton: false,
                  timer: 3000
                });
                const decfiscmens:Decfiscmens = new Decfiscmens();
                decfiscmens.impottype1={ type: '', traitementetsalaire: { salairebrut:'', salaireimposable: '', retenuealasource: '',contributionsociale: '', }, 
                location1: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location2: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
                location3: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location4: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
                 honoraire1: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, honoraire2: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
                 honoraire3: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10001: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
                 montant10002: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10003: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, 
                montant10004: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, autre: []}
                decfiscmens.impottype2={ type: '',reporttvamoisprecedent:'',tvacollecter:{
                  type:'',
                  chiffreaffaireht:'',
                  tvaammount:'',
                  ammountttc:'',
                  
                  },tvacollecter19:{
                    type:'',
                    chiffreaffaireht:'',
                    tvaammount:'',
                    ammountttc:'',
                    
                    },tvacollecter13:{
                      type:'',
                      chiffreaffaireht:'',
                      tvaammount:'',
                      ammountttc:'',
                      
                      }
                    ,tvarecuperableimmobilier:{
                      type:'',
                  achatlocauxht:'',
                  achatlocauxtva:'',
                  
                  
                  },
                  tvarecuperableequipement:{
                      type:'',
                  achatlocauxht:'',
                  achatlocauxtva:'',
                  achatimporteht:'',
                  achatimportetva:'',
                  
                  
                  },
                  tvarecuperableautreachat:{
                      type:'',
                  achatlocauxht:'',
                  achatlocauxtva:'',
                  achatimporteht:'',
                  achatimportetva:'',
                  
                  
                  },
                  locationhabitationmeuble:{
                      type:'',
                      htammount:'',
                      tvaammount:'',
                      ttcammount:'',
                      },
                  locationusagecommercial:{
                      type:'',
                          htammount:'',
                          tvaammount:'',
                          ttcammount:'',
                          },
                  operationlotissement:{
                      type:'',
                              htammount:'',
                              tvaammount:'',
                              ttcammount:'',
                              },
                  interetpercue:{
                      type:'',
                                  htammount:'',
                                  tvaammount:'',
                                  ttcammount:'',
                                  },
                  autretvaspecial:{
                      type:'',
                                      htammount:'',
                                      tvaammount:'',
                                      ttcammount:'',
                                      taux:'',
                                      }    }
                                      decfiscmens.impottype3={ type:'',
                                        basetfp:'',
                                        tfpsalairebrut:'',
                                        montanttfpmois:'',
                                        reporttfpmoisprecedent:'',
                                        montantavance:'',
                                        tfppayer:'',
                                        tfpreporter:'',
                                        salairesnonsoumistfp:''}
                                        decfiscmens.impottype4={ type:'',
                                        foprolossalairebrut:'',
                                        basefoprolos:'',
                                        montantfoprolos:'',
                                        salairesnonsoumisfoprolos:''}
                                        decfiscmens.impottype5={ type:'',
                                        nombrenotehonoraire:'',
                            totaldroittimbre:'',}
                            decfiscmens.impottype6={ type:'',
                            chiffreaffairettc:'',
                            tclpayer:'',}
                            decfiscmens.impottype7={ type:'',
                            chiffreaffaireht:'',
                            montantcontribution:'',}
                decfiscmens.userId = this.user._id;
                decfiscmens.activite=this.activite;
                decfiscmens.regimefiscalimpot=this.user.regimefiscalimpot;
                decfiscmens.sousactivite=this.sousactivite;
                decfiscmens.codepostal=this.user.codepostal;
                decfiscmens.adresse=this.user.adresseactivite
                decfiscmens.firstname=this.user.firstname
                decfiscmens.lastname=this.user.lastname
                decfiscmens.raisonsociale=this.user.raisonsociale
                decfiscmens.codegenre=this.user.codegenre
                decfiscmens.codetva=this.user.codetva
                decfiscmens.matriculefiscale=this.user.matriculefiscale
                decfiscmens.registrecommerce=this.user.registrecommerce
                decfiscmens.datearretactivite=this.user.datearretactivite
                decfiscmens.annee=this.option1Value
                decfiscmens.mois=this.option2Value
                decfiscmens.nature='Déclaration Mensuelle'
                decfiscmens.source=''
                decfiscmens.origine='généré automatiquement'
                decfiscmens.impottype1.type='Retenue à la source'
                this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype3.type='TFP':''
                decfiscmens.impottype4.type='FOPROLOS'
                this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype2.type='TVA':''
                this.user.activite!='Syndic des copropriétaires'?decfiscmens.impottype6.type='TCL':''
                decfiscmens.statutcollab=[]
                if(this.option1Value==''||this.option2Value=='')
                {
                  return (
                    Swal.fire({
                    title: 'veuillez indiquer le mois et l\'année de la déclaration',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {this.loading=false
                  }).catch(() => {
                    Swal.fire('opération non aboutie!')
                  }))
                } 
                if (this.realsalairebrut6!=0) 
                {    
            decfiscmens.impottype1.traitementetsalaire.salairebrut=this.realsalairebrut6.toString()
            decfiscmens.impottype1.traitementetsalaire.salaireimposable=this.realsalaireimposable6.toString()
            decfiscmens.impottype1.traitementetsalaire.retenuealasource=this.realretenue6.toString() 
            }
            if(this.realsalairebrut6!=0)
            {
              decfiscmens.impottype3.tfppayer=this.tfpapayer.toString()
              decfiscmens.impottype3.basetfp=this.realsalairebrut6.toString()
              decfiscmens.impottype3.montanttfpmois=this.tfpapayer.toString()
            }
            if(this.realsalairebrut6!=0)
            {
              decfiscmens.impottype4.montantfoprolos=this.foprolosapayer.toString()
              decfiscmens.impottype4.basefoprolos=this.realsalairebrut6.toString()
            }
            if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
            {
            console.log(this.realht2)
            decfiscmens.impottype2.tvacollecter.type='TVA collecté'
            decfiscmens.impottype2.tvacollecter.chiffreaffaireht=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe))*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter19.chiffreaffaireht=(Math.trunc(((this.realht119+this.realht219))*1000)/1000).toString()
            
            decfiscmens.impottype2.tvacollecter13.chiffreaffaireht=(Math.trunc(((this.realht113+this.realht213))*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter.tvaammount=(Math.trunc(((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva)*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter19.tvaammount=(Math.trunc(((this.realht119+this.realht219)*0.19)*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter13.tvaammount=(Math.trunc(((this.realht113+this.realht213)*0.13)*1000)/1000).toString()

            decfiscmens.impottype2.tvacollecter.ammountttc=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva)))*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter19.ammountttc=(Math.trunc((((this.realht119+this.realht219)+((this.realht119+this.realht219)*0.19)))*1000)/1000).toString()
            decfiscmens.impottype2.tvacollecter13.ammountttc=(Math.trunc((((this.realht113+this.realht213)+((this.realht113+this.realht213)*0.13)))*1000)/1000).toString()

          }
            if (this.realht3>0)
            {
            //decfiscmens.impottype2.type='TVA'
            decfiscmens.impottype2.tvarecuperableautreachat.type='TVA récupérable pour les autres achats'
            decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxht=this.realht3.toString()
            decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxtva=this.realtva3.toString()
            }
            if(this.realdt1>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt119>0&&this.user.activite!='Syndic des copropriétaires'||this.realdt113>0&&this.user.activite!='Syndic des copropriétaires')
            {     
              decfiscmens.impottype5.type='Droit de timbre'
              decfiscmens.impottype5.nombrenotehonoraire=(Math.floor((this.realdt1+this.realdt119+this.realdt113)/this.tauxdt)).toString();
              decfiscmens.impottype5.totaldroittimbre=(Math.round((this.realdt1+this.realdt119+this.realdt113)*1000)/1000).toString()
            }
            if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
            {
              decfiscmens.impottype6.chiffreaffairettc=(Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva+(this.realht119+this.realht219)*0.19+(this.realht113+this.realht213)*0.13)))*1000)/1000).toString()
              decfiscmens.impottype6.tclpayer=(Math.trunc((((Math.trunc(((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)+((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)*+this.tauxtva+(this.realht119+this.realht219)*+0.19+(this.realht113+this.realht213)*+0.13)))*1000)/1000))*0.002)*1000)/1000).toString()
            }
            if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
            this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
            ||this.activite=='Sage-femmes')
            {
              decfiscmens.impottype7.type='FSSP'
          
              if(this.realht1>0||this.realht2>0||this.realht119>0||this.realht219>0||this.realht113>0||this.realht213>0)
              {
              decfiscmens.impottype7.chiffreaffaireht=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213))*1000)/1000).toString()
              decfiscmens.impottype7.montantcontribution=(Math.trunc((((this.realht1+this.realht2-this.realht213-this.realht219-this.realht2exo-this.realht2exp-this.realht2sus-this.realht2fodec-this.realht2tpe)+this.realht119+this.realht219+this.realht113+this.realht213)*0.01)*1000)/1000).toString()
            } 
            }
            this.currentUser.role=='admin'||this.currentUser.role=='supervisor'? decfiscmens.statutcollab.push
              //@ts-ignore
              ({
                statutclient:'déposé pour le compte du client',
                motifclient:'',
                datefin:data,
                 duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
              }):
              decfiscmens.statutcollab.push
              //@ts-ignore
              ({
                statutclient:'déposé par le client',
                motifclient:'',
                datefin:data,
                duree:'',     
              })
              decfiscmens.affecte=''
            this.DecfiscmensService.create(decfiscmens).then(
              (data:any) => {
                this.tokenStorage.saved=true;
                this.loading = false;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'déclaration sauvegardée avec succès! un email vous a été envoyer pour confirmer la réception de votre déclaration. vous pouvez désormais modifier/compléter votre déclaration à travers votre tableau de bord',
                  showConfirmButton: false,
                  timer: 6000 
                });
                this.router.navigate(['user-board']);
              //  this.router.navigate(['modify-deccomptabilite/'+datacomptab.data._id])
              },
              (error) => {
                this.loading = false;
                
              }
            )
              },
              (error) => {
                this.loading = false;
                
                window.scrollTo(0, 0);   
              }
            )
      }
    )
  }
  else if(result.isDismissed)
  {
    this.loading=false
  }
}
  ):''

 
              
            }
          }
        )
      }
    )
}
  }
 
  showotheretatrecette()
  {
    //verify user choice about method of declaring invoices
  this.userservice.getUserById(this.deccomptabilite.userId).then(
    async (user: User) => {
      this.loading = false;
      this.user = user;
      this.showeditionnotepast=true
  
          //@ts-ignore
      if (user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))
  {
    this.contientnumeroother=false
    let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
   ammounts0.push(this.createammount0())
  }
  else
  {
      //@ts-ignore
      if(!user.numeronote.find(e => e.annee==`${this.option1Value}`))
      {
        const { value: numero } = await Swal.fire({
          title: 'Renseigner le numéro de la première quittance de l\'année' +`${this.option1Value}`,
          input: 'text',
          inputLabel: 'numéro',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Vous devez renseigner une valeur!'
            }
          }
        })
        
        if (numero) 
        {
          Swal.fire(`votre premier numéro est ${numero}`)
          const newuser= new User
          newuser.numeronote=user.numeronote
          newuser.numeronote.push
          //@ts-ignore
          ({
            annee:`${this.option1Value}`,
            numero: numero   
          }) 
            this.tokenStorage.saved=true
          this.userservice.modifyUserById(user._id,newuser).then(
            () => {
              this.showotheretatrecette();
            }
          )
        }
      }
  
    this.contientnumeroother=true
    let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
    ammounts0.push(this.createammount0())
   let numerofactureverif=[]
   let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)<new Date(this.deccomptabilite.created)&&!item.source&&item.annee==`${this.option1Value}`&&item.contientnumero==true)
console.log(desireddeccomptables)

desireddeccomptables.forEach(element =>element.autre1.forEach(e=>numerofactureverif.push(e.numeronote)))
 console.log(numerofactureverif)
  
    if (!numerofactureverif.find(e=>e!=''))
    {
      console.log('here')
      ammounts0.at(0).patchValue({
        numeronote:(this.deccompt.filterByValue(user.numeronote,`${this.option1Value}`))[0].numero
       })
    }
    else if (numerofactureverif.find(e=>e!=''))
    {
      console.log('here2')
      const c=Math.max(...(desireddeccomptables.map(a => Math.max(...a.autre0.map(b => +b.numeronote)))).map(b => b))+1
      console.log(JSON.stringify(c) )
      ammounts0.at(0).patchValue({
        numeronote:JSON.stringify(c) 
    })
  }
  }
    }
  )
  
  }
  myFunction1() {
   
    var checkbox:any = document.getElementById("myCheck1");
    var text2 = document.getElementById("tabcontainer");
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showcatab=true;
      this.option3Value=true;
//verify user choice about method of declaring invoices
this.userservice.getUserById(this.deccomptabilite.userId).then(
  async (user: User) => {
    this.loading = false;
    this.user = user;
      //@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.clients.push({cl:item.client})))))
this.user.activite!='Syndic des copropriétaires'?this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.clients.push({cl:e.client})))):''
  this.settedclients= this.clients.filter((obj, index) => {
    return index === this.clients.findIndex(o => obj.cl === o.cl);
  });
  this.settedclients=this.settedclients.sort()

  this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copnatures.push(e.nature))))
this.deccompt.deccomptabilites.forEach(element => element.autre0.forEach(e => (this.copnatures.push(e.nature))))
this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copobjets.push(e.objet))))
this.deccompt.deccomptabilites.forEach(element => element.autre0.forEach(e => (this.copobjets.push(e.objet))))
  this.settedcopnatures= this.copnatures.filter((obj, index) => {
    return index === this.copnatures.findIndex(o => obj === o);
  });
  this.copnatures=this.copnatures.sort()
  this.settedcopobjets= this.copobjets.filter((obj, index) => {
    return index === this.copobjets.findIndex(o => obj === o);
  });
  this.copobjets=this.copobjets.sort()
  //vérification du renseignement de la méthode de décalaration du chiffre d'affaire  
  //@ts-ignore
  if (!user.choixfacture.find(e => e.annee==`${this.option1Value}`)&&user.natureactivite!='société'&&user.activite!='Syndic des copropriétaires')
{
Swal.fire({
title: 'Veuillez préciser votre méthode de gestion concernant le chiffre d\'affaires. il est à noter que votre choix ne peut être changé au cours de la même année que suite à une demande à adresser à Macompta',
icon: 'info',
showDenyButton: true,
showCancelButton: true,
confirmButtonColor: '#3085d6',
cancelButtonColor: '#555',
confirmButtonText: 'Edition de factures',
cancelButtonText: 'Annuler',
denyButtonText: 'Recettes journalières',

}).then((result) => {
if (result.isConfirmed) {
  const newuser= new User
  newuser.choixfacture=user.choixfacture
  newuser.choixfacture.push
  //@ts-ignore
  ({
    annee:`${this.option1Value}`,
    choix:'edition note'   
  })  
  this.tokenStorage.saved=true
  this.userservice.modifyUserById(user._id,newuser).then(
    () => {
      this.myFunction1();
    }
  )
}
else if (result.isDenied)
{
  const newuser= new User
  newuser.choixfacture=user.choixfacture
  newuser.choixfacture.push
  //@ts-ignore
  ({
    annee:`${this.option1Value}`,
    choix:'saisie recette'
  })  
  this.tokenStorage.saved=true
  this.userservice.modifyUserById(user._id,newuser).then(
    () => {
      this.myFunction1();
    }
  )
}
else if (result.isDismissed)
{
  this.showcatab=false;
  this.option3Value=false;
}
}).catch(() => {
Swal.fire('opération non aboutie!');
});
}
//vérification du renseignement de la numérotation des factures de décalaration du chiffre d'affaire du syndic
  //@ts-ignore
  else if (!user.choixsyndic.find(e => e.annee==`${this.option1Value}`)&&user.activite=='Syndic des copropriétaires')
{
Swal.fire({
title: 'Veuillez préciser si vos notes d\'honoraires sont numérotées ou non. il est à noter que votre choix ne peut être changé au cours de la même année que suite à une demande à adresser à Macompta',
icon: 'info',
showDenyButton: true,
showCancelButton: true,
confirmButtonColor: '#3085d6',
cancelButtonColor: '#555',
confirmButtonText: 'Oui',
cancelButtonText: 'Annuler',
denyButtonText: 'Non',

}).then((result) => {
if (result.isConfirmed) {
  const newuser= new User
  newuser.choixsyndic=user.choixsyndic
  newuser.choixsyndic.push
  //@ts-ignore
  ({
    annee:`${this.option1Value}`,
    choix:'oui'   
  })  
  this.tokenStorage.saved=true
  this.userservice.modifyUserById(user._id,newuser).then(
    () => {
      this.myFunction1();
    }
  )
}
else if (result.isDenied)
{
  const newuser= new User
  newuser.choixsyndic=user.choixsyndic
  newuser.choixsyndic.push
  //@ts-ignore
  ({
    annee:`${this.option1Value}`,
    choix:'non'
  })  
  this.tokenStorage.saved=true
  this.userservice.modifyUserById(user._id,newuser).then(
    () => {
      this.myFunction1();
    }
  )
}
else if (result.isDismissed)
{
  this.showcatab=false;
  this.option3Value=false;
}
}).catch(() => {
Swal.fire('opération non aboutie!');
});
}
  //@ts-ignore
if (user.choixfacture.find(e => e.choix=='edition note'&&e.annee==`${this.option1Value}`)||user.natureactivite=='société'||user.activite=='Syndic des copropriétaires'&&user.choixsyndic.find(e => e.choix=='oui'&&e.annee==`${this.option1Value}`))
{
  user.activite=='Syndic des copropriétaires'?this.showbuttonotheretatrecette=true:''

    //@ts-ignore
  if(!user.numeronote.find(e => e.annee==`${this.option1Value}`))
  {
const { value: numero } = await Swal.fire({
  title: user.activite!='Syndic des copropriétaires'?'Renseigner le numéro de la première facture de l\'année'+`${this.option1Value}`:'Renseigner le numéro de la première quittance de l\'année' +`${this.option1Value}`,
  input: 'text',
  inputLabel: 'numéro',
  inputValue: '',
  showCancelButton: true,
  inputValidator: (value) => {
    if (!value) {
      return 'Vous devez renseigner une valeur!'
    }
  }
})

if (numero) 
{
  Swal.fire(`votre premier numéro est ${numero}`)
  const newuser= new User
  newuser.numeronote=user.numeronote
  newuser.numeronote.push
  //@ts-ignore
  ({
    annee:`${this.option1Value}`,
    numero: numero   
  }) 
    this.tokenStorage.saved=true
  this.userservice.modifyUserById(user._id,newuser).then(
    () => {
      this.myFunction1();
    }
  )
}

  }
  this.showinvoiceform=true
  this.showeditionnote=true
  this.showrecettejour=false
  let ammounts = this.editionnoteform.get('ammounts') as FormArray;
  ammounts.at(0).patchValue({
    type:'1',
    numeropiece: '1',
    jour: '',
    date: '',
    typepiece: 'facture',
    typepiecesyndic: 'facture',
    numeronote: '',
    montantht:'0',
  tauxtva:this.tauxtva,
  montanttva:'0',
  montantdt:this.tauxdt,
  montantttc:'0',
  montantht19:'0',
  tauxtva19:'0.19',
  montanttva19:'0',
  montantttc19:'0',
  montantht13:'0',
  tauxtva13:'0.13',
  montanttva13:'0',
  montantttc13:'0',
  montanthtexo:'0',
  tauxtvaexo:'0',
  montanttvaexo:'0',
  montantttcexo:'0',
  montanthtsus:'0',
  tauxtvasus:'0',
  montanttvasus:'0',
  montantttcsus:'0',

  montanthtfodec:'0',
  tauxtvafodec:'0',
  montanttvafodec:'0',
  montantttcfodec:'0',

  montanthttpe:'0',
  tauxtvatpe:'0',
  montanttvatpe:'0',
  montantttctpe:'0',
  devise:'euro',
  montanthtexpdevise:'0',
  montanthtexp:'0',
  tauxtvaexp:'0',
  montanttvaexp:'0',
  montantttcexp:'0',
  objet:'',
      autreobjet:'',
      annule:'',

      nature:'',
      autrenature:'',
      banque:'',
      autrebanque:'',
      numerocompte: '',
      autrenumerocompte: '',
      echeance:'',
      datevirement:'',

    client:'',
    autreclient:'',
   })
 let numerofactureverif=[]
 let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)<new Date(this.deccomptabilite.created)&&!item.source&&item.annee==`${this.option1Value}`&&item.contientnumero==true)
 console.log(desireddeccomptables)
 
 desireddeccomptables.forEach(element =>element.autre1.forEach(e=>numerofactureverif.push(e.numeronote)))
  console.log(numerofactureverif)

  if (!numerofactureverif.find(e=>e!=''))
  {
    console.log('here')
    ammounts.at(0).patchValue({
      numeronote:(this.deccompt.filterByValue(user.numeronote,`${this.option1Value}`))[0].numero
     })
  }
  else if (numerofactureverif.find(e=>e!=''))
  {
    console.log('here2')
    const c=Math.max(...(desireddeccomptables.map(a => Math.max(...a.autre1.map(b => +b.numeronote)))).map(b => b))+1
    console.log(JSON.stringify(c) )
    ammounts.at(0).patchValue({
      numeronote:JSON.stringify(c) 
  })
}
}
  //@ts-ignore
else if (user.choixfacture.find(e => e.annee==`${this.option1Value}`&&e.choix=='saisie recette'))
{
  this.showinvoiceform=true
  this.showrecettejour=true
  this.showeditionnote=false

  for (let i = 0; i < 31; i++)
          {
            let ammounts2 = this.recettejournaliereform.get('ammounts2') as FormArray;
            ammounts2.push(this.createammount2());
            ammounts2.at(i).patchValue({
              jour:i+1,
              numeropiece:i+1,
              type:'2',
      recette:'',
      recette19:'',
      recette13:'',

      recetteexo:'',
      recettesus:'',

      recettetpe:'',


      recettefodec:'',

      devise:'euro',
      recetteexp:'0',
      recetteexpdevise:'',
      montantht:'0',
      tauxtva:this.tauxtva,
      montanttva:'0',
      montantdt:'0',
      montantttc:'0',
      montantht19:'0',
      tauxtva19:'0.19',
      montanttva19:'0',
      montantttc19:'0',
      montantht13:'0',
      tauxtva13:'0.13',
      montanttva13:'0',
      montantttc13:'0',
      montanthtexo:'0',
      tauxtvaexo:'0',
      montanttvaexo:'0',
      montantttcexo:'0',
      montanthtsus:'0',
      tauxtvasus:'0',
      montanttvasus:'0',
      montantttcsus:'0',

      montanthtfodec:'0',
      tauxtvafodec:'0',
      montanttvafodec:'0',
      montantttcfodec:'0',

      montanthttpe:'0',
      tauxtvatpe:'0',
      montanttvatpe:'0',
      montantttctpe:'0',
      montanthtexp:'0',
      tauxtvaexp:'0',
      montanttvaexp:'0',
      montantttcexp:'0',
             })
             this.setdate2(i)
            }
          this.removeammount2(31)
          if(this.option2Value==='04'||this.option2Value==='06'||this.option2Value==='09'||this.option2Value==='11')
          {
            this.removeammount2(31)
            this.removeammount2(30)
          }
          if(this.option2Value=='02')
          {
            if(+this.option1Value % 4 ==0)
            {
              this.removeammount2(31)
              this.removeammount2(30)
              this.removeammount2(29)
            }
            else 
            {
            this.removeammount2(31)
            this.removeammount2(30)
            this.removeammount2(29)
            this.removeammount2(28)

            }    
          }

  
}
//@ts-ignore
else if (user.activite=='Syndic des copropriétaires'&&user.choixsyndic.find(e => e.choix=='non'&&e.annee==`${this.option1Value}`)) 
{
  this.showbuttonotheretatrecette=true
  
  this.showinvoiceform=true
  this.showeditionnote=true
  this.showrecettejour=false
  this.contientnumero=false
  let ammounts = this.editionnoteform.get('ammounts') as FormArray;
  ammounts.at(0).patchValue({
    type:'1',
    numeropiece: '1',
    jour: '',
    date: '',
    typepiece: 'facture',
    typepiecesyndic: 'facture',
    numeronote: '',
    montantht:'0',
  tauxtva:this.tauxtva,
  montanttva:'0',
  montantdt:this.tauxdt,
  montantttc:'0',
  montantht19:'0',
  tauxtva19:'0.19',
  montanttva19:'0',
  montantttc19:'0',
  montantht13:'0',
  tauxtva13:'0.13',
  montanttva13:'0',
  montantttc13:'0',
  montanthtexo:'0',
  tauxtvaexo:'0',
  montanttvaexo:'0',
  montantttcexo:'0',
  montanthtsus:'0',
  tauxtvasus:'0',
  montanttvasus:'0',
  montantttcsus:'0',

  montanthttpe:'0',
  tauxtvatpe:'0',
  montanttvatpe:'0',
  montantttctpe:'0',

  montanthtfodec:'0',
  tauxtvafodec:'0',
  montanttvafodec:'0',
  montantttcfodec:'0',
  devise:'euro',
  montanthtexpdevise:'0',
  montanthtexp:'0',
  tauxtvaexp:'0',
  montanttvaexp:'0',
  montantttcexp:'0',
  objet:'',
      autreobjet:'',
      annule:'',

      nature:'',
      autrenature:'',
      banque:'',
      autrebanque:'',
      numerocompte: '',
      autrenumerocompte: '',
      echeance:'',
      datevirement:'',

    client:'',
    autreclient:'',
   })

}
  }
)
      
      

    } else {
      let futurdeccomptabilite=this.deccompt.deccomptabilites.filter(item=>new Date(item.created)>new Date(this.deccomptabilite.created)&&!item.source)
      if(futurdeccomptabilite[0])
      {
        if(futurdeccomptabilite[0].autre0.length>0||futurdeccomptabilite[0].autre1.length>0)
        {
          return (
            Swal.fire({
            title:this.activite=='Syndic des copropriétaires'?
            'vous ne pouvez pas effectuer cette opération car vous avez tenu votre comptabilité des mois qui suivent, veuillez réintialiser les données relatives aux recettes pour pouvoir effectuer cette opération'
            :'vous ne pouvez pas effectuer cette opération car vous avez tenu votre comptabilité des mois qui suivent, veuillez réintialiser les données relatives aux chiffres d\'affaires pour pouvoir effectuer cette opération',
            icon: 'error',
            confirmButtonColor: '#3085d6',
          }).then((result) => {this.loading=false,checkbox.checked = true,this.option3Value=true;
          }).catch(() => {
            Swal.fire('opération non aboutie!')
          }))
        }
      }

      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au chiffre d\'affaires, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.resetcaall();
          this.showcatab=false;
          this.option3Value=false;
          

        }
        else{
          checkbox.checked = true
          this.option3Value=true;

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  myFunction2() {
    var checkbox:any = document.getElementById("myCheck2");
    var text2 = document.getElementById("tabcontainer");
    //setting content of lists
    this.deccompt.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
//@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur})))))   
 this.settednatures= this.natures.filter((obj, index) => {
      return index === this.natures.findIndex(o => obj.nat === o.nat);
    });
    this.settednatures=this.settednatures.sort()
    this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
      return index === this.fournisseurs.findIndex(o => obj.four === o.four);
    });
    this.settedfournisseurs=this.settedfournisseurs.sort()
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showachattab=true;
      this.option4Value=true;
      let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
      ammounts3.at(0).patchValue({
        type:'3',
        numeropiece: '1',
        jour: '',
        date: '',
        devise: 'euro',
        fournisseur:'',
        autrefournisseur:'',
        typepiece:'facture',
        numerofacture:'',
        natureachat:'',
        autrenatureachat:'',
        montantht:'0',
        montanthtachat:'0',
        montanthtdevise:'0',
        montanttva:'0',
        montantdt:'0',
        montantttc:'0',
        reglement:'',
        image:'',
        ficheUrl:'',
        contientfiche:false
             })

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux achats, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.resetachatall();
          this.showachattab=false;
          this.option4Value=false;
          this.uploadFilesautre3=[]

        }
        else{
          checkbox.checked = true
          this.option4Value=true;

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  myFunction7() {
    var checkbox:any = document.getElementById("myCheck7");
    var text2 = document.getElementById("tabcontainer");
    //setting content of lists
    //@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur})))))
    this.deccompt.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
    this.deccompt.deccomptabilites.forEach(element => element.autre8.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}))))
    this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => (this.banques.push({bq:e.banque}))))
    this.settedbanques= this.banques.filter((obj, index) => {
      return index === this.banques.findIndex(o => obj.bq === o.bq);
    });
    this.settedbanques=this.settedbanques.sort()
    this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
      return index === this.beneficiaires.findIndex(o => obj.four === o.four);
    });
    this.settedbeneficiaires=this.settedbeneficiaires.sort()
    this.settednatures= this.natures.filter((obj, index) => {
      return index === this.natures.findIndex(o => obj.nat === o.nat);
    });
    this.settednatures=this.settednatures.sort()
    this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
      return index === this.fournisseurs.findIndex(o => obj.four === o.four);
    });
    this.settedfournisseurs=this.settedfournisseurs.sort()
 
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showachatchequetab=true;
      this.option14Value=true;
      let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
      ammounts8.at(0).patchValue({
        type:'8',
        numeropiece: '1',
        jour: '',
        date: '',
        devise: 'euro',
        beneficiaire:'',
        autrebeneficiaire:'',
        fournisseur:'',
        autrefournisseur:'',
        typepiece:'facture',
        numerofacture:'',
        natureachat:'',
        autrenatureachat:'',
        montantht:'',
        montanthtachat:[{value:'0',disabled:true}],
        montanthtdevise:'0',
        montanttva:'0',
        montantdt:'0',
        montantttc:'0',
        reglement:'Chèque',
        image:'',
        ficheUrl:'',
        numerocheque:'',
        banque:'',
        contientfiche:false
             })

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux achats, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8085d6',
        cancelButtonColor: '#d88',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.resetachatchequeall();
          this.showachatchequetab=false;
          this.option14Value=false;
          this.uploadFilesautre8=[]

        }
        else{
          checkbox.checked = true
          this.option14Value=true;

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  myFunction6() {
    var checkbox:any = document.getElementById("myCheck6");
    var text2 = document.getElementById("tabcontainer");
    //setting content of lists
    //@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur})))))
this.deccompt.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
this.deccompt.deccomptabilites.forEach(element => element.autre9.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}))))
this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
  return index === this.beneficiaires.findIndex(o => obj.four === o.four);
});
this.settedbeneficiaires=this.settedbeneficiaires.sort()
this.settednatures= this.natures.filter((obj, index) => {
  return index === this.natures.findIndex(o => obj.nat === o.nat);
});
this.settednatures=this.settednatures.sort()
this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
  return index === this.fournisseurs.findIndex(o => obj.four === o.four);
});
this.settedfournisseurs=this.settedfournisseurs.sort()
this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
  return index === this.beneficiaires.findIndex(o => obj.four === o.four);
});
this.settedbeneficiaires=this.settedbeneficiaires.sort()
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showfacturefournisseurtab=true;
      this.option13Value=true;
      let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
      ammounts9.at(0).patchValue({
        type:'9',
        numeropiece: '1',
        jour: '',
        date: '',
        devise: 'euro',
        fournisseur:'',
        autrefournisseur:'',
        typepiece:'facture',
        numerofacture:'',
        natureachat:'',
        autrenatureachat:'',
        montantht:'0',
        montanthtachat:'0',
        montanthtdevise:'0',
        montanttva:'0',
        montantdt:'0',
        montantttc:'0',
        reglement:'',
        image:'',
        ficheUrl:'',
        contientfiche:false
             })

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux achats, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#11085d6',
        cancelButtonColor: '#d1111',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.resetfacturefournisseurall();
          this.showfacturefournisseurtab=false;
          this.option13Value=false;
          this.uploadFilesautre9=[]

        }
        else{
          checkbox.checked = true
          this.option13Value=true;

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  myFunction5() {
    var checkbox:any = document.getElementById("myCheck5");
    var text2 = document.getElementById("tabcontainer");
    //setting content of lists
    //@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur})))))
this.deccompt.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))
this.deccompt.deccomptabilites.forEach(element => element.autre11.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}))))
this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
  return index === this.beneficiaires.findIndex(o => obj.four === o.four);
});
this.settedbeneficiaires=this.settedbeneficiaires.sort()
this.settednatures= this.natures.filter((obj, index) => {
  return index === this.natures.findIndex(o => obj.nat === o.nat);
});
this.settednatures=this.settednatures.sort()
this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
  return index === this.fournisseurs.findIndex(o => obj.four === o.four);
});
this.settedfournisseurs=this.settedfournisseurs.sort()
this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
  return index === this.beneficiaires.findIndex(o => obj.four === o.four);
});
this.settedbeneficiaires=this.settedbeneficiaires.sort()
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showachatespecetab=true;
      this.option12Value=true;
      let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
      ammounts11.at(0).patchValue({
        type:'11',
        numeropiece: '1',
        jour: '',
        date: '',
        devise: 'euro',
        beneficiaire:'',
        autrebeneficiaire:'',
        fournisseur:'',
        autrefournisseur:'',
        typepiece:'facture',
        numerofacture:'',
        natureachat:'',
        autrenatureachat:'',
        montantht:'',
        montanthtachat:[{value:'0',disabled:true}],
        montanthtdevise:'0',
        montanttva:'0',
        montantdt:'0',
        montantttc:'0',
        reglement:'Espèce',
        image:'',
        ficheUrl:'',
        contientfiche:false
             })

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux achats, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9085d6',
        cancelButtonColor: '#d99',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.resetachatespeceall();
          this.showachatespecetab=false;
          this.option12Value=false;
          this.uploadFilesautre9=[]

        }
        else{
          checkbox.checked = true
          this.option12Value=true;

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  myFunction3() {
    var checkbox:any = document.getElementById("myCheck3");
    var text2 = document.getElementById("tabcontainer");
    //setting lists
    this.deccompt.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.clients.push({cl:e.client}))))
    this.deccompt.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}))))
    this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => (this.numeros.push({nc:e.numerocompte,bq:e.banque}))))
    this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => (this.banques.push({bq:e.banque}))))

    this.settednumeros= this.numeros.filter((obj, index) => {
      return index === this.numeros.findIndex(o => obj.nc === o.nc);
    });
    this.settednumeros=this.settednumeros.sort()
    this.settedbanques= this.banques.filter((obj, index) => {
      return index === this.banques.findIndex(o => obj.bq === o.bq);
    });
    this.settedbanques=this.settedbanques.sort()
        //@ts-ignore
    this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetsdebit.push(e.objetdebit)))))
//@ts-ignore
this.deccompt.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(e => (this.objetscredit.push(e.objetcredit)))))
    this.settedobjetsdebit= this.objetsdebit.filter((obj, index) => {
      return index === this.objetsdebit.findIndex(o => obj === o);
    });
    this.settedobjetsdebit=this.settedobjetsdebit.sort()
    this.settedobjetscredit= this.objetscredit.filter((obj, index) => {
      return index === this.objetscredit.findIndex(o => obj === o);
    });
    this.settedobjetscredit=this.settedobjetscredit.sort()
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showbanquetab=true;
      this.option5Value=true;
      //verify user choice about method of declaring sales
this.userservice.getUserById(this.deccomptabilite.userId).then(
  async (user: User) => {
    this.loading = false;
    this.user = user;
     //veirifcation of user choice about releve method
     Swal.fire({
      title: 'Veuillez choisir le mode de saisie des relevés bancaire!',
      icon: 'info',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#555',
      confirmButtonText: 'Saisie manuelle',
      cancelButtonText: 'Annuler',
      denyButtonText: 'Téléchargement de document',
      
      }).then((result) => {
      if (result.isConfirmed) {
        this.showrelevemanuel=true
        this.showrelevejoint=false
        //for (let i = 1; i < 32; i++)
         // {
           // this.addammount4()
           // let ammounts4 = this.relevemanuelform.get('ammounts4') as FormArray;
           // ammounts4.at(i).patchValue({
            //  jour:i
          //   })
         //    this.setdate4(i)
       //   }
       //   this.removeammount4(0)
      //    if(this.option2Value==='04'||this.option2Value==='06'||this.option2Value==='09'||this.option2Value==='11')
      //    {
      //      this.removeammount4(30)
       //   }
       //   if(this.option2Value=='02')
      //    {
         //   if(+this.option1Value % 4 ==0)
         //   {
         //   this.removeammount4(30)
         //   this.removeammount4(29)
         //   }
         //   else 
         //   {
         //   this.removeammount4(30)
         ////   this.removeammount4(29)
         //   this.removeammount4(28)
         //   }

            
          //}
      }
      else if (result.isDenied)
      {
        this.showrelevejoint=true
        this.showrelevemanuel=false
      }
      else if (result.isDismissed)
      {
        this.showrelevejoint=false
        this.showrelevemanuel=false
        this.showbanquetab=false;
        this.option5Value=false;
      }
      }).catch(() => {
      Swal.fire('opération non aboutie!');
      }); 
    })
    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux banques, voulez vous continuer?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) { 
          this.resetbanqueall();
          this.showbanquetab=false;
          this.option5Value=false;
          this.uploadFilesautre5=[]
        }
        else{
          checkbox.checked = true
          this.option5Value=true;
        }
      })   
    }
  }
  myFunction4() {
    var checkbox:any = document.getElementById("myCheck4");
    var text2 = document.getElementById("tabcontainer");
    
    if (checkbox.checked == true){
      text2.style.display = "block";
      this.showsalairetab=true;
      this.option6Value=true;
        //@ts-ignore
        this.deccompt.deccomptabilites.find(objet => objet.autre6.forEach(element => element.salarie!=null))?      
        this.deccompt.deccomptabilites.forEach(objet => objet.autre6.forEach(element => element.salarie.forEach(e =>this.naturescontrat.push({natcontrat:e.naturecontrat})))):''
        this.settednaturecontrats= this.naturescontrat.filter((obj, index) => {
                    //@ts-ignore
          return index === this.naturescontrat.findIndex(o => obj.natcontrat === o.natcontrat);
        });
        this.settednaturecontrats=this.settednaturecontrats.sort()
    //verify user choice about method of declaring salary
this.userservice.getUserById(this.deccomptabilite.userId).then(
  async (user: User) => {
    this.loading = false;
    this.user = user;
    this.showpaiemanuel=true

     //veirifcation of user choice about salary method
   /*  Swal.fire({
      title: 'Veuillez choisir le mode de saisie des fiches de paie!',
      
      icon: 'info',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#555',
      confirmButtonText: 'Saisie manuelle',
      cancelButtonText: 'Annuler',
      denyButtonText: 'Redirection vers le module paie',
      
      }).then((result) => {
      if (result.isConfirmed) {
        this.showpaiemanuel=true
      
      }
      else if (result.isDenied)
      {
        this.showpaiemanuel=false
        this.router.navigate(['declare-paie'])
      }
      else if (result.isDismissed)
{
  this.showpaiemanuel=false

  this.showsalairetab=false;
  this.option6Value=false;
}
      }).catch(() => {
      Swal.fire('opération non aboutie!');
      }); */
    })  

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs aux salaires, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) 
        {
          
          this.resetsalaireall();
          this.showsalairetab=false;
          this.option6Value=false;
          this.uploadFilesautre6=[]
        }
        else
        {
          checkbox.checked = true
          this.option6Value=true;
        }
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  /*myFunction5() {
    var checkbox:any = document.getElementById("choice");
    var text2 = document.getElementById("block2");
    var text3 = document.getElementById("editionnotepast");

    if (checkbox.checked == true){
      text2.style.display = "none";
      text3.style.display = "block";
      let ammounts = this.editionnoteform.get('ammounts') as FormArray;
      let ammounts0 = this.editionnotepastform.get('ammounts0') as FormArray;
      let i=ammounts.length
      let numeronote= +ammounts.value.at(i-1).numeronote 
      ammounts0.at(0).patchValue({
        montantdt:this.tauxdt,
        numeronote:numeronote+1
       })
      
    } 
    else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au factures des mois précédents, voulez vous continuer?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
   this.restartcapast()       
this.option10Value=''
          text3.style.display = "none";
        }
        else{
          checkbox.checked = true
          text3.style.display = "block";

        }
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
    }
  }*/
  /*myFunction6() {
    var checkbox:any = document.getElementById("choice1");
    var text2 = document.getElementById("block1");
    var text3 = document.getElementById("editionnotepast");

    if (checkbox.checked == true){
      text2.style.display = "none";
      text3.style.display = "none";

      
    } else {
       
      text2.style.display = "block";
    }
  }*/
  docjoint3(i:number) {
    let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
    
    var text2 = document.getElementById('achat'+`${i}`);
    var checkbox:any = document.getElementById('myCheck3'+`${i}`);
    if (ammounts3.controls[i].value.contientfiche == true)
    {
      if (ammounts3.value.at(i).montantht=='0'&&ammounts3.value.at(i).montanthtachat=='0'||ammounts3.value.at(i).montantht==''&&ammounts3.value.at(i).montanthtachat=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type achat avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } 
      text2.style.display = "block";
      ammounts3.controls[i].patchValue({ contientfiche: true });

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre3.splice(i,1)
          ammounts3.controls[i].patchValue({ contientfiche: false });


        }
        else{
          text2.style.display = "block";
          ammounts3.controls[i].patchValue({ contientfiche: true });

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  docjoint5(i:number) {
    let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;

    var text2 = document.getElementById('releve'+`${i}`);
    var checkbox:any = document.getElementById('myCheck5'+`${i}`);

    if (ammounts5.controls[i].value.contientfiche == true)
    {
      if (ammounts5.value.at(i).mois=='0'||ammounts5.value.at(i).mois=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type banque avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } 
      text2.style.display = "block";
      ammounts5.controls[i].patchValue({ contientfiche: true });

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre5.splice(i,1)
          ammounts5.controls[i].patchValue({ contientfiche: false });


        }
        else{
          text2.style.display = "block";
          ammounts5.controls[i].patchValue({ contientfiche: true });

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  docjoint6(typeindex:number,i:number) {
    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;

    var text2 = document.getElementById('salaire'+`${typeindex}`+`${i}`);
    var checkbox:any = document.getElementById('myCheck6'+`${i}`);

    if (this.ajoutsalarieform.get('contientfiche').value == true)
    {
      /*if (ammounts6.value.at(i).salairebrut=='0'||ammounts6.value.at(i).salairebrut=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type salaire avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } */
      text2.style.display = "block";
      this.ajoutsalarieform.patchValue({ contientfiche: true });

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre6.splice(i,1)
          this.ajoutsalarieform.patchValue({ contientfiche: false });
        }
        else{
          text2.style.display = "block";
          this.ajoutsalarieform.patchValue({ contientfiche: true });
        }
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  docjoint8(i:number) {
    let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
    
    var text2 = document.getElementById('achatcheque'+`${i}`);
    var checkbox:any = document.getElementById('myCheck8'+`${i}`);
    if (ammounts8.controls[i].value.contientfiche == true)
    {
      if (ammounts8.value.at(i).montantht=='0'&&ammounts8.value.at(i).montanthtachat=='0'||ammounts8.value.at(i).montantht==''&&ammounts8.value.at(i).montanthtachat=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type achat avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#8085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } 
      text2.style.display = "block";
      ammounts8.controls[i].patchValue({ contientfiche: true });
      console.log(ammounts8.controls[i].value.contientfiche)

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8085d6',
        cancelButtonColor: '#d88',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre8.splice(i,1)
          ammounts8.controls[i].patchValue({ contientfiche: false });
          console.log(ammounts8.controls[i].value.contientfiche)


        }
        else{
          text2.style.display = "block";
          ammounts8.controls[i].patchValue({ contientfiche: true });
          console.log(ammounts8.controls[i].value.contientfiche)

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  docjoint9(i:number) {
    let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
    
    var text2 = document.getElementById('facturefournisseur'+`${i}`);
    var checkbox:any = document.getElementById('myCheck9'+`${i}`);
    if (ammounts9.controls[i].value.contientfiche == true)
    {
      if (ammounts9.value.at(i).montantht=='0'&&ammounts9.value.at(i).montanthtachat=='0'||ammounts9.value.at(i).montantht==''&&ammounts9.value.at(i).montanthtachat=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type achat avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#9085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } 
      text2.style.display = "block";
      ammounts9.controls[i].patchValue({ contientfiche: true });
      console.log(ammounts9.controls[i].value.contientfiche)

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9085d6',
        cancelButtonColor: '#d99',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre9.splice(i,1)
          ammounts9.controls[i].patchValue({ contientfiche: false });
          console.log(ammounts9.controls[i].value.contientfiche)


        }
        else{
          text2.style.display = "block";
          ammounts9.controls[i].patchValue({ contientfiche: true });
          console.log(ammounts9.controls[i].value.contientfiche)

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  docjoint11(i:number) {
    let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
    
    var text2 = document.getElementById('achatespece'+`${i}`);
    var checkbox:any = document.getElementById('myCheck11'+`${i}`);
    if (ammounts11.controls[i].value.contientfiche == true)
    {
      if (ammounts11.value.at(i).montantht=='0'&&ammounts11.value.at(i).montanthtachat=='0'||ammounts11.value.at(i).montantht==''&&ammounts11.value.at(i).montanthtachat=='')
    {
      return (
        Swal.fire({
        title: 'veuillez saisir des données valides pour le type achat avant d\'insérer un fichier',
        icon: 'error',
        confirmButtonColor: '#11085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }),checkbox.checked = false)
    } 
      text2.style.display = "block";
      ammounts11.controls[i].patchValue({ contientfiche: true });
      console.log(ammounts11.controls[i].value.contientfiche)

    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de supprimer le fichier joint, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#11085d6',
        cancelButtonColor: '#d1111',
        confirmButtonText: 'supprimer',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          text2.style.display = "none";
          this.uploadFilesautre11.splice(i,1)
          ammounts11.controls[i].patchValue({ contientfiche: false });
          console.log(ammounts11.controls[i].value.contientfiche)


        }
        else{
          text2.style.display = "block";
          ammounts11.controls[i].patchValue({ contientfiche: true });
          console.log(ammounts11.controls[i].value.contientfiche)

        }

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
      
    }
  }
  update(e)
  {}
  onImagePick(event: Event,i:number) {
    this.uploadFilesautre3.splice(i,1)
    let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
   // let fileName = (event.target as HTMLInputElement).files[0].name;
    //ammounts3.controls[i].patchValue({ image: fileName });  
    const file = (event.target as HTMLInputElement).files[0];
    if (file)
    {
      const myRenamedFile = new File([file], ammounts3.value.at(i).numeropiece, {
        type: "application/pdf",
      });
      ammounts3.controls[i].patchValue({ image: myRenamedFile });  
      ammounts3.controls[i].updateValueAndValidity(); 
         
      if (ammounts3.value.at(i).montantht!=''&&ammounts3.value.at(i).montantht!='0'||ammounts3.value.at(i).montanthtachat!=''&&ammounts3.value.at(i).montanthtachat!='0')
      {
  this.uploadFilesautre3.push(ammounts3.value.at(i).image);

}
    }
    
    const reader = new FileReader();
    reader.onload = () => {
      if (ammounts3.controls[i].valid) {
        this.fileUploaded = true;
      } else {
      }
    };
  }
  onImagePick8(event: Event,i:number) {
    this.uploadFilesautre8.splice(i,1)
    let ammounts8 = this.factureachatchequeform.get('ammounts8') as FormArray;
   // let fileName = (event.target as HTMLInputElement).files[0].name;
    //ammounts3.controls[i].patchValue({ image: fileName });  
    const file = (event.target as HTMLInputElement).files[0];
    if (file)
    {
      const myRenamedFile = new File([file], ammounts8.value.at(i).numeropiece, {
        type: "application/pdf",
      });
      ammounts8.controls[i].patchValue({ image: myRenamedFile });  
      ammounts8.controls[i].updateValueAndValidity(); 
         
      if (ammounts8.value.at(i).montantht!=''&&ammounts8.value.at(i).montantht!='0')
      {
  this.uploadFilesautre8.push(ammounts8.value.at(i).image);

}
    }
    
    const reader = new FileReader();
    reader.onload = () => {
      if (ammounts8.controls[i].valid) {
        this.fileUploaded = true;
      } else {
      }
    };
  }
  onImagePick9(event: Event,i:number) {
    this.uploadFilesautre9.splice(i,1)
    let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
   // let fileName = (event.target as HTMLInputElement).files[0].name;
    //ammounts3.controls[i].patchValue({ image: fileName });  
    const file = (event.target as HTMLInputElement).files[0];
    if (file)
    {
      const myRenamedFile = new File([file], ammounts9.value.at(i).numeropiece, {
        type: "application/pdf",
      });
      ammounts9.controls[i].patchValue({ image: myRenamedFile });  
      ammounts9.controls[i].updateValueAndValidity(); 
         
      if (ammounts9.value.at(i).montantht!=''&&ammounts9.value.at(i).montantht!='0')
      {
  this.uploadFilesautre9.push(ammounts9.value.at(i).image);

}
    }
    
    const reader = new FileReader();
    reader.onload = () => {
      if (ammounts9.controls[i].valid) {
        this.fileUploaded = true;
      } else {
      }
    };
  }
  onImagePick11(event: Event,i:number) {
    this.uploadFilesautre11.splice(i,1)
    let ammounts11 = this.factureachatespeceform.get('ammounts11') as FormArray;
   // let fileName = (event.target as HTMLInputElement).files[0].name;
    //ammounts3.controls[i].patchValue({ image: fileName });  
    const file = (event.target as HTMLInputElement).files[0];
    if (file)
    {
      const myRenamedFile = new File([file], ammounts11.value.at(i).numeropiece, {
        type: "application/pdf",
      });
      ammounts11.controls[i].patchValue({ image: myRenamedFile });  
      ammounts11.controls[i].updateValueAndValidity(); 
         
      if (ammounts11.value.at(i).montantht!=''&&ammounts11.value.at(i).montantht!='0')
      {
  this.uploadFilesautre11.push(ammounts11.value.at(i).image);

}
    }
    
    const reader = new FileReader();
    reader.onload = () => {
      if (ammounts11.controls[i].valid) {
        this.fileUploaded = true;
      } else {
      }
    };
  }
  onImagePick2(event: Event,i:number) {
    this.uploadFilesautre5.splice(i,1)
    let ammounts5 = this.relevejointform.get('ammounts5') as FormArray;
    // let fileName = (event.target as HTMLInputElement).files[0].name;
     //ammounts3.controls[i].patchValue({ image: fileName });  
     const file = (event.target as HTMLInputElement).files[0];
     if (file)
{
  const myRenamedFile = new File([file], ammounts5.value.at(i).mois,{
    type: "application/pdf",
  });
  ammounts5.controls[i].patchValue({ image: myRenamedFile });  
  ammounts5.controls[i].updateValueAndValidity();
  if (ammounts5.value.at(i).mois=='0'||ammounts5.value.at(i).mois=='')
      {
        return (
          Swal.fire({
          title: 'veuillez saisir des données valides pour le type banque avant d\'insérer un fichier',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {this.loading=false
        }).catch(() => {
          Swal.fire('opération non aboutie!')
        }))
      }  
  if (ammounts5.value.at(i).mois!=''&&ammounts5.value.at(i).mois!='0')
  {
    this.uploadFilesautre5.push(ammounts5.value.at(i).image);
  
  }}
     
     const reader = new FileReader();
     reader.onload = () => {
       if (ammounts5.controls[i].valid) {
         this.fileUploaded = true;
       } else {
       }
     };
     reader.readAsDataURL(file);
    
  }
  onImagePick3(event: Event,typeindex:number,i:number) {
    this.uploadFilesautre6.splice(i,1)
    let ammounts6 = this.salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6') as FormArray;
    // let fileName = (event.target as HTMLInputElement).files[0].name;
     //ammounts3.controls[i].patchValue({ image: fileName });  
     const file = (event.target as HTMLInputElement).files[0];
     let myRenamedFile:File
     if (file)
     {
      ammounts6.value.at(i).matricule!=''?myRenamedFile = new File([file], ammounts6.value.at(i).matricule,{
        type: "application/pdf",
      }):
      myRenamedFile = new File([file], this.ajoutsalarieform.get('matricule').value,{
        type: "application/pdf",
      })
      this.ajoutsalarieform.patchValue({ image: myRenamedFile });  
      this.ajoutsalarieform.updateValueAndValidity();
     /* if (ammounts6.value.at(i).salairebrut=='0'||ammounts6.value.at(i).salairebrut=='')
      {
        return (
          Swal.fire({
          title: 'veuillez saisir des données valides pour le type salaire avant d\'insérer un fichier',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {this.loading=false
        }).catch(() => {
          Swal.fire('opération non aboutie!')
        }))
      }  */
      if (this.ajoutsalarieform.get('matricule').value!='')
      {
        this.uploadFilesautre6.push(this.ajoutsalarieform.get('image').value);      
      }    
     }
    
     console.log(this.uploadFilesautre6)
     const reader = new FileReader();
     reader.onload = () => {
       if (this.ajoutsalarieform.valid) {
         this.fileUploaded = true;
       } else {
       }
     };
     reader.readAsDataURL(file);
    
  }
  verifydoublons(i:number)
  {
    let ammounts = this.editionnoteform.get('ammounts') as FormArray;
let numerosavoir=[]
ammounts.value.filter((item)=>
{
  item.typepiece=='avoir'?numerosavoir.push(item.numeronote):''
})
console.log(ammounts.value.at(i).numeronote)
let numerosavoirsliced=numerosavoir.slice(0,-1)
console.log(numerosavoirsliced)
if(numerosavoirsliced.find(e => e==ammounts.value.at(i).numeronote))
{
  return (alert('numéro avoir déjà saisi'),
  ammounts.controls[i].patchValue({ numeronote: ''})
  )
}
  }
  verifyfacture(i:number,arrayglobal:Deccomptabilite[],arrayspecific:Autre[],value:any):boolean
  {
    let invoice:any
    let annee1:any
    let factexist:any
    let specificdate:any
    let specificdeccomptabilite:any

    let anneeexist:any
let numerosfacture=[]
let annees=[]
let factureexistente=false

arrayglobal.forEach(element => (annee1=element.annee===this.option1Value,annees.push({
  annee1
})))
annees.find(e => anneeexist=e.annee1===true);
//@ts-ignore
arrayspecific.forEach(e => e.forEach(element => {
  (invoice=element.numeronote == value,numerosfacture.push({
    in:invoice,
    date:element.date
  }))
}))
numerosfacture.find(e => e.in===true?(specificdate=e.date,factexist=e.in):'');
    //console.log(annees)
    /*console.log(fournisseurs)
    console.log(numerosfacture)
    console.log(invoice2)
    console.log(this.DeccomptabiliteService.deccomptabilites)
    console.log(fourexist)
    console.log(factexist)*/  
 if(anneeexist&&factexist)
  {
    arrayglobal.forEach(element => element.autre0.find(e => (e.numeronote==`${value}`))?specificdeccomptabilite=element:'')
    arrayglobal.forEach(element => element.autre1.find(e => (e.numeronote==`${value}`))?specificdeccomptabilite=element:'')
    console.log(specificdeccomptabilite)
    Swal.fire({
      title: `${specificdate}`?
      this.activite=='Syndic des copropriétaires'?'numéro quittance que vous voulez ajouter existe déjà à la date du '+`${specificdate}`+', pour pouvoir l\'ajouter, veuillez réinitialiser tous les fichiers comptables des mois qui suivent à commencer par le dernier mois':
      'numéro facture que vous voulez ajouter existe déjà à la date du '+`${specificdate}`+', pour pouvoir l\'ajouter, veuillez réinitialiser tous les fichiers comptables des mois qui suivent à commencer par le dernier mois'
      :this.activite=='Syndic des copropriétaires'?'numéro quittance que vous voulez ajouter existe déjà au fichier comptable du '+`${specificdeccomptabilite.mois}`+'/'+`${specificdeccomptabilite.annee}`+', pour pouvoir l\'ajouter, veuillez réinitialiser tous les fichiers comptables des mois qui suivent à commencer par le dernier mois':
      'numéro facture que vous voulez ajouter existe déjà à la date du '+`${specificdeccomptabilite.mois}`+'/'+`${specificdeccomptabilite.annee}`+', pour pouvoir l\'ajouter, veuillez réinitialiser tous les fichiers comptables des mois qui suivent à commencer par le dernier mois',
      icon: 'error',
      confirmButtonColor: '#3085d6',
    });
    this.loading = false;
    factureexistente=true
    console.log(factureexistente)

    return factureexistente
  }
  }
  async verifyinvoicesyndic(i:number)
  {
    let ammounts9 = this.facturefournisseurform.get('ammounts9') as FormArray;
    let invoice1:any
    let invoice2:any
    let invoice3:any
    let invoice4:any
    let invoice6:any
    let fourexist:any
    let factexist:any
    let anneeexist:any
let fournisseurs=[]
let numerosfacture=[]
let annees=[]
let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>!item.source)

    desireddeccomptables.forEach(element => element.autre9.forEach(e => (invoice1=e.fournisseur === ammounts9.value.at(i).fournisseur,fournisseurs.push({
      invoice1
    }))));
    fournisseurs.find(e => fourexist=e.invoice1===true);
    desireddeccomptables.forEach(element => (invoice2=element.annee===this.option1Value,annees.push({
      invoice2
    })))
    annees.find(e => anneeexist=e.invoice2===true);
    desireddeccomptables.forEach(element => element.autre9.forEach(e => (invoice3=e.numerofacture === ammounts9.value.at(i).numerofacture,console.log(invoice3),numerosfacture.push({
      invoice3
    }))));
    numerosfacture.find(e => factexist=e.invoice3===true);
    //console.log(annees)
    /*console.log(fournisseurs)
    console.log(numerosfacture)
    console.log(invoice2)
    console.log(this.DeccomptabiliteService.deccomptabilites)
    console.log(fourexist)
    console.log(factexist)*/
    if(ammounts9.length>1)
    {
      let ammounts3sliced=ammounts9.getRawValue().slice(0,-1)
      ammounts3sliced.find(e => invoice4=e.fournisseur === ammounts9.getRawValue()[i].fournisseur);
      ammounts3sliced.find(e => invoice6=e.numerofacture === ammounts9.getRawValue()[i].numerofacture);
    }
    
if(ammounts9.value.at(i).fournisseur&&ammounts9.value.at(i).numerofacture)
{
  if(fourexist&&anneeexist&&factexist||invoice4&&invoice6)
  try {
      const result = await Swal.fire({
        title: 'numéro facture pour cette année dèjà utilisé',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
      this.loading = false;
      ammounts9.controls[i].patchValue({ numerofacture: '',fournisseur:'' });  
    } catch {
      Swal.fire('opération non aboutie!');
    }
}
  }
  async verifyinvoice(i:number)
  {
    let ammounts3 = this.factureachatform.get('ammounts3') as FormArray;
    let invoice1:any
    let invoice2:any
    let invoice3:any
    let invoice4:any
    let invoice6:any
    let fourexist:any
    let factexist:any
    let anneeexist:any
let fournisseurs=[]
let numerosfacture=[]
let annees=[]
let desireddeccomptables=this.deccompt.deccomptabilites.filter(item=>!item.source)

    desireddeccomptables.forEach(element => element.autre3.forEach(e => (invoice1=e.fournisseur === ammounts3.value.at(i).fournisseur,fournisseurs.push({
      invoice1
    }))));
    fournisseurs.find(e => fourexist=e.invoice1===true);
    desireddeccomptables.forEach(element => (invoice2=element.annee===this.option1Value,annees.push({
      invoice2
    })))
    annees.find(e => anneeexist=e.invoice2===true);
    desireddeccomptables.forEach(element => element.autre3.forEach(e => (invoice3=e.numerofacture === ammounts3.value.at(i).numerofacture,console.log(invoice3),numerosfacture.push({
      invoice3
    }))));
    numerosfacture.find(e => factexist=e.invoice3===true);
    //console.log(annees)
    /*console.log(fournisseurs)
    console.log(numerosfacture)
    console.log(invoice2)
    console.log(this.DeccomptabiliteService.deccomptabilites)
    console.log(fourexist)
    console.log(factexist)*/
    if(ammounts3.length>1)
    {
      let ammounts3sliced=ammounts3.getRawValue().slice(0,-1)
      ammounts3sliced.find(e => invoice4=e.fournisseur === ammounts3.getRawValue()[i].fournisseur);
      ammounts3sliced.find(e => invoice6=e.numerofacture === ammounts3.getRawValue()[i].numerofacture);
    }
    
if(ammounts3.value.at(i).fournisseur&&ammounts3.value.at(i).numerofacture)
{
  if(fourexist&&anneeexist&&factexist||invoice4&&invoice6)
  try {
      const result = await Swal.fire({
        title: 'numéro facture pour cette année dèjà utilisé',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      });
      this.loading = false;
      ammounts3.controls[i].patchValue({ numerofacture: '',fournisseur:'' });  
    } catch {
      Swal.fire('opération non aboutie!');
    }
}
    

  }
  getNumeronote(user: any, option1Value: string, deccomptabilite: any): string {
    let numerofactureverif: string[] = [];
    
    const desireddeccomptables = this.deccompt.deccomptabilites.filter(item => 
      new Date(item.created) < new Date(deccomptabilite.created) && !item.source
    );
  
    desireddeccomptables.forEach(element => {
      if (!element.source && element.contientnumero && element.annee === option1Value) {
        numerofactureverif.push(...element.autre1.map(e => e.numeronote));
      }
    });
  
    if (numerofactureverif.every(e => e === '')) {
      console.log('here');
      return this.deccompt.filterByValue(user.numeronote, option1Value)[0].numero;
    } else {
      console.log('here2');
      const maxNumero = Math.max(...desireddeccomptables.map(a => 
        Math.max(...a.autre1.map(b => +b.numeronote)))
      ) + 1;
      return maxNumero.toString();
    }
  }
  
  sort()
  {
    this.settedobjetsdebit=this.settedobjetsdebit.sort()
    this.settedobjetscredit=this.settedobjetscredit.sort()
    this.settedclients=this.settedclients.sort()
    this.settednatures=this.settednatures.sort()
    this.settedfournisseurs=this.settedfournisseurs.sort()
   }

  ngOnDestroy(){
    this.intervalSub?this.intervalSub.unsubscribe():'';
    this.deccomptabilitesSub?this.deccomptabilitesSub.unsubscribe():'';
  this.usedres.getUsedressourcedataById(this.deccomptabilite._id).then(
      (usedres:Usedressourcemodel)=>
            {
              if(usedres)
              {
                if(usedres.connecteduser==this.currentUser.userId)
                {
                  this.usedres.deleteusedressourcedataById(this.deccomptabilite._id).then(
                    (usedres:Usedressourcemodel)=>
                          {
                            
                          }
                  )
                }
              }
            }
    )
  }
  closePopup() {
    this.displaysalaire ="none";
  }
  reloadPage (){
    setTimeout(() => window.location.reload(), 1000);
    
    
  }
  onChangecop(u:number)
  {
    let ammounts10= this.ajoutdccopform.get('ammounts10') as FormArray
    if(ammounts10.value.at(u).periodicite=='Autre')
        {
          this.periodicites.push(ammounts10.value.at(u).autreperiodicite
          )

          this.settedperiodicites= this.periodicites.filter((obj, index) => {
            return index === this.periodicites.findIndex(o => obj === o);
          });
          this.settedperiodicites=this.settedperiodicites.sort()
          ammounts10.at(u).patchValue(
            {
              periodicite:ammounts10.value.at(u).autreperiodicite
        
            }
          )
        }
  }
  async patch()
  {
    let ammounts10= this.ajoutdccopform.get('ammounts10') as FormArray
     for (let j = 0; j < ammounts10.length; j++)
      {
        ammounts10.at(j).patchValue({
          periodicite:this.option0Value
         })
      }
      const { value: numero } = await Swal.fire({
        title: 'est ce que le montant exigible par échéance est le même pour tous les copropriétaires?',
        input: 'text',
        inputLabel: 'Montant exigible par échéance',
        inputValue: '',
        showCancelButton: true,
        
      })
      
      if (numero) 
      {
        Swal.fire(`le montant exigible par échéance est  ${numero}`)
     let montant= parseFloat(numero).toFixed(3)
        for (let j = 0; j < ammounts10.length; j++)
        {
          ammounts10.at(j).patchValue({
            montantecheance:montant
           })
        }
      }
  }
  closecopPopup()
  {
    this.displaycop ="none";
    this.tokenStorage.saved=true
this.reloadPage()
  }
}
