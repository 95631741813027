<div class="ajout-autre-ds">
    <h1>Ajout Autre DS</h1>
    <mat-divider></mat-divider>
    <div class="form-group">
      <mat-form-field appearance="fill">
        <mat-label>Sélectionner client</mat-label>
        <mat-select id="client-select" [(ngModel)]="option01Value" (selectionChange)="getuserotherds(option01Value)" (click)="sort()">
          <mat-option></mat-option>
          <mat-option *ngFor="let us of filtredusers" [value]="us._id">
            {{us.clientcode}}
            <span *ngIf="us.nature === 'Personne Physique'">{{ us.firstname }} {{ us.lastname }}</span>
            <span *ngIf="us.nature === 'Personne Morale'">{{ us.raisonsociale }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <fieldset *ngIf="option01Value">
      <section *ngIf="currentUser.role === 'admin' && otherdsuser.length > 0">
        <legend>Liste des autres demandes de services</legend>
        <table class="table table-striped table-bordered table-hover" [formGroup]="otherdspreviousform">
          <thead>
            <tr>
              <th>Libelle</th>
              <th>Description</th>
              <th>Durée</th>
              <th>Total Honoraire</th>
              <th>Soumis/Non soumis</th>
              <th>Statut</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody formArrayName="ammounts1">
            <tr *ngFor="let ammount of ammountControls1; let i = index;" [formGroupName]="i">
              <td>
                <input type="text" class="form-control" formControlName="libelle" placeholder="Libéllé"/>
              </td>
              <td>
                <textarea class="form-control" formControlName="description" placeholder="Description" rows="2"></textarea>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="duree" placeholder="Durée" (change)="calculatetotalhonoraire(i, otherdspreviousform.get('ammounts1'))"/>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="totalhonoraire" placeholder="Total Honoraire" (blur)="setThreeNumberDecimal($event)"/>
              </td>
              <td>
                <mat-checkbox formControlName="soumis"></mat-checkbox>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="paye" placeholder="Statut"/>
              </td>
              <td>
                <button mat-icon-button color="warn" (click)="removeammount1(i, otherdspreviousform.getRawValue().ammounts1.at(i).id)">
                  <mat-icon>remove_circle</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="modifyammount1(i, otherdspreviousform.getRawValue().ammounts1.at(i).id)">
                  <mat-icon>build</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
  
      <section *ngIf="currentUser.role === 'admin' || currentUser.role === 'supervisor'">
        <legend>Ajouter nouveau autre DS</legend>
        <table class="table table-striped table-bordered table-hover" [formGroup]="otherdsform">
          <thead>
            <tr>
              <th>Libelle</th>
              <th>Description</th>
              <th>Durée</th>
              <th>Total Honoraire</th>
              <th>Soumis/Non soumis</th>
              <th>Statut</th>
              <th>Timing</th>
              <th colspan="4">Chronométrage</th>
            </tr>
          </thead>
          <tbody formArrayName="ammounts">
            <tr *ngFor="let ammount of ammountControls; let i = index;" [formGroupName]="i">
              <td>
                <mat-form-field appearance="fill">
                  <mat-select formControlName="libelle" (selectionChange)="getotherdsinfo(i, otherdsform.getRawValue().ammounts.at(i).libelle)">
                    <mat-option *ngFor="let lib of otherdss" [value]="lib.libelle">{{ lib.libelle }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <textarea class="form-control" formControlName="description" placeholder="Description" rows="2"></textarea>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="duree" placeholder="Durée" (change)="calculatetotalhonoraire(i, otherdsform.get('ammounts'))"/>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="totalhonoraire" placeholder="Total Honoraire" (blur)="setThreeNumberDecimal($event)"/>
              </td>
              <td>
                <mat-checkbox formControlName="soumis"></mat-checkbox>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="paye" placeholder="Statut"/>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="countdown" placeholder="Countdown" readonly/>
              </td>
              <td>
                <button mat-icon-button color="primary" (click)="play(i)">
                  <mat-icon>play_arrow</mat-icon>
                </button>
              </td>
              <td>
                <button mat-icon-button color="accent" (click)="replay(i)">
                  <mat-icon>redo</mat-icon>
                </button>
              </td>
              <td>
                <button mat-icon-button color="accent" (click)="pause(i)">
                  <mat-icon>pause</mat-icon>
                </button>
              </td>
              <td>
                <button mat-icon-button color="warn" (click)="removeammount(i)">
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="11" class="text-right">
                <button mat-icon-button color="primary" (click)="addammount()">
                  <mat-icon>add_circle</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button mat-raised-button color="primary" (click)="save()">
          <mat-icon *ngIf="submitting">hourglass_full</mat-icon> Valider
        </button>
      </section>
    </fieldset>
  </div>
  