import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDisplayDirective } from '../directive/user-display.directive'; // Adjust the path as necessary



@NgModule({
  declarations: [UserDisplayDirective],
  imports: [
    CommonModule
  ],
  exports: [UserDisplayDirective] // Export the directive to make it available for import
})
export class SharedmoduleModule { }
