  <div class="wrapper"*ngIf="deccomptabilite">
    <div id="payementinvoice">
        <h2 id="paiementitle">Détails traitement: <span>{{ deccomptabilite.mois }}</span>-<span>{{ deccomptabilite.annee }}</span></h2> 
        <div class="col-sm-3" style="margin: 0;">
            <img src="../../assets/img/Frame 2177.png" id="logo" class="navbar-left" alt="image-DGI" style="width: 20%; height: 20%; border: none;">
        </div>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title id="bloc1title">
                        Informations générales
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table id="table1">
                    <thead>
                        <tr>
                            <th>Date de création du fichier:</th>
                            <td colspan="2">{{ deccomptabilite.created | customDate }}</td>
                            <th>Date de modification du fichier:</th>
                            <td colspan="2">{{ deccomptabilite.updated | customDate }}</td>
                        </tr>
                        <tr *ngIf="(deccomptabilite.dateouverturedossier && currentUser.role === 'admin') || (deccomptabilite.dateouverturedossier && currentUser.role === 'supervisor')">
                            <th>Date ouverture dossier:</th>
                            <td>{{ deccomptabilite.dateouverturedossier | customDate }}</td>
                        </tr>
                        <tr *ngIf="(deccomptabilite.affecte && currentUser.role === 'admin') || (deccomptabilite.affecte && currentUser.role === 'supervisor')">
                            <th>Collaborateur affecté:</th>
                            <td>{{ filterusers(deccomptabilite.affecte) }}{{ prenomcollab }}&nbsp;{{ nomcollab }}</td>
                        </tr>
                        <tr *ngIf="currentUser.role === 'admin' || currentUser.role === 'supervisor'">
                            <th>Durée de traitement:</th>
                            <td>{{ countdown }}</td>
                        </tr>
                        <tr>
                            <th>Nom:</th>
                            <td>{{ lastname }}</td>
                            <th>Prénom:</th>
                            <td>{{ firstname }}</td>
                        </tr>
                        <tr>
                            <th>Nature:</th>
                            <td>{{ nature }}</td>
                        </tr>
                        <tr>
                            <th>Mois:</th>
                            <td>{{ deccomptabilite.mois }}</td>
                            <th>Annee:</th>
                            <td>{{ deccomptabilite.annee }}</td>
                        </tr>
                    </thead>
                </table>
            </mat-expansion-panel>
    
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title id="bloc2title">
                        Statuts et Motifs
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table id="bloc2content">
                    <thead>
                        <tr>
                            <th style="text-align: center;">Statuts:</th>
                            <th style="text-align: center;">Motifs:</th>
                            <th style="text-align: center;">Date traitement:</th>
                            <th style="text-align: center;">Durée de traitement:</th>
                            <th style="text-align: center;">Traité par:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let statut of sortedallstatuts">
                            <td style="text-align: center;">
                                <span *ngIf="statut.statutclient">{{ statut.statutclient }}</span>
                                <span *ngIf="statut.statut">{{ statut.statut }}</span>
                                <span *ngIf="statut.statutcoll">{{ statut.statutcoll }}</span>
                                <span *ngIf="statut.statutparametrage">{{ statut.statutparametrage }}</span>
                            </td>
                            <td style="text-align: center;">
                                <span *ngIf="statut.statut">{{ statut.motif }}</span>
                                <span *ngIf="statut.statutcoll">{{ statut.motifcoll }}</span>
                            </td>
                            <td style="text-align: center;">{{ statut.datefin | customDate }}</td>
                            <td style="text-align: center;">{{ statut.duree }}</td>
                            <td style="text-align: center;">
                                <span *ngIf="statut.statutclient">Client</span>
                                <span *ngIf="statut.statut || statut.statutparametrage">Admin</span>
                                <span *ngIf="statut.statutcoll">Collaborateur</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
    
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title id="bloc3title">
                        Résultats
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table id="bloc3content">
                    <tbody>
                        <tr id="resultrow">
                            <td colspan="3">
                                <div class="col-6">
                                    <p>Total traitement</p>
                                </div> 
                            </td>
                            <td style="text-align: center;">{{ totaltime }} <span *ngIf="totaltime !== 0">minutes</span></td>
                        </tr>
                        <tr id="resultrow2" *ngIf="prixminute">
                            <td colspan="3">
                                <div class="col-6">
                                    <p>Coût par minute (tarif de base)</p>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="col-6">
                                    <p>{{ prixminute | number:'1.3-3' | removeComma }} <span>dinars</span></p>
                                </div>
                            </td>
                        </tr>
                        <tr id="resultrow2" *ngIf="prixminutespecial">
                            <td colspan="3">
                                <div class="col-6">
                                    <p>Coût par minute (tarif préférentiel de groupe)</p>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="col-6">
                                    <p>{{ prixminutespecial | number:'1.3-3' | removeComma }} <span>dinars</span></p>
                                </div>
                            </td>
                        </tr>
                        <tr id="resultrow2" *ngIf="prixminuteindiv">
                            <td colspan="3">
                                <div class="col-6">
                                    <p>Coût par minute (tarif préférentiel individuel)</p>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="col-6">
                                    <p>{{ prixminuteindiv | number:'1.3-3' | removeComma }} <span>dinars</span></p>
                                </div>
                            </td>
                        </tr>
                        <tr id="resultrow2">
                            <td colspan="3">
                                <div class="col-6">
                                    <p>Total honoraires</p>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="col-6">
                                    <p>{{ totalhonoraire | number:'1.3-3' | removeComma }} <span>dinars</span></p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <button
        type="button"
        style="font-style: Monaco"
        class="btn btn-danger fs-5 mx-auto d-block rounded-pill mt-4 mb-3"
        (click)="payement()"
      >
        Generer Récapitulatif traitement
      </button>
      <mat-tab-group>
        <mat-tab label="Edition de quittance"*ngIf="showeditionnotepast">
            <ng-template matTabContent>
              <fieldset id="editionnotepast"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Edition de <span *ngIf="contientnumeroother == true">(quittances numerotées)</span><span *ngIf="contientnumeroother == false">(quittances non numerotées)</span></legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th rowspan="2">N° pièce</th>                        
                      <th rowspan="2">Jour</th>
                      <th rowspan="2">Date complète</th>
                      <th rowspan="2">Type</th>
                      <th *ngIf="contientnumeroother" rowspan="2">N°</th>
                      <th rowspan="2">Montant</th>
                      <th rowspan="2">Coproprietaire</th>
                      <th rowspan="2">Objet</th>
                      <th rowspan="2">Nature</th>
                      <th rowspan="2">Annulé</th>
                      <th colspan="4">Données complémentaires</th>
                    </tr>
                    <tr>
                      <th>Banque</th>
                      <th>Numéro</th>
                      <th>Echéance</th>
                      <th>Date de virement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre0; let i = index;">
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].numeropiece }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].jour | lowercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].date | lowercase }}</td>
                      <td style="text-align: center;"><span *ngIf="deccomptabilite.autre0[i].typepiece == 'facture'">quittance</span></td>
                      <td style="text-align: center;" *ngIf="contientnumeroother">{{ deccomptabilite.autre0[i].numeronote }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].montantht | number:'1.3-3' | removeComma }}</td>   
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].client | uppercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].objet | uppercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].nature | uppercase }}</td>
                      <td style="text-align: center;"><span *ngIf="deccomptabilite.autre0[i].annule" style="color: red;">X</span></td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].banque | uppercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].numerocompte | uppercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].echeance | uppercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre0[i].datevirement | uppercase }}</td>    
                    </tr>
                    <tr id="resultrow">
                      <td colspan="5">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{ totalht0 | number:'1.3-3' | removeComma }}</td>        
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
        </mat-tab>
          
        <mat-tab label="Edition Notes"*ngIf="showeditionnote">
            <ng-template matTabContent>
              <fieldset id="editionnote"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Edition de
                  <span *ngIf="contientnumero == true && activite == 'Syndic des copropriétaires'">(quittances numerotées)</span>
                  <span *ngIf="contientnumero == false && activite == 'Syndic des copropriétaires'">(quittances non numerotées)</span>
                  <span *ngIf="activite != 'Syndic des copropriétaires'">notes d'honoraires</span>
                </legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th rowspan="2">N° pièce</th>
                      <th rowspan="2">Jour</th>
                      <th rowspan="2">Date complète</th>
                      <th *ngIf="contientnumero == true || activite != 'Syndic des copropriétaires'" rowspan="2">N° Note d'honoraire</th>
                      <th rowspan="2">Type pièce</th>
                      <th rowspan="2" *ngIf="tvafodec">Montant FODEC</th>
                      <th rowspan="2" *ngIf="tvatpe">Montant TPE</th>

                      <th rowspan="2" *ngIf="tva7">Montant HT7</th>
                      <th rowspan="2" *ngIf="tva19">Montant HT19</th>
                      <th rowspan="2" *ngIf="tva13">Montant HT13</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires' && tvaexonere">Montant HT exonéré</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires' && tvasuspens">Montant HT suspension</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires' && tvaexport">Montant HT export</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires'">Montant TVA</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires'">Montant DT</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires'">Montant TTC</th>
                      <th rowspan="2" *ngIf="activite != 'Syndic des copropriétaires'">Règlement</th>
                      <th rowspan="2">
                        <span *ngIf="activite != 'Syndic des copropriétaires'">Client</span>
                        <span *ngIf="activite == 'Syndic des copropriétaires'">Coproprietaire</span>
                      </th>
                      <th rowspan="2" *ngIf="activite == 'Syndic des copropriétaires'">Objet</th>
                      <th rowspan="2" *ngIf="activite == 'Syndic des copropriétaires'">Nature</th>
                      <th rowspan="2">Annulé</th>
                      <th colspan="4" *ngIf="activite == 'Syndic des copropriétaires'">Données complémentaires</th>
                    </tr>
                    <tr *ngIf="activite == 'Syndic des copropriétaires'">
                      <th>Banque</th>
                      <th>Numéro</th>
                      <th>Echéance</th>
                      <th>Date de virement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre1; let i = index;">
                      <td style="text-align: center;">{{ deccomptabilite.autre1[i].numeropiece }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre1[i].jour | lowercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre1[i].date | lowercase }}</td>
                      <td style="text-align: center;" *ngIf="contientnumero == true || activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].numeronote }}</td>
                      <td style="text-align: center;">
                        <span *ngIf="deccomptabilite.autre1[i].typepiece == 'facture' && activite == 'Syndic des copropriétaires'">quittance</span>
                        <span *ngIf="activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].typepiece }}</span>
                      </td>
                      <td style="text-align: center;" *ngIf="tvafodec">{{ deccomptabilite.autre1[i].montanthtfodec | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="tvatpe">{{ deccomptabilite.autre1[i].montanthttpe | number:'1.3-3' | removeComma }}</td>

                      <td style="text-align: center;" *ngIf="tva7">{{ deccomptabilite.autre1[i].montantht | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="tva19">{{ deccomptabilite.autre1[i].montantht19 | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="tva13">{{ deccomptabilite.autre1[i].montantht13 | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvaexonere">{{ deccomptabilite.autre1[i].montanthtexo | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvasuspens">{{ deccomptabilite.autre1[i].montanthtsus | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvaexport">{{ deccomptabilite.autre1[i].montanthtexp | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].montanttva | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].montantdt | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].montantttc | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].reglement | lowercase }}</td>
                      <td style="text-align: center;">{{ deccomptabilite.autre1[i].client | uppercase }}</td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].objet | uppercase }}</td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].nature | uppercase }}</td>
                      <td style="text-align: center;"><span *ngIf="deccomptabilite.autre1[i].annule" style="color: red;">X</span></td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].banque | uppercase }}</td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].numerocompte | uppercase }}</td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].echeance | uppercase }}</td>
                      <td style="text-align: center;" *ngIf="activite == 'Syndic des copropriétaires'">{{ deccomptabilite.autre1[i].datevirement | uppercase }}</td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="5">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td *ngIf="tvafodec" style="text-align: center;">{{ totalhtfodec | number:'1.3-3' | removeComma }}</td>
                      <td *ngIf="tvatpe" style="text-align: center;">{{ totalhttpe | number:'1.3-3' | removeComma }}</td>

                      <td *ngIf="tva7" style="text-align: center;">{{ totalht | number:'1.3-3' | removeComma }}</td>
                      <td *ngIf="tva19" style="text-align: center;">{{ totalht19 | number:'1.3-3' | removeComma }}</td>
                      <td *ngIf="tva13" style="text-align: center;">{{ totalht13 | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvaexonere">{{ totalhtexo | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvasuspens">{{ totalhtsus | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires' && tvaexport">{{ totalhtexp | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ totaltva1 | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ totaldt | number:'1.3-3' | removeComma }}</td>
                      <td style="text-align: center;" *ngIf="activite != 'Syndic des copropriétaires'">{{ totalttc1 | number:'1.3-3' | removeComma }}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
        </mat-tab>
          
        <mat-tab label="Recette Journalière"*ngIf="showrecettejour">
            <ng-template matTabContent>
              <!--recette journaliére-->
              <fieldset id="recettejournaliere"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Recette journalière</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>N° pièce</th>
                      <th>Jour</th>
                      <th>Date complète</th>
                      <th *ngIf="tvafodec">Recette FODEC</th>
                      <th *ngIf="tvatpe">Recette TPE</th>

                      <th>Recette 7%<span *ngIf="activite === 'Médecin'">(Actes de soin)</span></th>
                      <th *ngIf="tva19">Recette 19%</th>
                      <th *ngIf="tva13">Recette 13%</th>
                      <th>Recette exo</th>
                      <th>Recette sus</th>
                      <th>Recette exp</th>
                      <th>Montant HT</th>
                      <th>Montant TVA</th>
                      <th>Montant TTC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre2; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].numeropiece}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].jour}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].date}}</td>
                      <td style="text-align: center;"*ngIf="tvafodec">{{deccomptabilite.autre2[i].recettefodec | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;"*ngIf="tvatpe">{{deccomptabilite.autre2[i].recettetpe | number:'1.3-3' | removeComma}}</td>

                      <td style="text-align: center;">{{deccomptabilite.autre2[i].recette | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;" *ngIf="tva19">{{deccomptabilite.autre2[i].recette19 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;" *ngIf="tva13">{{deccomptabilite.autre2[i].recette13 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].recetteexo | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].recettesus | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].recetteexp | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].montantht | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].montanttva | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre2[i].montantttc | number:'1.3-3' | removeComma}}</td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="3">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td *ngIf="tvafodec" style="text-align: center;">{{totalrecettefodec | number:'1.3-3' | removeComma}}</td>
                      <td *ngIf="tvatpe" style="text-align: center;">{{totalrecettetpe | number:'1.3-3' | removeComma}}</td>

                      <td style="text-align: center;">{{totalrecette | number:'1.3-3' | removeComma}}</td>
                      <td *ngIf="tva19" style="text-align: center;">{{totalrecette19 | number:'1.3-3' | removeComma}}</td>
                      <td *ngIf="tva13" style="text-align: center;">{{totalrecette13 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalrecetteexo | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalrecettesus | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalrecetteexp | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalhtrecette | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totaltva2recette | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalttc2recette | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
        </mat-tab>
          
        <mat-tab label="Facture Achat"*ngIf="showachat">
            <ng-template matTabContent>
              <!--facture achat section-->
              <fieldset id="achat"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Achat</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>N° pièce</th>
                      <th>Jour</th>
                      <th>Date complète</th>
                      <th>Fournisseur</th>
                      <th>Type pièce</th>
                      <th>N°</th>
                      <th>Nature de l'achat</th>
                      <th>Montant HT</th>
                      <th>Montant HT Devise</th>
                      <th>Montant TVA</th>
                      <th>Montant DT</th>
                      <th>Montant TTC</th>
                      <th>Règlement</th>
                      <th>Document Joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre3; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].numeropiece}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].jour | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].date | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].fournisseur | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].typepiece | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].numerofacture | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].natureachat | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].montantht | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].montanthtdevise | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].montanttva | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].montantdt | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].montantttc | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre3[i].reglement | uppercase}}</td>
                      <td *ngIf="deccomptabilite.autre3[i].ficheUrl">
                        <a href="{{deccomptabilite.autre3[i].ficheUrl | lowercase}}" target="_blank">Aperçu facture</a>
                      </td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="7">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{totalht3 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalht3devise | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totaltva3 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totaldt3 | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalttc3 | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Facture Fournisseur"*ngIf="showfacturefournisseur">
            <ng-template matTabContent>
              <!--facture fournisseur section-->
              <fieldset id="achat"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Factures Fournisseurs</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>N° pièce</th>
                      <th>Jour</th>
                      <th>Date complète</th>
                      <th>Type pièce</th>
                      <th>Fournisseur/Bénéficiaire</th>
                      <th>N°</th>
                      <th>Nature de l'achat</th>
                      <th>Montant</th>
                      <th>Document Joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre9; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].numeropiece}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].jour | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].date | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].typepiece | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].fournisseur | uppercase}}{{deccomptabilite.autre9[i].beneficiaire | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].numerofacture | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].natureachat | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre9[i].montantht | number:'1.3-3' | removeComma}}</td>
                      <td *ngIf="deccomptabilite.autre9[i].ficheUrl">
                        <a href="{{deccomptabilite.autre9[i].ficheUrl | lowercase}}" target="_blank">Aperçu facture</a>
                      </td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="7">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{totalht9 | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Dépenses Chèques"*ngIf="showachatcheque">
            <ng-template matTabContent>
              <!--dépenses chèques section-->
              <fieldset id="achat"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Dépenses chèques</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>N° pièce</th>
                      <th>Jour</th>
                      <th>Date complète</th>
                      <th>Type pièce</th>
                      <th>Fournisseur/Bénéficiaire</th>
                      <th>N°</th>
                      <th>Nature de l'achat</th>
                      <th>Montant</th>
                      <th>Règlement</th>
                      <th>Document Joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre8; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].numeropiece}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].jour | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].date | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].typepiece | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].fournisseur | uppercase}}{{deccomptabilite.autre8[i].beneficiaire | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].numerofacture | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].natureachat | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].montantht | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre8[i].reglement | uppercase}}</td>
                      <td *ngIf="deccomptabilite.autre8[i].ficheUrl">
                        <a href="{{deccomptabilite.autre8[i].ficheUrl | lowercase}}" target="_blank">Aperçu facture</a>
                      </td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="7">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{totalht8 | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Dépenses Espèces"*ngIf="showachatespece">
            <ng-template matTabContent>
              <!--dépenses espèces section-->
              <fieldset id="achat"  class="users-grid" style="overflow-x: auto; overflow-y: auto;">
                <legend>Dépenses espèces</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>N° pièce</th>
                      <th>Jour</th>
                      <th>Date complète</th>
                      <th>Type pièce</th>
                      <th>Fournisseur/Bénéficiaire</th>
                      <th>N°</th>
                      <th>Nature de l'achat</th>
                      <th>Montant</th>
                      <th>Règlement</th>
                      <th>Document Joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre11; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].numeropiece}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].jour | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].date | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].typepiece | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].fournisseur | uppercase}}{{deccomptabilite.autre11[i].beneficiaire | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].numerofacture | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].natureachat | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].montantht | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre11[i].reglement | uppercase}}</td>
                      <td *ngIf="deccomptabilite.autre11[i].ficheUrl">
                        <a href="{{deccomptabilite.autre11[i].ficheUrl | lowercase}}" target="_blank">Aperçu facture</a>
                      </td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="7">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{totalht11 | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Saisie Relevé Manuel"*ngIf="showrelevemanuel">
            <ng-template matTabContent>
              <!--saisie relevé manuel-->
              <fieldset id="relevemanuel"  class="users-grid" style="overflow-x:auto;overflow-y: auto;">
                <legend>Relevé manuel</legend>
                <div *ngFor="let ammount of deccomptabilite.autre4; let i = index;">
                  <div style="border: 1px solid blue; padding: 10px; margin: 5px;">
                    Détails du compte N°{{i}}:
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th rowspan="2">Banque</th>
                          <th rowspan="2">N° compte</th>
                          <th rowspan="2">Devise</th>
                          <th colspan="2">solde du mois précédent</th>
                        </tr>
                        <tr>
                          <th>Débit</th>
                          <th>Crédit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="text-align: center;">{{ammount.banque | uppercase}}</td>
                          <td style="text-align: center;">{{ammount.numerocompte | lowercase}}</td>
                          <td style="text-align: center;">{{ammount.devise | uppercase}}</td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">
                            {{ammount.soldedebit | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">
                            {{ammount.debitdevise | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">
                            {{ammount.soldecredit | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">
                            {{ammount.creditdevise | number:'1.3-3' | removeComma}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
          
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th rowspan="2">Jour de l'opération</th>
                          <th rowspan="2">Date complète</th>
                          <th rowspan="2">Désignation</th>
                          <th rowspan="2">Débit</th>
                          <th rowspan="2">Crédit</th>
                          <th rowspan="2">Objet Débit</th>
                          <th rowspan="2">Objet Crédit</th>
                          <th colspan="2">Tiers</th>
                        </tr>
                        <tr>
                          <th>Fournisseur</th>
                          <th>Client</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let am of ammount.mouvements; let j = index;">
                          <td style="text-align: center;">{{am.jour | lowercase}}</td>
                          <td style="text-align: center;">{{am.date | lowercase}}</td>
                          <td style="text-align: center;">{{am.designation | lowercase}}</td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">{{am.debit}}</td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">{{am.debitdevise}}</td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">{{am.credit}}</td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">{{am.creditdevise}}</td>
                          <td style="text-align: center;">{{am.objetdebit | lowercase}}</td>
                          <td style="text-align: center;">{{am.objetcredit | lowercase}}</td>
                          <td style="text-align: center;">{{am.fournisseur | uppercase}}</td>
                          <td style="text-align: center;">{{am.client | uppercase}}</td>
                        </tr>
                        <tr id="resultrow">
                          <td colspan="3">
                            <div class="col-6">
                              <p>Total des mouvement du mois</p>
                            </div>
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">
                            {{getTotaldebit(i) | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">
                            {{getTotalcredit(i) | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">
                            {{getTotaldebitdevise(i) | number:'1.2-2' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">
                            {{getTotalcreditdevise(i) | number:'1.2-2' | removeComma}}
                          </td>
                        </tr>
                        <tr id="resultrow">
                          <td colspan="3">
                            <div class="col-6">
                              <p>Solde fin du mois</p>
                            </div>
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise=='tnd'" style="text-align: center;">
                            {{getTotal(i) | number:'1.3-3' | removeComma}}
                          </td>
                          <td *ngIf="deccomptabilite.autre4[i].devise!='tnd'" style="text-align: center;">
                            {{getTotaldevise(i) | number:'1.2-2' | removeComma}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Facture Releve Joint"*ngIf="showrelevejoint">
            <ng-template matTabContent>
              <!--facture releve joint-->
              <fieldset id="relevejoint"  class="users-grid" style="overflow-x:auto;overflow-y: auto;">
                <legend>Releve joint</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Mois</th>
                      <th>Annee</th>
                      <th>Document joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre5; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre5[i].mois | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre5[i].annee | lowercase}}</td>
                      <td><a href="{{deccomptabilite.autre5[i].ficheUrl | lowercase}}" target="_blank">Aperçu relevé</a></td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
          <mat-tab label="Traitement des salaires"*ngIf="showpaiemanuel">
            <ng-template matTabContent>
              <!--salaire section-->
              <fieldset id="salaire"  class="users-grid" style="overflow-x:auto;overflow-y: auto;">
                <legend>Traitement des salaires</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Matricule</th>
                      <th>Nom et prénom</th>
                      <th>Salaire Brut</th>
                      <th>Salaire Net</th>
                      <th>Document Joint</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ammount of deccomptabilite.autre6; let i = index;">
                      <td style="text-align: center;">{{deccomptabilite.autre6[i].matricule | lowercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre6[i].nomprenom | uppercase}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre6[i].salairebrut | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{deccomptabilite.autre6[i].salairenet | number:'1.3-3' | removeComma}}</td>
                      <td *ngIf="deccomptabilite.autre6[i].ficheUrl">
                        <a href="{{deccomptabilite.autre6[i].ficheUrl | lowercase}}" target="_blank">Aperçu contrat</a>
                      </td>
                    </tr>
                    <tr id="resultrow">
                      <td colspan="2">
                        <div class="col-6">
                          <p>Totaux</p>
                        </div>
                      </td>
                      <td style="text-align: center;">{{totalsalairebrut | number:'1.3-3' | removeComma}}</td>
                      <td style="text-align: center;">{{totalsalairenet | number:'1.3-3' | removeComma}}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-template>
          </mat-tab>
          
      </mat-tab-group>
  <app-view-dec-fisc-mens *ngIf="decfiscmens"[decfiscconly]="non" [natureactivite]="natureactivite"[user]="user"></app-view-dec-fisc-mens>
  <mat-accordion>
    <!-- Admin Actions Panel -->
    <mat-expansion-panel *ngIf="currentUser.role === 'admin' && !deccomptabilite.source">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Gérer les statuts
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <label for="underactivity">Gérer les statuts:</label>
            <span>
                <select #cricket (change)="update($event)">
                    <option value="default"> </option>
                    <option (click)="deletestatutcollab()">supprimer le dernier statut collaborateur</option>
                    <option (click)="deletestatutadmin()">supprimer le dernier statut admin</option>
                    <option (click)="deletestatutparam()">supprimer le dernier statut paramêtrage</option>
                </select>
            </span>
        </div>
    </mat-expansion-panel>

    <!-- Table Actions Panel -->
    <mat-expansion-panel *ngIf="user">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Actions sur la déclaration
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <table>
                <tr *ngIf="!deccomptabilite.source && showbuttons">
                    <td>
                        <button mat-raised-button color="primary" *ngIf="currentUser.role === 'admin'" (click)="affect()">
                            <span *ngIf="!deccomptabilite.affecte">Affecter collaborateur (Admin)</span>
                            <span *ngIf="deccomptabilite.affecte">Réaffecter collaborateur (Admin)</span>
                        </button>
                    </td>
                    <td>
                        <select #cricket [(ngModel)]="optionValue" *ngIf="currentUser.role === 'admin'">
                            <option value="default"></option>
                            <option *ngFor="let col of collab" [value]="col._id">
                                {{ col.firstname }} {{ col.lastname }}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr *ngIf="!deccomptabilite.source && showbuttons">
                    <td>
                        <button mat-raised-button color="primary" *ngIf="currentUser.role === 'admin' && deccomptabilite.affecte" (click)="decideadmin()">
                            Modifier Statut fichier comptable (Admin)
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!deccomptabilite.source && showbuttons">
                    <td>
                        <button mat-raised-button color="primary" *ngIf="(currentUser.role === 'supervisor' && deccomptabilite.affecte === currentUser._id) || (currentUser.rolesuperviseur === 'Autorisé' && deccomptabilite.affecte === currentUser._id)" (click)="traite()">
                            Marquer comme traité (Collaborateur)
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!deccomptabilite.source">
                    <td>
                        <button mat-raised-button color="primary" *ngIf="currentUser.role === 'basic'" (click)="getNavigation('modify-deccomptabilite', deccomptabilite._id)"
                        [ngStyle]="{ 'color': (verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)) ? 'black' : 'white', 'background-color': (verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)) ? 'gray' : 'green' }"
           [disabled]="verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)">
                            Modifier/Compléter
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!deccomptabilite.source">
                    <td>
                        <button mat-raised-button color="primary" (click)="generateExcel()" *ngIf="user.regimefiscalimpot !== 'Forfait D\'assiette' && (currentUser.role === 'admin' || currentUser.role === 'supervisor')">
                            Générer fichier
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-raised-button color="primary" (click)="getNavigation('modify-user-admin', deccomptabilite.userId)" *ngIf="user.regimefiscalimpot !== 'Forfait D\'assiette' && (currentUser.role === 'admin' || currentUser.role === 'supervisor')">
                            Accéder à la fiche du client
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </mat-expansion-panel>

    <!-- Delete Declaration Panel -->
    <mat-expansion-panel *ngIf="currentUser.role === 'admin' && !deccomptabilite.source">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Actions de suppression
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <button mat-raised-button color="warn" *ngIf="currentUser.role === 'admin'" (click)="onDelete()">
                Supprimer fichier
            </button>
        </div>
    </mat-expansion-panel>
  </mat-accordion>
  </div>
  