<div *ngIf="loading" id="loader"></div>

  
<div class="container-fluid" *ngIf="usertype=='Client'&&!loading|| role=='admin'&&!loading">
    <div id="entetedec">
        <h2>Formulaire de modification/complétude des déclarations</h2>
        <h3>{{nature}}</h3>
        <div> <label for=""> Mois:<strong> {{option2Value}}</strong></label>
          <label for=""> Année:<strong> {{option1Value}}</strong></label>
          <label *ngIf="role=='admin'"> Temps passé:<strong> {{countdown}}</strong></label></div>
    </div>
    <div *ngIf="user.activite!='Syndic des copropriétaires'">
      <!--rubrique de choix des données à saisir-->
<fieldset id="datalist">
  <legend>Liste des donnés à saisir:</legend> 
  <table>
    
    <tbody>
    <tr>
      
     <td style="text-align: center;"> 
        <div><input type="checkbox" id="myCheck1" (click)="myFunction1()"[(ngModel)]="option3Value" /></div>
        <div><label for="rb1">Chiffre d'affaires</label></div>
     </td>
     <td style="text-align: center;">
        <div><input type="checkbox" id="myCheck2" (click)="myFunction2()"[(ngModel)]="option4Value" /></div>
        <div><label for="rb1">Achats</label></div>
      </td>
      <td style="text-align: center;">
        <div><input type="checkbox" id="myCheck3"(click)="myFunction3()"[(ngModel)]="option5Value" /></div>
        <div><label for="rb1">Banques</label></div>
        <div class="spinner-grow" role="status"*ngIf="!showrelevemanuel">
          <span class="visually-hidden">Loading...</span>
        </div>
      </td>
     <td style="text-align: center;">
        <div><input type="checkbox" id="myCheck4" (click)="myFunction4()"[(ngModel)]="option6Value" /></div>
        <div><label for="rb1">Salaires</label></div>
      </td>
  </tr>
  <!--
  <tr id="verifrow">
      
    <td>
      
       <div><input *ngIf="showretenueverif===true" type="checkbox" id="myCheck24" (click)="myFunction24()"[(ngModel)]="option65Value" /></div>
       <div><label  *ngIf="option65Value===true" style="color: green;">Validé</label></div>
       <div><label  *ngIf="option65Value===false" style="color: red;">Non Validé</label></div>

       
    </td>
    <td>
       <div><input  *ngIf="showtfpverif===true" type="checkbox" id="myCheck25" (click)="myFunction25()"[(ngModel)]="option66Value" /></div>
       <div><label *ngIf="option66Value===true" style="color: green;">Validé</label></div>
       <div><label  *ngIf="option66Value===false" style="color: red;">Non Validé</label></div>

     </td>
     <td>
      <div><input *ngIf="showfoprolosverif===true" type="checkbox" id="myCheck26"(click)="myFunction26()"[(ngModel)]="option67Value" /></div>
        <div><label *ngIf="option67Value===true" style="color: green;">Validé</label></div>
        <div><label  *ngIf="option67Value===false" style="color: red;">Non Validé</label></div>

     </td>
     <td>
      <div><input *ngIf="showtvaverif===true" type="checkbox" id="myCheck27" (click)="myFunction27()"[(ngModel)]="option68Value" /></div>
        <div><label *ngIf="option68Value===true" style="color: green;">Validé</label></div>
        <div><label  *ngIf="option68Value===false" style="color: red;">Non Validé</label></div>

     </td>
     <td>
      <div><input *ngIf="showtimbreverif===true" type="checkbox" id="myCheck28" (click)="myFunction28()"[(ngModel)]="option69Value" /></div>
        <div><label *ngIf="option69Value===true" style="color: green;">Validé</label></div>
        <div><label  *ngIf="option69Value===false" style="color: red;">Non Validé</label></div>

     </td>
     <td>
      <div><input *ngIf="showtclverif===true" type="checkbox" id="myCheck29" (click)="myFunction29()"[(ngModel)]="option70Value" /></div>
        <div><label *ngIf="option70Value===true" style="color: green;">Validé</label></div>
        <div><label  *ngIf="option70Value===false" style="color: red;">Non Validé</label></div>

     </td>
   
 </tr>
 -->
    </tbody>
  </table>
</fieldset> 
<!--buttonslist-->
<button id="savebutton" class="btn btn-success"type="button" (click)="onSubmit($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Sauvegarder</button>
<button id="resetbutton" class="btn btn-secondary" type="button" (click)="restartform()"
      *ngIf="option3Value===true||option4Value===true||option5Value===true||option6Value===true">Réinitialiser tout</button>
      <button id="sendbutton" class="btn btn-success"type="button" (click)="onSend($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Valider les données</button>
<!--datacontainer-->
<div class="container-fluid"id="tabcontainer">
  <mat-tab-group (selectedTabChange)="onTabClick($event)">
      <mat-tab label="Chiffre d'affaires" *ngIf="showcatab===true"aria-label="ca">
<!--saisie des données factures-->
<div class="container-fluid" id="invoiceform"*ngIf="showinvoiceform===true">
<!--edition note section-->
<fieldset id="editionnote" *ngIf="showeditionnote">
<legend>Factures du mois {{option2Value}} de l'année {{option1Value}}</legend>
<table class="table table-striped" id="editionnotesection" [formGroup]="editionnoteform">
<thead>
<tr>
<th>N° pièce</th>                        
<th>Jour</th>
<th>Date complète</th>
<th>Type</th>
<th>N°</th>
<th *ngIf="tvafodec">Montant Fodec</th>
<th *ngIf="tvatpe">Montant TPE</th>

<th *ngIf="tva7">Montant HT7</th>
<th *ngIf="tva19">Montant HT19</th>
        <th *ngIf="tva13">Montant HT13</th>
        <th *ngIf="tvaexonere">Montant HT exonéré</th>
<th *ngIf="tvasuspens">Montant HT suspension</th>
<th *ngIf="tvaexport">Montant export</th>
<th>Montant TVA</th> 
<th>droit de timbre</th>
<th>Montant TTC</th>

<th>Client</th>
<th>Annulation</th>
<th>Suppression</th>

</tr>
</thead>
<tbody formArrayName="ammounts"> 
    <tr *ngFor="let ammount of ammountControls; let i = index;"[formGroupName]="i">    
      <td>
        <div >
            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
               class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/>
        </div>
      </td>      
      <td style="width: 50px;">
                    <input type="text"class="form-control"(change)="setdate(i)"formControlName="jour"placeholder="Jour"/>
                       <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                      </td>
               <td>
               <div >
                   <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
               </div>
               <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
                veuillez vérifier votre saisie!
              </div> 
              </td>
              <td>
                  <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiece" name="typepiece">
                    <option value="facture" selected >Facture</option>
                    <option value="avoir">Avoir</option>
                  </select>
              </td>
              <td style="width: 50px;">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     *ngIf="editionnoteform.get('ammounts').value.at(i).typepiece=='facture'"class="form-control"formControlName="numeronote"(change)="onChange(i)"placeholder="N° note honoraire"readonly='true'/>
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   *ngIf="editionnoteform.get('ammounts').value.at(i).typepiece=='avoir'"class="form-control"formControlName="numeronote"(change)="verifydoublons(i)"(change)="onChange(i)"placeholder="N° note honoraire"/>
                 </div>
               </td>
               <td *ngIf="tvafodec">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montanthtfodec"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montanthtfodec').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
              </td>
              <td *ngIf="tvatpe">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montanthttpe"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montanthttpe').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
              </td>
              <td *ngIf="tva7">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
              </td>
               <td *ngIf="tva19">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montantht19"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montantht19').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
               </td>
               <td *ngIf="tva13">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montantht13"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montantht13').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
               </td>
               <td *ngIf="tvaexonere">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montanthtexo"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montanthtexo').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div>
              </td>
              <td *ngIf="tvasuspens">
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montanthtsus"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                   (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"/>
                </div>
                <div *ngIf="ammount.get('montanthtsus').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
              </td>
              <td *ngIf="tvaexport">
                <div >
                  <select class="btn btn-select resizeselect"placeholder="devise" id="devise"formControlName="devise" name="devise"(change)="changedevise(i)"(change)="onChange(i)">
                    <option value="tnd">TND</option>
                    <option value="euro" selected >Euro</option>
                    <option value="dollar">USD</option>
                    <option value="gbp">GBP</option>
                    <option value="dzd">DZD</option>
                      <option value="sar">SAR</option>
                      <option value="cad">CAD</option>
                      <option value="dkk">DKK</option>
                      <option value="jpy">JPY</option>
                      <option value="mad">MAD</option>
                      <option value="nok">NOK</option>
                      <option value="sek">SEK</option>
                      <option value="chf">CHF</option>
                      <option value="kwd">KWD</option>
                      <option value="aed">AED</option>
                      <option value="lyd">LYD</option>
                      <option value="mru">MRU</option>
                      <option value="bhd">BHD</option>
                      <option value="qar">QAR</option>
                      <option value="cny">CNY</option>
                  </select>
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                  class="form-control"formControlName="montanthtexp"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                  (change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"
                  *ngIf="editionnoteform.get('ammounts').value.at(i).devise=='tnd'"/>
                  <div *ngIf="ammount.get('montanthtexp').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
class="form-control"formControlName="montanthtexpdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
(change)="settva(i)"(change)="setttc(i)"(change)="onChange(i)"appdeuxDigitDecimaNumber 
*ngIf="editionnoteform.get('ammounts').value.at(i).devise!='tnd'"/>
<div *ngIf="ammount.get('montanthtexpdevise').hasError('pattern')" class="invalid">
  veuillez vérifier votre saisie!
</div>                 
</div>
              </td>
               <td>
                <div>
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montanttva"(blur)="setThreeNumberDecimal($event)"placeholder="Montant TVA" (change)="onChange(i)"readonly='true'/>
                </div>
                <div *ngIf="ammount.get('montanttva').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
               </td>
               <td>
                <div >
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')" 
                   class="form-control"formControlName="montantdt"(blur)="setThreeNumberDecimal($event)"placeholder="droit de timbre"(change)="onChange(i)"readonly='true'/>
                </div>
                <div *ngIf="ammount.get('montantdt').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
               </td>
               <td>
                <div (click)="setThreeNumberDecimal($event)">
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="montantttc"(change)="setht(i)"(blur)="setThreeNumberDecimal($event)"(change)="onChange(i)"placeholder="Montant TTC"readonly='true'/>
                </div>
                <div *ngIf="ammount.get('montantttc').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
               </td>
              <td style="width: 150px;display: block;">
                <div >
                  <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName formControlName="client" name="client" (click)="sort()">
                    <option hidden [value]=""></option>
                    <option value=""></option>
              <option *ngFor="let cl of settedclients" [value]="cl.cl">
                {{cl.cl}}
            </option>
                    <option value="Autre">Autre</option>
                  </select>
                    </div>
                    <div *ngIf="someHashName?.value == 'Autre'">
                      <input class="form-control" type="text"(change)="onChange(i)" placeholder="client" id="client" formControlName="autreclient">
                    </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="annule" (change)="onannule(i)"  formControlName=annule/><span><i class="glyphicon glyphicon-remove"></i></span>Annuler
                </div>
               </td>
               <td>                     
                <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount(i)"><span><i class="fa fa-minus-circle"></i></span></button>                          
               </td>
      </tr>       
    <tr>
        <td colspan="13"></td>
       
        <td>
            <div>
            <button type="button" class="btn btn-primary mb-2" (click)="addammount()"><span><i class="fa fa-plus-circle"></i></span></button>   
            </div>
           </td>

</tr>
      <tr id="resultrow">
        <td colspan="5">
            <div >
                 <p>Totaux</p>
            </div>
        </td>
        <td style="text-align: center;"*ngIf="tvafodec">{{totalhtfodec | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;"*ngIf="tvatpe">{{totalhttpe | number:'1.3-3'| removeComma}}</td>

        <td style="text-align: center;"*ngIf="tva7">{{totalht | number:'1.3-3'| removeComma}}</td>
        <td *ngIf="tva19">{{totalht19 | number:'1.3-3'| removeComma}}</td>
        <td *ngIf="tva13">{{totalht13 | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;"*ngIf="tvaexonere">{{totalhtexo | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;"*ngIf="tvasuspens">{{totalhtsus | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;"*ngIf="tvaexport">{{totalhtexp | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;">{{totaltva | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;">{{totaldt | number:'1.3-3'| removeComma}}</td>
        <td style="text-align: center;">{{totalttc | number:'1.3-3'| removeComma}}</td> 
      </tr>
</tbody>   
</table>
</fieldset>
<!--recette journaliére-->
<fieldset id="recettejournaliere" *ngIf="showrecettejour">
    <legend>Recette journaliere</legend>
    <table class="table table-striped" id="editionnotesection" [formGroup]="recettejournaliereform">
        <thead>
        <tr>
        <th>N° pièce</th>            
        <th>Jour</th>
        <th>Date complète</th>
        <th *ngIf="tvafodec">Recette FODEC</th>
        <th *ngIf="tvatpe">Recette TPE</th>

        <th *ngIf="tva7">Recette 7%<span *ngIf="activite==='Médecin'">(Actes de soin)</span> </th>
        <th *ngIf="tva19">Recette 19%</th>
        <th *ngIf="tva13">Recette 13%</th>
        <th *ngIf="tvaexonere">Recette exonéré</th>
        <th *ngIf="tvasuspens">Recette suspension</th>
        <th *ngIf="tvaexport">Recette export</th>
        <th>Montant HT</th>
        <th>Montant TVA</th>
        <th>Montant TTC</th>
      </tr>
        </thead>
        <tbody formArrayName="ammounts2">
            <tr *ngFor="let ammount of ammountControls2; let i = index;"[formGroupName]="i">
              <td>
                <div >
                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                       class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/>
                </div>
              </td>
                      <td>
                            <input type="text"class="form-control"(change)="setdate2(i)"formControlName="jour"placeholder="Jour"readonly='true'/>
                               <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
                                veuillez vérifier votre saisie!
                              </div> 
                              </td>
                       <td>
                       <div >
                           <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
                       </div>
                       <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                      </td>
                      <td *ngIf="tvafodec">
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="recettefodec"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                        </div>
                        <div *ngIf="ammount.get('recettefodec').hasError('pattern')" class="invalid">
                         veuillez vérifier votre saisie!
                       </div> 
                       </td>
                       <td *ngIf="tvatpe">
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="recettetpe"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                        </div>
                        <div *ngIf="ammount.get('recettetpe').hasError('pattern')" class="invalid">
                         veuillez vérifier votre saisie!
                       </div> 
                       </td>
                      <td *ngIf="tva7">
                       <div >
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="recette"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                       </div>
                       <div *ngIf="ammount.get('recette').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                      </td>
                      <td *ngIf="tva19">
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="recette19"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                        </div>
                        <div *ngIf="ammount.get('recette19').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                       </td>
                        <td *ngIf="tva13">
                         <div >
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"formControlName="recette13"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                         </div>
                         <div *ngIf="ammount.get('recette13').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                        </td>
                       <td *ngIf="tvaexonere">
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="recetteexo"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                        </div>
                        <div *ngIf="ammount.get('recetteexo').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div>
                       </td>
                       <td *ngIf="tvasuspens">
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="recettesus"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"/>
                        </div>
                        <div *ngIf="ammount.get('recettesus').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div>
                       </td>
                       <td *ngIf="tvaexport">
                        
                        <div >
                          <select class="btn btn-select resizeselect"placeholder="devise" id="devise"formControlName="devise" name="devise"(change)="changedevise(i)"(change)="setht2(i)"(change)="onChange2(i)">
                            <option value="tnd">TND</option>
                            <option value="euro" selected >Euro</option>
                            <option value="dollar">USD</option>
                            <option value="gbp">GBP</option>
                            <option value="dzd">DZD</option>
                      <option value="sar">SAR</option>
                      <option value="cad">CAD</option>
                      <option value="dkk">DKK</option>
                      <option value="jpy">JPY</option>
                      <option value="mad">MAD</option>
                      <option value="nok">NOK</option>
                      <option value="sek">SEK</option>
                      <option value="chf">CHF</option>
                      <option value="kwd">KWD</option>
                      <option value="aed">AED</option>
                      <option value="lyd">LYD</option>
                      <option value="mru">MRU</option>
                      <option value="bhd">BHD</option>
                      <option value="qar">QAR</option>
                      <option value="cny">CNY</option>
                          </select>
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="recetteexp"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setThreeNumberDecimal($event)"placeholder="Recette"
                          *ngIf="recettejournaliereform.get('ammounts2').value.at(i).devise=='tnd'"/>
                          <div *ngIf="ammount.get('recetteexp').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="recetteexpdevise"(change)="setht2(i)"(change)="onChange2(i)"(blur)="setTwoNumberDecimal($event)"placeholder="Recette"appdeuxDigitDecimaNumber
                          *ngIf="recettejournaliereform.get('ammounts2').value.at(i).devise!='tnd'"/>
                          <div *ngIf="ammount.get('recetteexpdevise').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                        </div>
                       </td>
                      <td>
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"readonly='true'/>
                        </div>
                        <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                       </td>
                      
                      
                       <td>
                        <div >
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="montanttva"(blur)="setThreeNumberDecimal($event)"placeholder="Montant TVA" readonly='true'/>
                        </div>
                        <div *ngIf="ammount.get('montanttva').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                       </td>
                      
                      
                       <td>
                        <div (click)="setThreeNumberDecimal($event)">
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="montantttc"(blur)="setThreeNumberDecimal($event)"placeholder="Montant TTC"readonly='true'/>
                        </div>
                        <div *ngIf="ammount.get('montantttc').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                       </td>
                      
                      
                      <td>
                       <div>
                       <button type="button" class="btn btn-danger mb-2"*ngIf="i>0" (click)="removeammount2(i)">Supprimer recette</button>   
                       </div>
                      </td>               
            </tr>
            <tr>
                <td colspan="12"></td>
                <td>
                    <div>
                      <button type="button" class="btn btn-success mb-2" (click)="addammount2()">Ajouter recette</button>   
                    </div>
                   </td>
            </tr>
            <tr id="resultrow">
                <td colspan="3">
                    <div >
                         <p>Totaux</p>
                    </div>
                </td>
                <td *ngIf="tvafodec">{{totalrecettefodec | number:'1.3-3'| removeComma}}</td>
                <td *ngIf="tvatpe">{{totalrecettetpe | number:'1.3-3'| removeComma}}</td>

                <td style="text-align: center;"*ngIf="tva7">{{totalrecette | number:'1.3-3'| removeComma}}</td>
                <td *ngIf="tva19">{{totalrecette19 | number:'1.3-3'| removeComma}}</td>
                <td *ngIf="tva13">{{totalrecette13 | number:'1.3-3'| removeComma}}</td>
                <td style="text-align: center;"*ngIf="tvaexonere">{{totalrecette2exo | number:'1.3-3'| removeComma}}</td>
                <td style="text-align: center;"*ngIf="tvasuspens">{{totalrecette2sus | number:'1.3-3'| removeComma}}</td>
                <td style="text-align: center;"*ngIf="tvaexport">{{totalrecette2exp | number:'1.3-3'| removeComma}}</td>
                <td style="text-align: center;">{{totalht2 | number:'1.3-3'| removeComma}}</td> 
                <td style="text-align: center;">{{totaltva2 | number:'1.3-3'| removeComma}}</td>  
                <td style="text-align: center;">{{totalttc2 | number:'1.3-3'| removeComma}}</td>
              </tr>
        </tbody>   
        </table>
</fieldset>
</div>
</mat-tab>
<mat-tab label="Achats" *ngIf="showachattab===true"aria-label="achat"> 
<!--facture achat section-->
<fieldset id="factureachat">
<legend>Gestion des factures d'achat</legend>
<table class="table table-striped" id="factureachatsection" [formGroup]="factureachatform">
<thead>
<tr>
<th>N° pièce</th>                        
<th>Jour</th>
<th>Date complète</th>
<th *ngIf="!forassiette">Fournisseur</th>
<th>Type pièce</th>
<th>N°</th>
<th>Nature de l'achat</th>
<th>Montant HT</th>
<th>Montant devise</th>
<th>Montant TVA</th>
<th>droit de timbre</th>
<th>Montant TTC</th>
<th *ngIf="!forassiette">Règlement</th>
<th>Contient fichier</th>
<th>Document Joint</th>
</tr>
</thead>
<tbody formArrayName="ammounts3">     
  <tr *ngFor="let ammount of ammountControls3; let i = index;"[formGroupName]="i">
    <td>
<input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/> 
    </td>
    <td>
<input type="text" class="form-control"(change)="setdate3(i,factureachatform.get('ammounts3'))"formControlName="jour"placeholder="Jour"/>
<div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
  veuillez vérifier votre saisie!
</div> 
</td>
    <td>
    <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>  
    <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
      veuillez vérifier votre saisie!
    </div> 
  </td>
    <td style="width: max-width;display: block;"*ngIf="!forassiette">
<select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName (change)="verifyinvoice(i)"formControlName="fournisseur" name="fournisseur" (click)="sort()">
                  <option hidden [value]=""></option>
                  <option value=""></option>
                  <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                  <option value="Autre">Autre</option>
</select>                       
<input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)" placeholder="Fournisseur" id="fournisseur" (change)="verifyinvoice(i)"formControlName="autrefournisseur">
                  
    </td>
    <td>
      <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiece" name="typepiece">
        <option hidden [value]=""></option>
        <option value="facture">Facture</option>
        <option value="avoir">Avoir</option>
      </select>
  </td>
            <td>
              
                   <input type="text" 
                 class="form-control"formControlName="numerofacture"(change)="verifyinvoice(i)"placeholder="Facture"/>
              
             </td>
             <td style="width: max-width; display: block;">
              
          <select  class="form-control"placeholder="Nature" id="natureachat"#someHashName2 formControlName="natureachat" name="natureachat" (click)="sort()">
            <option hidden [value]=""></option>
            <option value=""></option>
            <option *ngFor="let na of settednatures" [value]="na.nat">
              {{na.nat}}
          </option>
            <option value="Autre">Autre</option>
          </select>
            
              <input *ngIf="someHashName2?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)" placeholder="Nature" id="nature" formControlName="autrenatureachat">
            
             </td>
             <td>
              <div>                    
                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                      class="form-control"formControlName="montantht"(change)="setttc3(i)"(blur)="setThreeNumberDecimal($event)"
                    (change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)"placeholder="Montant HT"/>
              </div>   
              <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                veuillez vérifier votre saisie!
              </div>   
                        </td>
  <td>
  <div>
    <select class="btn btn-select resizeselect"placeholder="devise" id="devise"formControlName="devise" name="devise"(change)="changedevise3(i)">
      <option value="euro" selected >Euro</option>
      <option value="dollar">USD</option>
      <option value="gbp">GBP</option>
      <option value="gbp">GBP</option>
                  <option value="dzd">DZD</option>
                  <option value="sar">SAR</option>
                  <option value="cad">CAD</option>
                  <option value="dkk">DKK</option>
                  <option value="jpy">JPY</option>
                  <option value="mad">MAD</option>
                  <option value="nok">NOK</option>
                  <option value="sek">SEK</option>
                  <option value="chf">CHF</option>
                  <option value="kwd">KWD</option>
                  <option value="aed">AED</option>
                  <option value="lyd">LYD</option>
                  <option value="mru">MRU</option>
                  <option value="bhd">BHD</option>
                  <option value="qar">QAR</option>
                  <option value="cny">CNY</option>
    </select>
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"formControlName="montanthtachat"(change)="setttc3(i)"(blur)="setThreeNumberDecimal($event)"
        (change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)"placeholder="Montant HT"
        *ngIf="factureachatform.get('ammounts3').value.at(i).devise=='tnd'"/>
        <div *ngIf="ammount.get('montanthtachat').hasError('pattern')" class="invalid">
          veuillez vérifier votre saisie!
        </div> 
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"formControlName="montanthtdevise"(change)="setttc3(i)"(blur)="setTwoNumberDecimal($event)"placeholder="Recette"appdeuxDigitDecimaNumber
        *ngIf="factureachatform.get('ammounts3').value.at(i).devise!='tnd'"/>
        <div *ngIf="ammount.get('montanthtdevise').hasError('pattern')" class="invalid">
          veuillez vérifier votre saisie!
        </div> 
      </div>     
            </td>
            <td>
             
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                class="form-control"formControlName="montanttva"(change)="setttc3(i)"(blur)="setThreeNumberDecimal($event)"(change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)"placeholder="Montant TVA"/>
                <div *ngIf="ammount.get('montanttva').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
            </td>
            <td>
             
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')" 
                class="form-control"formControlName="montantdt"(change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)"(change)="setttc3(i)"(blur)="setThreeNumberDecimal($event)"placeholder="droit de timbre"/>
                <div *ngIf="ammount.get('montantdt').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
            </td>
            <td>
             
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                class="form-control"formControlName="montantttc"(change)="setht3(i)"(change)="setttc3(i)"(blur)="setThreeNumberDecimal($event)"(change)="onChange3(i,factureachatform.get('ammounts3'),factureachatform.getRawValue().ammounts3)"placeholder="Montant TTC"/>
                <div *ngIf="ammount.get('montantttc').hasError('pattern')" class="invalid">
                  veuillez vérifier votre saisie!
                </div> 
            </td>
            <td *ngIf="!forassiette">
              
                  <select class="form-control" placeholder="Entrer Votre mode de règelemnt" id="reglement"formControlName="reglement" name="reglement" >
                  <option value="default"> </option>
                  <option  value="Espèce">Espèce</option>
                  <option  value="Chèque">Chèque</option>
                  <option  value="Traite">Traite</option>
                  <option  value="Non réglé">Non réglé</option>
              </select>
          
            </td>
            <td style="width:20px"> 
                <input type="checkbox" id="{{ 'myCheck3' + i }}" (change)="docjoint3(i)"  formControlName=contientfiche/>
                <span *ngIf="factureachatform.get('ammounts3').value.at(i).ficheUrl"><a href="{{factureachatform.get('ammounts3').value.at(i).ficheUrl| lowercase}}" target="_blank">Aperçu facture</a></span>       

           </td>
           <td id="{{ 'achat' + i }}" style="display: none;width: 300px"> 
                 <input type="file"  #filePicker (change)="onImagePick($event,i)" class="form-control" accept="application/pdf">
                 <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
             
           </td>
            <td>
             
             <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount3(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
             
            </td>
            
  </tr>
  <tr>
    <td colspan="12"></td>
    <td>
        <div>
        <button type="button" class="btn btn-primary mb-2" (click)="addammount3()"><span><i class="fa fa-plus-circle"></i></span></button>   
        </div>
       </td>
</tr>
  <tr id="resultrow">
      <td colspan="7">
          <div >
               <p>Totaux</p>
          </div>
      </td>
      <td style="text-align: center;">{{totalht3 | number:'1.3-3'| removeComma}}</td>
      <td style="text-align: center;">{{totalht3devise | number:'1.3-3'| removeComma}}</td>
      <td style="text-align: center;">{{totaltva3 | number:'1.3-3'| removeComma}}</td>
      <td style="text-align: center;">{{totaldt3 | number:'1.3-3'| removeComma}}</td>
      <td style="text-align: center;">{{totalttc3 | number:'1.3-3'| removeComma}}</td>
      
  </tr>
</tbody>

</table>
</fieldset>
</mat-tab>
<mat-tab label="Banques" *ngIf="showbanquetab===true"aria-label="banque"> 
<!--saisie des données banques-->
<!--saisie relevé manuel-->
<fieldset id="relevemanuel" *ngIf="showrelevemanuel">
  <legend>Saisie des relevés manuels</legend>
  <div class="container fluid" id="relevemanuelsection" [formGroup]="relevemanuelform"style="margin:0px">
    <div formArrayName="employees" id="allreleve">
      <div *ngFor="let employee of employees().controls; let empIndex=index">
        <div [formGroupName]="empIndex"style="border: 1px solid blue; padding: 10px; margin: 5px;width:max-content">Détails du compte N°{{empIndex}}:
          <table class="table table-striped" style="width: 50%;">
            <thead>
              <tr>
                <th>Banque</th>
                <th>N° compte</th>
                <th>Devise</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>Débit</th>
                <th>Crédit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 150px">
                  <div>
              <select  class="form-control"placeholder="banque" id="banque"#someHashName4 formControlName="banque" name="banque"(change)="controlbankaccount(empIndex)"(change)="changedevise4(empIndex,skillIndex)" >
                <option hidden [value]=""></option>
                <option value=""></option>
                <option *ngFor="let bq of settedbanques" [value]="bq.bq">
                  {{bq.bq}}
              </option>
                <option value="Autre">Autre</option>
              </select>
                </div>
                <div *ngIf="someHashName4?.value == 'Autre'">
                  <input class="form-control" type="text"(change)="onChange4(empIndex)" placeholder="Banque" id="banque" formControlName="autrebanque">
                </div>
                 </td>
                 <td style="width: 150px">
                  <div>
              <select  class="form-control"placeholder="numero compte" id="numerocompte"#someHashName2 formControlName="numerocompte" name="numerocompte"(change)="controlbankaccount(empIndex)"(change)="changedevise4(empIndex,skillIndex)"(change)="controlbankaccount(empIndex)"(click)="filternumeros(empIndex)" >
                <option hidden [value]=""></option>
                <option value=""></option>
                <option *ngFor="let nc of filternumeros(empIndex)"[value]="nc.nc">
                  {{nc.nc}}
              </option>
                <option value="Autre">Autre</option>
              </select>
                </div>
                <div *ngIf="someHashName2?.value == 'Autre'">
                  <input class="form-control" type="text"(change)="onChange4(empIndex)" placeholder="Numero compte" id="numero" formControlName="autrenumerocompte">
                </div>
                 </td>
                 <td>
                  <div>
                    <select class="btn btn-select resizeselect"placeholder="devise" id="devise"formControlName="devise" name="devise"(change)="changedevise4(empIndex,skillIndex)"(change)="onChange4(empIndex,skillIndex)">
                      <option value="tnd">TND</option>
                      <option value="euro" selected >Euro</option>
                      <option value="dollar">USD</option>
                      <option value="gbp">GBP</option>
                      <option value="dzd">DZD</option>
                      <option value="sar">SAR</option>
                      <option value="cad">CAD</option>
                      <option value="dkk">DKK</option>
                      <option value="jpy">JPY</option>
                      <option value="mad">MAD</option>
                      <option value="nok">NOK</option>
                      <option value="sek">SEK</option>
                      <option value="chf">CHF</option>
                      <option value="kwd">KWD</option>
                      <option value="aed">AED</option>
                      <option value="lyd">LYD</option>
                      <option value="mru">MRU</option>
                      <option value="bhd">BHD</option>
                      <option value="qar">QAR</option>
                      <option value="cny">CNY</option>
                    </select>
                  </div>
                </td>            
              </tr>
                <tr>
              <td colspan="3">
                Solde à la fin du mois précédent:
              </td>
              <td>
                <div>
                   <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex)"placeholder="Débit"
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                   <div *ngIf="employee.get('debit').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="debitdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
  (change)="onChange4(empIndex)"appdeuxDigitDecimaNumber
  *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
  <div *ngIf="employee.get('debitdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div>   
</div>
               </td>
               <td>
                <div >
                     <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex)"placeholder="Crédit"
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                   <div *ngIf="employee.get('credit').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="creditdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
                   (change)="onChange4(empIndex)"appdeuxDigitDecimaNumber
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
                   <div *ngIf="employee.get('creditdevise').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                  </div>
               </td>
               <td>
                <div>
                <button type="button" class="btn btn-danger btn-sm" (click)="removeEmployee(empIndex)">Supprimer compte</button>   
                </div>
               </td> 
            </tr>
            </tbody>
          </table>
        
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th rowspan="2">Jour de l'opération</th>
                      <th rowspan="2"> Date de l'opération</th>
                      <th rowspan="2"> Désignation</th>
                      <th rowspan="2"> Débit</th>
                      <th rowspan="2"> Crédit</th>
                      <th rowspan="2"> Objet Débit</th>
                      <th rowspan="2"> Objet Crédit</th>
                      <th rowspan="2"> Contient tiers</th>
                      <th colspan="2"> Tiers fournisseur/client</th>
                    </tr>
                    <tr>
                      <th>Fournisseur</th>
                      <th>Client</th> 
                    </tr>   
                  </thead>
                  <tbody formArrayName="skills">
                    <tr *ngFor="let skill of employeeSkills(empIndex).controls; let skillIndex=index"[formGroupName]="skillIndex">
                      <td style="width: 50px;">
                        <div class="col-6">
                            <input type="text"class="form-control"(change)="setdate4(empIndex,skillIndex)"formControlName="jour"placeholder="Jour"/>
                        </div>
                        <div *ngIf="skill.get('jour').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                    </td>
                       <td>
                       <div class="col-6">
                           <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
                       </div>
                       <div *ngIf="skill.get('date').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                      </td>
                      <td>
                        <div >
                          <textarea type="text"class="form-control"formControlName="designation"placeholder="Désignation" cols="30" rows="2"></textarea>
                        </div>
                       </td>
                       <td>
                        <div >
                             <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex,skillIndex)"placeholder="Débit"
                           *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                           <div *ngIf="skill.get('debit').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
   class="form-control"formControlName="debitdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
   (change)="onChange4(empIndex,skillIndex)"appdeuxDigitDecimaNumber
   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
   <div *ngIf="skill.get('debitdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div> 
  </div>
                       </td>
                       <td>
                        <div >
                             <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                           class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex,skillIndex)"placeholder="Crédit"
                           *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                           <div *ngIf="skill.get('credit').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
   class="form-control"formControlName="creditdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
   (change)="onChange4(empIndex,skillIndex)"appdeuxDigitDecimaNumber
   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
   <div *ngIf="skill.get('creditdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div> 
  </div>
                       </td>
                      <td style="width: 150px;">
                        <div >
                    <select class="btn btn-select resizeselect"placeholder="objet" id="objet"#someHashName10 formControlName="objetdebit" name="objetdebit" (click)="sort(empIndex,skillIndex)">
                      <option hidden [value]=""></option>
                      <option value=""></option>
                      <option *ngFor="let ob of settedobjetsdebit" [value]="ob">
                        {{ob}}
                    </option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName10?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Objet" id="numerocompte" formControlName="autreobjetdebit">
                      </div>
                       </td>
                       <td style="width: 150px;">
                        <div >
                    <select class="btn btn-select resizeselect"placeholder="objet" id="objet"#someHashName11 formControlName="objetcredit" name="objetcredit" (click)="sort(empIndex,skillIndex)">
                      <option hidden [value]=""></option>
                      <option value=""></option>
                      <option *ngFor="let ob of settedobjetscredit" [value]="ob">
                        {{ob}}
                    </option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName11?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Objet" id="numerocompte" formControlName="autreobjetcredit">
                      </div>
                       </td>
                       <td style="width:20px"> 
                        <input type="checkbox" (change)="onChange4(empIndex,skillIndex)"  formControlName=contientauxiliaire/>
                       </td>
                       <td>
                        <div id="{{ 'relevemanuelfournisseur' + empIndex + skillIndex }}">
                          <select class="btn btn-select resizeselect"placeholder="Fournisseur" id="natureachat"#someHashName30 formControlName="fournisseur" name="fournisseur" (click)="sort()">
                            <option hidden [value]=""></option>
                            <option value=""></option>
                            <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                            <option value="Autre">Autre</option>
                          </select>                       
                          <input *ngIf="someHashName30?.value == 'Autre'"class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Fournisseur" id="fournisseur" (change)="verifyinvoice(i)"formControlName="autrefournisseur">
                        </div>
                       </td>
                       <td>
                        <div id="{{ 'relevemanuelclient' + empIndex + skillIndex }}">
                          <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName31 formControlName="client" name="client" (click)="sort()">
                            <option hidden [value]=""></option>
                            <option value=""></option>
                      <option *ngFor="let cl of settedclients" [value]="cl.cl">
                        {{cl.cl}}
                    </option>
                            <option value="Autre">Autre</option>
                          </select>
                            <input *ngIf="someHashName31?.value == 'Autre'"class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="client" id="client" formControlName="autreclient">
                        </div>
                       </td>
                       <td>
                        <div>
                          <button type="button" class="btn btn-danger btn-sm"*ngIf="skillIndex>0" (click)="removeEmployeeSkill(empIndex,skillIndex)"><span><i class="fa fa-minus-circle"></i></span></button>   
                        </div>
                       </td>     
              
                    </tr>
                    <tr>
                      <td colspan="10"></td>
                      <td >
                        <div>
                        <button type="button" class="btn btn-primary mb-2" (click)="addEmployeeSkill(empIndex)"><span><i class="fa fa-plus-circle"></i></span></button>   
                        </div>
                       </td>
                    </tr>
                    
                    <tr id="resultrow">
                      <td colspan="3">
                          <div class="col-6">
                               <p>Total des mouvement du mois</p>
                          </div>
                      </td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotaldebit(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotalcredit(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotaldebitdevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotalcreditdevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>                     </tr>
                  <tr id="resultrow">
                    <td colspan="2">
                        <div class="col-6">
                             <p>Solde fin du mois</p>
                        </div>
                    </td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotal(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
<td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotaldevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>                  </tr>
                  </tbody>
                </table>
        </div>
      </div>
      <button type="button" class="btn btn-primary mb-2"(click)="addEmployee()">Ajouter compte</button>
    </div>
  </div>

<!--
    <table class="table table-striped" id="relevemanuelsection" [formGroup]="relevemanuelform">
  <thead>
      <tr>            
      <th rowspan="2">Mois</th>
      <th rowspan="2">Année</th>
      <th colspan="2">solde du mois précédent</th>
      </tr>
      <tr>
        <th>Débit</th>
        <th>Crédit</th>
      </tr>
  </thead>
  <tbody>
    <tr>

<td>
  <div class="col-6">
    <select class="btn btn-select resizeselect" id="month" name="month" #cricket (change)="updaterelevemanuelmois($event)"formControlName="mois">
      <option></option>
      <option value="01">(01)janvier</option>
      <option value="02">(02)février</option>
      <option value="03">(03)Mars</option>
      <option value="04">(04)avril</option>
      <option value="05">(05)Mai</option>
      <option value="06">(06)juin</option>
      <option value="07">(07)juillet</option>
      <option value="08">(08)août</option>
      <option value="09">(09)septembre</option>
      <option value="10">(10)octobre</option>
      <option value="11">(11)novembre</option>
      <option value="12">(12)décembre</option>
   </select>
  </div> 
</td>
<td>
  <div class="col-6">
    <select class="btn btn-select resizeselect" id="year" name="year"formControlName="annee"(change)="updaterelevemanuelannee($event)">
      <option></option>
      <option value="2023">2023</option>
      <option  value="2022">2022</option>
      <option  value="2021">2021</option>   
   </select>
  </div>
</td>
<td>
  <div class="col-6">
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="soldemoisprecedentdebit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Solde Débit du mois précédent"/>
</div>
</td>
<td>
  <div class="col-6">
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="soldemoisprecedentcredit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Solde Crédit du mois précédent"/>
</div>
</td>
    </tr>
  </tbody>
  <thead>
  <tr>            
  <th>Jour</th>
  <th>Date complète</th>
  <th>Débit</th>
  <th>Crédit</th>
  </tr>
  </thead>
  <tbody formArrayName="ammounts4">
      
      <tr *ngFor="let ammount of ammountControls4; let i = index;"[formGroupName]="i">
             
              <td style="width: 50px;">
                  <div class="col-6">
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                         class="form-control"(change)="setdate4(i)"formControlName="jour"placeholder="Jour"/>
                  </div>
              </td>
                 <td>
                 <div class="col-6">
                     <input type="text"class="form-control"formControlName="date"onkeyup="value=value.replace(/[^0-9^\.\/]+/g,'').replace(/\./g,'')"placeholder="Date"readonly='true'/>
                 </div>
                </td>
                <td>
                 <div class="col-6">
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                    class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Débit"/>
                 </div>
                </td>
                <td>
                 <div class="col-6">
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                    class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Crédit"/>
                 </div>
                </td> 
                <td>
                  <div>
                  <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount4(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                  </div>
                 </td>
              
      </tr>
      <tr>
          <td colspan="5"></td>
          <td>
              <div>
              <button type="button" class="btn btn-primary mb-2" (click)="addammount4()"><span><i class="fa fa-plus-circle"></i></span></button>   
              </div>
          </td>
      </tr>
      <tr id="resultrow">
          <td colspan="2">
              <div class="col-6">
                   <p>Total des mouvement du mois</p>
              </div>
          </td>
          <td style="text-align: center;">{{totaldebit | number:'1.3-3'| removeComma}}</td>
          <td style="text-align: center;">{{totalcredit | number:'1.3-3'| removeComma}}</td>
      </tr>
      <tr id="resultrow">
        <td colspan="2">
            <div class="col-6">
                 <p>Solde fin du mois</p>
            </div>
        </td>
        <td style="text-align: center;">{{totalsoldemois | number:'1.3-3'| removeComma}}</td>
    </tr>
  </tbody>
  <div class="text-right">
      <input type="submit" class="visually-hidden" value="Submit"(click)="logValue4()">
  </div>
  </table>
-->

  </fieldset>
<!--saisie des relevés joints-->
<fieldset id="relevejoint" *ngIf="showrelevejoint">
    <legend>Attacher les relevés bancaires</legend>
    <table class="table table-striped" id="relevejointsection" [formGroup]="relevejointform">
        <thead>
        <tr>            
        <th>Mois</th>
        <th>Annee</th>
        <th>Document joint</th>
        </tr>
        </thead>
        <tbody formArrayName="ammounts5">
            <tr *ngFor="let ammount of ammountControls5; let i = index;"[formGroupName]="i">
                   
                    <td >
                        <div class="col-6">
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                               class="form-control"formControlName="mois"placeholder="Mois"/>
                        </div>
                       </td>
                       <td>
                       <div class="col-6">
                           <input type="text"class="form-control"formControlName="annee"placeholder="Annee"/>
                       </div>
                      </td>
                      <td style="display: block;"> 
                        <div class="col-6"style="width: 300px">
                           <input type="file"  #filePicker (change)="onImagePick2($event,i)" class="form-control" accept="application/pdf">
                           <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                       </div>
                       <div>
                        <a href="{{deccomptabilite.autre5[i].ficheUrl| lowercase}}" target="_blank">Aperçu relevé</a>
                       </div>
                     </td>
                     <td>
                      <div>
                      <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount5(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                      </div>
                     </td>
                    
            </tr>
            <tr>
                <td colspan="3"></td>
                <td>
                    <div>
                    <button type="button" class="btn btn-primary mb-2" (click)="addammount5()">Ajouter relevé</button>   
                    </div>
                   </td>
            </tr>
        </tbody>
        <div class="text-right">
            <input type="submit" class="visually-hidden" value="Submit"(click)="logValue5()">
        </div>
        </table>
</fieldset>
</mat-tab>
<mat-tab label="Salaires" *ngIf="showsalairetab===true"aria-label="salaire"> saisie salaire section

<fieldset id="saisiesalaire"*ngIf="showpaiemanuel===true">
    <legend>Gestion des salaires</legend>
    <div class="container fluid" id="relevemanuelsection" [formGroup]="salaireform"style="margin:0px">
      <div formArrayName="typessalaire" id="allreleve">
        <div *ngFor="let ty of typessalaire().controls; let typeindex=index">
          <div [formGroupName]="typeindex"style="border: 1px solid blue; padding: 10px; margin: 5px;width:max-content">Type salaire:{{salaireform.getRawValue().typessalaire.at(typeindex).typesalaire}}:
            <table class="table table-striped" style="width: 50%;">
              <thead>
                <tr>                      
                  <th>Type salaire</th>
                </tr>                 
              </thead>
              <tbody>
                <tr>

                  <td style="width: 150px">
                    <div>
                <select  class="form-control"placeholder="type" id="typesalaire"#someHashName4 formControlName="typesalaire" name="typesalaire">
                  <option hidden [value]=""></option>
                  <option value=""></option>
                  <option *ngFor="let type of typessalaires" [value]="type">
                    {{type}}
                </option>
                  <option value="Autre">Autre</option>
                </select>
                  </div>
                  <div *ngIf="someHashName4?.value == 'Autre'">
                    <input class="form-control" type="text"(change)="onChange6(typeindex)" placeholder="type" id="type" formControlName="autretypesalaire">
                  </div>
                   </td>
                          
                </tr>
              </tbody>
            </table>
          
                  <table class="table table-striped"style="width: 50%;">
                    <thead>
                      <tr>
                      <th></th>            
                      <th>Matricule</th>
                      <th>Nom et prénom</th>
                      <th>Nature du contrat</th>
                      <th>fiche contrat</th>
                      <th>Salaire Brut</th>
                      <th>Salaire Net</th>
                      <th>Actions fiche</th>
                      <!--<th>Document Joint</th> -->    
                      </tr>
                      </thead>
                    <tbody formArrayName="ammounts6">
                      <tr *ngFor="let ammount of salaires(typeindex).controls; let i=index"[formGroupName]="i">
                        <td>
                          <!-- modal for adding new matricules-->
                          <div class="modal-signup"tabindex="-1"role="dialog"[ngStyle]="{'display':displaysalaire}">
                             <div class="modal-content">
                              <div class="right-section-signup">
                                <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
                                  <h4>Formulaire d'ajouts de salariés</h4>
                                  <table class="table table-striped"[formGroup]="ajoutsalarieform">
                                    <thead>
                                      <tr>
                                        <th>Document</th>
                                        <th>Valeur</th>            
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr >
                                        <td>Matricule</td>
                                        <td style="width: 100px;">
                          <input type="text" class="form-control"formControlName="matricule"placeholder="Matricule"/>
                                        </td>          
                                      </tr>
                                      <tr >
                                        <td>Nom et prénom</td>
                                        <td style="width: 50px;">
                          <input type="text" class="form-control"formControlName="nomprenom"placeholder="nom"/>
                                         </td>
                                      </tr>
                                      <tr >
                                        <td>Sexe</td>
                                        <td style="width: 50px;">
                                          <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="sexe" name="client" (click)="sort()">
                                            <option hidden [value]=""></option>
                                            <option value=""></option>
                                      <option *ngFor="let sexe of sexes" [value]="sexe">
                                        {{sexe}}
                                    </option>
                                          </select>
                                                                         </td>
                                      </tr>
                                      <tr >
                                        <td> Date de naissance</td>
                                        <td style="width: 50px;">
                          <input type="date" class="form-control"formControlName="naissance"placeholder="date de naissance"/>
                                     </td>
                                        
                                      </tr>
                                      <tr>
                                        <td>Etat Civil</td>
                                        <td style="width: 50px;">
                                          <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="etatcivil" name="client" (click)="sort()">
                                            <option hidden [value]=""></option>
                                            <option value=""></option>
                                      <option *ngFor="let etat of etatscivil" [value]="etat">
                                        {{etat}}
                                    </option>
                                          </select>
                                     </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <span>CIN</span>
                                         </td>
                                        <td>
                                          <span>Delivre le</span>
                                        </td>
                                      </tr>
                                      <tr>
                          <td>
                            <input type="text"  class="form-control"formControlName="cin"placeholder="CIN"/>
                          </td>
                          <td style="width: 50%;">
                            <input type="date"  class="form-control"formControlName="delivre"placeholder="Delivre le"/>
                          </td>
                                      </tr>
                                      <tr >
                                        <td> Adresse</td>
                                        <td>
                                          <textarea name="" id="" cols="30" rows="10"class="form-control"formControlName="adresse"placeholder="adresse"></textarea>
                                        </td>             
                                      </tr>
                                      <tr >
                                        <td> Mobile</td>
                          <td><input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" class="form-control"formControlName="mobile"placeholder="Mobile"/></td>             
                                        
                                      </tr>
                                      
                                      <tr>
                                        <td>Chef de famille</td>
                              <td><input type="checkbox" formControlName=chef/></td>            
                                      </tr>
                                      <tr>
                                        <td>Nature du contrat</td>
                                        <td style="display: flex;">
                                          <span style="width: 150px;display: block;">
                                            <div >
                                              <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="naturecontrat" name="client" (click)="sort()">
                                                <option hidden [value]=""></option>
                                                <option value=""></option>
                                          <option *ngFor="let naturecontrat of settednaturecontrats" [value]="naturecontrat.natcontrat">
                                            {{naturecontrat.natcontrat}}
                                        </option>
                                                <option value="Autre">Autre</option>
                                              </select>
                                                </div>
                                                <div *ngIf="someHashName?.value == 'Autre'">
                                                  <input class="form-control" type="text" placeholder="nature contrat" id="client" formControlName="autrenaturecontrat"(change)="onchange6(typeindex,i)">
                                                </div>
                                        </span>
                                        <span>
                                          <input type="checkbox" id="{{ 'myCheck6' + typeindex + i }}" (change)="docjoint6(typeindex,i)"  formControlName=contientfiche/>
                                         </span>
                                         <span id="{{ 'salaire' + typeindex + i }}"style="display: none;">
                                          <input type="file"  #filePicker (change)="onImagePick3($event,typeindex,i)" class="form-control" accept="application/pdf">
                                                   <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                                         </span>
                                        </td>                         
                                      </tr>
                                      <tr>
                                        <td>Nombre d'enfants à charge</td>
                                        <td><input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" class="form-control"formControlName="enfant"placeholder="nombre d'enfants"/></td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                            
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-danger" (click)="closePopup()">Annuler</button>
                                  <button type="button" class="btn btn-success" (click)="ajoutersalarie(typeindex,i)">Valider</button>
                          
                                </div>
                                </div>
                              </div>
                           <!-- Close Button (Top-left corner) -->
          <div class="close-button" (click)="closePopup()" style="background-color: #F8F8F9;">
            <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
          </div>
                             </div>
                         </div>
                     </td>
        
                           <td style="width: 100px;">
                               <div >
                                   <input type="text" onkeyup="value=value.replace(/\./g,'')" (blur)="verifymatricule(typeindex,i)"
                                      class="form-control"formControlName="matricule"placeholder="Matricule"/>
                               </div>
                              </td>
                              <td>
                              <div >
                                  <input type="text"class="form-control"formControlName="nomprenom"placeholder="Nom et Prenom"readonly="true">
                              </div>
                             </td>
                             <td style="width: 150px">
                                     <input class="form-control" type="text" placeholder="client" id="client" formControlName="naturecontrat"readonly="true">
                             </td>
                             <td >                         
                               <div *ngIf="salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6').value.at(i).ficheUrl">
                                <a href="{{salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6').value.at(i).ficheUrl}}" target="_blank">Aperçu fiche</a>
                               </div>
                             </td>
                             <td style="width: 70px">
                               <div >
                               <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                             class="form-control"formControlName="salairebrut"(blur)="setThreeNumberDecimal($event)"(change)="onChange6(typeindex,i)"placeholder="Salaire Brut"/>
                                          </div>
                             </td>
                                        
                             <td style="width: 70px">
                                   <div >
                                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
         class="form-control"formControlName="salairenet"(blur)="setThreeNumberDecimal($event)"(change)="onChange6(typeindex,i)"placeholder="Salaire Net"/>
                                              </div>
                               </td>
                               <td>
                                 <div>
                                 <button type="button" class="btn btn-primary mb-2" (click)="modifyemploye(typeindex,i)"> Modifier<span><i class="glyphicon glyphicon-pencil"></i></span></button>   
                                 </div>
                                </td>
                            <!--
                             <td > 
                               <div style="width:20px">
                                 <input type="checkbox" id="{{ 'myCheck6' + i }}" (change)="docjoint6(i)"  formControlName=contientfiche/>
                              </div>
                            </td>
                            <td id="{{ 'salaire' + i }}"style="display: none;" > 
                               <div style="width: 300px">
                                  <input type="file"  #filePicker (change)="onImagePick3($event,i)" class="form-control" accept="application/pdf">
                                  <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                              </div>
                            </td>
                            --> 
                             <td>
                              <div>
                              <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount6(typeindex,i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                              </div>
                             </td>
                      </tr>
                      <tr>
                        <td colspan="10"></td>
                        <td>
                            <div>
                            <button type="button" class="btn btn-primary mb-2" (click)="addammount6(typeindex)"><span><i class="fa fa-plus-circle"></i></span></button>   
                            </div>
                           </td>
                    </tr>
                      <tr id="resultrow">
                          <td colspan="5">
                              <div >
                                   <p>Totaux</p>
                              </div>
                          </td>
                          <td style="text-align: center;">{{totalsalairebrut | number:'1.3-3'| removeComma}}</td>
                          <td style="text-align: center;">{{totalsalairenet | number:'1.3-3'| removeComma}}</td>
                      </tr>
                    </tbody>
                  </table>
          </div>
        </div>
        <button type="button" class="btn btn-primary mb-2"(click)="addtypesalaire()">Ajouter autre type de salaire</button>
      </div>
    </div>
    </fieldset>
</mat-tab>
</mat-tab-group>
</div>
    </div>
<!--maquette pour les syndics-->
<div *ngIf="user.activite=='Syndic des copropriétaires'">
   <!-- modal for adding new cop dc-->



   <div class="modal-signup" [ngStyle]="{'display':displaycop}">
    <div class="modal-content">
      <div class="right-section-signup">
        <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
          <h1>Formulaire de complétude des données des copropriétaires</h1>
        <div class="form-group">
          <label class="col-md-4 control-label">Périodicité</label>
          <div class="col-md-8 inputGroupContainer">
             <div class="input-group"><span class="input-group-addon"><i class="glyphicon glyphicon-time"></i></span>
              <select class="btn btn-select resizeselect"placeholder="periodicite" id="perio"#someHashName20 [(ngModel)]="option0Value"(click)="sort()"(change)="patch()">
                <option hidden [value]=""></option>
                <option value=""></option>
          <option *ngFor="let per of settedperiodicites" [value]="per">
            {{per}}
        </option>
                <option hidden value="Autre">Autre</option>
              </select>
              </div>
          </div>
       </div>
       <table class="table table-striped"[formGroup]="ajoutdccopform">
        <thead>
        <tr>
          <th>N°</th>
          <th>copropriétaires</th>
          <th>annee</th>            
          <th>périodicité</th>            
          <th>montant exigible par échéance</th>              
        </tr>
      </thead>
   
      <tbody formArrayName="ammounts10">
      
        <tr *ngFor="let ammount of ammountControls10; let u = index;"[formGroupName]="u">
          <td>
            <div >
                 <input type="text"  
               class="form-control"formControlName="numero"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
            </div>
          </td>
          <td>
            <div >
                 <input type="text"  
               class="form-control"formControlName="coproprietaire"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
            </div>
          </td>
          <td style="width: 100px">
            <div >
                 <input type="text"  
               class="form-control"formControlName="annee"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
            </div>
          </td>

                <td style="width: 150px;display: block;">
                  <div >
                    <select class="btn btn-select resizeselect"style="pointer-events: none;"onclick="return false;" onkeydown="return false;"placeholder="periodicite" id="perio"#someHashName20 formControlName="periodicite" name="periodicite" (click)="sort()"readonly='true'>
                      <option hidden [value]=""></option>
                      <option value=""></option>
                <option *ngFor="let per of settedperiodicites" [value]="per">
                  {{per}}
              </option>
                      <option hidden value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName20?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChangecop(u)" placeholder="perio" id="period" formControlName="autreperiodicite">
                      </div>
                </td> 
                <td>
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montantecheance"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
                  </div>
                </td>
           
                
        </tr>
      </tbody>
    </table>
</div>
        
        <div class="btn-signup-section">
            <div style="width: 50%; text-align: end;">
                <button class="reinstaller-btn-modal" (click)="closecopPopup()">Annuler</button>
            </div>
            <div style="width: 50%;">
                <button class="signup-btn-modal" (click)="ajouterdccop()">Valider</button>
            </div>
        </div>
      </div>
  
      <!-- Close Button (Top-left corner) -->
      <div class="close-button" (click)="closecopPopup()" style="background-color: #F8F8F9;">
        <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
      </div>
    </div>
  </div>
  <!--rubrique de choix des données à saisir-->
  <fieldset id="datalist">
    <legend>Liste des donnés à saisir:</legend> 
    <table>
      
      <tbody>
      <tr>
        
       <td style="text-align: center;"> 
          <div><input type="checkbox" id="myCheck1" (click)="myFunction1()"[(ngModel)]="option3Value" /></div>
          <div><label for="rb1">Recettes</label></div>
       </td>
       <td style="text-align: center;">
          <div><input type="checkbox" id="myCheck7" (click)="myFunction7()"[(ngModel)]="option14Value" /></div>
          <div><label for="rb1">Dépenses chèques</label></div>
        </td>
        <td style="text-align: center;">
          <div><input type="checkbox" id="myCheck5" (click)="myFunction5()"[(ngModel)]="option12Value" /></div>
          <div><label for="rb1">Dépenses espèces</label></div>
        </td>
        <td style="text-align: center;">
          <div><input type="checkbox" id="myCheck6" (click)="myFunction6()"[(ngModel)]="option13Value" /></div>
          <div><label for="rb1">Factures fournisseurs</label></div>
        </td>
        <td style="text-align: center;"*ngIf="!forassiette">
          <div><input type="checkbox" id="myCheck3"(click)="myFunction3()"[(ngModel)]="option5Value" /></div>
          <div><label for="rb1">Banques</label></div>
          <div class="spinner-grow" role="status"*ngIf="!showrelevemanuel">
            <span class="visually-hidden">Loading...</span>
          </div>
        </td>
         <td style="text-align: center;">
          <div><input type="checkbox" id="myCheck4" (click)="myFunction4()"[(ngModel)]="option6Value" /></div>
          <div><label for="rb1">Salaires</label></div>
        </td>
    </tr>
    <!--
    <tr id="verifrow">
        
      <td>
        
         <div><input *ngIf="showretenueverif===true" type="checkbox" id="myCheck24" (click)="myFunction24()"[(ngModel)]="option65Value" /></div>
         <div><label  *ngIf="option65Value===true" style="color: green;">Validé</label></div>
         <div><label  *ngIf="option65Value===false" style="color: red;">Non Validé</label></div>
  
         
      </td>
      <td>
         <div><input  *ngIf="showtfpverif===true" type="checkbox" id="myCheck25" (click)="myFunction25()"[(ngModel)]="option66Value" /></div>
         <div><label *ngIf="option66Value===true" style="color: green;">Validé</label></div>
         <div><label  *ngIf="option66Value===false" style="color: red;">Non Validé</label></div>
  
       </td>
       <td>
        <div><input *ngIf="showfoprolosverif===true" type="checkbox" id="myCheck26"(click)="myFunction26()"[(ngModel)]="option67Value" /></div>
          <div><label *ngIf="option67Value===true" style="color: green;">Validé</label></div>
          <div><label  *ngIf="option67Value===false" style="color: red;">Non Validé</label></div>
  
       </td>
       <td>
        <div><input *ngIf="showtvaverif===true" type="checkbox" id="myCheck27" (click)="myFunction27()"[(ngModel)]="option68Value" /></div>
          <div><label *ngIf="option68Value===true" style="color: green;">Validé</label></div>
          <div><label  *ngIf="option68Value===false" style="color: red;">Non Validé</label></div>
  
       </td>
       <td>
        <div><input *ngIf="showtimbreverif===true" type="checkbox" id="myCheck28" (click)="myFunction28()"[(ngModel)]="option69Value" /></div>
          <div><label *ngIf="option69Value===true" style="color: green;">Validé</label></div>
          <div><label  *ngIf="option69Value===false" style="color: red;">Non Validé</label></div>
  
       </td>
       <td>
        <div><input *ngIf="showtclverif===true" type="checkbox" id="myCheck29" (click)="myFunction29()"[(ngModel)]="option70Value" /></div>
          <div><label *ngIf="option70Value===true" style="color: green;">Validé</label></div>
          <div><label  *ngIf="option70Value===false" style="color: red;">Non Validé</label></div>
  
       </td>
     
   </tr>
   -->
      </tbody>
    </table>
  </fieldset> 
  <!--buttonslist-->
  <button id="savebutton" class="btn btn-success"type="button" (click)="onSubmit($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Sauvegarder</button>
<button id="resetbutton" class="btn btn-secondary" type="button" (click)="restartform()"
      *ngIf="option3Value===true||option4Value===true||option5Value===true||option6Value===true||option14Value===true||option13Value===true||option12Value===true">Réinitialiser tout</button>
      <button id="sendbutton" class="btn btn-success"type="button" (click)="onSend($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Valider les données</button>
  <!--datacontainer-->
  <div class="container-fluid"id="tabcontainer">
    <mat-tab-group (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Recettes" *ngIf="showcatab===true" aria-label="ca">
  
  <!--étape de décision
  <div title="indiquer votre choix"*ngIf="showeditionnote&&!showrecettejour">
  <div class="form-group">
  <label for="choice"> Avez vous d'autres factures relatifs à des mois précédents:</label>    
  <div id="block1"><input type="checkbox"  id="choice"class="input-field"(click)="myFunction5()"[(ngModel)]="option8Value">Oui</div>  
  <div id="block2"><input type="checkbox"  id="choice1"class="input-field"(click)="myFunction6()"[(ngModel)]="option9Value">Non</div>
  </div>
  </div>
  edition note section (passé)
  <fieldset id="editionnotepast">
  <legend>Factures du mois {{option10Value}} de l'année {{option1Value}}</legend>
    <div class="checkbox-container" id="datelist">
         <div class="input-container" id="Month"> 
           <i class="glyphicon glyphicon-time"></i>
           <label for="month">Sélectionner mois</label>
           <select id="month" name="month" [(ngModel)]="option10Value">
            <option></option>
            <option value="01">(01)janvier</option>
                           <option value="02">(02)février</option>
                           <option value="03">(03)Mars</option>
                           <option value="04">(04)avril</option>
                           <option value="05">(05)Mai</option>
                           <option value="06">(06)juin</option>
                           <option value="07">(07)juillet</option>
                           <option value="08">(08)août</option>
                           <option value="09">(09)septembre</option>
                           <option value="10">(10)octobre</option>
                           <option value="11">(11)novembre</option>
                           <option value="12">(12)décembre</option>
          </select>
         </div>
     </div>
  <table class="table table-striped" id="editionnotesection" [formGroup]="editionnotepastform">
  <thead>
  <tr>            
  <th>Jour</th>
  <th>Date complète</th>
  <th>N° de facture</th>
  <th>Montant HT</th>
  <th *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">Montant HT19</th>
  <th>Montant TVA</th>
  <th *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">Montant TVA19</th>
  <th>droit de timbre</th>
  <th>Montant TTC</th>
  <th *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">Montant TTC19</th> 
  <th>Client</th>
  
  </tr>
  </thead>
  <tbody formArrayName="ammounts0">
      
      <tr *ngFor="let ammount of ammountControls0; let i = index;"[formGroupName]="i">
             
              <td style="width: 50px;">
                  <div >
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                         class="form-control"(change)="setdate0(i)"formControlName="jour"placeholder="Jour"/>
                  </div>
                 </td>
                 <td>
                 <div >
                     <input type="text"class="form-control"formControlName="date"onkeyup="value=value.replace(/[^0-9^\.\/]+/g,'').replace(/\./g,'')"placeholder="Date"readonly='true'/>
                 </div>
                </td>
                <td style="width: 50px;">
                 <div >
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                    class="form-control"formControlName="numeronote"(change)="onChange0(i)"placeholder="N° note honoraire"readonly='true'/>
                 </div>
                </td>
                <td>
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                     (change)="settva0(i)"(change)="setttc0(i)"(change)="onChange0(i)"/>
                  </div>
                 </td>
                 <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
                 &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montantht19"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                     (change)="settva0(i)"(change)="setttc0(i)"(change)="onChange0(i)"/>
                  </div>
                 </td>
                 <td>
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montanttva"(blur)="setThreeNumberDecimal($event)"placeholder="Montant TVA" (change)="onChange0(i)"/>
                  </div>
                 </td>
                 <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
                 &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montanttva19"(blur)="setThreeNumberDecimal($event)"placeholder="Montant TVA" (change)="onChange0(i)"/>
                  </div>
                 </td>
                 <td>
                  <div >
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')" 
                     class="form-control"formControlName="montantdt"(blur)="setThreeNumberDecimal($event)"placeholder="droit de timbre"(change)="onChange0(i)"/>
                  </div>
                 </td>
                 <td>
                  <div (click)="setThreeNumberDecimal($event)">
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montantttc"(change)="setht0(i)"(blur)="setThreeNumberDecimal($event)"(change)="onChange0(i)"placeholder="Montant TTC"/>
                  </div>
                 </td>
                 <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
                 &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">
                  <div (click)="setThreeNumberDecimal($event)">
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                     class="form-control"formControlName="montantttc19"(change)="setht0(i)"(blur)="setThreeNumberDecimal($event)"(change)="onChange0(i)"placeholder="Montant TTC"/>
                  </div>
                 </td>
                <td style="width: 150px;display: block;">
                  <div >
                    <select class="form-control"placeholder="Client" id="client"#someHashName formControlName="client" name="client" >
                      <option hidden [value]=""></option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName?.value == 'Autre'">
                        <input class="form-control" type="text" placeholder="client" id="client" formControlName="autreclient">
                      </div>
                </td>     
      </tr>
      <tr>
          <td colspan="11"></td>
          <td>
            <div>
            <button type="button" class="btn btn-danger btn-sm"*ngIf="editionnotepastform.get('ammounts0').value.length>1" (click)="removeammount0(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
            </div>
           </td>
          <td>
              <div>
              <button type="button" class="btn btn-primary mb-2" (click)="addammount0()"><span><i class="fa fa-plus-circle"></i></span></button>   
              </div>
             </td>
      </tr>
      <tr id="resultrow">
          <td colspan="3">
              <div >
                   <p>Totaux<span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
                    activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
                    ||activite==='Sage-femmes'"> (C.A à 7%) </span></p>
              </div>
          </td>
          <td style="text-align: center;">{{totalht0 | number:'1.3-3'| removeComma}}</td>
          <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'"></td>
          <td style="text-align: center;">{{totaltva0 | number:'1.3-3'| removeComma}}</td>
          <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'"></td>
          <td style="text-align: center;">{{totaldt0 | number:'1.3-3'| removeComma}}</td>
          <td style="text-align: center;">{{totalttc0 | number:'1.3-3'| removeComma}}</td>
          <td *ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
          &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'"></td>
      </tr>
      <tr id="resultrow"*ngIf="option1Value==='2023'&&sousactivite==='Médecin spécialiste'||option1Value==='2023'&&sousactivite==='Médecin'||option1Value==='2023'
      &&sousactivite==='Médecin dentiste'||option1Value==='2023'&&sousactivite==='Médecin vétérinaire'">
        <td colspan="3">
            <div >
                 <p>Totaux<span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
                  activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
                  ||activite==='Sage-femmes'"> (C.A à 19%) </span></p>
            </div>
        </td>
        <td></td>
        <td style="text-align: center;">{{totalht019 | number:'1.3-3'| removeComma}}</td>
        <td></td>
        <td style="text-align: center;">{{totaltva019 | number:'1.3-3'| removeComma}}</td>
        <td></td>
        <td></td>
        <td style="text-align: center;">{{totalttc019 | number:'1.3-3'| removeComma}}</td>
    </tr>
  
  </tbody>
  </table>
  </fieldset>-->
  <!--saisie des données factures-->
  <div class="container-fluid" id="invoiceform"*ngIf="showinvoiceform===true">
    <!--etat de recette numerote section-->
    <fieldset id="editionnote" *ngIf="showeditionnote">
    <legend>Recette du mois  {{option2Value}} de l'année {{option1Value}} <span *ngIf="contientnumero==true">(quittances numerotées)</span><span *ngIf="contientnumero==false">(quittances non numerotées)</span></legend>
    <table class="table table-striped" id="editionnotesection" [formGroup]="editionnoteform">
    <thead>
    <tr>
    <th rowspan="2">N° pièce</th>                        
    <th rowspan="2">Jour</th>
    <th rowspan="2">Date complète</th>
    <th rowspan="2">Type</th>
    <th *ngIf="contientnumero==true"rowspan="2">N°</th>
    <th rowspan="2">Montant</th>
    <th rowspan="2">Coproprietaire</th>
    <th rowspan="2">Objet</th>
    <th rowspan="2">Nature</th>
    <th colspan="4">Données complémentaires</th>
    <th *ngIf="contientnumero==true">Annulation</th>

    </tr>
    <tr>
      <th>Banque</th>
      <th>Numéro</th>
      <th>Echéance</th>
      <th>Date de virement</th>
    </tr>
    </thead>
    <tbody formArrayName="ammounts"> 
        <tr *ngFor="let ammount of ammountControls; let i = index;"[formGroupName]="i">    
          <td>
            <div >
                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/>
            </div>
          </td>      
          <td style="width: 50px;">
                        <input type="text"class="form-control"(change)="setdate(i)"formControlName="jour"placeholder="Jour"/>
                           <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                          </td>
                   <td>
                   <div >
                       <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
                   </div>
                   <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                  </td>
                  <td>
                      <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiecesyndic" name="typepiece">
                        <option hidden value="avoir">Avoir</option>
                        <option value="facture" selected>Quittance</option>
  
                      </select>
                  </td>
                  <td style="width: 50px;"*ngIf="contientnumero==true">
                   <div >
                        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                        class="form-control"formControlName="numeronote"(change)="onChange(i)"placeholder="N° note honoraire"readonly='true'/>
                      
                    </div>
                  </td>
                  <td>
                    <div >
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                       class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                       (change)="onChange(i)"/>
                    </div>
                    <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                      veuillez vérifier votre saisie!
                    </div> 
                  </td>
                  <td style="width: 150px">
                    <div >
                      <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName34 formControlName="client" name="client"(change)="onChange(i)" (click)="sort()">
                        <option hidden [value]=""></option>
                        <option value=""></option>
                  <option *ngFor="let cl of user.coproprietaires" [value]="cl.numero">
                    {{cl.numero}}&nbsp;{{cl.coproprietaire}}
                </option>
                        <option hidden value="Autre">Autre</option>
                      </select>
                        </div>
                        <div *ngIf="someHashName34?.value == 'Autre'">
                          <input class="form-control" type="text"(change)="onChange(i)" placeholder="client" id="client" formControlName="autreclient">
                        </div>
                  </td>
                  
                  <td style="width: 150px">
                    <div >
                      <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName16 formControlName="objet" name="objet"(change)="onchangenature(i)" (click)="sort()">
                        <option hidden [value]=""></option>
                        <option value=""></option>
                  <option *ngFor="let ob of settedcopobjets" [value]="ob">
                    {{ob}}
                </option>
                        <option value="Autre">Autre</option>
                      </select>
                        </div>
                        <div *ngIf="someHashName16?.value == 'Autre'">
                          <input class="form-control" type="text"(change)="onChange(i)" placeholder="client" id="obj" formControlName="autreobjet">
                        </div>
                  </td>
                  <td style="width: 150px">
                    <div >
                      <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName15 formControlName="nature"(change)="onchangenature(i)" name="nature" (click)="sort()">
                        <option hidden [value]=""></option>
                        <option value=""></option>
                  <option *ngFor="let na of settedcopnatures" [value]="na">
                    {{na}}
                </option>
                        <option value="Autre">Autre</option>
                      </select>
                        </div>
                        <div *ngIf="someHashName15?.value == 'Autre'">
                          <input class="form-control" type="text"(change)="onChange(i)" placeholder="client" id="nat" formControlName="autrenature">
                        </div>
                  </td>
                      <td style="width: 150px" >                      
                      <div style="display: none;" id="{{ 'copbanque' + i }}">
                        <input class="form-control"placeholder="banque" id="banque"#someHashName4 formControlName="banque" name="banque">
                      </div>
                      </td>
                      <td style="width: 150px" >                   
                      <div style="display: none;" id="{{ 'copnumero' + i }}">
                        <input class="form-control"placeholder="numero chèque" id="banque"#someHashName5 formControlName="numerocompte" name="banque">
                      </div>
                      </td>
                      <td style="width: 150px" >
                    
                      <div style="display: none;" id="{{ 'echeance' + i }}">
                        <input class="form-control" type="date" placeholder="échéance" id="banque" formControlName="echeance">
                      </div>
                      </td>
                      <td style="width: 150px" >
                    
                        <div style="display: none;" id="{{ 'datevirement' + i }}">
                          <input class="form-control" type="date" placeholder="Banque" id="banque" formControlName="datevirement">
                        </div>
                        </td>
                        <td *ngIf="contientnumero==true">
                          <div>
                            <input type="checkbox" id="annule" (change)="onannule(i)"  formControlName=annule/><span><i class="glyphicon glyphicon-remove"></i></span>Annuler
                          </div>
                         </td>  
                       
        </tr>
        <tr>
            <td colspan="10"></td>
            
            <td>
                <div>
                <button type="button" class="btn btn-primary mb-2" (click)="addammount()"><span><i class="fa fa-plus-circle"></i></span></button>   
                </div>
               </td>
               <td>                     
                <button type="button" class="btn btn-danger btn-sm" (click)="removeammount()"><span><i class="fa fa-minus-circle"></i></span></button>                          
               </td>
        </tr>
          <tr id="resultrow">
            <td colspan="5" *ngIf="contientnumero==true">
                <div >
                     <p>Totaux</p>
                </div>
            </td>
            <td colspan="4" *ngIf="contientnumero==false">
              <div >
                   <p>Totaux</p>
              </div>
          </td>
            <td style="text-align: center;">{{totalht | number:'1.3-3'| removeComma}}</td>         
          </tr>
    </tbody>   
    </table>
    </fieldset>
    <button type="button" class="btn btn-primary mb-2" (click)="showotheretatrecette()"><span><i class="fa fa-plus-circle"></i></span>Ajouter autre recette</button>   
    <!--etat de recette non numerote section-->
    <fieldset id="editionnote" *ngIf="showeditionnotepast">
      <legend>Recette du mois {{option2Value}} de l'année {{option1Value}}<span *ngIf="contientnumeroother==true">(quittances numerotées)</span><span *ngIf="contientnumeroother==false">(quittances non numerotées)</span></legend>
      <table class="table table-striped" id="editionnotesection" [formGroup]="editionnotepastform">
        <thead>
          <tr>
          <th rowspan="2">N° pièce</th>                        
          <th rowspan="2">Jour</th>
          <th rowspan="2">Date complète</th>
          <th rowspan="2">Type</th>
          <th *ngIf="contientnumeroother==true"rowspan="2">N°</th>
          <th rowspan="2">Montant</th>
          <th rowspan="2">Coproprietaire</th>
          <th rowspan="2">Objet</th>
          <th rowspan="2">Nature</th>
          <th colspan="4">Données complémentaires</th>
          <th *ngIf="contientnumeroother==true">Annulation</th>

          </tr>
          <tr>
            <th>Banque</th>
            <th>Numéro</th>
            <th>Echéance</th>
            <th>Date de virement</th>
          </tr>
          </thead>
      <tbody formArrayName="ammounts0"> 
          <tr *ngFor="let ammount of ammountControls0; let i = index;"[formGroupName]="i">    
            <td>
              <div >
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                  class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/>
              </div>
            </td>      
            <td style="width: 50px;">
                          <input type="text"class="form-control"(change)="setdate0(i)"formControlName="jour"placeholder="Jour"/>
                             <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
                              veuillez vérifier votre saisie!
                            </div> 
                            </td>
                     <td>
                     <div >
                         <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
                     </div>
                     <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
                      veuillez vérifier votre saisie!
                    </div> 
                    </td>
                    <td>
                        <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiecesyndic" name="typepiece">
                          <option hidden value="avoir">Avoir</option>
                          <option  value="facture" selected>Quittance</option>
  
                        </select>
                    </td>
                    <td style="width: 50px;"*ngIf="contientnumeroother==true">
                     <div >
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="numeronote"(change)="onChange0(i)"placeholder="N° note honoraire"readonly='true'/>
                        
                      </div>
                    </td>
                    <td>
                      <div >
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                         class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"placeholder="Montant HT"
                         (change)="onChange0(i)"/>
                      </div>
                      <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                    </td>
                    <td style="width: 150px">
                      <div >
                        <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName34 formControlName="client" name="client"(change)="onChange0(i)" (click)="sort()">
                          <option hidden [value]=""></option>
                          <option value=""></option>
                    <option *ngFor="let cl of user.coproprietaires" [value]="cl.numero">
                      {{cl.numero}}&nbsp;{{cl.coproprietaire}}
                  </option>
                          <option hidden value="Autre">Autre</option>
                        </select>
                          </div>
                          <div *ngIf="someHashName34?.value == 'Autre'">
                            <input class="form-control" type="text"(change)="onChange(i)" placeholder="client" id="client" formControlName="autreclient">
                          </div>
                    </td>
                    
                    <td style="width: 150px">
                      <div >
                        <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName35 formControlName="objet" name="objet"(change)="onchangenature0(i)" (click)="sort()">
                          <option hidden [value]=""></option>
                          <option value=""></option>
                    <option *ngFor="let ob of settedcopobjets" [value]="ob">
                      {{ob}}
                  </option>
                          <option value="Autre">Autre</option>
                        </select>
                          </div>
                          <div *ngIf="someHashName35?.value == 'Autre'">
                            <input class="form-control" type="text"(change)="onChange0(i)" placeholder="client" id="obj" formControlName="autreobjet">
                          </div>
                    </td>
                    <td style="width: 150px">
                      <div >
                        <select class="btn btn-select resizeselect"placeholder="Client" id="client"#someHashName36 formControlName="nature" name="nature"(change)="onchangenature0(i)" (click)="sort()">
                          <option hidden [value]=""></option>
                          <option value=""></option>
                    <option *ngFor="let na of settedcopnatures" [value]="na">
                      {{na}}
                  </option>
                          <option value="Autre">Autre</option>
                        </select>
                          </div>
                          <div *ngIf="someHashName36?.value == 'Autre'">
                            <input class="form-control" type="text"(change)="onChange0(i)" placeholder="client" id="nat" formControlName="autrenature">
                          </div>
                    </td>
                    <td style="width: 150px" >                      
                      <div style="display: none;" id="{{ 'copbanque0' + i }}">
                        <input class="form-control"placeholder="banque" id="banque0"#someHashName4 formControlName="banque" name="banque">
                      </div>
                      </td>
                      <td style="width: 150px" >                   
                      <div style="display: none;" id="{{ 'copnumero0' + i }}">
                        <input class="form-control"placeholder="numero chèque" id="banque"#someHashName5 formControlName="numerocompte" name="banque">
                      </div>
                      </td>
                    <td style="width: 150px" >
                  
                    <div style="display: none;" id="{{ 'echeance0' + i }}">
                      <input class="form-control" type="date" placeholder="échéance" id="banque" (change)="onChange0(i)"formControlName="echeance">
                    </div>
                    </td>
                    <td style="width: 150px" >
                  
                      <div style="display: none;" id="{{ 'datevirement0' + i }}">
                        <input class="form-control" type="date" placeholder="Banque" id="banque" (change)="onChange0(i)"formControlName="datevirement">
                      </div>
                      </td>
                      <td *ngIf="contientnumeroother==true">
                        <div>
                          <input type="checkbox" id="annule" (change)="onannule0(i)"  formControlName=annule/><span><i class="glyphicon glyphicon-remove"></i></span>Annuler
                        </div>
                       </td>
                      
          </tr>
          <tr>
              <td colspan="10"></td>
            
              <td>
                  <div>
                  <button type="button" class="btn btn-primary mb-2" (click)="addammount0()"><span><i class="fa fa-plus-circle"></i></span></button>   
                  </div>
                 </td>
                 <td>
                  <div>
                  <button type="button" class="btn btn-danger mb-2" (click)="removeammount0()"><span><i class="fa fa-minus-circle"></i></span></button>   
                  </div>
                 </td> 
          </tr>
            <tr id="resultrow">
              <td colspan="5"*ngIf="contientnumeroother==true">
                  <div >
                       <p>Totaux</p>
                  </div>
              </td>
              <td colspan="4"*ngIf="contientnumeroother==false">
                <div >
                     <p>Totaux</p>
                </div>
            </td>
              <td style="text-align: center;">{{totalht0 | number:'1.3-3'| removeComma}}</td>         
            </tr>
      </tbody>   
      </table>
      </fieldset>
    </div>
  </mat-tab>
  <mat-tab label="Dépenses chèques" *ngIf="showachatchequetab===true"aria-label="Dépenses chèques"> 
    <!--facture achat section-->
    <fieldset id="factureachat">
      <legend>Gestion des dépenses chèques</legend>
      <table class="table table-striped" id="factureachatsection" [formGroup]="factureachatchequeform">
      <thead>
      <tr>
      <th>N° pièce</th>                        
      <th>Jour</th>
      <th>Date complète</th>
      <th>Objet règlement</th>
      <th>Fournisseur/Bénéficiaire</th>
      <th>N°</th>
      <th>Nature de la dépense</th>
      <th>Montant</th>
      <th>Banque</th>
      <th>Numéro de chèque</th>
      <th>Contient document</th>
      <th>Document Joint</th>
      </tr>
      </thead>
      <tbody formArrayName="ammounts8">     
          <tr *ngFor="let ammount of ammountControls8; let i = index;"[formGroupName]="i">
            <td>
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/> 
            </td>
            <td>
    <input type="text"class="form-control"(change)="setdate3(i,factureachatchequeform.get('ammounts8'))"formControlName="jour"placeholder="Jour"/>
    <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
      veuillez vérifier votre saisie!
    </div>      
  </td>
            <td>
            <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>  
            <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
              veuillez vérifier votre saisie!
            </div> 
          </td>
            <td>
              <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiece" name="typepiece"(change)="onchangetypefacture(i,factureachatchequeform.get('ammounts8'))">
                <option hidden [value]=""></option>
                <option value="facture">Facture</option>
                <option value="recu">Recu</option>
              </select>
          </td>
            <td style="width: max-width;display: block;"*ngIf="factureachatchequeform.getRawValue().ammounts8.at(i).typepiece=='facture'">
              
      <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName (change)="filtrednumfactures(i,factureachatchequeform.get('ammounts8'))"formControlName="fournisseur" name="fournisseur" (click)="sort()">
                          <option value="" disabled="true" [selected]="true">..fournisseur</option>
                          <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                          <option hidden value="Autre">Autre</option>
      </select>                       
      <input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatchequeform.get('ammounts8'),factureachatchequeform.getRawValue().ammounts8)" placeholder="Fournisseur" id="fournisseur" formControlName="autrefournisseur">
                          
            </td>
            <td style="width: max-width;display: block;"*ngIf="factureachatchequeform.getRawValue().ammounts8.at(i).typepiece=='recu'">
              
              <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName formControlName="beneficiaire"(change)="filtrednumfactures(i,factureachatchequeform.get('ammounts8'))" name="beneficiaire" (click)="sort()">
                                  <option value="" disabled="true" [selected]="true">..bénéficiaire</option>
                                  <option *ngFor="let fou of settedbeneficiaires" [value]="fou.four">{{fou.four}}</option>
                                  <option value="Autre">Autre</option>
              </select>                       
              <input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatchequeform.get('ammounts8'),factureachatchequeform.getRawValue().ammounts8)" placeholder="beneficiaire" id="beneficiaire" formControlName="autrebeneficiaire">
                                  
                    </td>
                    <td>
                      
                      <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName formControlName="numerofacture"name="numerofacture" (change)="filtrednumfactures(i,factureachatchequeform.get('ammounts8'))"(click)="sort()"*ngIf="!factureachatchequeform.getRawValue().ammounts8.at(i).numerofacture">
                        <option value="" disabled="true" [selected]="true">..Numéro</option>
                        <option *ngFor="let numfact of filtrednumfactures(i,factureachatchequeform.get('ammounts8'))" [value]="numfact.numerofacture">{{numfact.numerofacture}}</option>
    </select> 
                {{factureachatchequeform.getRawValue().ammounts8.at(i).numerofacture}}      
                     </td>
                     <td style="width: max-width; display: block;">
                      
                  <select  class="form-control"placeholder="Nature" id="natureachat"#someHashName2 formControlName="natureachat" name="natureachat" (click)="sort()">
                    <option hidden [value]=""></option>
                    <option value=""></option>
                    <option *ngFor="let na of settednatures" [value]="na.nat">
                      {{na.nat}}
                  </option>
                    <option value="Autre">Autre</option>
                  </select>
                    
                      <input *ngIf="someHashName2?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatchequeform.get('ammounts8'),factureachatchequeform.getRawValue().ammounts8)" placeholder="Nature" id="nature" formControlName="autrenatureachat">
                    
                     </td>
                     <td>
                      <div>                    
                        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"
                            (change)="onChange3(i,factureachatchequeform.get('ammounts8'),factureachatchequeform.getRawValue().ammounts8)"placeholder="Montant HT"/>
                      </div> 
                      <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div>     
                        </td>
                        <td style="width: 150px">
                                  <div>
                              <select  class="form-control"placeholder="banque" id="banque"#someHashName4 formControlName="banque" name="banque">
                                <option hidden [value]=""></option>
                                <option value=""></option>
                                <option *ngFor="let bq of settedbanques" [value]="bq.bq">
                                  {{bq.bq}}
                              </option>
                                <option hidden value="Autre">Autre</option>
                              </select>
                                </div>
                                <div *ngIf="someHashName4?.value == 'Autre'">
                                  <input class="form-control" type="text"placeholder="Banque" id="banque" formControlName="autrebanque">
                                </div>
                        </td>
                    <td>
                      <div>                    
              <input type="text" class="form-control"formControlName="numerocheque"placeholder="numéro chèque"/>
                      </div>     
                    </td>
                    <td style="width:20px"> 
                        <input type="checkbox" id="{{ 'myCheck8' + i }}" (change)="docjoint8(i)"  formControlName=contientfiche/>
                        <span *ngIf="factureachatchequeform.get('ammounts8').value.at(i).ficheUrl"><a href="{{factureachatchequeform.get('ammounts8').value.at(i).ficheUrl| lowercase}}" target="_blank">Aperçu facture</a></span>       

                   </td>
                   <td id="{{ 'achatcheque' + i }}" style="display: none;width: 300px"> 
                         <input type="file"  #filePicker (change)="onImagePick($event,i)" class="form-control" accept="application/pdf">
                         <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                     
                   </td>
                    <td>
                     
                     <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount8(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                     
                    </td>
                    
          </tr>
          <tr>
            <td colspan="12"></td>
            <td>
                <div>
                <button type="button" class="btn btn-primary mb-2" (click)="addammount8()"><span><i class="fa fa-plus-circle"></i></span></button>   
                </div>
               </td>
        </tr>
          <tr id="resultrow">
              <td colspan="7">
                  <div >
                       <p>Totaux</p>
                  </div>
              </td>
              <td style="text-align: center;">{{totalht8 | number:'1.3-3'| removeComma}}</td>
             
              
          </tr>
      </tbody>
      
      </table>
      </fieldset>
    </mat-tab>
  <mat-tab label="Dépenses espèces" *ngIf="showachatespecetab===true"aria-label="Dépenses espèces"> 
  <!--facture achat section-->
  <fieldset id="factureachat">
    <legend>Gestion des dépenses espèces</legend>
    <table class="table table-striped" id="factureachatsection" [formGroup]="factureachatespeceform">
    <thead>
    <tr>
    <th>N° pièce</th>                        
    <th>Jour</th>
    <th>Date complète</th>
    <th>Objet règlement</th>
    <th>Fournisseur/Bénéficiaire</th>
    <th>N°</th>
    <th>Nature de la dépense</th>
    <th>Montant</th>
    <th>Contient document</th>
    <th>Document Joint</th>
    </tr>
    </thead>
    <tbody formArrayName="ammounts11">     
        <tr *ngFor="let ammount of ammountControls11; let i = index;"[formGroupName]="i">
          <td>
  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/> 
          </td>
          <td>
  <input type="text" class="form-control"(change)="setdate3(i,factureachatespeceform.get('ammounts11'))"formControlName="jour"placeholder="Jour"/>
  <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div>  
</td>
          <td>
          <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>  
          <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
            veuillez vérifier votre saisie!
          </div> 
        </td>
          <td>
            <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiece" name="typepiece"(change)="onchangetypefacture(i,factureachatespeceform.get('ammounts11'))">
              <option hidden [value]=""></option>
              <option value="facture">Facture</option>
              <option value="recu">Recu</option>
            </select>
        </td>
          <td style="width: max-width;display: block;"*ngIf="factureachatespeceform.getRawValue().ammounts11.at(i).typepiece=='facture'">
    
            <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName (change)="filtrednumfactures(i,factureachatespeceform.get('ammounts11'))"formControlName="fournisseur" name="fournisseur" (click)="sort()">
              <option value="" disabled="true" [selected]="true">..fournisseur</option>
                        <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                        <option hidden value="Autre">Autre</option>
    </select>                       
    <input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatespeceform.get('ammounts11'),factureachatespeceform.getRawValue().ammounts11)" placeholder="Fournisseur" id="fournisseur"formControlName="autrefournisseur">
                        
          </td>
          <td style="width: max-width;display: block;"*ngIf="factureachatespeceform.getRawValue().ammounts11.at(i).typepiece=='recu'">
            
            <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName formControlName="beneficiaire" name="beneficiaire" (click)="sort()"(change)="filtrednumfactures(i,factureachatespeceform.get('ammounts11'))">
              <option value="" disabled="true" [selected]="true">..bénéficiaire</option>
                                <option *ngFor="let fou of settedbeneficiaires" [value]="fou.four">{{fou.four}}</option>
                                <option value="Autre">Autre</option>
            </select>                       
            <input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatespeceform.get('ammounts11'),factureachatespeceform.getRawValue().ammounts11)" placeholder="beneficiaire" id="beneficiaire" formControlName="autrebeneficiaire">
                                
                  </td>
                  <td>
                      
                    <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName formControlName="numerofacture"name="numerofacture"(change)="filtrednumfactures(i,factureachatespeceform.get('ammounts11'))" (click)="sort()"*ngIf="!factureachatespeceform.getRawValue().ammounts11.at(i).numerofacture">
                      <option value="" disabled="true" [selected]="true">..Numéro</option>
                      <option *ngFor="let numfact of filtrednumfactures(i,factureachatespeceform.get('ammounts11'))" [value]="numfact.numerofacture">{{numfact.numerofacture}}</option>
                    </select> 
                    {{factureachatespeceform.getRawValue().ammounts11.at(i).numerofacture}}
                   </td>
                   <td style="width: max-width; display: block;">
                    
                <select  class="form-control"placeholder="Nature" id="natureachat"#someHashName2 formControlName="natureachat" name="natureachat" (click)="sort()">
                  <option hidden [value]=""></option>
                  <option value=""></option>
                  <option *ngFor="let na of settednatures" [value]="na.nat">
                    {{na.nat}}
                </option>
                  <option value="Autre">Autre</option>
                </select>
                  
                    <input *ngIf="someHashName2?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,factureachatespeceform.get('ammounts11'),factureachatespeceform.getRawValue().ammounts11)" placeholder="Nature" id="nature" formControlName="autrenatureachat">
                  
                   </td>
                   <td>
                    <div>                    
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"
                          (change)="onChange3(i,factureachatespeceform.get('ammounts11'),factureachatespeceform.getRawValue().ammounts11)"placeholder="Montant HT"/>
                    </div> 
                    <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                      veuillez vérifier votre saisie!
                    </div>     
                              </td>
                  <td style="width:20px"> 
                      <input type="checkbox" id="{{ 'myCheck11' + i }}" (change)="docjoint11(i)"  formControlName=contientfiche/>
                      <span *ngIf="factureachatespeceform.get('ammounts11').value.at(i).ficheUrl"><a href="{{factureachatespeceform.get('ammounts11').value.at(i).ficheUrl| lowercase}}" target="_blank">Aperçu facture</a></span>       

                 </td>
                 <td id="{{ 'achatespece' + i }}" style="display: none;width: 300px"> 
                       <input type="file"  #filePicker (change)="onImagePick10($event,i)" class="form-control" accept="application/pdf">
                       <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                   
                 </td>
                  <td>
                   
                   <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount11(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                   
                  </td>
                  
        </tr>
        <tr>
          <td colspan="12"></td>
          <td>
              <div>
              <button type="button" class="btn btn-primary mb-2" (click)="addammount11()"><span><i class="fa fa-plus-circle"></i></span></button>   
              </div>
             </td>
      </tr>
        <tr id="resultrow">
            <td colspan="7">
                <div >
                     <p>Totaux</p>
                </div>
            </td>
            <td style="text-align: center;">{{totalht11 | number:'1.3-3'| removeComma}}</td>
           
            
        </tr>
    </tbody>
    
    </table>
    </fieldset>
  </mat-tab>
  <mat-tab label="Factures fournisseurs" *ngIf="showfacturefournisseurtab===true"aria-label="facture fournisseur"> 
    <!--facture achat section-->
    <fieldset id="facturefournisseur">
      <legend>Gestion des factures fournisseurs</legend>
      <table class="table table-striped" id="factureachatsection" [formGroup]="facturefournisseurform">
      <thead>
      <tr>
      <th>N° pièce</th>                        
      <th>Jour</th>
      <th>Date complète</th>
      <th>Objet règlement</th>
      <th>Fournisseur/Bénéficiaire</th>
      <th>N°</th>
      <th>Nature de la dépense</th>
      <th>Montant</th> 
      <th>Contient document</th>
      <th>Document Joint</th>
      </tr>
      </thead>
      <tbody formArrayName="ammounts9">     
          <tr *ngFor="let ammount of ammountControls9; let i = index;"[formGroupName]="i">
            <td>
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" class="form-control"formControlName="numeropiece"placeholder="numeropiece"readonly='true'/> 
            </td>
            <td>
    <input type="text"class="form-control"(change)="setdate3(i,facturefournisseurform.get('ammounts9'))"formControlName="jour"placeholder="Jour"/>
    <div *ngIf="ammount.get('jour').hasError('pattern')" class="invalid">
      veuillez vérifier votre saisie!
    </div>           
  </td>
            <td>
            <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>  
            <div *ngIf="ammount.get('date').hasError('pattern')" class="invalid">
              veuillez vérifier votre saisie!
            </div> 
          </td>
            <td>
              <select class="btn btn-select resizeselect"placeholder="type" id="type"formControlName="typepiece" name="typepiece"(change)="onchangetypefacture(i,facturefournisseurform.get('ammounts9'))">
                <option hidden [value]=""></option>
                <option value="facture">Facture</option>
                <option value="avoir">Avoir</option>
              </select>
          </td>
            <td style="width: max-width;display: block;">
              
      <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName (change)="verifyinvoicesyndic(i)"formControlName="fournisseur" name="fournisseur" (click)="sort()">
                          <option value="" disabled="true" [selected]="true">..fournisseur</option>
                          <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                          <option value="Autre">Autre</option>
      </select>                       
      <input *ngIf="someHashName?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,facturefournisseurform.get('ammounts9'),facturefournisseurform.getRawValue().ammounts9)" placeholder="Fournisseur" id="fournisseur" (change)="verifyinvoicesyndic(i)"formControlName="autrefournisseur">
                          
            </td>
                    <td>
                      
                           <input type="text" 
                         class="form-control"formControlName="numerofacture"(change)="verifyinvoicesyndic(i)"placeholder="Facture"/>
                      
                     </td>
                     <td style="width: max-width; display: block;">
                      
                  <select  class="form-control"placeholder="Nature" id="natureachat"#someHashName2 formControlName="natureachat" name="natureachat" (click)="sort()">
                    <option hidden [value]=""></option>
                    <option value=""></option>
                    <option *ngFor="let na of settednatures" [value]="na.nat">
                      {{na.nat}}
                  </option>
                    <option value="Autre">Autre</option>
                  </select>
                    
                      <input *ngIf="someHashName2?.value == 'Autre'"class="form-control" type="text"(change)="onChange3(i,facturefournisseurform.get('ammounts9'),facturefournisseurform)" placeholder="Nature" id="nature" formControlName="autrenatureachat">
                    
                     </td>
                     <td>
                      <div>                    
                        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"formControlName="montantht"(blur)="setThreeNumberDecimal($event)"
                            (change)="onChange3(i,facturefournisseurform.get('ammounts9'),facturefournisseurform.getRawValue().ammounts9)"placeholder="Montant HT"/>
                      </div> 
                      <div *ngIf="ammount.get('montantht').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div>     
                                </td>
          
                  
                    <td style="width:20px"> 
                        <input type="checkbox" id="{{ 'myCheck9' + i }}" (change)="docjoint9(i)"  formControlName=contientfiche/>
                        <span *ngIf="facturefournisseurform.get('ammounts9').value.at(i).ficheUrl"><a href="{{facturefournisseurform.get('ammounts9').value.at(i).ficheUrl| lowercase}}" target="_blank">Aperçu facture</a></span>       

                   </td>
                   <td id="{{ 'facturefournisseur' + i }}" style="display: none;width: 300px"> 
                         <input type="file"  #filePicker (change)="onImagePick9($event,i)" class="form-control" accept="application/pdf">
                         <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                     
                   </td>
                    <td>
                     
                     <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount9(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                     
                    </td>
                    
          </tr>
          <tr>
            <td colspan="12"></td>
            <td>
                <div>
                <button type="button" class="btn btn-primary mb-2" (click)="addammount9()"><span><i class="fa fa-plus-circle"></i></span></button>   
                </div>
               </td>
        </tr>
          <tr id="resultrow">
              <td colspan="7">
                  <div >
                       <p>Totaux</p>
                  </div>
              </td>
              <td style="text-align: center;">{{totalht9 | number:'1.3-3'| removeComma}}</td>
             
              
          </tr>
      </tbody>
      
      </table>
      </fieldset>
    </mat-tab>
  <mat-tab label="Banques" *ngIf="showbanquetab===true&&!forassiette"aria-label="banque"> 
  <!--saisie des données banques-->
  <!--saisie relevé manuel-->
  <fieldset id="relevemanuel" *ngIf="showrelevemanuel">
  <legend>Saisie des relevés manuels</legend>
  <div class="container fluid" id="relevemanuelsection" [formGroup]="relevemanuelform"style="margin:0px">
    <div formArrayName="employees" id="allreleve">
      <div *ngFor="let employee of employees().controls; let empIndex=index">
        <div [formGroupName]="empIndex"style="border: 1px solid blue; padding: 10px; margin: 5px;width:max-content">Détails du compte N°{{empIndex}}:
          <table class="table table-striped" style="width: 50%;">
            <thead>
              <tr>
                <th>Banque</th>
                <th>N° compte</th>
                <th>Devise</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>Débit</th>
                <th>Crédit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 150px">
                  <div>
              <select  class="form-control"placeholder="banque" id="banque"#someHashName4 formControlName="banque" name="banque"(change)="controlbankaccount(empIndex)"(change)="changedevise4(empIndex,skillIndex)" >
                <option hidden [value]=""></option>
                <option value=""></option>
                <option *ngFor="let bq of settedbanques" [value]="bq.bq">
                  {{bq.bq}}
              </option>
                <option value="Autre">Autre</option>
              </select>
                </div>
                <div *ngIf="someHashName4?.value == 'Autre'">
                  <input class="form-control" type="text"(change)="onChange4(empIndex)" placeholder="Banque" id="banque" formControlName="autrebanque">
                </div>
                 </td>
                <td style="width: 150px">
                  <div>
              <select  class="form-control"placeholder="numero compte" id="numerocompte"#someHashName2 formControlName="numerocompte" name="numerocompte"(change)="controlbankaccount(empIndex)"(change)="changedevise4(empIndex,skillIndex)"(click)="filternumeros(empIndex)" >
                <option hidden [value]=""></option>
                <option value=""></option>
                <option *ngFor="let nc of filternumeros(empIndex)" [value]="nc.nc">
                  {{nc.nc}}
              </option>
                <option value="Autre">Autre</option>
              </select>
                </div>
                <div *ngIf="someHashName2?.value == 'Autre'">
                  <input class="form-control" type="text"(change)="onChange4(empIndex)" placeholder="Numero compte" id="numero" formControlName="autrenumerocompte">
                </div>
                 </td>
                 <td>
                  <div>
                    <select class="btn btn-select resizeselect"placeholder="devise" id="devise"formControlName="devise" name="devise"(change)="changedevise4(empIndex,skillIndex)"(change)="onChange4(empIndex,skillIndex)">
                      <option value="tnd">TND</option>
                      <option value="euro" selected >Euro</option>
                      <option value="dollar">USD</option>
                      <option value="gbp">GBP</option>
                      <option value="gbp">GBP</option>
                        <option value="dzd">DZD</option>
                        <option value="sar">SAR</option>
                        <option value="cad">CAD</option>
                        <option value="dkk">DKK</option>
                        <option value="jpy">JPY</option>
                        <option value="mad">MAD</option>
                        <option value="nok">NOK</option>
                        <option value="sek">SEK</option>
                        <option value="chf">CHF</option>
                        <option value="kwd">KWD</option>
                        <option value="aed">AED</option>
                        <option value="lyd">LYD</option>
                        <option value="mru">MRU</option>
                        <option value="bhd">BHD</option>
                        <option value="qar">QAR</option>
                        <option value="cny">CNY</option>
                    </select>
                  </div>
                </td>            
              </tr>
                <tr>
              <td colspan="3">
                Solde à la fin du mois précédent:
              </td>
              <td>
                <div>
                   <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex)"placeholder="Débit"
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                   <div *ngIf="employee.get('debit').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="debitdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
  (change)="onChange4(empIndex)"appdeuxDigitDecimaNumber
  *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
  <div *ngIf="employee.get('debitdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div> 
</div>
               </td>
               <td>
                <div >
                     <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex)"placeholder="Crédit"
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                   <div *ngIf="employee.get('credit').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                   class="form-control"formControlName="creditdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
                   (change)="onChange4(empIndex)"appdeuxDigitDecimaNumber
                   *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
                   <div *ngIf="employee.get('creditdevise').hasError('pattern')" class="invalid">
                    veuillez vérifier votre saisie!
                  </div> 
                  </div>
               </td>
               <td>
                <div>
                <button type="button" class="btn btn-danger btn-sm" (click)="removeEmployee(empIndex)">Supprimer compte</button>   
                </div>
               </td> 
            </tr>
            </tbody>
          </table>
        
                <table class="table table-striped"style="width: 50%;">
                  <thead>
                    <tr>
                      <th rowspan="2">Jour de l'opération</th>
                      <th rowspan="2"> Date de l'opération</th>
                      <th rowspan="2"> Désignation</th>
                      <th rowspan="2"> Débit</th>
                      <th rowspan="2"> Crédit</th>
                      <th rowspan="2"> Objet Débit</th>
                      <th rowspan="2"> Objet Crédit</th>
                      <th rowspan="2"> Contient tiers</th>
                      <th colspan="2"> Tiers fournisseur/client</th>
                    </tr>
                    <tr>
                      <th>Fournisseur</th>
                      <th>Client</th> 
                    </tr> 
                  </thead>
                  <tbody formArrayName="skills">
                    <tr *ngFor="let skill of employeeSkills(empIndex).controls; let skillIndex=index"[formGroupName]="skillIndex">
                      <td style="width: 50px;">
                        <div >
                            <input type="text"  
                               class="form-control"(change)="setdate4(empIndex,skillIndex)"formControlName="jour"placeholder="Jour"/>
                        </div>
                        <div *ngIf="skill.get('jour').hasError('pattern')" class="invalid">
                          veuillez vérifier votre saisie!
                        </div> 
                    </td>
                       <td>
                       <div >
                           <input type="text"class="form-control"formControlName="date"placeholder="Date"readonly='true'/>
                       </div>
                       
                       <div *ngIf="skill.get('date').hasError('pattern')" class="invalid">
                        veuillez vérifier votre saisie!
                      </div> 
                      </td>
                      <td>
                        <div >
                          <textarea type="text"class="form-control"formControlName="designation"placeholder="Désignation" cols="30" rows="2"></textarea>
                        </div>
                       </td>
                      <td>
                       <div >
                            <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex,skillIndex)"placeholder="Débit"
                          *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                          <div *ngIf="skill.get('debit').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="debitdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
  (change)="onChange4(empIndex,skillIndex)"appdeuxDigitDecimaNumber
  *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
  <div *ngIf="skill.get('debitdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div>      
</div>
                      </td>
                      <td>
                       <div >
                            <input type="text"style="max-width: 100px" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(empIndex,skillIndex)"placeholder="Crédit"
                          *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'"/>
                          <div *ngIf="employee.get('credit').hasError('pattern')" class="invalid">
                            veuillez vérifier votre saisie!
                          </div> 
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="creditdevise"(blur)="setTwoNumberDecimal($event)"placeholder="Montant HT"
  (change)="onChange4(empIndex,skillIndex)"appdeuxDigitDecimaNumber
  *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'"/>
  <div *ngIf="employee.get('creditdevise').hasError('pattern')" class="invalid">
    veuillez vérifier votre saisie!
  </div> 
</div>
                      </td>
                      <td style="width: 150px;">
                        <div >
                    <select  class="form-control"placeholder="objet" id="objet"#someHashName10 formControlName="objetdebit" name="objetdebit" (click)="sort(empIndex,skillIndex)">
                      <option hidden [value]=""></option>
                      <option value=""></option>
                      <option *ngFor="let ob of settedobjetsdebit" [value]="ob">
                        {{ob}}
                    </option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName10?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Objet" id="numerocompte" formControlName="autreobjetdebit">
                      </div>
                       </td>
                       <td style="width: 150px;">
                        <div >
                    <select  class="form-control"placeholder="objet" id="objet"#someHashName11 formControlName="objetcredit" name="objetcredit" (click)="sort(empIndex,skillIndex)">
                      <option hidden [value]=""></option>
                      <option value=""></option>
                      <option *ngFor="let ob of settedobjetscredit" [value]="ob">
                        {{ob}}
                    </option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName11?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Objet" id="numerocompte" formControlName="autreobjetcredit">
                      </div>
                       </td>
                       <td style="width:20px"> 
                        <input type="checkbox" (change)="onChange4(empIndex,skillIndex)"  formControlName=contientauxiliaire/>
                       </td>
                       <td>
                        <div id="{{ 'relevemanuelfournisseur' + empIndex + skillIndex }}">
                          <select class="form-control"placeholder="Fournisseur" id="natureachat"#someHashName30 formControlName="fournisseur" name="fournisseur" (click)="sort()">
                            <option hidden [value]=""></option>
                            <option value=""></option>
                            <option *ngFor="let fou of settedfournisseurs" [value]="fou.four">{{fou.four}}</option>
                            <option value="Autre">Autre</option>
                          </select>                       
                          <input *ngIf="someHashName30?.value == 'Autre'"class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="Fournisseur" id="fournisseur" (change)="verifyinvoicesyndic(i)"formControlName="autrefournisseur">
                        </div>
                       </td>
                       <td>
                        <div id="{{ 'relevemanuelclient' + empIndex + skillIndex }}">
                          <select class="form-control"placeholder="Client" id="client"#someHashName31 formControlName="client" name="client" (click)="sort()">
                            <option hidden [value]=""></option>
                            <option value=""></option>
                            <option *ngFor="let cl of user.coproprietaires" [value]="cl.numero">
                              {{cl.numero}}&nbsp;{{cl.coproprietaire}}
                          </option>
                                  <option hidden value="Autre">Autre</option>
                          </select>
                            <input *ngIf="someHashName31?.value == 'Autre'"class="form-control" type="text"(change)="onChange4(empIndex,skillIndex)" placeholder="client" id="client" formControlName="autreclient">
                        </div>
                       </td>
                       <td>
                        <div>
                        <button type="button" class="btn btn-danger btn-sm"*ngIf="skillIndex>0" (click)="removeEmployeeSkill(empIndex,skillIndex)"><span><i class="fa fa-minus-circle"></i></span></button>   
                        </div>
                       </td>     
              
                    </tr>
                    <tr>
                      <td colspan="10"></td>
                      <td >
                        <div>
                        <button type="button" class="btn btn-primary mb-2" (click)="addEmployeeSkill(empIndex)"><span><i class="fa fa-plus-circle"></i></span></button>   
                        </div>
                       </td>
                    </tr>
                    
                    <tr id="resultrow">
                      <td colspan="3">
                          <div >
                               <p>Total des mouvement du mois</p>
                          </div>
                      </td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotaldebit(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotalcredit(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotaldebitdevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotalcreditdevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>                  
  </tr>
                  <tr id="resultrow">
                    <td colspan="2">
                        <div >
                             <p>Solde fin du mois</p>
                        </div>
                    </td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise=='tnd'" style="text-align: center;">{{getTotal(empIndex,skillIndex) | number:'1.3-3'| removeComma}}</td>
  <td *ngIf="relevemanuelform.getRawValue().employees.at(empIndex).devise!='tnd'" style="text-align: center;">{{getTotaldevise(empIndex,skillIndex) | number:'1.2-2'| removeComma}}</td>
                  
  </tr>
                  </tbody>
                </table>
        </div>
      </div>
      <button type="button" class="btn btn-primary mb-2"(click)="addEmployee()">Ajouter compte</button>
    </div>
  </div>
  
  <!--
    <table class="table table-striped" id="relevemanuelsection" [formGroup]="relevemanuelform">
  <thead>
      <tr>            
      <th rowspan="2">Mois</th>
      <th rowspan="2">Année</th>
      <th colspan="2">solde du mois précédent</th>
      </tr>
      <tr>
        <th>Débit</th>
        <th>Crédit</th>
      </tr>
  </thead>
  <tbody>
    <tr>
  
  <td>
  <div >
    <select class="form-control" id="month" name="month" #cricket (change)="updaterelevemanuelmois($event)"formControlName="mois">
      <option></option>
      <option value="01">(01)janvier</option>
      <option value="02">(02)février</option>
      <option value="03">(03)Mars</option>
      <option value="04">(04)avril</option>
      <option value="05">(05)Mai</option>
      <option value="06">(06)juin</option>
      <option value="07">(07)juillet</option>
      <option value="08">(08)août</option>
      <option value="09">(09)septembre</option>
      <option value="10">(10)octobre</option>
      <option value="11">(11)novembre</option>
      <option value="12">(12)décembre</option>
   </select>
  </div> 
  </td>
  <td>
  <div >
    <select class="form-control" id="year" name="year"formControlName="annee"(change)="updaterelevemanuelannee($event)">
      <option></option>
      <option value="2023">2023</option>
      <option  value="2022">2022</option>
      <option  value="2021">2021</option>   
   </select>
  </div>
  </td>
  <td>
  <div >
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="soldemoisprecedentdebit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Solde Débit du mois précédent"/>
  </div>
  </td>
  <td>
  <div >
    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
  class="form-control"formControlName="soldemoisprecedentcredit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Solde Crédit du mois précédent"/>
  </div>
  </td>
    </tr>
  </tbody>
  <thead>
  <tr>            
  <th>Jour</th>
  <th>Date complète</th>
  <th>Débit</th>
  <th>Crédit</th>
  </tr>
  </thead>
  <tbody formArrayName="ammounts4">
      
      <tr *ngFor="let ammount of ammountControls4; let i = index;"[formGroupName]="i">
             
              <td style="width: 50px;">
                  <div >
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                         class="form-control"(change)="setdate4(i)"formControlName="jour"placeholder="Jour"/>
                  </div>
              </td>
                 <td>
                 <div >
                     <input type="text"class="form-control"formControlName="date"onkeyup="value=value.replace(/[^0-9^\.\/]+/g,'').replace(/\./g,'')"placeholder="Date"readonly='true'/>
                 </div>
                </td>
                <td>
                 <div >
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                    class="form-control"formControlName="debit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Débit"/>
                 </div>
                </td>
                <td>
                 <div >
                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                    class="form-control"formControlName="credit"(blur)="setThreeNumberDecimal($event)"(change)="onChange4(i)"placeholder="Crédit"/>
                 </div>
                </td> 
                <td>
                  <div>
                  <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount4(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                  </div>
                 </td>
              
      </tr>
      <tr>
          <td colspan="5"></td>
          <td>
              <div>
              <button type="button" class="btn btn-primary mb-2" (click)="addammount4()"><span><i class="fa fa-plus-circle"></i></span></button>   
              </div>
          </td>
      </tr>
      <tr id="resultrow">
          <td colspan="2">
              <div >
                   <p>Total des mouvement du mois</p>
              </div>
          </td>
          <td style="text-align: center;">{{totaldebit | number:'1.3-3'| removeComma}}</td>
          <td style="text-align: center;">{{totalcredit | number:'1.3-3'| removeComma}}</td>
      </tr>
      <tr id="resultrow">
        <td colspan="2">
            <div >
                 <p>Solde fin du mois</p>
            </div>
        </td>
        <td style="text-align: center;">{{totalsoldemois | number:'1.3-3'| removeComma}}</td>
    </tr>
  </tbody>
  <div class="text-right">
      <input type="submit" class="visually-hidden" value="Submit"(click)="logValue4()">
  </div>
  </table>
  -->
  
  </fieldset>
  <!--saisie des relevés joints-->
  <fieldset id="relevejoint" *ngIf="showrelevejoint">
      <legend>Attacher les relevés bancaires</legend>
      <table class="table table-striped" id="relevejointsection" [formGroup]="relevejointform">
          <thead>
          <tr>            
          <th>Mois</th>
          <th>Annee</th>
          <th>Document joint</th>
          </tr>
          </thead>
          <tbody formArrayName="ammounts5">
              <tr *ngFor="let ammount of ammountControls5; let i = index;"[formGroupName]="i">
                     
                      <td >
                          <div >
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace(/\./g,'')" 
                                 class="form-control"formControlName="mois"placeholder="Mois"/>
                          </div>
                         </td>
                         <td>
                         <div >
                             <input type="text"class="form-control"formControlName="annee"placeholder="Annee"/>
                         </div>
                        </td>
                        <td > 
                          <div style="width:20px">
                            <input type="checkbox" id="{{ 'myCheck5' + i }}" (change)="docjoint5(i)"  formControlName=contientfiche/>
                         </div>
                       </td>
                       <td id="{{ 'releve' + i }}"style="display: none;"> 
                          <div style="width: 300px;">
                             <input type="file"  #filePicker (change)="onImagePick2($event,i)" class="form-control" accept="application/pdf">
                             <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                         </div>
                       </td>
                       <td>
                        <div>
                        <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount5(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                        </div>
                       </td>
                      
              </tr>
              <tr>
                  <td colspan="3"></td>
                  <td>
                      <div>
                      <button type="button" class="btn btn-primary mb-2" (click)="addammount5()">Ajouter relevé</button>   
                      </div>
                     </td>
              </tr>
          </tbody>
          <div class="text-right">
              <input type="submit" class="visually-hidden" value="Submit"(click)="logValue5()">
          </div>
          </table>
  </fieldset>
  </mat-tab>
  <mat-tab label="Salaires" *ngIf="showsalairetab===true"aria-label="salaire"> saisie salaire section
  
    <fieldset id="saisiesalaire"*ngIf="showpaiemanuel===true">
        <legend>Gestion des salaires</legend>
        <div class="container fluid" id="relevemanuelsection" [formGroup]="salaireform"style="margin:0px">
          <div formArrayName="typessalaire" id="allreleve">
            <div *ngFor="let ty of typessalaire().controls; let typeindex=index">
              <div [formGroupName]="typeindex"style="border: 1px solid blue; padding: 10px; margin: 5px;width:max-content">Type salaire:{{salaireform.getRawValue().typessalaire.at(typeindex).typesalaire}}:
                <table class="table table-striped" style="width: 50%;">
                  <thead>
                    <tr>                      
                      <th>Type salaire</th>
                    </tr>                 
                  </thead>
                  <tbody>
                    <tr>
  
                      <td style="width: 150px">
                        <div>
                    <select  class="form-control"placeholder="type" id="typesalaire"#someHashName4 formControlName="typesalaire" name="typesalaire">
                      <option hidden [value]=""></option>
                      <option value=""></option>
                      <option *ngFor="let type of typessalaires" [value]="type">
                        {{type}}
                    </option>
                      <option value="Autre">Autre</option>
                    </select>
                      </div>
                      <div *ngIf="someHashName4?.value == 'Autre'">
                        <input class="form-control" type="text"(change)="onChange6(typeindex)" placeholder="type" id="type" formControlName="autretypesalaire">
                      </div>
                       </td>
                              
                    </tr>
                  </tbody>
                </table>
              
                      <table class="table table-striped"style="width: 50%;">
                        <thead>
                          <tr>
                          <th></th>            
                          <th>Matricule</th>
                          <th>Nom et prénom</th>
                          <th>Nature du contrat</th>
                          <th>fiche contrat</th>
                          <th>Salaire Brut</th>
                          <th>Salaire Net</th>
                          <th>Actions fiche</th>
                          <!--<th>Document Joint</th> -->    
                          </tr>
                          </thead>
                        <tbody formArrayName="ammounts6">
                          <tr *ngFor="let ammount of salaires(typeindex).controls; let i=index"[formGroupName]="i">
                            <td>
                              <!-- modal for adding new matricules-->
                              <div class="modal-signup"tabindex="-1"role="dialog"[ngStyle]="{'display':displaysalaire}">
                                 <div class="modal-content">
                                  <div class="right-section-signup">
                                    <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
                                      <h4>Formulaire d'ajouts de salariés</h4>
                                      <table class="table table-striped"[formGroup]="ajoutsalarieform">
                                        <thead>
                                          <tr>
                                            <th>Document</th>
                                            <th>Valeur</th>            
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr >
                                            <td>Matricule</td>
                                            <td style="width: 100px;">
                              <input type="text" class="form-control"formControlName="matricule"placeholder="Matricule"/>
                                            </td>          
                                          </tr>
                                          <tr >
                                            <td>Nom et prénom</td>
                                            <td style="width: 50px;">
                              <input type="text" class="form-control"formControlName="nomprenom"placeholder="nom"/>
                                             </td>
                                          </tr>
                                          <tr >
                                            <td>Sexe</td>
                                            <td style="width: 50px;">
                                              <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="sexe" name="client" (click)="sort()">
                                                <option hidden [value]=""></option>
                                                <option value=""></option>
                                          <option *ngFor="let sexe of sexes" [value]="sexe">
                                            {{sexe}}
                                        </option>
                                              </select>
                                                                             </td>
                                          </tr>
                                          <tr >
                                            <td> Date de naissance</td>
                                            <td style="width: 50px;">
                              <input type="date" class="form-control"formControlName="naissance"placeholder="date de naissance"/>
                                         </td>
                                            
                                          </tr>
                                          <tr>
                                            <td>Etat Civil</td>
                                            <td style="width: 50px;">
                                              <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="etatcivil" name="client" (click)="sort()">
                                                <option hidden [value]=""></option>
                                                <option value=""></option>
                                          <option *ngFor="let etat of etatscivil" [value]="etat">
                                            {{etat}}
                                        </option>
                                              </select>
                                         </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>CIN</span>
                                             </td>
                                            <td>
                                              <span>Delivre le</span>
                                            </td>
                                          </tr>
                                          <tr>
                              <td>
                                <input type="text"  class="form-control"formControlName="cin"placeholder="CIN"/>
                              </td>
                              <td style="width: 50%;">
                                <input type="date"  class="form-control"formControlName="delivre"placeholder="Delivre le"/>
                              </td>
                                          </tr>
                                          <tr >
                                            <td> Adresse</td>
                                            <td>
                                              <textarea name="" id="" cols="30" rows="10"class="form-control"formControlName="adresse"placeholder="adresse"></textarea>
                                            </td>             
                                          </tr>
                                          <tr >
                                            <td> Mobile</td>
                              <td><input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" class="form-control"formControlName="mobile"placeholder="Mobile"/></td>             
                                            
                                          </tr>
                                          
                                          <tr>
                                            <td>Chef de famille</td>
                                  <td><input type="checkbox" formControlName=chef/></td>            
                                          </tr>
                                          <tr>
                                            <td>Nature du contrat</td>
                                            <td style="display: flex;">
                                              <span style="width: 150px;display: block;">
                                                <div >
                                                  <select class="btn btn-select resizeselect"placeholder="nature contrat" id="client"#someHashName formControlName="naturecontrat" name="client" (click)="sort()">
                                                    <option hidden [value]=""></option>
                                                    <option value=""></option>
                                              <option *ngFor="let naturecontrat of settednaturecontrats" [value]="naturecontrat.natcontrat">
                                                {{naturecontrat.natcontrat}}
                                            </option>
                                                    <option value="Autre">Autre</option>
                                                  </select>
                                                    </div>
                                                    <div *ngIf="someHashName?.value == 'Autre'">
                                                      <input class="form-control" type="text" placeholder="nature contrat" id="client" formControlName="autrenaturecontrat"(change)="onchange6(typeindex,i)">
                                                    </div>
                                            </span>
                                            <span>
                                              <input type="checkbox" id="{{ 'myCheck6' + typeindex + i }}" (change)="docjoint6(typeindex,i)"  formControlName=contientfiche/>
                                             </span>
                                             <span id="{{ 'salaire' + typeindex + i }}"style="display: none;">
                                              <input type="file"  #filePicker (change)="onImagePick3($event,typeindex,i)" class="form-control" accept="application/pdf">
                                                       <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                                             </span>
                                            </td>                         
                                          </tr>
                                          <tr>
                                            <td>Nombre d'enfants à charge</td>
                                            <td><input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" class="form-control"formControlName="enfant"placeholder="nombre d'enfants"/></td>                         
                                          </tr>
                                        </tbody>
                                      </table>
                                
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-danger" (click)="closePopup()">Annuler</button>
                                      <button type="button" class="btn btn-success" (click)="ajoutersalarie(typeindex,i)">Valider</button>
                              
                                    </div>
                                    </div>
                                  </div>
                                           <!-- Close Button (Top-left corner) -->
          <div class="close-button" (click)="closePopup()" style="background-color: #F8F8F9;">
            <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
          </div>
                                 </div>
                             </div>
                         </td>
            
                               <td style="width: 100px;">
                                   <div >
                                       <input type="text" onkeyup="value=value.replace(/\./g,'')" (blur)="verifymatricule(typeindex,i)"
                                          class="form-control"formControlName="matricule"placeholder="Matricule"/>
                                   </div>
                                  </td>
                                  <td>
                                  <div >
                                      <input type="text"class="form-control"formControlName="nomprenom"placeholder="Nom et Prenom"readonly="true">
                                  </div>
                                 </td>
                                 <td style="width: 150px">
                                         <input class="form-control" type="text" placeholder="client" id="client" formControlName="naturecontrat"readonly="true">
                                 </td>
                                 <td >                         
                                   <div *ngIf="salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6').value.at(i).ficheUrl">
                                    <a href="{{salaireform.get('typessalaire')['controls'].at(typeindex).get('ammounts6').value.at(i).ficheUrl}}" target="_blank">Aperçu fiche</a>
                                   </div>
                                 </td>
                                 <td style="width: 70px">
                                   <div >
                                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                 class="form-control"formControlName="salairebrut"(blur)="setThreeNumberDecimal($event)"(change)="onChange6(typeindex,i)"placeholder="Salaire Brut"/>
                                              </div>
                                 </td>
                                            
                                 <td style="width: 70px">
                                       <div >
                                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
             class="form-control"formControlName="salairenet"(blur)="setThreeNumberDecimal($event)"(change)="onChange6(typeindex,i)"placeholder="Salaire Net"/>
                                                  </div>
                                   </td>
                                   <td>
                                     <div>
                                     <button type="button" class="btn btn-primary mb-2" (click)="modifyemploye(typeindex,i)"> Modifier<span><i class="glyphicon glyphicon-pencil"></i></span></button>   
                                     </div>
                                    </td>
                                <!--
                                 <td > 
                                   <div style="width:20px">
                                     <input type="checkbox" id="{{ 'myCheck6' + i }}" (change)="docjoint6(i)"  formControlName=contientfiche/>
                                  </div>
                                </td>
                                <td id="{{ 'salaire' + i }}"style="display: none;" > 
                                   <div style="width: 300px">
                                      <input type="file"  #filePicker (change)="onImagePick3($event,i)" class="form-control" accept="application/pdf">
                                      <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
                                  </div>
                                </td>
                                --> 
                                 <td>
                                  <div>
                                  <button type="button" class="btn btn-danger btn-sm"*ngIf="i>0" (click)="removeammount6(typeindex,i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                                  </div>
                                 </td>
                          </tr>
                          <tr>
                            <td colspan="10"></td>
                            <td>
                                <div>
                                <button type="button" class="btn btn-primary mb-2" (click)="addammount6(typeindex)"><span><i class="fa fa-plus-circle"></i></span></button>   
                                </div>
                               </td>
                        </tr>
                          <tr id="resultrow">
                              <td colspan="5">
                                  <div >
                                       <p>Totaux</p>
                                  </div>
                              </td>
                              <td style="text-align: center;">{{totalsalairebrut | number:'1.3-3'| removeComma}}</td>
                              <td style="text-align: center;">{{totalsalairenet | number:'1.3-3'| removeComma}}</td>
                          </tr>
                        </tbody>
                      </table>
              </div>
            </div>
            <button type="button" class="btn btn-primary mb-2"(click)="addtypesalaire()">Ajouter autre type de salaire</button>
          </div>
        </div>
        </fieldset>
  </mat-tab>
  </mat-tab-group>
  </div>
        </div>
</div>