
<body>
  
  <div *ngIf="!showdeccomptabilite">
    <span  class="spinner-grow" role="status"></span>
    <span>Chargement des donnees...</span>
  </div>
  <div *ngIf="!showrecouvrementdetails">
    <span  class="spinner-grow" role="status"></span>
    <span>Actualisation du module recouvrement...</span>
  </div>
        <!-- modal for search filter results-->
        <div class="modal-signup"  [ngStyle]="{'display':displaysearchresultsalldsnonpaye}">
          <div class="modal-content">
            <div class="right-section-signup">
                <h1>Resultats de recherche</h1>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="_id" ></i></th>
                      <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="nature"  ></i></th>
                      <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="mois"  ></i></th>
                      <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="annee" ></i></th>
                      <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="lastname" ></i></th>
                      <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="statut" ></i></th>
                      <th scope="col">Total honoraire HT</th>
                      <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="created" ></i></th>  
                    </tr>
                    </thead>
                  <tbody>
                  <tr *ngFor="let dsnonpaye of filtredalldsnonpayearray"> 
                    <td>{{dsnonpaye._id}}</td>
                    <td><span *ngIf="dsnonpaye.nature">{{dsnonpaye.nature}}</span> <span *ngIf="!dsnonpaye.nature">Autre demande de service</span></td>
                    <td><span *ngIf="dsnonpaye.mois">{{dsnonpaye.mois}}</span> </td>
                    <td><span *ngIf="dsnonpaye.annee">{{dsnonpaye.annee}}</span> </td>
                    <td appUserDisplay="{{dsnonpaye.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
                    <td>
                      <span *ngIf="dsnonpaye.statutadmin">
                        <span *ngIf="dsnonpaye.statutadmin.length>0">
                          <ng-container *ngFor="let statut of dsnonpaye.statutadmin; let j = index">
                            <td  *ngIf="j==dsnonpaye.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                          </ng-container>
                        </span> 
                      </span>           
                    </td>
                    <td>{{dsnonpaye.totalhonoraire | number:'1.3-3'| removeComma}}</td>
                    <td>{{dsnonpaye.created | customDate}}</td>
                    <td>
        <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds',dsnonpaye._id)"*ngIf="!dsnonpaye.nature"target="_blank">Consulter</button>
        <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='comptabilite'"target="_blank">Consulter</button>
        <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmenss('view-decfiscmens',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='Déclaration Mensuelle'"target="_blank">Consulter</button>           
      </td>
      <td>
        <button type="button" class="btn btn-primary" (click)="payinvoice(dsnonpaye._id,dsnonpaye.nature)"><span><i class="fa fa-spinner" *ngIf="submitting"></i></span> Marquer paye</button>           
      </td>
                  </tr>
                  </tbody>
                </table>
                <button type="button" class="btn btn-danger" (click)="closefiltredresultsalldsnonpaye()">Fermer</button>
            </div>
          </div>
     </div>
      <!-- modal for search filter results-->
   <div class="modal-signup"  [ngStyle]="{'display':displaysearchresults}">
        <div class="modal-content">
          <div class="right-section-signup">
              <h1>Resultats de recherche</h1>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
                  <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
                  <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
                  <th scope="col">Raison sociale <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="raisonsociale" ></i></th>
                  <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
                  <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
                  <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
                  <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
                  <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
                  <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>      
                  <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
                  <th scope="col">D.comptabilite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="droitcompta" ></i></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of filtredusersarray"> 
                  <td>{{user.email}}</td>
                  <td>{{user.firstname}}</td>
                  <td>{{user.lastname}}</td>
                  <td>{{user.raisonsociale}}</td>
                  <td >{{user.usertype}}</td>
                  <td>{{user.mobile}}</td>
                  <td>{{user.clientcode}}</td>
                  <td>{{user.natureactivite}}</td>
                  <td>{{user.activite}}</td>
                  <td>{{user.sousactivite}}</td>
                  <td>{{user.role}}</td>
                  <td>{{user.droitcompta}}</td>
                  <td><button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
                </tr>
                </tbody>
              </table>
              <button type="button" class="btn btn-danger" (click)="closefiltredresults()">Fermer</button>
          </div>
        </div>
   </div>
    <!-- modal for viex all ds non paye by user-->
   <div class="modal-signup"  [ngStyle]="{'display':displaydsnonpaye}">
        <div class="modal-content">
          <div class="right-section-signup">
            <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
              <h1>Liste des DS non payees par utilisateur</h1>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">Code </th>
                  <th scope="col">Nature </th>
                  <th scope="col">Mois </th>
                  <th scope="col">Annee </th> 
                  <th scope="col">Client </th>
                  <th scope="col">Statut </th>
                  <th scope="col">Total honoraire HT</th>
                  <th scope="col">Date de creation </th>  
                  <th>Payement</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let dsnonpaye of alldsbyusernonpaye; let i = index"> 
                  <td>{{dsnonpaye._id}}</td>
                  <td><span *ngIf="dsnonpaye.nature">{{dsnonpaye.nature}}</span> <span *ngIf="!dsnonpaye.nature">Autre demande de service</span></td>
                  <td><span *ngIf="dsnonpaye.mois">{{dsnonpaye.mois}}</span> </td>
                  <td><span *ngIf="dsnonpaye.annee">{{dsnonpaye.annee}}</span> </td>
                  <td>
                    <span *ngIf="dsnonpaye.nature"appUserDisplay="{{dsnonpaye.userId}}" [displayProperties]="['firstname','raisonsociale']"></span>
                    <span *ngIf="!dsnonpaye.nature"appUserDisplay="{{dsnonpaye.clientId}}" [displayProperties]="['firstname','raisonsociale']"></span>
                  </td>
                  <td>
                    <span *ngIf="dsnonpaye.statutadmin">
                      <span *ngIf="dsnonpaye.statutadmin.length>0">
                        <ng-container *ngFor="let statut of dsnonpaye.statutadmin; let j = index">
                          <td  *ngIf="j==dsnonpaye.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                        </ng-container>
                      </span> 
                    </span>           
                  </td>
                  <td>{{dsnonpaye.totalhonoraire | number:'1.3-3'| removeComma}}</td>
                  <td>{{dsnonpaye.created | customDate}}</td>
                  <td > 
                    <div style="width:20px">
                      <input type="checkbox" id="{{ 'dsnonpayecheckbox' + i }}" (change)="shownotehonoraire()"/>
                   </div>
                 </td>
                  <td>
      <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds',dsnonpaye._id)"*ngIf="!dsnonpaye.nature"target="_blank">Consulter</button>
      <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='comptabilite'"target="_blank">Consulter</button>
      <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmenss('view-decfiscmens',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='Déclaration Mensuelle'"target="_blank">Consulter</button>           
    </td>
    <td>
      <button type="button" class="btn btn-primary" (click)="payinvoice(dsnonpaye._id,dsnonpaye.nature)"><span><i class="fa fa-spinner" *ngIf="submitting"></i></span> Marquer paye</button>           
    </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="grid-container">
              <div class="grid-item">
                <label for="">TotalHT:</label>
                <p>{{shownotehonoraire()[0]+shownotehonoraire()[6]| number:'1.3-3'| removeComma}}</p>
              </div>
              <div class="grid-item">
                <label for="">TotalTVA(19%):</label>
                <p>{{shownotehonoraire()[1]| number:'1.3-3'| removeComma}}</p>
              </div>
              <div class="grid-item">
                <label for="">Timbrefiscal:</label>
                <p>{{shownotehonoraire()[2]| number:'1.3-3'| removeComma}}</p>
              </div>
              <div class="grid-item">
                <label for="">TotalTTC:</label>
                <p>{{shownotehonoraire()[7]| number:'1.3-3'| removeComma}}</p>
              </div>
            </div>     
            <div class="grid-container2" style="text-align: center;">
              <button type="button" class="btn btn-danger" (click)="closecopPopup()">Fermer</button>
              <button type="button" class="btn btn-info" (click)="previewnotehonoraire()"*ngIf="shownotehonoraire()[3]!=1">Aperçu Note honoraires</button>
            </div>        
          </div>
        </div>
   </div>
       <!-- modal for viex all notif rec by user-->
   <div class="modal-signup"  [ngStyle]="{'display':displaynotifrec}">
        <div class="modal-content">
          <div class="right-section-signup">
            <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
              <h1>Liste des notifications par client</h1>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">Client </th>
                  <th scope="col">Type de notification </th>
                  <th scope="col">Date de creation </th>  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let notifrec of allnotifrecs; let i = index"> 
                  <td appUserDisplay="{{notifrec.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
      <td><span *ngIf="!notifrec.type">SMS</span><span *ngIf="notifrec.type">Email</span></td>
      <td>{{notifrec.created | customDate}}</td>
      <td>
        <button type="button" class="btn btn-success" (click)="getNavigationnotifrec('view-notifrec',notifrec._id)"target="_blank">Consulter</button>
       </td>
                </tr>
                </tbody>
              </table>
            </div>  
            <div class="grid-container2" style="text-align: center;">
              <button type="button" class="btn btn-danger" (click)="closenotifrecpopup()">Fermer</button>
            </div>  
          </div>
        </div>
   </div>
   <button class="toggle-btn" (click)="toggleMenu()">
    <mat-icon>{{ isMenuOpen ? 'close' : 'menu' }}</mat-icon>
  </button>
   <div class="sidebar-container" [ngClass]="{ 'open': isMenuOpen }">
   
    <mat-sidenav #sidenav mode="side" [opened]="isMenuOpen" (closed)="closeMenu()">
      <mat-nav-list>
        <mat-expansion-panel *ngIf="decfiscmenss.length > 0 || deccomptabilites.length > 0 || contacts.length > 0 || condidates.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>folder</mat-icon> Liste des dossiers
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click37(); getdossiersencourspasencorevalide(); closeMenu()">
              <span class="badge bg-danger">{{ dossdecfiscpasencorevalide + dossdeccomppasencorevalide + dosscandpasencorevalide + dosscontactpasencorevalide }}</span>
              Dossiers en instance
            </li>
            <li class="list-group-item" (click)="click30(); getdossiersencoursnonaffecte(); closeMenu()">
              <span class="badge bg-danger">{{ dossdecfiscnonaffecte + dossdeccompnonaffecte + dosscandnonaffecte + dosscontactnonaffecte }}</span>
              Dossiers valides pas encore affectes
            </li>
            <li class="list-group-item" (click)="click29(); getdossiersencours(); closeMenu()">
              <span class="badge bg-danger">{{ dossdecfiscencours + dossdeccompencours + dosscandencours + dosscontactencours }}</span>
              Dossiers en cours execution
            </li>
            <li class="list-group-item" (click)="click53(); closeMenu()">
              <span class="badge bg-danger">{{ usedreslength }}</span>
              Dossiers Ouverts
            </li>
          </ul>
        </mat-expansion-panel>
  
        <mat-expansion-panel *ngIf="getalldsuser()[1] > 0 && showrecouvrementdetails">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment</mat-icon> Liste des demandes de services
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click43(); closeMenu()">
              <span class="badge bg-danger">{{ getalldsusernonpaye()[1] }}</span>
              <span class="badge bg-danger">{{ getalldsusernonpaye()[2] | number:'1.3-3' | removeComma }} DT</span>
              Liste des demandes de services pas encore règlees
            </li>
            <li class="list-group-item" (click)="click44(); closeMenu()">
              <span class="badge bg-danger">{{ getalldsuserpaye()[1] }}</span>
              Liste des demandes de services règlees
            </li>
            <li class="list-group-item" (click)="click52(); closeMenu()">
              <span class="badge bg-danger">{{ getalldsusermarquepaye()[1] }}</span>
              Liste des demandes de services marque comme règlees
            </li>
            <li class="list-group-item" (click)="click51(); closeMenu()">
              <span class="badge bg-danger">{{ allproformatinvoice.length }}</span>
              Liste des factures proformat générées
            </li>
            <li class="list-group-item" (click)="showproinvoicearchivated(); closeMenu()">
              <span class="badge bg-danger">{{ allproformatarchivatedinvoice.length }}</span>
              Liste des factures proformat générées archivées
            </li>
            <li class="list-group-item" (click)="click47(); closeMenu()">
              <span class="badge bg-danger">{{ allinvoice.length }}</span>
              Liste des factures règlees
            </li>
            <li class="list-group-item" (click)="showinvoicearchivated(); closeMenu()">
              <span class="badge bg-danger">{{ allarchivatedinvoice.length }}</span>
              Liste des factures générées archivées
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="getalldsusernonpaye()[1] > 0 && showrecouvrementdetails">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>account_balance</mat-icon> Module recouvrement
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click55(); closeMenu()">
              <span class="badge bg-danger">{{ getalldsusernonpaye()[1] }}</span>
              <span class="badge bg-danger">{{ getalldsusernonpaye()[2] | number:'1.3-3' | removeComma }}</span>
              Liste des clients avec des impayes
            </li>
          </ul>
        </mat-expansion-panel>
        
        <mat-expansion-panel *ngIf="users">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>people</mat-icon> Liste des utilisateurs
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click33(); closeMenu()">
              <span class="badge bg-danger">{{ clienttotal }}</span>
              Tous les utilisateurs
            </li>
            <li class="list-group-item" (click)="click35(); closeMenu()">
              <span class="badge bg-danger">{{ utilconnecte }}</span>
              Utilisateurs connectes
            </li>
            <li class="list-group-item" (click)="click1(); closeMenu()">
              <span class="badge bg-danger">{{ ca }}</span>
              Clients actifs
            </li>
            <li class="list-group-item" (click)="click2(); closeMenu()">
              <span class="badge bg-danger">{{ cb }}</span>
              Clients bloques
            </li>
            <li class="list-group-item" (click)="click3(); closeMenu()">
              <span class="badge bg-danger">{{ del }}</span>
              Clients supprimes temporairement
            </li>
            <li class="list-group-item" (click)="click4(); closeMenu()">
              <span class="badge bg-danger">{{ coll }}</span>
              Collaborateurs
            </li>
            <li class="list-group-item" (click)="click5(); closeMenu()">
              <span class="badge bg-danger">{{ cons }}</span>
              Consultants
            </li>
            <li class="list-group-item" (click)="click6(); closeMenu()">
              <span class="badge bg-danger">{{ condida }}</span>
              Candidats
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="decfiscmenss.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment</mat-icon> Liste declarations fiscales
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click7(); closeMenu()">
              <span class="badge bg-danger">{{ decfiscvali }}</span>
              Clôture
            </li>
            <li class="list-group-item" (click)="click8(); closeMenu()">
              <span class="badge bg-danger">{{ decfiscnonvali }}</span>
              En cours execution
            </li>
            <li class="list-group-item" (click)="click40(); closeMenu()">
              <span class="badge bg-danger">{{ decfiscvalicopie }}</span>
              Copie clôtures
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="deccomptabilites.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>description</mat-icon> Liste des fichiers comptables
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click9(); closeMenu()">
              <span class="badge bg-danger">{{ deccomptvalid }}</span>
              Clôture
            </li>
            <li class="list-group-item" (click)="click10(); closeMenu()">
              <span class="badge bg-danger">{{ deccompnonval }}</span>
              En cours execution
            </li>
            <li class="list-group-item" (click)="click42(); closeMenu()">
              <span class="badge bg-danger">{{ deccomptvalidcopie }}</span>
              Copies clôtures
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="condidates.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment_ind</mat-icon> Liste des Candidatures
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click11(); closeMenu()">
              <span class="badge bg-danger">{{ condval }}</span>
              Clôture
            </li>
            <li class="list-group-item" (click)="click12(); closeMenu()">
              <span class="badge bg-danger">{{ condnonal }}</span>
              En cours execution
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="contacts.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>report_problem</mat-icon> Liste des reclamations
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group">
            <li class="list-group-item" (click)="click13(); closeMenu()">
              <span class="badge">{{ contval }}</span>
              Clôture
            </li>
            <li class="list-group-item" (click)="click14(); closeMenu()">
              <span class="badge">{{ contnonval }}</span>
              En cours execution
            </li>
          </ul>
        </mat-expansion-panel>
        

        <!-- Additional mat-expansion-panel structures can be added here based on your provided components -->
  
      </mat-nav-list>
    </mat-sidenav>
  </div>
  <mat-tab-group (selectedTabChange)="onTabClick($event)"> 
<mat-tab label="Dossiers pas encore valides" *ngIf="showdosspasencorevalide && sorteddosspasencorevalide">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">Dossiers en instance</div>
        <span (click)="getdossiersencourspasencorevalide(); click37()">
          <i class="bi bi-arrow-clockwise"></i>
        </span>
        <span (click)="click38()">
          <i class="bi bi-x-circle-fill"></i>
        </span>
      </ng-template>     
        <button mat-button (click)="exportusersAsXLSX(sorteddosspasencorevalide, 'dossiers pas encore valide')">
          <i class="fa fa-file" aria-hidden="true"></i> Exporter
        </button>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Client</th>
                <th>De</th>
                <th>Mois</th>
                <th>Annee</th>
                <th>Type</th>
                <th>Origine</th>
                <th>Statut admin</th>
                <th>Statut collab</th>
                <th>Statut client</th>
                <th>
                  Date de creation
                  <i class="fa fa-sort" aria-hidden="true" [appSort]="dosspasencorevalide" data-order="desc" data-name="created"></i>
                </th>
                <th>Date de modification</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dossenc of paginateddosspasencorevalide; let i = index">
                <td appUserDisplay="{{dossenc.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
                <td>
                  <span *ngIf="!dossenc.emailenvoyea && dossenc.type == 'réclamation'">{{ dossenc.email }}</span>
                </td>
                <td>
                  <span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || 'comptabilite'">{{ dossenc.mois }}</span>
                </td>
                <td>
                  <span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || 'comptabilite'">{{ dossenc.annee }}</span>
                </td>
                <td>
                  <span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || 'comptabilite'">{{ dossenc.nature }}</span>
                  <span *ngIf="dossenc.type === 'candidature'">{{ dossenc.type }}</span>
                  <span *ngIf="dossenc.type === 'réclamation'">{{ dossenc.type }}</span>
                </td>
                <td>
                  <span *ngIf="dossenc.nature === 'Déclaration Mensuelle'">{{ dossenc.origine }}</span>
                </td>
                <td>
                  <span *ngIf="dossenc.statutadmin">
                    <ng-container *ngFor="let statut of dossenc.statutadmin; let j = index">
                      <span *ngIf="j == dossenc.statutadmin.length - 1">{{ statut.statut }}</span>
                    </ng-container>
                  </span>
                </td>
                <td>
                  <span *ngIf="dossenc.statutcollab">
                    <ng-container *ngFor="let statut of dossenc.statutcollab; let k = index">
                      <span *ngIf="k == dossenc.statutcollab.length - 1">{{ statut.statutcoll }}</span>
                    </ng-container>
                  </span>
                </td>
                <td>
                  <span *ngIf="dossenc.statutcollab">
                    <ng-container *ngFor="let statut of dossenc.statutcollab; let k = index">
                      <span *ngIf="k == dossenc.statutcollab.length - 1" [ngStyle]="{ 'color': (statut.statutclient == 'modifie par le client') ? 'red' : 'black', 'background-color': (statut.statutclient == 'depose par le client') ? 'green' : 'white' }">
                        {{ statut.statutclient }}
                      </span>
                    </ng-container>
                  </span>
                </td>
                <td>{{ dossenc.created | customDate }}</td>
                <td>{{ dossenc.updated }}</td>
                <td>
                  <button class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.origine != 'généré automatiquement'" (click)="debutcompteurdecfiscale('view-decfiscmens', dossenc._id)">Consulter</button>
                  <button class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.source != 'copie fiscalite'" (click)="getadmincollabview('modify-decfiscmens', dossenc._id)" [ngStyle]="{ 'color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'black' : 'black', 'background-color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
                  <button class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' || dossenc.nature === 'déclaration comptable'" (click)="debutcompteurcomptabilite('view-deccomptabilite', dossenc._id)">Consulter</button>
                  <button class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' && dossenc.source != 'copie comptabilite' || dossenc.nature === 'déclaration comptable' && dossenc.source != 'copie comptabilite'" (click)="getadmincollabview('modify-deccomptabilite', dossenc._id)" [ngStyle]="{ 'color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'black' : 'black', 'background-color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
                  <button class="btn btn-success" *ngIf="dossenc.type === 'candidature'" (click)="debutcandidature('view-condidate', dossenc._id)">Consulter</button>
                  <button class="btn btn-success" *ngIf="dossenc.type === 'réclamation'" (click)="debutcompteur('view-reclamation', dossenc._id)">Consulter</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- Pagination Component -->
        <mat-paginator [length]="sorteddosspasencorevalide.length"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       (page)="onPageChangedosspasencorevalide($event)">
        </mat-paginator>
    </mat-tab>
    
<mat-tab label="Dossiers pas encore affectes" *ngIf="showdosspasencoreaffecte && sorteddossnonaffecte">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">Dossiers pas encore affectes</div>
        <span (click)="getdossiersencoursnonaffecte(); click30()">
          <i class="bi bi-arrow-clockwise"></i>
        </span>
        <span (click)="click32()">
          <i class="bi bi-x-circle-fill"></i>
        </span>
      </ng-template>
      <button mat-button (click)="exportusersAsXLSX(sorteddossnonaffecte, 'dossiers pas encore affectes')">
        <i class="fa fa-file" aria-hidden="true"></i> Exporter
      </button>
      <div class="table-responsive">
        <table class="table table-striped">
          <!-- Table headers -->
          <thead>
            <tr>
              <th scope="col">Client</th>
              <th scope="col">De</th>
              <th scope="col">Mois</th>
              <th scope="col">Annee</th>
              <th scope="col">Type</th>
              <th scope="col">Origine</th>
              <th scope="col">Statut admin</th>
              <th scope="col">Statut collab</th>
              <th scope="col">Statut client</th>
              <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="sorteddossnonaffecte" data-order="desc" data-name="created"></i></th>
              <th scope="col">Date de modification</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <!-- Replace ngFor with paginated data -->
            <tr *ngFor="let dossenc of paginateddosspasencoreaffecte">
              <!-- Table data -->
              <td appUserDisplay="{{dossenc.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
              <td><span *ngIf="!dossenc.emailenvoyea && dossenc.type === 'réclamation'">{{ dossenc.email }}</span></td>
              <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.mois }}</span></td>
              <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.annee }}</span></td>
              <td>
                <span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.nature }}</span>
                <span *ngIf="dossenc.type === 'candidature'">{{ dossenc.type }}</span>
                <span *ngIf="dossenc.type === 'réclamation'">{{ dossenc.type }}</span>
              </td>
              <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle'">{{ dossenc.origine }}</span></td>
              <td>
                <span *ngIf="dossenc.statutadmin">
                  <ng-container *ngFor="let statut of dossenc.statutadmin; let j = index">
                    <span *ngIf="j === dossenc.statutadmin.length - 1">{{ statut.statut }}</span>
                  </ng-container>
                </span>
              </td>
              <td>
                <span *ngIf="dossenc.statutcollab">
                  <ng-container *ngFor="let statut of dossenc.statutcollab; let k = index">
                    <span *ngIf="k === dossenc.statutcollab.length - 1">{{ statut.statutcoll }}</span>
                  </ng-container>
                </span>
              </td>
              <td>
                <span *ngIf="dossenc.statutcollab">
                  <ng-container *ngFor="let statut of dossenc.statutcollab; let k = index">
                    <span *ngIf="k === dossenc.statutcollab.length - 1" [ngStyle]="{ 'color': (statut.statutclient === 'modifie par le client') ? 'red' : 'black', 'background-color': (statut.statutclient === 'depose par le client') ? 'green' : 'white' }">{{ statut.statutclient }}</span>
                  </ng-container>
                </span>
              </td>
              <td>{{ dossenc.created | customDate }}</td>
              <td>{{ dossenc.updated }}</td>
              <td>
                <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.origine !== 'généré automatiquement'" (click)="debutcompteurdecfiscale('view-decfiscmens', dossenc._id)">Consulter</button>
                <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.source !== 'copie fiscalite'" (click)="getadmincollabview('modify-decfiscmens', dossenc._id)" [ngStyle]="{ 'color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'black' : 'black', 'background-color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
                <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' || dossenc.nature === 'déclaration comptable'" (click)="debutcompteurcomptabilite('view-deccomptabilite', dossenc._id)">Consulter</button>
                <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' && dossenc.source !== 'copie comptabilite' || dossenc.nature === 'déclaration comptable' && dossenc.source !== 'copie comptabilite'" (click)="getadmincollabview('modify-deccomptabilite', dossenc._id)" [ngStyle]="{ 'color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'black' : 'black', 'background-color': verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
                <button type="button" class="btn btn-success" *ngIf="dossenc.type === 'candidature'" (click)="debutcandidature('view-condidate', dossenc._id)">Consulter</button>
                <button type="button" class="btn btn-success" *ngIf="dossenc.type === 'réclamation'" (click)="debutcontact('view-contactreq', dossenc._id)">Consulter</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <!-- Pagination -->
      <mat-paginator
        [length]="sorteddossnonaffecte.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="onPageChangedosspasencoreaffecte($event)"
      ></mat-paginator>
    </mat-tab>
    
<mat-tab label="Dossiers en cours execution" *ngIf="showdossencours && sorteddossencours">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Dossiers en cours execution</div>
    <span (click)="getdossiersencours(); click29()"><i class="bi bi-arrow-clockwise"></i></span>
    <span (click)="click31()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <button mat-button (click)="exportusersAsXLSX(sorteddossencours, 'dossiers en cours execution')">
    <i class="fa fa-file" aria-hidden="true"></i> Exporter
  </button>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Client</th>
          <th scope="col">De</th>
          <th scope="col">Mois</th>
          <th scope="col">Annee</th>
          <th scope="col">Type</th>
          <th scope="col">Origine</th>
          <th scope="col">Statut Admin</th>
          <th scope="col">Statut Collaborateur</th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="dossencours" data-order="desc" data-name="created"></i></th>
          <th scope="col">Date de modification</th>
          <th scope="col">Collaborateur affecte</th>
          <th scope="col">Actions</th>
          <th scope="col">Consulter maquette</th>
        </tr>
      </thead>
      <tbody>
        <!-- Iterate over pagedItems instead of using cdkVirtualFor -->
        <tr *ngFor="let dossenc of paginateddossencours">
          <td appUserDisplay="{{dossenc.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
          <td><span *ngIf="!dossenc.emailenvoyea && dossenc.type === 'réclamation'">{{ dossenc.email }}</span></td>
          <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.mois }}</span></td>
          <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.annee }}</span></td>
          <td>
            <span *ngIf="dossenc.nature === 'Déclaration Mensuelle' || dossenc.nature === 'comptabilite'">{{ dossenc.nature }}</span>
            <span *ngIf="dossenc.type === 'candidature'">{{ dossenc.type }}</span>
            <span *ngIf="dossenc.type === 'réclamation'">{{ dossenc.type }}</span>
          </td>
          <td><span *ngIf="dossenc.nature === 'Déclaration Mensuelle'">{{ dossenc.origine }}</span></td>
          <td>
            <span *ngIf="dossenc.statutadmin">
              <ng-container *ngFor="let statut of dossenc.statutadmin; let j = index">
                <span *ngIf="j === dossenc.statutadmin.length - 1">{{ statut.statut }}</span>
              </ng-container>
            </span>
          </td>
          <td>
            <span *ngIf="dossenc.statutcollab">
              <ng-container *ngFor="let statut of dossenc.statutcollab; let k = index">
                <span *ngIf="k === dossenc.statutcollab.length - 1">{{ statut.statutcoll }}</span>
              </ng-container>
            </span>
          </td>
          <td>{{ dossenc.created | customDate }}</td>
          <td>{{ dossenc.updated }}</td>
          <td appUserDisplay="{{dossenc.affecte}}" [displayProperties]="['firstname']"></td>
          <td>
            <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.origine !== 'généré automatiquement'" (click)="debutcompteurdecfiscale('view-decfiscmens', dossenc._id)">Consulter</button>
            <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.source !== 'copie fiscalite'" (click)="getadmincollabview('modify-decfiscmens', dossenc._id)" [ngStyle]="{ 'color': (verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)) ? 'black' : 'black', 'background-color': (verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
            <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' || dossenc.nature === 'déclaration comptable'" (click)="debutcompteurcomptabilite('view-deccomptabilite', dossenc._id)">Consulter</button>
            <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'comptabilite' && dossenc.source !== 'copie comptabilite' || dossenc.nature === 'déclaration comptable' && dossenc.source !== 'copie comptabilite'" (click)="getadmincollabview('modify-deccomptabilite', dossenc._id)" [ngStyle]="{ 'color': (verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)) ? 'black' : 'black', 'background-color': (verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)) ? 'white' : 'green' }" [disabled]="verifyexercicecloture(dossenc.annee, dossenc.userId,dossenc.mois)">Modifier</button>
            <button type="button" class="btn btn-success" *ngIf="dossenc.type === 'candidature'" (click)="debutcandidature('view-condidate', dossenc._id)">Consulter</button>
            <button type="button" class="btn btn-success" *ngIf="dossenc.type === 'réclamation'" (click)="debutcontact('view-contactreq', dossenc._id)">Consulter</button>
          </td>
          <td>
            <button type="button" class="btn btn-success" *ngIf="dossenc.nature === 'Déclaration Mensuelle' && dossenc.source !== 'copie fiscalite'" (click)="getadmincollabview('modify-decfiscmens', dossenc._id)" target="_blank">Consulter maquette</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <mat-paginator
    [length]="sorteddossencours.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="onPageChangedossencours($event)"
  ></mat-paginator>
</mat-tab>

<mat-tab label="Dossiers ouverts" *ngIf="usedressourcewindow">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Dossiers Ouverts</div>
    <span (click)="click53()"><i class="bi bi-arrow-clockwise"></i></span>
    <span (click)="click54()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
      <button (click)="exportusersAsXLSX(usedressources, 'dossiers ouverts')">
        <i class="fa fa-file" aria-hidden="true"></i> Exporter
      </button>
      <div class="users-grid" style="overflow-x:auto; overflow-y: auto">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Prenom Utilisateur</th>
              <th scope="col">Nom Utilisateur</th>
              <th scope="col">Prenom Client</th>
              <th scope="col">Nom Client</th>
              <th scope="col">Mois</th>
              <th scope="col">Annee</th>
              <th scope="col">Type</th>
              <th scope="col">Date ouverture</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dossouv of paginatedusedressources">
              <td>{{ dossouv.firstname }}</td>
              <td>{{ dossouv.lastname }}</td>
              <td>{{ dossouv.firstnameclient }}</td>
              <td>{{ dossouv.lastnameclient }}</td>
              <td>{{ dossouv.mois }}</td>
              <td>{{ dossouv.annee }}</td>
              <td>{{ dossouv.type }}</td>
              <td>{{ dossouv.created | customDate }}</td>
              <td>
                <button type="button" class="btn btn-primary" (click)="liberateusedressource(dossouv.ressourceid)">
                  <span><i class="fa fa-spinner" *ngIf="submitting"></i></span>Liberer
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="usedressources.length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChangeusedressources($event)"></mat-paginator>
</mat-tab>

<mat-tab label="liste des ds pas encore rêglees"*ngIf="alldssusernonpayewindow&&getalldsusernonpaye()[0]">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">liste des ds pas encore rêglees</div>
    <span (click)="click45()"><i class="bi bi-x-circle-fill"></i></span>
    <p *ngIf="getalldsusernonpaye()[1]>0" style="color: red;" ></p>
  </ng-template>
  <div class="row-container">
  <app-search-bar [globalarrayalldsnonpaye]="getalldsusernonpaye()[0]"[globalarrayusers]="getclients()" [showalldsform]=true (filteredArrayOutput)="handleFilteredArrayalldsnonpaye($event)"></app-search-bar>    
  <app-export-array [globalarray]="getalldsusernonpaye()[0]" style="float: right;"></app-export-array> 
  </div>         
  <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="_id" ></i></th>
          <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="nature"  ></i></th>
          <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="mois"  ></i></th>
          <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="annee" ></i></th>
          <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="lastname" ></i></th>
          <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="statut" ></i></th>
          <th scope="col">Total honoraire HT</th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="created" ></i></th>  
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dsnonpaye of getalldsusernonpaye()[0]; let i = index"> 
          <td>{{dsnonpaye._id}}</td>
          <td><span *ngIf="dsnonpaye.nature">{{dsnonpaye.nature}}</span> <span *ngIf="!dsnonpaye.nature">Autre demande de service</span></td>
          <td><span *ngIf="dsnonpaye.mois">{{dsnonpaye.mois}}</span> </td>
          <td><span *ngIf="dsnonpaye.annee">{{dsnonpaye.annee}}</span> </td>
          <td>
            <span *ngIf="dsnonpaye.nature"appUserDisplay="{{dsnonpaye.userId}}" [displayProperties]="['firstname','raisonsociale']"></span>
            <span *ngIf="!dsnonpaye.nature"appUserDisplay="{{dsnonpaye.clientId}}" [displayProperties]="['firstname','raisonsociale']"></span>
          </td>
          <td>
            <span *ngIf="dsnonpaye.statutadmin">
              <span *ngIf="dsnonpaye.statutadmin.length>0">
                <ng-container *ngFor="let statut of dsnonpaye.statutadmin; let j = index">
                  <td  *ngIf="j==dsnonpaye.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span> 
            </span>           
          </td>
          <td>{{dsnonpaye.totalhonoraire | number:'1.3-3'| removeComma}}</td>
          <td>{{dsnonpaye.created | customDate}}</td>
          <td>
  <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds',dsnonpaye._id)"*ngIf="!dsnonpaye.nature"target="_blank">Consulter</button>
  <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='comptabilite'"target="_blank">Consulter</button>
  <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmenss('view-decfiscmens',dsnonpaye._id)"*ngIf="dsnonpaye.nature==='Déclaration Mensuelle'"target="_blank">Consulter</button>           
  </td>
  <td>
  <button type="button" class="btn btn-primary" (click)="payinvoice(dsnonpaye._id,dsnonpaye.nature)"><span><i class="fa fa-spinner" *ngIf="submitting"></i></span> Marquer paye</button>           
  </td>
        </tr>
        </tbody>
      </table>
  
  </mat-tab>
  
  <mat-tab label="liste des ds rêglees"*ngIf="alldssuserpayewindow&&getalldsuserpaye()[0]">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">liste des ds rêglees</div>
    <span (click)="click46()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
  <div class="team-member" >
    <div class="users-grid" style="overflow-x:auto;"> 
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="_id" ></i></th>
          <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="nature"  ></i></th>
          <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="mois"  ></i></th>
          <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="annee" ></i></th>
          <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="statut" ></i></th>
          <th scope="col">Total honoraire HT</th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="created" ></i></th>  
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dspaye of getalldsuserpaye()[0]; let i = index"> 
          <td>{{dspaye._id}}</td>
          <td><span *ngIf="dspaye.nature">{{dspaye.nature}}</span> <span *ngIf="!dspaye.nature">Autre demande de service</span></td>
          <td><span *ngIf="dspaye.mois">{{dspaye.mois}}</span> </td>
          <td><span *ngIf="dspaye.annee">{{dspaye.annee}}</span> </td>
          <td>
            <span *ngIf="dspaye.nature"appUserDisplay="{{dspaye.userId}}" [displayProperties]="['firstname','raisonsociale']"></span>
            <span *ngIf="!dspaye.nature"appUserDisplay="{{dspaye.clientId}}" [displayProperties]="['firstname','raisonsociale']"></span>
          </td>
          <td>
            <span *ngIf="dspaye.statutadmin">
              <span *ngIf="dspaye.statutadmin.length>0">
                <ng-container *ngFor="let statut of dspaye.statutadmin; let j = index">
                  <td  *ngIf="j==dspaye.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span> 
            </span>           
          </td>
          <td>{{dspaye.totalhonoraire | number:'1.3-3'| removeComma}}</td>
          <td>{{dspaye.created | customDate}}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds',dspaye._id)"*ngIf="!dspaye.nature"target="_blank">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',dspaye._id)"*ngIf="dspaye.nature==='comptabilite'"target="_blank">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmenss('view-decfiscmens',dspaye._id)"*ngIf="dspaye.nature==='Déclaration Mensuelle'"target="_blank">Consulter</button>                   
            <img src="../../assets/free.png" id="logo" alt="image-DGI"style="width:40% ;height:40%;border: none"*ngIf="dspaye.marquepaye">
  
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    
  </div>
  </div>
  </mat-tab>
  <mat-tab label="liste des ds rêglees"*ngIf="alldssusermarquepayewindow&&getalldsusermarquepaye()[0]">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">liste des ds marques payes</div>
    <span (click)="click48()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
  <div class="team-member" >
    <div class="users-grid" style="overflow-x:auto;"> 
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="_id" ></i></th>
          <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="nature"  ></i></th>
          <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="mois"  ></i></th>
          <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="annee" ></i></th>
          <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="statut" ></i></th>
          <th scope="col">Total honoraire HT</th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusermarquepaye()[0]" data-order="desc" data-name="created" ></i></th>  
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dspaye of getalldsusermarquepaye()[0]; let i = index"> 
          <td>{{dspaye._id}}</td>
          <td><span *ngIf="dspaye.nature">{{dspaye.nature}}</span> <span *ngIf="!dspaye.nature">Autre demande de service</span></td>
          <td><span *ngIf="dspaye.mois">{{dspaye.mois}}</span> </td>
          <td><span *ngIf="dspaye.annee">{{dspaye.annee}}</span> </td>
          <td>
            <span *ngIf="dspaye.nature"appUserDisplay="{{dspaye.userId}}" [displayProperties]="['firstname','raisonsociale']"></span>
            <span *ngIf="!dspaye.nature"appUserDisplay="{{dspaye.clientId}}" [displayProperties]="['firstname','raisonsociale']"></span>
          </td>
                    <td>
            <span *ngIf="dspaye.statutadmin">
              <span *ngIf="dspaye.statutadmin.length>0">
                <ng-container *ngFor="let statut of dspaye.statutadmin; let j = index">
                  <td  *ngIf="j==dspaye.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span> 
            </span>           
          </td>
          <td>{{dspaye.totalhonoraire | number:'1.3-3'| removeComma}}</td>
          <td>{{dspaye.created | customDate}}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds',dspaye._id)"*ngIf="!dspaye.nature"target="_blank">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',dspaye._id)"*ngIf="dspaye.nature==='comptabilite'"target="_blank">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmenss('view-decfiscmens',dspaye._id)"*ngIf="dspaye.nature==='Déclaration Mensuelle'"target="_blank">Consulter</button>                   
            <img src="../../assets/free.png" id="logo" alt="image-DGI"style="width:20% ;height:20%;border: none"*ngIf="dspaye.marquepaye">
  
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    
  </div>
  </div>
  </mat-tab>
  <mat-tab label="liste des factures proforma archive"*ngIf="allproformatarchivewindow">
    <ng-template mat-tab-label style="padding: 3px;">
      <div class="col" style="margin-left: 20px;">liste des factures proforma archivées</div>
      <span (click)="closeproformaarchivated()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <app-filter-invoice (filtersSubmitted)="onFiltersSubmittedproinvoices($event)"></app-filter-invoice>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="_id" ></i></th>
        <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
        <th scope="col">Total facture <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
        <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="created" ></i></th>  
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let profinv of allproformatarchivatedinvoice; let i = index"> 
        <td>P{{profinv.prefixe}}/{{profinv.ref}}</td>
        <td appUserDisplay="{{profinv.totalds.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
        <td>{{profinv.totalds.totalgeneral| number:'1.3-3'| removeComma| currency:'TND'}}</td>
        <td>{{profinv.created | customDate}}</td>
        <td>
          <button type="button" class="btn btn-success" (click)="getNavigationproformatinvoice('view-proformatinvoice',profinv._id)"target="_blank">Consulter</button>
        </td>
      </tr>
      </tbody>
    </table>
    </mat-tab>
    <mat-tab label="liste des factures règlees archive"*ngIf="allinvoicearchivewindow">
    <ng-template mat-tab-label style="padding: 3px;">
      <div class="col" style="margin-left: 20px;">liste des factures règlees archivées</div>
      <span (click)="closeinvoicearchivated()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <app-filter-invoice (filtersSubmitted)="onFiltersSubmittedinvoices($event)"></app-filter-invoice>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="_id" ></i></th>
        <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="userId" ></i></th>
        <th scope="col">Total facture <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
        <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="created" ></i></th>  
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let inv of allarchivatedinvoice; let i = index"> 
        <td>F{{inv.prefixe}}/{{inv.ref}}</td>
        <td appUserDisplay="{{inv.totalds.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
        <td>{{inv.totalds.totalgeneral| number:'1.3-3'| removeComma| currency:'TND'}}</td>
        <td>{{inv.created | customDate}}</td>
        <td>
          <button type="button" class="btn btn-success" (click)="getNavigationinvoice('view-invoice',inv._id)"target="_blank">Consulter</button>
        </td>
      </tr>
      </tbody>
    </table>
    </mat-tab>
  <mat-tab label="liste des factures proforma"*ngIf="allproformatwindow&&allproformatinvoice">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">liste des factures proforma</div>
    <span (click)="click49()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
  <div class="team-member" >
    <div class="users-grid" style="overflow-x:auto;"> 
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="_id" ></i></th>
          <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Total facture <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="created" ></i></th>  
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let profinv of allproformatinvoice; let i = index"> 
          <td>P{{profinv.prefixe}}/{{profinv.ref}}</td>
          <td appUserDisplay="{{profinv.totalds.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
          <td>{{profinv.totalds.totalgeneral| number:'1.3-3'| removeComma| currency:'TND'}}</td>
          <td>{{profinv.created | customDate}}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationproformatinvoice('view-proformatinvoice',profinv._id)"target="_blank">Consulter</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
  </mat-tab>
  <mat-tab label="liste des factures règlees"*ngIf="allinvoicewindow&&allinvoice">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">liste des factures règlees</div>
    <span (click)="click50()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
  <div class="team-member" >
    <div class="users-grid" style="overflow-x:auto;"> 
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="_id" ></i></th>
          <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Total facture <i class="fa fa-sort" aria-hidden="true" [appSort]="allproformatinvoice" data-order="desc" data-name="userId" ></i></th>
          <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="created" ></i></th>  
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let inv of allinvoice; let i = index"> 
          <td>F{{inv.prefixe}}/{{inv.ref}}</td>
          <td appUserDisplay="{{inv.totalds.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
          <td>{{inv.totalds.totalgeneral| number:'1.3-3'| removeComma| currency:'TND'}}</td>
          <td>{{inv.created | customDate}}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationinvoice('view-invoice',inv._id)"target="_blank">Consulter</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
  </mat-tab>
  <mat-tab label="module recouvrement" *ngIf="showmodulerecouvrement&&getalldsusernonpaye()[0]">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Liste des clients avec des impayes</div>
    <span (click)="click56()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
    <div class="team-member">
      <hr> 
        <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"[style.top]="inverseOfTranslation" >Client</th>
                  <th scope="col"[style.top]="inverseOfTranslation" >Total impaye</th>
                  <th scope="col"[style.top]="inverseOfTranslation" >Seuil impaye</th> 
                  <th scope="col"[style.top]="inverseOfTranslation" >Tendance</th> 
                  <th scope="col"[style.top]="inverseOfTranslation" >Notifications</th> 
                  <th scope="col"[style.top]="inverseOfTranslation" colspan="2" >Actions</th> 
  
                </tr>
                </thead> 
          <tbody *ngFor="let user of getclients()">
              <tr *ngIf="gettotalimpayebyuser(user._id,60)[1]&&user.role=='basic'||gettotalimpayebyuser(user._id,user.countingdays)[1]&&user.role=='basic'"> 
                <td appUserDisplay="{{user._id}}" [displayProperties]="['firstname','raisonsociale']"></td>                
                <td><span *ngIf="user.countingdays">{{gettotalimpayebyuser(user._id,user.countingdays)[1]| number:'1.3-3'| removeComma}}</span><span *ngIf="!user.countingdays">{{gettotalimpayebyuser(user._id,60)[1]| number:'1.3-3'| removeComma}}</span> </td>
                <td><span *ngIf="user.countingdays">{{gettotalimpayebyuser(user._id,user.countingdays)[2]| number:'1.3-3'| removeComma}}</span><span *ngIf="!user.countingdays">{{gettotalimpayebyuser(user._id,60)[2]| number:'1.3-3'| removeComma}}</span> </td>
                <td style="color: white;"><span *ngIf="user.countingdays" [ngStyle]="{'background-color': (0.2>gettotalimpayebyuser(user._id,user.countingdays)[4])? '#0FD506':
                  (0.4>gettotalimpayebyuser(user._id,user.countingdays)[4])>0.2? '#A7FD8E':
                  (0.6>gettotalimpayebyuser(user._id,user.countingdays)[4])>0.4? '#D5FD8E':
                  (0.8>gettotalimpayebyuser(user._id,user.countingdays)[4])>0.6? '#FAA405':
                  (1>gettotalimpayebyuser(user._id,user.countingdays)[4])>0.8? '#CD5C5C':
                  (1.2>gettotalimpayebyuser(user._id,user.countingdays)[4])>1? '#FB8484':'#FB0606'}">{{gettotalimpayebyuser(user._id,user.countingdays)[4]| percent: '1.0-0'| removeComma}}</span><span *ngIf="!user.countingdays"[ngStyle]="{'background-color': (0.2>gettotalimpayebyuser(user._id,60)[4])? '#0FD506':
                    (0.4>gettotalimpayebyuser(user._id,60)[4])>0.2? '#A7FD8E':
                    (0.6>gettotalimpayebyuser(user._id,60)[4])>0.4? '#D5FD8E':
                    (0.8>gettotalimpayebyuser(user._id,60)[4])>0.6? '#FAA405':
                    (1>gettotalimpayebyuser(user._id,60)[4])>0.8? '#CD5C5C':
                    (1.2>gettotalimpayebyuser(user._id,60)[4])>1? '##FB8484':'#FB0606'}">{{gettotalimpayebyuser(user._id,60)[4]| percent: '1.0-0'| removeComma}}</span> </td>
                    <td (click)="opennotifrecs(user._id)" target="_blank" style="cursor: pointer;text-decoration: underline;">{{getnotifrecbyuser(user._id)[1]}}</td>
                <td><button type="button" *ngIf="user.countingdays"class="btn btn-primary" (click)="opendetails(user._id,user.countingdays)">Consulter Details</button><button type="button" *ngIf="!user.countingdays"class="btn btn-primary" (click)="opendetails(user._id,60)">Consulter Details</button></td>  
  <td><button type="button" class="btn btn-success" *ngIf="user.countingdays" (click)="notifrec(user._id,user.countingdays,user)">Notifier Client</button><button type="button" class="btn btn-success"*ngIf="!user.countingdays" (click)="notifrec(user._id,60,user)">Notifier Client</button></td>  
              </tr>
          </tbody>
        </table>  
    </div>
  </mat-tab>
  <mat-tab label="tous les utilisateurs" *ngIf="showdallusers&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">tous les utilisateurs</div>
    <span (click)="click34()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12">
    <div class="team-member">
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getclients(),'Clients Actifs')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        <div class="searchbar">
          
          <input class="search_input" id="email"  formControlName="email"type="text"  placeholder="Chercher par Email">
         <a class="search_icon"(click)="getusersbyemail()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
          <div class="searchbar">
          
            <input class="search_input" id="lastname"  formControlName="lastname"type="text"  placeholder="Chercher par Nom">
           <a class="search_icon"(click)="getusersbylastname()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          <div class="searchbar">
          
              <input class="search_input" id="firstname"  formControlName="firstname"type="text"  placeholder="Chercher par Prenom">
             <a class="search_icon"(click)="getusersbyfirstname()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
            
          </div><br>
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getclients()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>      
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            <th scope="col">D.comptabilite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="droitcompta" ></i></th>
  
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of getallusers()"> 
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td >{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            
            <td>{{user.role}}</td>
            <td>{{user.droitcompta}}</td>
  
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="utilisateurs connectes" *ngIf="showconnected&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">utilisateurs connectes</div>
    <span (click)="click36()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12">
    <div class="team-member">
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getclients(),'Clients Actifs')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        <div class="searchbar">
          
          <input class="search_input" id="email"  formControlName="email"type="text"  placeholder="Chercher par Email">
         <a class="search_icon"(click)="getusersbyemail()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
          <div class="searchbar">
          
            <input class="search_input" id="lastname"  formControlName="lastname"type="text"  placeholder="Chercher par Nom">
           <a class="search_icon"(click)="getusersbylastname()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          <div class="searchbar">
          
              <input class="search_input" id="firstname"  formControlName="firstname"type="text"  placeholder="Chercher par Prenom">
             <a class="search_icon"(click)="getusersbyfirstname()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
            
          </div><br>
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getclients()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>      
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            <th scope="col">D.comptabilite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="droitcompta" ></i></th>
  
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of getconnectedusers()"> 
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td >{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            
            <td>{{user.role}}</td>
            <td>{{user.droitcompta}}</td>
  
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="Clients actifs" *ngIf="clientactif&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Clients actifs</div>
    <span (click)="click15()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <app-search-bar [globalarrayusers]="getclients()"[showusersform]=true (filteredArrayOutput)="handleFilteredArray($event)"></app-search-bar>    
  <table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
    <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
    <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
    <th scope="col">Raison sociale <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="raisonsociale" ></i></th>
    <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
    <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
    <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
    <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
    <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
    <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>      
    <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
    <th scope="col">D.comptabilite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="droitcompta" ></i></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of getclients()"> 
    <td>{{user.email}}</td>
    <td>{{user.firstname}}</td>
    <td>{{user.lastname}}</td>
    <td>{{user.raisonsociale}}</td>
    <td >{{user.usertype}}</td>
    <td>{{user.mobile}}</td>
    <td>{{user.clientcode}}</td>
    <td>{{user.natureactivite}}</td>
    <td>{{user.activite}}</td>
    <td>{{user.sousactivite}}</td>
    <td>{{user.role}}</td>
    <td>{{user.droitcompta}}</td>
    <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
  </tr>
  </tbody>
  </table>
  </mat-tab>
  <mat-tab label="Clients bloques" *ngIf="clientbloque&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Clients bloques</div>
    <span (click)="click16()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <button (click)="exportusersAsXLSX(getclientsbloqued(),'Clients bloques')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <h2>Liste des Clients bloques</h2>
      <hr>
          
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>
            
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            
          </tr>
          </thead>
          <tbody *ngIf="users">
          <tr *ngFor="let user of getclientsbloqued()">
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td >{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            
            <td>{{user.role}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="Clients supprimes temporairement" *ngIf="clientsupptemporairement&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Clients supprimes temporairement</div>
    <span (click)="click17()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <button (click)="exportusersAsXLSX(usersdeleted(),'Clients supprimes temporairement')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <h2>Liste des Clients supprimes temporairement</h2>
      <hr>
      <div class="container-fluid">
       
      <form [formGroup]="searchForm">
       
        
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldeleted()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>  
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="sousactivite" ></i></th>
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="usersdeleted" data-order="desc" data-name="role" ></i></th>
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let userdeleted of usersdeleted">
      
            
            <td>{{userdeleted.email}}</td>
            <td>{{userdeleted.firstname}}</td>
            
      
            <td>{{userdeleted.lastname}}</td>
            <td >{{userdeleted.usertype}}</td>
            <td>{{userdeleted.mobile}}</td>
            <td>{{userdeleted.clientcode}}</td>
            <td>{{userdeleted.natureactivite}}</td>
            <td>{{userdeleted.activite}}</td>
            <td>{{userdeleted.sousactivite}}</td>
            <td>{{userdeleted.role}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user-deleted',userdeleted._id)"target="_blank">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="Collaborateurs" *ngIf="collaborateurs&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Collaborateurs</div>
    <span (click)="click18()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <button (click)="exportusersAsXLSX(getcollaborateurs(),'Collaborateurs')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <h2>Liste des Collaborateurs</h2>
      <hr>
            
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            <th scope="col">rolesuperviseur <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="rolesuperviseur" ></i></th>
  
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of getcollaborateurs()">
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td>{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            <td>{{user.role}}</td>
            <td>{{user.rolesuperviseur}}</td>
  
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="Consultants" *ngIf="consultants&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Consultants</div>
    <span (click)="click19()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <button (click)="exportusersAsXLSX(getconsultants(),'Consultants')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <h2>Liste des Consultants</h2>
      <hr>
            
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of getconsultants()">
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td >{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            <td>{{user.role}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="Candidats" *ngIf="candidat&&users">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Candidats</div>
    <span (click)="click20()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <button (click)="exportusersAsXLSX(getcondidates(),'Candidats')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <h2>Liste des Candidats</h2>
      <hr>
              
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th scope="col">Email <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="email" ></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="firstname"  ></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="lastname" ></i></th>
            <th scope="col">Type <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="usertype" ></i></th>
            <th scope="col">Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Clientcode <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="clientcode" ></i></th>
            <th scope="col">Nature-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="natureactivite"></i></th>
            <th scope="col">Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="activite" ></i></th>
            <th scope="col">Sous-Activite <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="sousactivite" ></i></th>
            <th scope="col">Role <i class="fa fa-sort" aria-hidden="true" [appSort]="users" data-order="desc" data-name="role" ></i></th>
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of getcondidates()">
      
            
            <td>{{user.email}}</td>
            <td>{{user.firstname}}</td>
            
      
            <td>{{user.lastname}}</td>
            <td >{{user.usertype}}</td>
            <td>{{user.mobile}}</td>
            <td>{{user.clientcode}}</td>
            <td>{{user.natureactivite}}</td>
            <td>{{user.activite}}</td>
            <td>{{user.sousactivite}}</td>
            <td>{{user.role}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationusers('view-user',user._id)"target="_blank">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="déclaration fiscales valide" *ngIf="decfiscmensvalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">déclaration fiscales valide</div>
    <span (click)="click21()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdecfiscmenssvalide(),'Declarations Clôtures')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
        <form [formGroup]="searchForm">
          <div class="searchbar" style="float: right;">
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldecfiscmenss()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="_id" ></i></th>
            <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="nature"  ></i></th>
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="userId"  ></i></th>
            <th scope="col">Origine</th>
            <th scope="col">Source</th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="created" ></i></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let decfiscmens of getdecfiscmenssvalide()"> 
            <td>{{decfiscmens._id}}</td>
            <td>{{decfiscmens.nature}}</td>
            <td>{{decfiscmens.mois}}</td>
            <td>{{decfiscmens.annee}}</td>
            <td appUserDisplay="{{decfiscmens.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td>{{decfiscmens.origine}}</td>
            <td><span *ngIf="decfiscmens.source">{{decfiscmens.source}}</span> </td>
            <td>
              <span *ngIf="decfiscmens.statutadmin">
                <ng-container *ngFor="let statut of decfiscmens.statutadmin; let j=index">
                  <td  *ngIf="j==decfiscmens.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="decfiscmens.statutcollab">
                <ng-container *ngFor="let statut of decfiscmens.statutcollab; let k=index">
                  <td *ngIf="k==decfiscmens.statutcollab.length-1">{{statut.statutcoll}}</td>
                </ng-container>
              </span>     
            </td>  
            <td>{{decfiscmens.created | customDate}}</td>
            
            
            <td>  <button type="button" class="btn btn-success"(click)="getNavigationdecfiscmenss('view-decfiscmens',decfiscmens._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="dec fiscale En cours execution" *ngIf="decfiscmensnonvalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">dec fiscale En cours execution</div>
    <span (click)="click22()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdecfiscmenssnonvalide(),'Declarations en cours execution')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        
          
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldecfiscmenss()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            
            <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="_id" ></i></th>
            <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="nature"  ></i></th>
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="userId" ></i></th>
            <th scope="col">Origine</th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="created" ></i></th>
            <th scope="col">Date de modification <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="updated" ></i></th>
            <th scope="col">Affecte à<i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="affectation" ></i></th>
  
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let decfiscmens of getdecfiscmenssnonvalide()">
      
            
            <td>{{decfiscmens._id}}</td>
            <td>{{decfiscmens.nature}}</td>
            <td>{{decfiscmens.mois}}</td>
            <td>{{decfiscmens.annee}}</td>
            <td appUserDisplay="{{decfiscmens.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td>{{decfiscmens.origine}}</td>
            <td>
              <span *ngIf="decfiscmens.statutadmin">
                <ng-container *ngFor="let statut of decfiscmens.statutadmin; let j=index">
                  <td  *ngIf="j==decfiscmens.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="decfiscmens.statutcollab">
                <ng-container *ngFor="let statut of decfiscmens.statutcollab; let k=index">
                  <td *ngIf="k==decfiscmens.statutcollab.length-1">{{statut.statutcoll}}</td>
                </ng-container>
              </span>     
            </td>  
            <td>{{decfiscmens.created | customDate}}</td>
            <td>{{decfiscmens.updated}}</td>
            <td appUserDisplay="{{decfiscmens.affecte}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <!--<td *ngIf="users">
              <select #cricket (change)="update($event)"id="collabfirstname"formControlName="collabfirstname" name="collabfirstname">
                <option value="default"> </option>
                <option *ngFor="let collaborateur of getcollaborateurs()" [value]="collaborateur">
                    {{collaborateur.firstname}}
                </option>
            </select>
            </td>-->
            
            <td>  <button type="button" class="btn btn-success"*ngIf="decfiscmens.origine!='généré automatiquement'" 
              (click)="getNavigationdecfiscmenss('view-decfiscmens',decfiscmens._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="déclaration fiscales valide copies" *ngIf="decfiscmensvalidecopie">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">déclaration fiscales valide copie</div>
    <span (click)="click39()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdecfiscmenssvalide(),'Declarations Clôtures')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
        <form [formGroup]="searchForm">
          <div class="searchbar" style="float: right;">
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldecfiscmenss()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="_id" ></i></th>
            <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="nature"  ></i></th>
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="userId" ></i></th>
            <th scope="col">Origine</th>
            <th scope="col">Source</th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="created" ></i></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let decfiscmens of getdecfiscmenssvalidecopie()"> 
            <td>{{decfiscmens._id}}</td>
            <td>{{decfiscmens.nature}}</td>
            <td>{{decfiscmens.mois}}</td>
            <td>{{decfiscmens.annee}}</td>
            <td appUserDisplay="{{decfiscmens.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td>{{decfiscmens.origine}}</td>
            <td><span *ngIf="decfiscmens.source">{{decfiscmens.source}}</span> </td>
            <td>
              <span *ngIf="decfiscmens.statutadmin">
                <ng-container *ngFor="let statut of decfiscmens.statutadmin; let j=index">
                  <td  *ngIf="j==decfiscmens.statutadmin.length-1"style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="decfiscmens.statutcollab">
                <ng-container *ngFor="let statut of decfiscmens.statutcollab; let k=index">
                  <td *ngIf="k==decfiscmens.statutcollab.length-1">{{statut.statutcoll}}</td>
                </ng-container>
              </span>     
            </td>  
            <td>{{decfiscmens.created | customDate}}</td>
            
            
            <td>  <button type="button" class="btn btn-success"(click)="getNavigationdecfiscmenss('view-decfiscmens',decfiscmens._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="déclaration comptables valide" *ngIf="deccomptabilitevalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">comptabilites valide</div>
    <span (click)="click23()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading&&users">
    <div class="team-member">
      <h2>Liste des fichiers comptables</h2>
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdeccomptabilitesvalide(),'Fichiers pas encore affectes')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        
          
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldeccomptabilites()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            
            
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="userId" ></i></th>
            <th scope="col">Source</th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="created" ></i></th>
           
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let deccomptabilite of getdeccomptabilitesvalide()">
      
            
           
            <td>{{deccomptabilite.mois}}</td>
            <td>{{deccomptabilite.annee}}</td>
            <td appUserDisplay="{{deccomptabilite.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td > <span *ngIf="deccomptabilite.source">{{deccomptabilite.source}}</span></td>
           
            <td>
              <span *ngIf="deccomptabilite.statutadmin">
                <ng-container *ngFor="let statut of deccomptabilite.statutadmin; let j=index">
                  <td *ngIf="j==deccomptabilite.statutadmin.length-1" style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="deccomptabilite.statutcollab">
                <ng-container *ngFor="let statut of deccomptabilite.statutcollab; let k=index">
                  <td *ngIf="k==deccomptabilite.statutcollab.length-1" style="display: block;">{{statut.statutcoll}}</td>        
                </ng-container>
              </span>     
            </td>
            <td>{{deccomptabilite.created | customDate}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',deccomptabilite._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="deccomptabilite En cours execution" *ngIf="deccomptabilitenonvalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">deccomptabilite En cours execution</div>
    <span (click)="click24()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading&&users">
    <div class="team-member">
      <h2>Liste des fichiers comptables</h2>
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdeccomptabilitesnonvalide(),'Fichiers en cours execution')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        
          
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldeccomptabilites()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            
            
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="userId" ></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="created" ></i></th>
           
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let deccomptabilite of getdeccomptabilitesnonvalide()">
      
            
           
            <td>{{deccomptabilite.mois}}</td>
            <td>{{deccomptabilite.annee}}</td>
            <td appUserDisplay="{{deccomptabilite.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td>
              <span *ngIf="deccomptabilite.statutadmin">
                <ng-container *ngFor="let statut of deccomptabilite.statutadmin; let j=index">
                  <td *ngIf="j==deccomptabilite.statutadmin.length-1" style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="deccomptabilite.statutcollab">
                <ng-container *ngFor="let statut of deccomptabilite.statutcollab; let k=index">
                  <td *ngIf="k==deccomptabilite.statutcollab.length-1" style="display: block;">{{statut.statutcoll}}</td>        
                </ng-container>
              </span>     
            </td>
            <td>{{deccomptabilite.created | customDate}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',deccomptabilite._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="déclaration comptables valide copie" *ngIf="deccomptabilitevalidecopie">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">comptabilites valide copies</div>
    <span (click)="click41()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading&&users">
    <div class="team-member">
      <h2>Liste des fichiers comptables</h2>
      <hr>
      <div class="container-fluid">
        <button (click)="exportusersAsXLSX(getdeccomptabilitesvalide(),'Fichiers pas encore affectes')"><i class="fa fa-file" aria-hidden="true"></i> Exporter</button> 
      <form [formGroup]="searchForm">
       
        
          
          <div class="searchbar" style="float: right;">
            
            <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout" disabled>
           <a class="search_icon"(click)="getalldeccomptabilites()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
          </form> 
        </div>     
      <div class="users-grid"  style="overflow-x:auto;overflow-y: auto">
  
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            
            
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="mois"  ></i></th>
            <th scope="col">Annee <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="annee" ></i></th>
            <th scope="col">Client <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="userId" ></i></th>
            <th scope="col">Source</th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th scope="col">Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="created" ></i></th>
           
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let deccomptabilite of getdeccomptabilitesvalidecopie()">
      
            
           
            <td>{{deccomptabilite.mois}}</td>
            <td>{{deccomptabilite.annee}}</td>
            <td appUserDisplay="{{deccomptabilite.userId}}" [displayProperties]="['firstname','raisonsociale']"></td>
            <td > <span *ngIf="deccomptabilite.source">{{deccomptabilite.source}}</span></td>
            <td>
              <span *ngIf="deccomptabilite.statutadmin">
                <ng-container *ngFor="let statut of deccomptabilite.statutadmin; let j=index">
                  <td *ngIf="j==deccomptabilite.statutadmin.length-1" style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="deccomptabilite.statutcollab">
                <ng-container *ngFor="let statut of deccomptabilite.statutcollab; let k=index">
                  <td *ngIf="k==deccomptabilite.statutcollab.length-1" style="display: block;">{{statut.statutcoll}}</td>        
                </ng-container>
              </span>     
            </td>
            <td>{{deccomptabilite.created | customDate}}</td>
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationdeccomptabilites('view-deccomptabilite',deccomptabilite._id)">Consulter</button></td>
            
              
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="candidature valide" *ngIf="candidaturevalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">Candidatures valides</div>
    <span (click)="click25()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <h2>Liste des Candidatures</h2>
      <hr>
              
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto">
        <form [formGroup]="searchForm">
       
          <div class="searchbar">
            
            <input class="search_input" id="email"  formControlName="email"type="text"  placeholder="Chercher par Email">
           <a class="search_icon"(click)="getcondidatesbyemail()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
        <div class="searchbar" style="float: right;">
            
          <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout">
         <a class="search_icon"(click)="getcondidatesall()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
            
            </form> 
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="email" ></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="firstname" ></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="lastname" ></i></th>
            <th>Adresse <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="adresse" ></i></th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th>CV</th>
            <th>Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="created" ></i></th>
  
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let condidate of getcondidatevalide()">
      
            
            <td>{{condidate.email}}</td>
            <td>{{condidate.firstname}}</td>
            
      
            <td>{{condidate.lastname}}</td>
            <td>{{condidate.adresse}}</td>
            <td>{{condidate.mobile}}</td>
            <td>
              <span *ngIf="condidate.statutadmin">
                <ng-container *ngFor="let statut of condidate.statutadmin">
                  <td  style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="condidate.statutcollab">
                <ng-container *ngFor="let statut of condidate.statutcollab">
                  {{statut.statutcoll}}
                </ng-container>
              </span>     
            </td>                      
            <td><a href="{{condidate.ficheUrl}}" target="_blank">Aperçu CV</a></td>
            <td>{{condidate.created | customDate}}</td>
  
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationcondidates('view-condidate',condidate._id)">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="candidature En cours execution"*ngIf="candidaturenonvalide">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">candidature En cours execution</div>
    <span (click)="click26()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <h2>Liste des Candidatures</h2>
      <hr>
              
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto">
        <form [formGroup]="searchForm">
       
          <div class="searchbar">
            
            <input class="search_input" id="email"  formControlName="email"type="text"  placeholder="Chercher par Email">
           <a class="search_icon"(click)="getcondidatesbyemail()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
        <div class="searchbar" style="float: right;">
            
          <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout">
         <a class="search_icon"(click)="getcondidatesall()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
            
            </form> 
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="email" ></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="firstname" ></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="lastname" ></i></th>
            <th>Adresse <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="adresse" ></i></th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="mobile" ></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th>CV</th>
            <th>Date de creation <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="created" ></i></th>
  
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let condidate of getcondidatenonvalide()">
      
            
            <td>{{condidate.email}}</td>
            <td>{{condidate.firstname}}</td>
            
      
            <td>{{condidate.lastname}}</td>
            <td>{{condidate.adresse}}</td>
            <td>{{condidate.mobile}}</td>
            <td>
              <span *ngIf="condidate.statutadmin">
                <ng-container *ngFor="let statut of condidate.statutadmin">
                  <td  style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="condidate.statutcollab">
                <ng-container *ngFor="let statut of condidate.statutcollab">
                  {{statut.statutcoll}}
                </ng-container>
              </span>     
            </td>                      
            <td><a href="{{condidate.ficheUrl}}" target="_blank">Aperçu CV</a></td>
            <td>{{condidate.created | customDate}}</td>
  
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationcondidates('view-condidate',condidate._id)">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="reclamation traite" *ngIf="reclamationtraite">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">reclamations traite</div>
    <span (click)="click27()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <h2>Liste des reclamations</h2>
      <hr>
              
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto;height: 400px;">
        <form [formGroup]="searchForm">
       <div class="dategroup">
        <label for="date"> reclamations Inferieur à:</label>
          <div class="searchbar" title="chercher les reclamations avec la date de creation inferieur à">
            
            <input class="search_input" id="date"  formControlName="date"type="date"  placeholder="inferieur à:">
           <a class="search_icon"(click)="getcontactreqsbydateinf()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
      </div>
      <div class="dategroup">
        <label for="date"> reclamations Superieur à:</label>
        <div class="searchbar"title="chercher les reclamations avec la date de creation superieur à">
            
          <input class="search_input" id="date"  formControlName="date"type="date"  placeholder="superieur à:">
         <a class="search_icon"(click)="getcontactreqsbydatesup()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
    </div>
        <div class="searchbar" style="float: right;">
            
          <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout">
         <a class="search_icon"(click)="getcontactsall()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
            
            </form> 
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            <th>Date Creation <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="created" ></i></th>
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="email" ></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="firstname" ></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="lastname" ></i></th>
            <th>Fichier Joint</th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="mobile" ></i></th>
            <th>Description <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="description" ></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contact of getcontactvalide()">
      
            <td>{{contact.created | customDate}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.firstname}}</td>
            
      
            <td>{{contact.lastname}}</td>
            <td><a href="{{contact.ficheUrl}}" target="_blank">Aperçu Fichier</a></td>
            <td>{{contact.mobile}}</td>
            <td>{{contact.description}}</td>
            <td>
              <span *ngIf="contact.statutadmin">
                <ng-container *ngFor="let statut of contact.statutadmin">
                  <td  style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="contact.statutcollab">
                <ng-container *ngFor="let statut of contact.statutcollab">
                  {{statut.statutcoll}}
                </ng-container>
              </span>     
            </td>
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationcontacts('view-contactreq',contact._id)">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
  <mat-tab label="reclamation En cours execution" *ngIf="reclamationnontraite">
  <ng-template mat-tab-label style="padding: 3px;">
    <div class="col" style="margin-left: 20px;">reclamation En cours execution</div>
    <span (click)="click28()"><i class="bi bi-x-circle-fill"></i></span>
  </ng-template>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="team-member">
      <h2>Liste des reclamations</h2>
      <hr>
              
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto;height: 400px;">
        <form [formGroup]="searchForm">
       <div class="dategroup">
        <label for="date"> reclamations Inferieur à:</label>
          <div class="searchbar" title="chercher les reclamations avec la date de creation inferieur à">
            
            <input class="search_input" id="date"  formControlName="date"type="date"  placeholder="inferieur à:">
           <a class="search_icon"(click)="getcontactreqsbydateinf()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
          
        </div>
      </div>
      <div class="dategroup">
        <label for="date"> reclamations Superieur à:</label>
        <div class="searchbar"title="chercher les reclamations avec la date de creation superieur à">
            
          <input class="search_input" id="date"  formControlName="date"type="date"  placeholder="superieur à:">
         <a class="search_icon"(click)="getcontactreqsbydatesup()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
    </div>
        <div class="searchbar" style="float: right;">
            
          <input class="search_input" type="text"  placeholder="Cliquer pour chercher tout">
         <a class="search_icon"(click)="getcontactsall()" style="cursor: pointer;"> <i class="fas fa-search"></i></a>
        
      </div>
            
            </form> 
        
        
        <table class="table table-striped">
          <thead>
          <tr>
      
            <th>Date Creation <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="created" ></i></th>
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="email" ></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="firstname" ></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="lastname" ></i></th>
            <th>Fichier Joint</th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="mobile" ></i></th>
            <th>Description <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="description" ></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>                      
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contact of getcontactnonvalide()">
      
            <td>{{contact.created | customDate}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.firstname}}</td>
            
      
            <td>{{contact.lastname}}</td>
            <td><a href="{{contact.ficheUrl}}" target="_blank">Aperçu Fichier</a></td>
            <td>{{contact.mobile}}</td>
            <td>{{contact.description}}</td>
            <td>
              <span *ngIf="contact.statutadmin">
                <ng-container *ngFor="let statut of contact.statutadmin">
                  <td  style="display: block;">{{statut.statut}}</td>
                </ng-container>
              </span>  
            </td>
            <td>
              <span *ngIf="contact.statutcollab">
                <ng-container *ngFor="let statut of contact.statutcollab">
                  {{statut.statutcoll}}
                </ng-container>
              </span>     
            </td>                      
            
            
            <td>  <button type="button" class="btn btn-success" (click)="getNavigationcontacts('view-contactreq',contact._id)">Consulter</button></td>
            
          
          </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
  </mat-tab>
         
  </mat-tab-group>

</body>
  