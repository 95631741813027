import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatTabsModule} from '@angular/material/tabs';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { ModalModule } from 'ngx-bootstrap/modal';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import {CustomDatePipe} from './_helpers/custom.datepipe'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { WhoComponent } from './who/who.component';
import { WhyComponent } from './why/why.component';
import { ContactComponent } from './home/contact/contact.component';
import { ProfilComponent } from './profil/profil.component';
import { AdminBoardComponent } from './admin-board/admin-board.component';
import { UserBoardComponent } from './user-board/user-board.component';
import { SupervisorBoardComponent } from './supervisor-board/supervisor-board.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { ModifyUserAdminComponent } from './modify-user-admin/modify-user-admin.component';

import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CareerComponent } from './career/career.component';
import { ComingSoonPageComponent } from './coming-soon-page/coming-soon-page.component';
import { ViewCondidateComponent } from './view-condidate/view-condidate.component';
import { ModifyCondidateComponent } from './modify-condidate/modify-condidate.component';
import { DeclareFiscalityComponent } from './declare-fiscality/declare-fiscality.component';
import { AlertComponent } from './alert/alert.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DueDateTimelineComponent } from './due-date-timeline/due-date-timeline.component';
import { ViewContactreqComponent } from './view-contactreq/view-contactreq.component';
import { ModifyContactreqComponent } from './modify-contactreq/modify-contactreq.component';
import { CompleteProfilComponent } from './complete-profil/complete-profil.component';
import { CalendarFiscalityComponent } from './calendar-fiscality/calendar-fiscality.component';
import { CollabUserCreateComponent } from './collab-user-create/collab-user-create.component';
import { SortDirective } from './directive/sort.directive';
import { TwoDigitDecimaNumberDirective } from './directive/TwoDigitDecimaNumber.directive';
import { FourDigitDecimaNumberDirective } from './directive/fourdigits.directive';
import { deuxDigitDecimaNumberDirective } from './directive/deuxdigits.directive';

import { RemoveCommaPipe } from './pipes/pipe-number';

import { AddEventComponent } from './calendar-fiscality/add-event/add-event.component';
import { ViewEventComponent } from './view-event/view-event.component';
import { ModifyEventComponent } from './modify-event/modify-event.component';
import { SettingsComponent } from './settings/settings.component';
import { HttpErrorInterceptor } from './interceptors/error-interceptor';
import { ExcelService } from './services/excel.service';
import { DeclareComptabiliteComponent } from './declare-comptabilite/declare-comptabilite.component';
import { ViewUserDeletedComponent } from './view-user-deleted/view-user-deleted.component';
import { ViewDecFiscMensComponent } from './view-dec-fisc-mens/view-dec-fisc-mens.component';
import { ModifyDecFiscMensComponent } from './modify-dec-fisc-mens/modify-dec-fisc-mens.component';
import { CanDeactivateGuard } from './services/auth-guard.service';
import { CreateReportComponent } from './create-report/create-report.component';
import { ViewCarouselComponent } from './view-carousel/view-carousel.component';
import { ModifyCarouselComponent } from './modify-carousel/modify-carousel.component';
import { ModifyDeccomptabiliteComponent } from './modify-deccomptabilite/modify-deccomptabilite.component';
import { ViewDeccomptabiliteComponent } from './view-deccomptabilite/view-deccomptabilite.component';
import { CollabBoardComponent } from './collab-board/collab-board.component';
import { AddOtherdsComponent } from './add-otherds/add-otherds.component';
import { ViewAutredsComponent } from './view-autreds/view-autreds.component';
import { ViewProformatinvoiceComponent } from './view-proformatinvoice/view-proformatinvoice.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RecNotListComponent } from './rec-not-list/rec-not-list.component';
import { ExportArrayComponent } from './export-array/export-array.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component'; // Import the routing module
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { ConfigReleveBanqueComponent } from './config-releve-banque/config-releve-banque.component';
import { FilterInvoiceComponent } from './filter-invoice/filter-invoice.component'; // Adjust the path as necessary

//ang features
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// Angular Material modules
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
//directives
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { DosspasencorevalideComponent } from './dosspasencorevalide/dosspasencorevalide.component';
import { UsedressourceComponent } from './usedressource/usedressource.component';
import { DosspasencoreaffecteComponent } from './dosspasencoreaffecte/dosspasencoreaffecte.component';
import { DossencoursexecutionComponent } from './dossencoursexecution/dossencoursexecution.component';
import { ChoixDeclarationComponent } from './choix-declaration/choix-declaration.component'
import { UppercaseDirective } from './directive/uppercase.directive'

//Modules
import { DescFiscMensDirectModuleModule } from './desc-fisc-mens-direct-module.module';
import { FacturationModule } from './facturation/facturation.module';
import { DecEmployeurModule } from './dec-employeur/dec-employeur.module';
import { SharedmoduleModule } from './sharedmodule/sharedmodule.module';

@NgModule({
  declarations: [
    AppComponent,
    
    
    
    HeaderComponent,
   
    LoginComponent,
    SignupComponent,
   
    FooterComponent,
    HomeComponent,
    WhoComponent,
    WhyComponent,
    ContactComponent,
    ProfilComponent,
    AdminBoardComponent,
    UserBoardComponent,
    SupervisorBoardComponent,
    ModifyUserComponent,
    ViewUserComponent,
    ModifyUserAdminComponent,
    CustomDatePipe,
    RemoveCommaPipe,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CareerComponent,
    ComingSoonPageComponent,
    ViewCondidateComponent,
    ModifyCondidateComponent,
    DeclareFiscalityComponent,
    AlertComponent,
    SearchBarComponent,
    DueDateTimelineComponent,
    ViewContactreqComponent,
    ModifyContactreqComponent,
    CompleteProfilComponent,
    CalendarFiscalityComponent,
    CollabUserCreateComponent,
    SortDirective,
    TwoDigitDecimaNumberDirective,
    FourDigitDecimaNumberDirective,
    deuxDigitDecimaNumberDirective,

    AddEventComponent,
    ViewEventComponent,
    ModifyEventComponent,
    SettingsComponent,
    DeclareComptabiliteComponent,
    ViewUserDeletedComponent,
    ViewDecFiscMensComponent,
    ModifyDecFiscMensComponent,
    CreateReportComponent,
    ViewCarouselComponent,
    ModifyCarouselComponent,
    ModifyDeccomptabiliteComponent,
    ViewDeccomptabiliteComponent,
    CollabBoardComponent,
    AddOtherdsComponent,
    ViewAutredsComponent,
    ViewProformatinvoiceComponent,
    ViewInvoiceComponent,
    RecNotListComponent,
    ExportArrayComponent,
    ClickOutsideDirective,
    DosspasencorevalideComponent,
    UsedressourceComponent,
    DosspasencoreaffecteComponent,
    DossencoursexecutionComponent,
    ChoixDeclarationComponent,
    InvoiceListComponent,
    InvoiceDetailsComponent,
    NumberToWordsPipe,
    ConfigReleveBanqueComponent,
    UppercaseDirective,
    FilterInvoiceComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatTabsModule,
    CarouselModule,
    ScrollingModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatExpansionModule,MatSnackBarModule,
    MatSlideToggleModule,MatCheckboxModule,MatDatepickerModule,MatFormFieldModule,MatNativeDateModule,MatTabsModule,MatGridListModule,MatSidenavModule,MatListModule,MatPaginatorModule,
    DescFiscMensDirectModuleModule,
    FacturationModule,
    DecEmployeurModule,
    PdfViewerModule,
    MatDialogModule,
    SharedmoduleModule, // Import the shared module

    ModalModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
    
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },ExcelService,CanDeactivateGuard,RemoveCommaPipe,BnNgIdleService],
  bootstrap: [AppComponent]

})
export class AppModule { }
